@import 'partials/variables';

.nav {
  display: flex;
  @include r('width', 368px, 276px, 245px, 184px, 100%);
  cursor: pointer;

  .text {
    @include r('font-size', initial, initial, initial, 0px, initial);
  }

  .headline {
    font-weight: 700;
    @include r('text-transform', initial, initial, initial, capitalize, initial);
  }

  > .toggledrop {
    flex: 1;
    position: relative;
    display: flex;
    outline: 0;
    border: 0;
    background-color: $background-grey-light-color;
    margin-bottom: 6px;

    background-position: right 15px center;
    background-repeat: no-repeat;
    background-image: url('../images/ui/nav-arrow-down.svg');

    @include t;

    > .brand {
      @include r('width', 90px, 90px, 90px, 60px, 90px);
      @include r('padding', 0, 0, 0, 0 8px 0 0, 0);
      height: 90px;
      display: flex;
      align-items: center;
      pointer-events: auto;

      width: auto;
      flex: 5;
      justify-content: flex-end;
      padding: 16px 13px 16px 20px;
      margin-right: 12px;

      img {
        @include r('width', 151px, 151px, 129px, 93px, 151px);
        height: auto;
      }

      @media (max-width: 1200px) and (min-width: 801px) {
        margin-right: 0;
        padding: 15px 8px 15px 15px;
        flex: 4;

        img {
          width: 100%;
        }
      }
    }

    > .title {
      display: flex;
      height: 90px;
      align-items: center;
      font-size: 20px;
      color: $grey-50;

      flex: 3;

      @media (max-width: 1200px) and (min-width: 801px) {
        font-size: 16px;
        flex: 5;
        padding-bottom: 4px;
      }

    }

    &[data-state=closed] ul {
      pointer-events: none;
    }

    &[data-state=open] {
      background-color: $grey-dark;
      background-image: url('../images/ui/nav-arrow-up-light.svg');
      @include color-svg(white);

      > .title {
        color: white;
      }

      &.home {
        background-image: url('../images/ui/nav-arrow-right-light.svg');
      }
    }

    ul {
      pointer-events: auto;
      position: absolute;
      z-index: 100;
      margin-top: 90px;
      width: 100%;

      li {
        .nav-link {
          display: flex;
          align-items: center;
          height: 60px;
          font-size: 16px;
          padding: 0 15px;
          background-color: hsl(0, 0%, 96%);
          color: $grey-50;
          margin: 1px 0 0 0;
          box-shadow: 0 -1px 0 #e7e7e7;
          transition: ease-in color 0.2s,
          ease-in background-color 0.2s,
          ease-in background-image 0.2s;

          &.active {
            background: $grey-90;
            color: $primary;
            pointer-events: none;
          }

          &:active, &:focus, &:hover {
            background-image: url('../images/ui/nav-arrow-right-light.svg');
            background-position: right 15px center;
            background-repeat: no-repeat;
            background-color: $grey-50;
            color: white;
          }
        }
      }
    }
  }
}
