.heading.tabbed {
  div {
    display: flex;
    @include flex-center;
    flex: 1;
    font-size: 16px;
    margin: 0 1px;
    @include all-caps;

    &:not(.active) {
      color: $grey-75;
      cursor: pointer;
    }

    &.active {
      color: $grey-50;
      cursor: default;
      background-image: linear-gradient(hsl(0, 0%, 100%), hsl(0, 0%, 95%));
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }
}

.messages-nav, .timeframe-filter-nav {
  @include r('width', 368px, 276px, 245px, 276px, 368px);

  ul, li {
    list-style: none;
  }

  > li a {
    height: 90px;
    font-size: 20px;
    @media (max-height: 969px) {
      height: 75px;
      font-size: 19px;
    }
    @media (max-height: 875px) {
      height: 60px;
      font-size: 18px;
    }
    @media (max-height: 775px) {
      height: 45px;
      font-size: 16px;
    }
    display: flex;
    flex: 1;
    @include flex-center;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    color: hsl(0, 0%, 70%);

    border: 1px solid $grey-85;
    border-top: 0;

    &:hover {
      color: $primary;
      background-color: hsl(0, 0%, 97%);
      @include t;
      cursor: pointer;
    }

    &.active.current {
      background-color: hsl(0, 0%, 94%);
      color: $grey-50;
    }
  }

  > li:first-child a {
    border: 0;
    box-shadow: 0 1px 0 $grey-85;
    margin-bottom: 1px;
    background-image:
      linear-gradient($transparent, $grey-85),
      linear-gradient($transparent, $grey-85);
    background-size: 1px 100%, 1px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;

    &:hover {
      background-color: $transparent;
      background-image:
        linear-gradient($transparent, $grey-85),
        linear-gradient($transparent, $grey-85),
        linear-gradient($transparent, hsl(0, 0%, 97%));
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0;
    }

    &.active.current {
      background-color: $transparent;
      background-image:
        linear-gradient($transparent, $grey-85),
        linear-gradient($transparent, $grey-85),
        linear-gradient($transparent, hsl(0, 0%, 94%));
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0;
    }
  }

  > li:last-child a {
    border: 0;
    box-shadow: 0 -1px 0 $grey-85;
    background-image:
      linear-gradient($grey-85, $transparent),
      linear-gradient($grey-85, $transparent);
    background-size: 1px 100%, 1px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;

    &:hover {
      background-color: $transparent;
      background-image:
        linear-gradient($grey-85, $transparent),
        linear-gradient($grey-85, $transparent),
        linear-gradient(hsl(0, 0%, 97%), $transparent);
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0;
    }

    &.active.current {
      background-color: $transparent;
      background-image:
        linear-gradient($grey-85, $transparent),
        linear-gradient($grey-85, $transparent),
        linear-gradient(hsl(0, 0%, 94%), $transparent);
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0;
    }
  }

}


.message {

  .message {
    line-height: 44px;
    flex: 1;
    cursor: text;
    @include ellip;

    > span {
      padding-left: 10px;
    }
  }

  .title .name {
    label {
      color: $orange;
      padding: 0 10px 0 0;
    }
  }

  &.deleted {
    .title .name {
      label {
        color: $red;
      }
    }
  }

  .status {
    padding-right: 10px;
    font-style: italic;
    color: $grey-75;
  }

  &.cannot-edit {
    * {
      cursor: wait !important;
    }

    .sort-handle {
      position: relative;
    }

    @include spinner(
      absolute, // $position
      8px, // $topValue
      'right', // $side
      8px, // $sideValue
      28px, // $diameter
      2px solid $orange, // $borderOn
      2px solid rgba($orange, 0.25) // $borderOff
    );
  }

  &.past {
    .name span { display: initial !important; }
  }

  .tabular-regions {
    display: flex;

    div {
      line-height: normal;
    }

    > div:not(:first-child) {
      border-left: $item_row_border;
    }

    .tab-heading {
      display: flex;
      @include flex-center;
      height: 44px;
      color: hsl(0, 0%, 65%);
      @include all-caps;

      background-image: linear-gradient(hsl(0, 0%, 96%), hsl(0, 0%, 100%));
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
    }

    .tab-content {
      padding: 0 10px 10px;
    }

    .tab-content-title {
      font-size: 14px;
      color: $primary;
      margin: 10px 0 5px;

      &.tab-content-title-link {
        display: inline-flex;
        flex: 3;
      }
    }

    .tab-content-link {
      display: inline-flex;
      flex: 7;
      position: relative;
      bottom: 6px;
      left: 12px;
    }

    .tab-content-deep-link {
      display: flex;
      justify-content: space-between;
      margin-bottom: -10px;
    }
  }
}

.message.expanded {

  .title, .sort-handle {
    cursor: default;
  }

  .message {
    white-space: normal;
    overflow: hidden;
    text-overflow: initial;

    textarea {
      line-height: 1.2;
      padding: 13px 10px;
      width: 100%;
      height: 90px;
      resize: none;
    }
  }
  div {
    user-select: text;
  }

  .status {
    display: none;
  }
}

.preview-toggle {
  display: flex;
  height: 45px;
  margin-bottom: 16px;

  div {
    display: flex;
    @include flex-center;
    flex: 1;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.0625em;
    border: 1px solid $grey-85;

    &:not(.active) {
      color: $grey-50;
      cursor: pointer;
    }

    &.active {
      color: $grey-mid;
      border-color: $grey-mid;
      cursor: default;
      font-weight: 600;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.icon-button {
  display: flex;
  @include flex-center;
  height: 44px;
  width: 44px;
  font-size: 24px;
  cursor: pointer;
}

.commit {
  .icon-button {
    border-left: $item_dashed_border;
  }
}

[class*='hovering-'] {
  &.disabled {
    pointer-events: none;
  }
}

.hovering-orange {
  color: $orange;
  &:hover {
    background-color: rgba($orange, 0.2);
  }
}

.hovering-green {
  color: $green;
  &:hover {
    background-color: rgba($green, 0.2);
  }
}

.hovering-red {
  color: $red;
  &:hover {
    background-color: rgba($red, 0.2);
  }
}
