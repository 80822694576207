// Import variables
@import 'partials/variables';

// Fonts

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);
@import url(https://fonts.googleapis.com/css?family=Wire+One&text=%3A);

$grey-75: hsl(0, 0%, 75%);

// Universal

// https://github.com/ianstormtaylor/slate/issues/5110#issuecomment-1267863791
@supports not (-apple-trailing-word: inherit) {
  div[data-slate-editor] {
    -webkit-user-modify: read-write !important;
  }
}

html, body {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro';
}

* {
  @include mp;
  box-sizing: border-box;
}

article, section {
  transform: translateZ(0);
}

// Needed so that mouse events aren't lost when hovering on rows
.isvg {
  pointer-events: none;
  &.loaded { display: flex; }
}

.page {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  isolation: isolate;
  @include r('padding'
    , 30px 30px 0 30px
    , 30px 30px 0 30px
    , 30px 30px 0 30px
    , 30px 30px 0 15px
    , 30px 15px 0 15px
  );
}

.icon {
  display: flex;
  @include flex-center;
  width: 44px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.jaqen {
  display: flex;
  @include flex-center;
  height: 44px;
  width: 44px;
  font-size: 24px;

  &:not(.interactive) {
    pointer-events: none;
  }

  &.interactive {
    cursor: pointer;
  }
}

.text-input {
  position: relative;
  overflow: hidden;
  line-height: normal;
  padding: 5px 10px;
  background-color: white;

  &.disabled {
    $stripe: hsl(0, 0%, 97.5%);
    background-size: 45px 46px;
    background-image: linear-gradient(-45deg, $stripe 25%, $transparent 25%, $transparent 50%, $stripe 50%, $stripe 75%, $transparent 75%, $transparent);

    &:focus {
      box-shadow: none;
      background-image: linear-gradient(-45deg, $stripe 25%, $transparent 25%, $transparent 50%, $stripe 50%, $stripe 75%, $transparent 75%, $transparent);
    }
  }

}

.text-disabled {
  color: $grey-75 !important;
}

.disableField {
  pointer-events: none;
}

a {
  color: rgba($blue, 0.6);
  text-decoration: none;

  &:hover,
  &:focus {
    color: $blue;
    -webkit-filter: drop-shadow(0 0 1px rgba($blue, 0.3));
  }
}

div {
  user-select: none;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  resize: none;
}

h1 {
  font-size: 32px;
  font-weight: 300;
}

::-webkit-input-placeholder {
  color: hsla(0, 0%, 50%, 0.5);
}
::-moz-placeholder {
  color: hsla(0, 0%, 50%, 0.5);
}
:-moz-placeholder {
  color: hsla(0, 0%, 50%, 0.5);
}
:-ms-input-placeholder {
  color: hsla(0, 0%, 50%, 0.5);
}

.special-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9000;
}

.blurred-overlay {
  @extend .special-fullscreen;
  z-index: 7000;
  background-color: hsla(0, 0%, 0%, 0.42);
  opacity: 1;
  transition: opacity 0.12s ease-in;
  pointer-events: none;

  &.true { ~ div { -webkit-filter: blur(8px); } }
  ~ div { transition: -webkit-filter 0s; }

  &.false { opacity: 0; }
  &.none { display: none; }
}

.placeholder-text-input {
  display: flex;
  flex: 1;
  position: relative;

  input {
    display: flex;
    flex: 1;

    &[type='checkbox'] {
      height: 100%;
      margin-left: 10px;
    }

    &:hover, &:focus {
      +.placeholder.active {
        opacity: 1;
      }
    }
  }

  .placeholder {
    opacity: 0;
    position: absolute;
    right: 10px;
    color: rgba(#808080, 0.5);
    pointer-events: none;

    &.force {
      opacity: 1;
    }
  }

  + .placeholder-text-input {
    border-left: $item_dashed_border;
  }

}

// Alert that appears above the heading
.alert {
  position: absolute;
  z-index: 8000;
  display: flex;
  @include flex-center;
  background: #ffffff;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 44px;
  visibility: hidden;
  opacity: 0;
  color: $grey-50;
  cursor: pointer;
  transition: opacity 0.3s ease;

  span {
    font-weight: 600;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
  }

  @each $type, $color in (success: $green, error: $red, warning: $orange, info: $grey-75, reload: $blue) {
    &.#{$type} {
      visibility: visible;
      opacity: 1;
      color: $color;
      border-bottom: 1px solid $color;

      @if ($type == 'reload') {
        border-bottom: 1px solid $primary;
        color: $primary;
      }
    }
  }

  &.reload {
    height: 90px;
  }

  &.fixed {
    position: fixed;
  }
}

.react-kronos {
  .days {
    line-height: normal;
  }
}

.image-container {
  display: flex;
  flex: 1;

  + .image-container {
    border-left: $item_dashed_border;
  }

  .image-current {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 200px;
  }

  .image-processing {
    display: flex;
    flex: 1;
    position: relative;
    background-color: rgba($grey-90, 0.5);
  }

  .image-detail {
    display: flex;
    height: 44px;
    @include flex-center;
    background-color: rgba($grey-90, 0.2);

    .image-detail-label {
      padding: 0px 5px;
      font-style: italic;
      color: $grey-85;
    }

  }

  .image-preview {
    display: flex;
    flex: 1;
    flex-direction: column;

    .image-preview-wrapper {
      position: relative;
      display: flex;
      @include flex-center;
      flex: 1;
      flex-direction: column;
      background-color: rgba($grey-90, 0.5);

      .image-preview-image-wrapper {
        max-height: 115px;
        position: relative;

        img {
          max-height: 115px;
          box-shadow: 0 3px 6px rgba(#000000, 0.16), 0 3px 6px rgba(#000000, 0.23);
        }

      }

    }

    &:hover {

      .delete-button {
        display: flex;
        cursor: pointer;

        &:hover {
          .red-button {
            background: rgba($red, 1.0);
          }

        }

      }

    }

    .delete-button {
      display: none;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      position: absolute;
      right: -22px;
      top: -22px;

      .red-button {
        display: flex;
        @include flex-center;
        font-size: 24px;
        color: #ffffff;
        background: rgba($red, 0.4);
        border-radius: 24px;
        width: 22px;
        height: 22px;
      }

    }

  }

  .upload-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    border-top: dashed 1px #e6e6e6;

    &:hover {
      background-color: rgba($green, 0.12);
    }

    &:before {
      content: 'Upload Image';
      padding: 0 15px;
      color: $green;
    }

    ::-webkit-file-upload-button {
      visibility: hidden;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      opacity: 0;
      cursor: pointer !important;
    }

    .upload-icon {
      font-size: 24px;
      width: 44px;
      text-align: center;
      // This allows the user to click the input under the icon
      pointer-events: none;

      &:before {
        color: $green;
      }
    }
  }

  @include spinner(
    absolute, // $position
    calc(50% - 32px), // $topValue
    'left', // $side
    calc(50% - 32px), // $sideValue
    64px, // $diameter
    4px solid hsl(0, 0%, 80%), // $borderOn
    4px solid white // $borderOff
  );

}

.empty {
  text-align: center;
  font-style: italic;
  color: $grey-75;
}

/* Bug workaround for flex issues on Chrome 48 */
* {
  min-width: 0;
  min-height: 0;
}

.primary {
  color: $primary;
}

.progress-bar {
  height: 2px;
  background-color: $primary;
  position: relative;
  border-radius: 6px;
  transition: all 500ms;

  &:after {
    position: absolute;
    right: 0;
    height: 2px;
    width: 24px;
    content: "";
    display: block;
    box-shadow:

        12px 0 6px 1px transparentize($primary, .4),
        10px 0 6px 1px transparentize($primary, .4),
         8px 0 6px 1px transparentize($primary, .4),
         6px 0 6px 1px transparentize($primary, .4),
         4px 0 6px 1px transparentize($primary, .4),
         2px 0 6px 1px transparentize($primary, .3),
         0   0 6px 1px transparentize($primary, .2),
        -4px 0 6px 1px transparentize($primary, .3),
        -8px 0 6px 1px transparentize($primary, .4),
       -12px 0 6px 1px transparentize($primary, .5),
       -16px 0 6px 1px transparentize($primary, .6),
       -20px 0 6px 1px transparentize($primary, .7),
       -24px 0 6px 1px transparentize($primary, .8)
    ;
  }
}
