.sync {
  position: relative;
  display: flex;
  height: 45px;
  @include r('width', 100%, 100%, 100%, 240px, 100%);
  margin-bottom: 16px;
  @include r('margin-top', 0, 0, 0, 16px, 16px);
  @include r('order', initial, initial, initial, 2, 1);

  color: $grey-75;
  font-size: 15px;
  line-height: 44px;
  border-radius: 4px;
  cursor: default;

  div {
    display: flex;
    flex: 1;
  }

  .title {
    flex: 1;
    justify-content: center;
    font-weight: 600;
    letter-spacing: 0.0625em;
  }

  .details {
    flex: 1;
    justify-content: center;
  }

  .title-bg {
    position: relative;
    height: 45px;
    top: -1px;
    left: -1px;
    border: 1px solid $grey-75;
    border-radius: 4px;
  }

  @include t;

  @each $status, $color in (live: $green, needs_sync: $orange, re_sync: $orange, requested: $primary, in_process: $primary) {
    &.#{$status} {
      border: 1px solid $color;
    }

    .#{$status} {
      color: $color;
    }
  }

  @each $status, $color in (needs_sync: $orange, re_sync: $orange, in_process: $primary) {
    &.#{$status} {
      .alternating.first .title {
        color: $color;
      }
    }
  }

  .state, .always, .alternating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }

  .icon, .jaqen {
    flex: initial;
    width: 44px;
  }

  .alternating {
    animation-fill-mode: both;

    $time: 10s;
    &.first { animation: fade-first $time infinite ease-in-out; }
    &.second { animation: fade-second $time infinite ease-in-out; }
  }

  &.needs_sync {
    cursor: pointer;
  }

  @include spinner(
    absolute, // $position
    8px, // $topValue
    'right', // $side
    8px, // $sideValue
    28px, // $diameter
    2px solid $primary, // $borderOn
    2px solid rgba($primary, 0.25) // $borderOff
  );
}
