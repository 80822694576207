@import 'partials/variables';

$chart-border: hsl(0, 0%, 50%);

.stacked-bar-chart {

  .anchor-label {
    text-align: center;
    font-size: 12px;
    line-height: 2;
  }

  .empty-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-top: 15px;
      margin-bottom: 25px;
      font-weight: 600;
    }
  }

  .chart-container {
    display: flex;
    justify-content: center;

    .align-left {
      justify-content: flex-start;
    }

    .align-right {
      justify-content: flex-end;
    }

    .anchor {
      position: absolute;
      background-color: black;
      width: 1px;
      height: 90px;
    }

    .chart {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;

      .category {
        height: 50px;
        color: black;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .value {
          font-size: 16px;
        }
        .percentage {
          font-size: 11px;
          text-align: center;
          padding-top: 5px;
        }
      }

      .overflow {
        visibility: hidden;
      }

      .margin {
        height: 1px;
        background-color: $chart-border;
      }
    }

    .diverging {
      border-left: $chart-border solid 1px;
      border-right: $chart-border solid 1px;
    }
  }

  .legend {
    display: flex;
    justify-content: center;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
    }

    .swatch {
      float: left;
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    .label {
      font-style: italic;
      font-size: 14px;
    }
  }

  .orange {
    background-color: $orange;
  }

  .light-orange {
    background-color: rgba($orange, .5);
  }

  .blue {
    background-color: $primary;
  }

  .light-blue {
    background-color: rgba($primary, .5);
  }

  .white {
    background-color: white;
    color: black !important;
    border-style: solid;
    border-width: 1px;
    border-color: $chart-border;
  }
}
