main {
  display: flex;
  flex: 1;
  overflow: auto;
  @include r('overflow', initial, initial, initial, auto, auto);
  @include r('flex-wrap', initial, initial, initial, wrap, wrap);
}

@import 'content/left';
@import 'content/center';
@import 'content/right';
