.center {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @include r('margin', 0 15px, 0 15px, 0 15px, 0 15px 0 0, 0);
  @include r('flex', 1, 1, 1, 1, 1 100%);

  &.no-right {
    @include r('margin', 0 0 0 15px, 0 0 0 15px, 0 0 0 15px, 0 0 0 15px, 0);
  }
}

@import '../universal';

// Row at the top of the list
.heading {
  position: relative;
  display: flex;
  height: 43px;
  color: $grey-50;

  > .jaqen {
    width: 44px;
    color: $grey-75;
  }

  > .title {
    cursor: default;
    font-size: 16px;
    line-height: 43px;
    flex: 1;
    padding: 0 11px;
  }
}

.list {
  z-index: 1;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  transition: top .3s cubic-bezier(.46, .04, .52, .9);
  flex: 1;

  article:first-child {
    margin-top: 18px;
  }
  article:last-child {
    @include r('margin-bottom', 120px, 120px, 120px, 0, 0);
  }
}

.container.expanded ~ .list,
.message-heading.expanded ~ .list {
  position: relative;
  top: 0;
}

.message-heading.expanded > .title {
  background-color: hsl(0, 0%, 96%);
  border-bottom: $item_row_border;
}

// Basic row & expanded styles
article {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1px 15px 0;
  line-height: 44px;
  font-size: 16px;
  color: $grey-50;
  background-color: white;
  border-top: 1px solid $grey-90;

  &:last-child {
    border-bottom: 1px solid $grey-90;

    .field {
      input {
        &[type=text], &[type=password] {
          height: 42px;
        }
      }
    }
  }

  &.empty {
    box-shadow: none;

    .warning-about-regions {
      line-height: normal;
      color: $orange;
    }
  }

  &.dragging {
    .details {
      display: none;
    }
  }

  input[type='text'], textarea, input.rw-input {
    border: 0;
    outline: 0;
    padding-left: 10px;
    background-size: 45px 46px;
    color: $grey-50;

    &:focus {
      color: black;
    }
  }

  .sort-handle {
    display: flex;
    @include flex-center;
    width: 44px;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    user-select: none;
    border-right: 1px solid white;
    flex: none;
  }

  .sort-handle-icon-svg {
    height: 24px;

    svg {
      height: 100%;
    }
  }

  .left-handle {
    display: flex;
    @include flex-center;
    width: 44px;
    user-select: none;
    border-right: 1px solid white;

    &.sort {
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

  }

  .edit-handle {
    color: $grey-75;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .reference {
    cursor: pointer;
    border-left: 1px dashed #e6e6e6;
  }

  .attribute, .templates {
    .sort-handle {
      cursor: default;
    }
  }

  .secondary-label {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  label {
    padding: 0 10px;
    font-style: italic;
  }

  &:not(.hovering) {
    .jaqen.postscript {
      color: $aqua;
    }
  }

  &.expanded:not(.region) {
    .sort-handle {
      border-right: $item_row_border;
    }
    .left-handle {
      border-right: $item_row_border;
    }
  }
}

// Single title row
section.title {
  display: flex;

  .name {
    display: flex;
    flex: 1;
    cursor: text;
    outline: none;
    min-height: 43px;
    @include ellip;

    &:hover a {
      color: $primary;
    }

    textarea, input {
      flex: 0;
      padding-left: 0px;
    }
    span,
    .message-title {
      padding: 0 10px;
      flex: 1;
      @include ellip;
    }

    a {
      cursor: pointer;
      flex: 1;
      display: block;
      padding-left: 10px;
      padding-right: 15px;
      text-decoration: none;
      line-height: 44px;
      color: $grey-50;
      -webkit-user-drag: none;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    label {
      padding: 0 0 0 10px;
    }

    .campus-label {
      font-size: 14px;
      font-style: italic;
      color: $aqua;
      pointer-events: none;
    }
  }
}


article.expanded section.title {
  .name {
    flex: 1;
    display: flex;

    textarea, input {
      flex: 1;
      padding: 12px 10px;
    }

    a, span, label, .campus-label {
      display: none;
    }
  }
}

.incomplete-label {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: italic;
  color: $orange;
  pointer-events: none;

  span {
    padding: 0;
  }
}

// Specific type row & expanded styles as list heading element
.list > article {
  @each $type, $color in (
          'container': $primary,
          'item': $grey-50,
          'user-row': $primary,
          'message.draft': $orange,
          'message.scheduled': $green,
          'message.sending': $primary,
          'message.complete': $grey-75,
          'message.failed': $grey-75,
          'message.deleted': $red,
          'region': $red,
  ) {
    &.#{$type}:not(.expanded) .title .name:hover a {
      color: $color;
    }
  }
}
// Specific type row & expanded styles
article {

  @each $type, $color in (
    'container': $primary,
    'container-ccb': $brown,
    'item': $grey-50,
    'message-heading': $grey-75,
    'user-row': $primary,
    'message.draft': $orange,
    'message.scheduled': $green,
    'message.sending': $primary,
    'message.complete': $grey-75,
    'message.failed': $grey-75,
    'message.deleted': $red,
    'region': $red,
  ) {
    &.#{$type} {
      .icon {
        color: $color;
      }
      .reset-password-button{
        color: $grey-75;
        border-left: 1px dashed #e6e6e6;
        cursor: pointer;
      }
      .sort-handle {
        color: $color;
      }
      .left-handle {
        color: $color;
      }
      .right-handle {
        color: $color;
      }
      .reference {
        color: $grey-75;
      }
      .title > [class*='-handle']:hover, .reference:hover {
        color: $color;
      }
    }

    &.#{$type}.hovering {
      box-shadow: 0 -1px 0 $grey-90, 0 1px 0 $grey-90, 2px 0 0 $color, -2px 0 0 $color;
    }

    &.#{$type}.expanded {
      background-color: white;
      z-index: 250;
      box-shadow: 6px 0 0 1px $color, -6px 0 0 1px $color;
      .name a {
        cursor: text;
      }
    }
  }

  // text input backgrounds
  $light_blue: rgba($blue, .05);
  $light_grey-50: rgba($grey-50, .06);
  $light_orange: rgba($orange, .08);
  $light_green: rgba($green, .08);
  $light_red: rgba($red, .05);
  $light_purple: rgba($purple, .07);

  @each $type, $stripe in (
    'container': $light_blue,
    'item': $light_grey-50,
    'user-row': $light_blue,
    'message.draft': $light_orange,
    'message.scheduled': $light_green,
    'region': $light_red,
  ) {
    &.#{$type}.expanded .title .title-text{
      display: inline;
      background-size: 45px 46px;
      color: #3F3F3F;
      -webkit-user-select: text;
    }

    &.#{$type} input[type='text']:focus, &.#{$type} textarea:focus {
      background-image:
        linear-gradient(-45deg, $stripe 25%, $transparent 25%, $transparent 50%, $stripe 50%, $stripe 75%, $transparent 75%, $transparent);
    }
  }

  // separate because it's a child, not &.
  .property input[type='text']:focus, .property textarea:focus {
    background-image:
      linear-gradient(-45deg, $light_orange 25%, $transparent 25%, $transparent 50%, $light_orange 50%, $light_orange 75%, $transparent 75%, $transparent);
  }

  // label colors
  @each $type, $color in (
    'container': $blue,
    'item': $grey-50,
    'property': $orange,
    'attribute.campus': $aqua,
    'region': $red,
  ) {
    &.#{$type} label, .#{$type} label {
      color: $color;
    }
  }

  // action bar separator color
  @each $type, $color in (
    'container': $blue,
    'item': $grey-50,
    'message.draft': $orange,
    'message.scheduled': $green,
    'message.complete': $grey-75,
    'message.failed': $grey-75
  ) {
    &.#{$type} .attribute.solid-separated {
      box-shadow: 0 1px 0 $color, 0 -1px 0 $color;
    }
  }
}

.attitude-poll-results {
  // This is lifted right from section.details { .attribute{} }
  position: relative;
  border-top: dashed 1px $grey-90;
  background: white;
  padding-left: 10px; // From .tab-content
  padding-right: 10px;

  .title {
    // Lifted from .tabular-regions { .tab-content-title {} }
    font-size: 14px;
    color: $primary;
    margin: 10px 0 5px;
  }
}

// Transitional styles for showing expanded state
section.details {

  .property, .attribute {
    position: relative;
    border-top: dashed 1px $grey-90;
    background: white;
  }

  .property:not(:last-child) {
    border-bottom: dashed 1px #e6e6e6;
    margin-bottom: -1px;
  }

  .templates {
    border-top: dashed 1px $grey-90;
    height: 77px;
  }

  .solid-separated {
    margin-top: 1px;
    border: 0;
    z-index: 12;
  }

  .card-definition,
  .related-content-setting {
    border-top: dashed 1px $grey-90;
    background: $background-grey-light-color;
    align-items: center;
    padding: 10px;
    display: flex;
    color: $grey-mid;
  }

  .card-properties {
    flex-direction: column;
  }

}


// Attributes of a container/item
.attributes {

  .attribute {
    background: white;
    display: flex;
    &.hidden {
      height: 0;
      opacity: 0;
      border: 0;
      display: none;
    }
  }

  .campus {
    .sort-handle {
      color: $aqua;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }

  .color {
    .sort-handle {
      color: $aqua;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }

  .restricted {
    .sort-handle, label {
      color: $red;
    }
  }

  .templates {
    display: flex;
    background: white;
  }

  .template-picker {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  .template {
    display: flex;
    cursor: pointer;
    border-right: dashed 1px $grey-90;
    @include color-svg($grey-75);
    @include t;

    .options {
      width: 120px;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      @include t;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > label {
          @include mp;
        }

        > input {
          margin-left: 10px;
        }
      }
    }

    &:not(.expanded) .options {
      width: 0;
      padding: 0;
      opacity: 0;
      pointer-events: none;
    }

    &.expanded .options {
      background-color: hsla(0, 0%, 100%, .65);
      box-shadow: inset 0 0 3px hsla(0, 0%, 0%, .08);
    }
  }

  .upload {
    position: relative;
    width: 25%;
    cursor: pointer !important;

    &:hover {
      background-color: rgba($green, 0.12);
    }

    &:before {
      content: 'Upload image';
      padding: 0 15px;
      color: $green;
    }

    ::-webkit-file-upload-button {
      visibility: hidden;
    }

    input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer !important;
    }

    .upload-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 24px;
      width: 44px;
      text-align: center;
      // This allows the user to click the input under the icon
      pointer-events: none;

      &:before {
        color: $green;
      }
    }
  }

  .image {
    display: flex;

    .image-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      .image-heading {
        background-color: hsl(0, 0%, 98%);
        border-bottom: $item_dashed_border;
        display: flex;
        justify-content: space-between;

        > div {
          flex: 1;
          padding: 0 15px;

          .dimensions {
            font-style: italic;
            font-size: 13px;
            color: hsl(0, 0%, 65%);
          }

          + div {
            border-left: $item_dashed_border;
          }
        }
      }

      .image-content {
        display: flex;
        @include mp;
        height: 135px;
        font-size: 0;
        line-height: 0;

        .image-item {
          flex: 1;
          display: flex;
          @include flex-center;
          background-color: $grey-90;
          position: relative;
          width: 100%;
          height: 100%;
          padding: 10px;
          box-shadow: inset 0 0 3px hsla(0, 0%, 0%, .08);

          + .image-item {
            border-left: 1px solid white;
          }

          img {
            max-width: 100%;
            max-height: 115px;
            margin: auto;
          }

          $shadow: hsla(0, 0%, 0%, 0.33);

          > img {
            opacity: 0.33;
            outline: 1px solid hsla(0, 0%, 0%, 0.33);
          }

          .image-crop {
            display: flex;
            @include flex-center;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            img {
              opacity: 0;
              box-shadow: 0 0 1px 1px $shadow;
            }

            img.image-full-opacity {
              opacity: 1;
            }
          }
        }
      }
    }

    @include spinner(
      absolute, // $position
      calc(50% - 32px), // $topValue
      'left', // $side
      calc(50% - 32px), // $sideValue
      64px, // $diameter
      4px solid hsl(0, 0%, 80%), // $borderOn
      4px solid white // $borderOff
    );
  }

  textarea {
    white-space: normal;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: initial;
    line-height: 1.2;
    padding: 13px 10px;
    width: 100%;
    // height: 89px;
    resize: none;
    background-color: $transparent;
  }
}

// Highlighted template in template picker
article {
  @each $type, $color in (container: $primary, item: $grey-50) {
    &.#{$type} {
      .template.expanded {
        background-color: rgba($color, .08);
        @include color-svg($color);
      }
    }
  }
}

// Generic properties
.properties {

  display: flex;
  flex-direction: column;

  .property {
    display: flex;
    .sort-handle {
      background: white;
      color: $orange;
    }
  }

  .prop-keys, .prop-key {
    display: flex;
    justify-content: space-between;
    flex: 1;

    label {
      margin: auto 0;
    }
  }

  input {
    flex: 1;

    &[type='checkbox'] {
      flex: initial;
      margin: auto 15px;
    }
  }

  textarea {
    white-space: normal;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: initial;
    line-height: 1.2;
    padding: 13px 10px;
    width: 100%;
    // height: 89px;
    resize: none;
  }

  .done {
    width: 44px;
    display: flex;
    @include flex-center;
    background-color: rgba($green, 0.1);
    color: $green;

    &:hover {
      cursor: pointer;
    }
  }

  .hidden {
    display: none;
  }
}

// Specific properties

.property.rich-text-editor {
  max-height: 246px;
  line-height: 1.5;

  .juicy-rich-text-editor{
    overflow: scroll;
    word-break: break-word;
  }

  .html-preview {
    overflow: scroll;
    word-break: break-word;
    padding: 0px 15px;
    overflow: auto;
    width: 100%;
    display: block;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0px;
      height: 15px;
      width: 100%;
      z-index: 1;
    }

    &:before {
      top: 0px;
      background-image: linear-gradient(white, $transparent);
    }

    &:after {
      bottom: 0px;
      background-image: linear-gradient($transparent, white);
    }
  }
}

.property .geocode {
  width: 44px;
  cursor: pointer;

  border-left: $item_dashed_border;
  background-color: rgba($green, 0.1);
  color: $green;

  &.disabled {
    background-color: white;
    cursor: not-allowed;
  }

  @include spinner(
    relative, // $position
    8px, // $topValue
    'left', // $side
    8px, // $sideValue
    28px, // $diameter
    2px solid $green, // $borderOn
    2px solid rgba($green, 0.25) // $borderOff
  );
}

.attribute .run-feed {
  display: flex;
  @include flex-center;
  width: 44px;
  height: 44px;
  cursor: pointer;

  border-left: $item_dashed_border;
  background-color: rgba($aqua, 0.1);
  color: $aqua;

  &.disabled {
    background-color: white;
    cursor: not-allowed;
  }

  @include spinner(
    relative, // $position
    0px, // $topValue
    'left', // $side
    0px, // $sideValue
    28px, // $diameter
    2px solid $aqua, // $borderOn
    2px solid rgba($aqua, 0.25) // $borderOff
  );
}

// Fields that apply to both attributes & properties

.attr-bos, .prop-bos {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.value {

  display: flex;
  justify-content: space-between;
  flex: 1;

  input {
    flex: 1;
  }

  textarea {
    flex: 1;
    padding: 12px 10px;
  }

  > div {
    display: flex;
  }

  > span {
    padding: 0 15px 0 10px;
  }

  &.pad {
    padding-left: 10px;
  }

  &.zero {
    flex: initial;
  }

  &.double {
    flex: 2;
  }

  &.triple {
    flex: 3;
  }

  &.selected {
    input.rw-input {
      color: hsl(0, 0%, 25%) !important;
      background-color: rgba($grey-50, .08);
    }
  }

  &.unselected {
    input.rw-input, label, span {
      color: $grey-75 !important;
    }
  }

  &.option {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > input[type='checkbox'] {
      flex: initial;
      margin: 0 15px;
    }
  }

  &.vertical {
    flex-direction: column;
    padding-top: 12px;

    > span {
      width: 100%;
      line-height: 1;
      padding-bottom: 5px;
    }
  }

  &.disabled {
    color: $grey-90;
    cursor: default;
    pointer-events: none;
  }

  .border-left {
    border-left: dashed 1px $grey-90;
  }
}

.value-row {
  height: 45px;
  display: flex;

  &:first-child {
    height: 44px;
  }

  &.double {
    height: 90px;
  }

  &.triple {
    height: 135px;
  }

  &.unconstrained {
    height: auto;
  }

  &.show-dashed-border {
    height: 45px;
    border-top: dashed 1px $grey-90;
  }

  &.column {
    flex-direction: column;
  }

  + .value-row {
    border-top: dashed 1px $grey-90;
  }

  .value + .value {
    border-left: dashed 1px $grey-90;
  }
}

.valid-input {
  display: flex;
  flex: 1;

  .icon {
    position: absolute;
    right: 0;

    &.valid {
      color: $green;
    }

    &.invalid {
      color: $red;
    }

  }
}

.row-layer {
  display: flex;
  @extend .right;
}

.row-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0 0 10px 10px;

  .sub-section {
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
    }

    &:not(:first-child){
      padding-left: 10px;
    }
  }
}


// Switch

.switch-container {
  display: flex;
  align-items: center;
  outline: none;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}

.switch-container-lock {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 50%;
}

.switch-background {
  flex: 1;
  height: 100%;
}

.switch-button {
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
}

// DHS and related content settings

.card-definition-greyout {
  opacity: 0.7;
}

.card-definition-section,
.related-content-section {
  display: flex;
  align-items: center;
  flex: 1;
}

.card-definition-label,
.related-content-label {
  font-size: 15px;
  padding-right: 12px;
}

.related-content-label-wrapper {
  line-height: 100%;
  margin-left: 10px;
  align-items: baseline;
}

.card-properties-container {
  display: flex;
}

.card-properties-detail-container {
  flex-direction: column;
  flex: 1;

  .text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.card-definition-cta {
  align-items: center;
  justify-content: center;
  flex: 1;
  fill: grey;
  padding: 0 7px;
}

.card-definition-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.related-content-custom-title {
  .value-row {
    flex: 1;
  }
}

$line-box-height: 1.2;

.action-bar-hide-label {
  line-height: $line-box-height;
}

.action-bar-dropdown-list {
  & .rw-dropdown-list-input {
    line-height: $line-box-height;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
  }
}

