@import "../../../styles/global_colors.css";

.feedRow {
  display: flex;
  align-items: center;
}

.title {
  line-height: normal;
}

.valueContainer {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  height: 100%;
  align-items: center;
}

.valueContainer input {
  border-width: 0;
  height: 100%;
}

.dropdownList {
  padding-left: 10px;
  min-width: 150px;
}

.tooltipLink {
  color: white;
  display: block;
  margin-top: 10px;
}

.tooltipIcon {
  height: 16px;
  font-size: 16px;
  margin-top: 2px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.label {
  font-style: italic;
  color: var(--blue);
  padding-right: 10px;
  line-height: normal;
}

.messageClickable {
  pointer-events: auto;
}
