:root {
  --font-family: Helvetica, Arial, sans-serif;
  --font-size-tiny: 10px;
  --font-size-small: 14px;
  --font-size-medium: 17px;
  --font-size-large: 25px;

  --font-error-color: #ba1309;
  --font-default-color: var(--grey-dark);
  --font-placeholder-color: var(--grey-light);

  --breakpoint-x-large: 1500px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 800px;

  @custom-media --smaller-than-breakpoint-large (max-width: 1200px);
  @custom-media --smaller-than-breakpoint-medium (max-width: 800px);
}
