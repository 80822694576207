@charset 'UTF-8';

@font-face {
  font-family: 'UI Icons';
  src: url('~@pushpay/app-components/dist/assets/ui-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^='ui-icon-']:before, [class*=' ui-icon-']:before {
  font-family: 'UI Icons' !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
}

// Assets

.ui-icon-plus:before { content: 'a'; }
.ui-icon-chevron-down:before { content: 'b'; }
.ui-icon-chevron-left:before { content: 'c'; }
.ui-icon-chevron-right:before { content: 'd'; }
.ui-icon-bin:before { content: 'e'; }
.ui-icon-arrow-left:before { content: 'f'; }
.ui-icon-circle-cross:before { content: 'g'; }
.ui-icon-cross:before { content: 'h'; }
.ui-icon-magnify:before { content: 'i'; }
.ui-icon-tick:before { content: 'j'; }
.ui-icon-chevron-up:before { content: 'k'; }
.ui-icon-profile:before { content: 'l'; }
.ui-icon-profile-filled:before { content: 'm'; }
.ui-icon-pencil:before { content: 'n'; }
.ui-icon-headphones:before { content: 'o'; }
.ui-icon-cog:before { content: 'p'; }
.ui-icon-circle-info:before { content: 'q'; }
.ui-icon-circle-question:before { content: 'r'; }
.ui-icon-doc-edit:before { content: 's'; }
.ui-icon-doc-edit-filled:before { content: 't'; }
.ui-icon-lock:before { content: 'u'; }
.ui-icon-lock-filled:before { content: 'v'; }
.ui-icon-bookmark:before { content: 'w'; }
.ui-icon-bookmark-filled:before { content: 'x'; }
.ui-icon-cal-tick:before { content: 'y'; }
.ui-icon-cal-tick-filled:before { content: 'z'; }
.ui-icon-bell:before { content: '1'; }
.ui-icon-bell-filled:before { content: '2'; }
.ui-icon-burst-star:before { content: '3'; }
.ui-icon-burst-heart:before { content: '4'; }
.ui-icon-mobile:before { content: '5'; }
.ui-icon-exclamation:before { content: '6'; }
.ui-icon-triangle-exclamation:before { content: '7'; }
