@import 'partials/variables';

.page.admin {
  @include r('padding'
    , 30px 0 0 30px
    , 30px 0 0 30px
    , 30px 0 0 30px
    , 30px 0 0 15px
    , 30px 15px 0 15px
  );

  header {
    @include r('margin-right', 30px, 30px, 30px, 15px, 15px);
    z-index: 1000;
  }

  article, section {
    transform: none;
  }
}

// Navigation

.admin-nav {
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro';

  ul, li { list-style: none; }

  li {
    flex: 1;
    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 64px;
      font-size: 18px;
      color: hsl(0, 0%, 65%);
      border: 1px solid $transparent;
      padding-right: 15px;
      letter-spacing: 0.0625em;
      text-align: right;

      &.active {
        color: $blue;
        background: white;
        border-top-color: $grey-90;
        border-bottom-color: $grey-90;
        cursor: default;
      }
    }
    &.active {
      > a {
        @extend a.active;
      }
    }
    &:not(.active):hover a:not(.active):hover {
      color: $grey-50;
      background-color: hsl(0, 0%, 96%);
    }
  }

  .admin-nav-child {
    li a {
      font-size: 15px;
      height: 32px;
      &:not(.active):hover {
        background-color: hsl(0, 0%, 94%);
      }
    }
    background-color: hsl(0, 0%, 98%);
  }
}

// Admin List & Table

.admin-list {
  position: absolute;
  top: 44px;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  margin-top: 1px;
}

.admin-table {
  margin-bottom: 90px;

  .title {
    @include ellip;
    flex: 2;

    &.version {
      flex: 1;
    }

    &.status {
      flex: 1;
      text-align: center;
    }

    &.file-upload {
      min-width: $field-upload-min-width;
    }

    &.push-category-title {
      font-size: 12px;
      font-style: italic;
      padding-top: 8px;
    }

    &.push-category-subscribed {
      flex: 1;
    }

    &.push-category-name {
      flex: 13;
    }
  }

  .icon-placeholder {
    width: 88px;
  }

  .admin-row:not(.creation) {
    height: 44px;
    flex-direction: row;
  }
  .admin-row {
    &.disabled,
    .field.disabled,
    .attribute.disabled {
      background-color: hsl(0, 0%, 96%);
    }

    &.disabled .field,
    .field.disabled {
      label {
        color: $grey-75;
      }
    }
  }
  .attribute {
    flex: 1;
    input[type=checkbox] {
      margin: 0 10px;
    }
    label {
      &.true {
        color: $green;
      }
      &.false {
        color: $red;
      }
      &.disabled {
        color: $transparent;
      }
    }
  }

  .reveal-more {
    position: relative;
    cursor: copy;
    display: flex;

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    &::before {
      content: attr(data-tip);
      font-size: 12px;
      position: absolute;
      z-index: 999;
      top: 4px;
      left: 44px;
      background: hsla(0, 0%, 0%, 0.8);
      color: #e0e0e0;
      padding: 8px 12px 10px;
      border-radius: 4px;
      line-height: 1.6;
      opacity: 0;
      transition: opacity 0.3s ease-out 0.1s;
      pointer-events: none;
    }

    &:hover::before {
      opacity: 1;
      overflow: visible;
      transition: opacity 0.3s ease-out 0.3s;
    }
  }
}

// Rows

.admin-row {
  &.editing {
    box-shadow:
      3px 0 0 1px $blue,
      -3px 0 0 1px $blue;
    z-index: 250;

    &.live {
      box-shadow:
        3px 0 0 1px $green,
        -3px 0 0 1px $green;
    }

    .title {
      border-bottom: 1px dashed $grey-90;
    }
  }

  &.inline {
    box-shadow: none;
    background-color: #fafafa;
    border: none;
  }



  &.creation {
    flex-direction: column;
    margin-bottom: 18px;
    .field label {
      color: $blue;
    }
    .field-checkbox {
      flex: none;
    }
  }

  .icon {
    color: $grey-75;

    &.push-category-icon {
      padding-left: 10px;
    }
  }

  .field {
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;

    label {
      font-size: 14px;
      color: rgba($blue, 0.8);
      &.live {
        color: rgba($green, 0.8);
      }
    }

    input {
      flex: 1;
      padding: 0 10px;
      border: 0;

      &[type=text], &[type=password] {
        height: 44px;

        &:focus {
          box-shadow: inset 0 0 6px rgba($blue, 0.3);
          outline: 0;
        }
      }

      &[disabled], &[readOnly] {
        @include disabled;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }

    input.input--error::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $red;
      margin: 0 0 0 1em;
      opacity: 1; /* Firefox */
    }

    .longTextContent  {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 11px;
    }

    &.double {
      flex: 2;
    }

    &.file-upload {
      min-width: $field-upload-min-width;
    }

    &.action {
      @include all-caps;
      flex: 1;
      @include flex-center;
      text-align: center;

      .highlighted {
        color: $blue;
        font-weight: 600;
      }

      .action-checkbox {
        display: flex;
        width: 44px;
        justify-content: center;
        align-items: center;
      }

      .action-button {
        flex: 1;
        text-align: center;
        cursor: pointer;
        background-color: rgba($green, 0.8);
        color: white;

        &:hover {
          background-color: $green;

          span {
            -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
          }
        }
      }
    }

    .checkboxContainer {
      flex: 1;
      height: 100%;
    }

    .disabled {
      @include disabled;
      padding-left: 10px;
    }

    &.push-category-field {
      padding-left: 30px;
    }
  }

  .actions {
    display: flex;
  }

  .right-label {
    width: 44px;
    text-align: center;
    font-size: 18px;
    color: rgba($grey-50, 0.8);
  }

  .action-checkbox {
    display: flex;
    width: 44px;
    justify-content: center;
    align-items: center;
  }

  .details {
    position: relative;
    .field {
      border-top: 1px dashed $grey-90;
      &:first-of-type {
        border-top: 0;
      }
      &.field-inline {
        border: none;
        display: inline-block;
        margin-right: 0;
        padding-left: 30px;

        &:first-of-type {
          padding-left: 0;
        }

        .push-category-name-create {
          border: 1px solid #eee;
          width: 300px;
        }
      }

      .file-exists {
        margin: 0 0.5em;
      }

      .input-message {
        display: flex;
        padding-left: 0.5em;
        border-left: 1px dashed $grey-90;
      }
    }

    .enabled-button {
      color: $primary;
    }

    .disabled-button {
      cursor: auto;
    }

    .action-button {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 132px;
      background: $blue;
      color: white;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1em;
      border: 0;
    }
    .action-button ~ .field:not(.field-inline) {
      margin-right: 132px;
    }

    @include spinner(
      static, // $position
      8px, // $topValue
      'right', // $side
      8px, // $sideValue
      34px, // $diameter
      3px solid $primary, // $borderOn
      3px solid rgba($primary, 0.25) // $borderOff
    );

    .spinner-container {
      padding-left: 5px;
      gap: 5px;
      display: flex;
      align-items: center;
    }

    .status-tooltip {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      font-size: 24px;
    }
  }

  .field-border {
    border-top: 1px dashed $grey-90;
    &:first-of-type {
      border-top: 0;
    }
  }

  &:first-of-type {
    margin-top: 18px;
  }
}

.admin-list > .admin-row, .admin-table > .admin-row {
  &:last-child {
    margin-bottom: 120px;
  }
}

// User status
// Use the class below when FF SSO_BROKEN_USER_FIX is on and use the second class below when FF is off
.admin-user-status {
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 13px;
  margin-right: 10px;
  margin-left: 10px;
  &.active {
    color: $green;
  }
  &.expired,
  &.invalid {
    color: $red;
  }
  &.pending {
    color: $orange;
  }
}

.admin-invite-status {
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 13px;
  margin-right: 10px;
  margin-left: 10px;
  &.active {
    color: $green;
  }
  &.expired {
    color: $red;
  }
  &.pending {
    color: $orange;
  }
}

.admin-no-org-key-message {
  color: gray;
  padding: 20px 15px;
  font-size: 18px;
}

// Actions (icon buttons)

.admin-action {
  height: 44px;
  color: rgba($blue, 0.6);
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    color: $blue;
    -webkit-filter: drop-shadow(0 0 1px rgba($blue, 0.3));
  }

  &.disabled,
  &:disabled {
    color: $grey-90;
    cursor: default;
    pointer-events: none;
  }

  &.on {
    color: $green;
    &:hover {
      -webkit-filter: drop-shadow(0 0 1px rgba($green, 0.6));
    }
  }

  &.off {
    color: $grey-75;
    &:hover {
      color: $grey-50;
    }
  }
}

.tab-button {
  width: 100%;
  padding: 0 1em;
}

.react-tabs__tab--selected .tab-button::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border: solid transparent;
  border-width: 10px;
  border-top-color: $blue;
  content: '';
}

.react-tabs__tab:hover {
  background: $background-grey-light-color;
}

.react-tabs__tab--selected:hover {
  background: $blue;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid #cccccc;
  color: grey;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 0 16px;
  cursor: pointer;
  background: $background-white-color;
  font-size: 15px;
  font-weight: 600;
}

.react-tabs__tab--selected {
  background: $blue;
  color: $background-white-color;
  border-radius: 6px 6px 0 0;
  border: 1px solid $blue;
}

.react-tabs__tab-list {
  border-bottom: 0;
  border-color: #cccccc;
}

.tab-group {
  border-radius: 10% 0 0 10%;
}

.center-tab {
  border-radius: 0;
  border-width: 1px 0 1px 0;
  @media (max-width: 460px) {
    border-radius: 6px;
    border: 1px solid #cccccc;
  }
}

.left-tab {
  border-radius: 6px 0 0 6px;
  @media (max-width: 460px) {
    border-radius: 6px;
  }
}

.right-tab {
  border-radius: 0 6px 6px 0;
  @media (max-width: 460px) {
    border-radius: 6px;
  }
}

.tabs-container {
  padding: 0 10px;
  margin-bottom: 10px;


  .tabs-container__header {
    height: 32px;
    margin-bottom: 8px;
  }

  .tabs-container__body.field {
    border-top: 0;
  }
}

.information-block {
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  background-color: #fcf1df;
  padding: 6px;
  margin: 8px 10px 15px 8px;
  color: #b0894f;
  box-shadow: inset 0 0 3px #ffcf7c;
  line-height: 18px;
  font-size: 16px;
  @media (max-width: 1370px) {
    order: -1;
    flex: 1 0 100%;
    margin-left: 0px;
  }

  .information-block--hidden {
    display: none;
  }

  .information-block--icon {
    margin-right: 6px;
    font-weight: bolder;
    flex: 1 3%;
  }

  .information-block--text {
    flex: 1 0 94%;
    white-space: nowrap;
    overflow: hidden;
  }

  .information-block--closebtn {
    color: #b0894f;
    font-size: 32px;
    font-weight: 100;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 0 3%;
  }
}

.organization-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.view-admins-link-button-container {
  margin-left: 32px;
  margin-top: 16px;
}

.view-admins-link-button {
  height: 44px;
  border-radius: 6px;
  border: 0;
  color: white;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: $primary;
  padding: 0px 15px;
  &:hover {
    background-color: #0d5c96;
    cursor: pointer;
  }
}
