@import "../styles/global_themeing.css";

.help-product-wrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
}

@media (--smaller-than-breakpoint-medium) {
  .help-product-wrapper {
    width: 100%;
    padding-right: 0;
  }
}
