$collapible-content-padding: 15px 25px;

.collapsible-content-container {
  border: 1px #CCCCCC solid;
  border-radius: 4px;
}

.collapsible-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #EEEEEE;
  padding: $collapible-content-padding;
  font-size: 17px;
  font-weight: 500;
}

.collapsible-content-children {
  padding: $collapible-content-padding;
}

.collapsible-content-button {
  background-image: url('../images/ui/nav-arrow-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  background-size: 15px 15px;
  cursor: pointer;
  transform: rotate(180deg);
  &.collapsible-content-button-collapsed {
    transform: rotate(0deg);
  }
}
