@import "../../styles/global_colors.css";

.add-icon {
  padding: 3px 5px 0 3px;
}

.upload {
  max-width: 220px;
  flex-basis: auto;
  flex-grow: 1;
  justify-content: space-between;
}

.upload-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.clear-content {
  color: var(--green);
}

.attribute.upload.clear-content::before {
  content: none;
}
