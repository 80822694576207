@media (min-width: 1400px) {
  .toolbar-height {
    height: 36px;
    min-height: 36px;
    max-height: 36px;
  }

  .toolbar-dropdown :global(.rw-input) {
    line-height: 36px;
  }

  .toolbar :global(.rw-i) {
    line-height: 36px;
  }
}

@media (max-width: 1400px) {
  .toolbar-height {
    height: 28px;
    min-height: 28px;
    max-height: 28px;
  }

  .toolbar-dropdown :global(.rw-input) {
    line-height: 28px;
  }

  .toolbar :global(.rw-i) {
    line-height: 28px;
  }
}

.toolbar {
  composes: toolbar-height;
  display: flex;
  justify-content: flex-start;
  line-height: 36px;
  background-color: #eeeeee;
  color: #424242;
  flex-wrap: nowrap;
  user-select: none;
  box-shadow: 0 2px 2px -1px #cccccc;
  width: 100%;
}

@media (max-width: 760px) {
  .toolbar {
    line-height: 28px;
  }
}

/* @media (min-width: 1280px) { } */

.toolbar-button {
  composes: toolbar-height;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  padding: 2px 6px;
}

@media (max-width: 1400px) {
  .toolbar-button {
    padding: 3px;
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    padding: 2px 3px;
  }
}

.toolbar-button-disabled {
  filter: brightness(2.3);
}

.toolbar-button-icon {
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}

.toolbar-divider {
  composes: toolbar-height;
  width: 0px;
  min-width: 0px;
  max-width: 0px;
  margin: 0;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1400px) {
  .toolbar-divider {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.toolbar-button-active {
  background-color: #d8d8d8;
}

.toolbar-button-disabled {
  opacity: 0.3;
  cursor: default;
}

.toolbar-dropdown:global(.rw-widget), .toolbar-dropdown:global(.rw-widget.rw-state-focus){
  max-width: 130px;
  min-width: 130px;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.toolbar-dropdown :global(.rw-input){
  padding-left: 13px;
}

.toolbar-dropdown :global(.rw-select .rw-btn), .toolbar-dropdown :global(.rw-input){
  background-color: #eeeeee;
  border: none;
}

@media (min-width: 1400px) {
  .toolbar-dropdown :global(.rw-select .rw-btn), .toolbar-dropdown :global(.rw-input){
    height: 36px;
    min-height: 36px;
    max-height: 36px;
  }
}

@media (max-width: 1400px) {
  .toolbar-dropdown :global(.rw-select .rw-btn), .toolbar-dropdown :global(.rw-input) {
    height: 28px;
    min-height: 28px;
    max-height: 28px;
  }
}

.toolbar-dropdown :global(.rw-popup){
  background-color: #eeeeee;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 2px -1px #cccccc;
}

.toolbar-dropdown :global(.rw-popup-container) {
  top: 36px;
}

@media (max-width: 1400px) {
  .toolbar-dropdown :global(.rw-popup-container) {
    top: 28px;
  }
}

.toolbar-dropdown :global(.rw-list-option.rw-state-selected),
.toolbar-dropdown :global(.rw-list-option.rw-state-selected.rw-state-focus){
  color: #424242;
  background-color: white;
  border: none;
  border-radius: 0;
}

.toolbar-prompt {
  font-size: 16px;
  font-style: normal;
  background: #eeeeee;
  color: #424242;
  padding: 10px;
  width: 332px;
}

.toolbar-prompt-checkbox {
  display: inline-block;
  margin-right: 20px;
}

.toolbar-prompt-action-button {
  display: block;
  margin: auto;
  width: 147px;
  height: 44px;
  border-radius: 4px;
  background-color: #4d4d4d;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  &:disabled {
    background-color: #8a8f96;
  }
}
