@import "../../../styles/global_colors.css";
@import "../../../styles/global_themeing.css";

.settings {
  width: 100%;
}

.count-selector {
  border-bottom: var(--item_dashed_border);
  display: flex;
  flex-direction: row;
}

.option {
  padding: 30px;
  display: flex;
  flex-direction: row;
  border: none;
  border-right: var(--item_dashed_border);
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.option-selected {
  background-color: var(--grey-lightest);
}

.option-input {
  position: absolute;
  left: -50px;
  top: 0;
}

.square {
  height: 13px;
  background-color: var(--grey-light);
  margin: 2px;
}

.square-selected {
  background-color: var(--grey-mid);
}

.square-1 {
  width: 24px;
}

.square-2 {
  width: 18px;
}

.square-3 {
  width: 12px;
}

.inputs {
  display: flex;
  flex-direction: row;
}

.char-limit {
  color: var(--grey-base);
  font-weight: bold;
  font-size: var(--font-size-tiny);
  line-height: 13px;
  padding: 10px 10px 0;
  justify-content: flex-end;
  min-height: 24px;
}

.char-limit-warning {
  color: var(--orange);
}

.char-limit-invalid {
  color: var(--red);
}

.metric {
  flex-basis: 33.33%;
  display: flex;
  flex-direction: column;
  border-right: var(--item_dashed_border);
}

.metric:last-child {
  border-right: none;
}

.metric-value,
.metric-title {
  text-align: center;
}

.metric-value {
  font-size: var(--font-size-large);
  border-bottom: var(--item_dashed_border);
}

.metric .metric-value {
  overflow: hidden;
}
