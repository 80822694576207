@import "../../styles/global_colors.css";

.back {
  color: var(--blue);
}

.row {
  flex: 1;
  line-height: 22px;
  padding: 12px 10px;
  white-space: normal;
}
