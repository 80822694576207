textarea {
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
}

.action {
  position: relative;
  flex: 1;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 hsla(0, 0%, 0%, 0.42);
}

.action textarea {
  line-height: normal;
  background: none;
}

.action :global(.react-kronos) {
  flex: 1;
}
.action :global(.react-kronos) input {
  font-size: inherit;
  border: 0;
  background: none;
}

.bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid hsl(0, 0%, 90%);

  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.bar :global(.jaqen) {
  color: rgb(151, 99, 219);
  border-radius: 4px;
}

.bar :global(.jaqen.true) {
  border: 1px solid rgb(151, 99, 219);
}

.bar textarea {
  color: rgb(151, 99, 219);
  text-align: center;
  padding: 0 10px;
}

.values {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 6px 0;
  border-radius: 0 0 4px 4px;
  background-color: hsl(0, 0%, 98%);
}

.values label {
  font-size: 12px;
  line-height: 24px;
  padding: 0 10px;
  color: rgb(151, 99, 219);
}

.values textarea, .values input[type='text'] {
  padding: 3px 10px;
  flex: 1;
  color: hsl(0, 0%, 50%);
}

.values textarea:focus, .values input[type='text']:focus {
  color: hsl(0, 0%, 0%);
}

.values input[type='checkbox'] {
  margin: auto 10px auto 0;
}

.row {
  display: flex;
  flex: 1;
  min-height: 24px;
  justify-content: space-between;
}
