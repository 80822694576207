@import "../../styles/global_colors.css";

.type {
  color: var(--blue);
  margin-right: 10px;
}

.cardName {
  padding-left: 10px;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
