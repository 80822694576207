@import '../shared.css';

:root {
  --green: #7ed321;
  --green-lighter: #7ed321cc;
}

.container {
  display: flex;
  flex-direction: row;
}

.button {
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  background: var(--green-lighter);
  @nest &:hover {
    background: var(--green);
  }
}
