@import "../../styles/global_colors.css";

.wrapper {
  display: flex;
  color: hsl(0, 0%, 50%);
  cursor: pointer;

  margin: 1px 15px 0;
  box-shadow:
    0 -1px 0 hsl(0, 0%, 90%),
    0 1px 0 hsl(0, 0%, 90%);
}

.wrapper:first-child {
  margin-top: 18px;
}

.wrapper:hover {
  box-shadow:
    0 -1px 0 hsl(0, 0%, 90%),
    0 1px 0 hsl(0, 0%, 90%),
    2px 0 0 hsl(0, 0%, 75%),
    -2px 0 0 hsl(0, 0%, 75%);
}

.icon {
  display: flex;
  color: hsl(0, 0%, 75%);
  justify-content: center;
  width: 44px;
  align-items: center;
}

.title {
  display: flex;
  flex: 6;
  padding: 12px 10px;
}

.count {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 12px 10px;
}

.subscribed {
  color: var(--blue);
}

.subscribedLabel {
  font-size: 14px;
}
