@import "../../../styles/global_colors.css";

.image-wrapper-border {
  border-bottom: var(--item_dashed_border);
}

.image-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 33.33%;
}

.image-item-cursor {
  cursor: -webkit-grab;
  cursor: grab;
}

.dragging {
  opacity: 0;
}

.image-item:not(:last-child) {
  padding: 0 5px 0 0;
}

@media (max-width: 1440px) {
  .image-item {
    flex: 1 0 33.33%;
  }

  .image-container {
    flex-wrap: wrap;
  }

  .image-item:not(:nth-child(3n)) {
    padding: 0 5px 0 0;
  }
}

.image-content {
  border-bottom: var(--item_dashed_border);
}

.image-delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--background-grey-light-color);
  color: var(--blue);
  height: 44px;
  border: none;
  cursor: pointer;
}

.image-delete > span {
  opacity: 0.8;
}

.image-delete:hover {
  background-color: var(--grey-94)
}

.image-delete:hover > span {
  opacity: 1;
}

.del-icon {
  padding: 3px 5px 0 0;
  height: 20px;
}

.playing-ellipsis:after {
  display: inline-block;
  animation: ellipsis steps(4, end) 1s infinite;
  content: ".\2009\2009";
  width: 10px;
  padding: 0 3px;
}

/*content: "\2009"; !* ascii code for the ellipsis character *!*/

@keyframes ellipsis {
  33% {
    content: "..\2009";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".\2009\2009";
  }
}
