$hover: hsl(0, 0%, 96%);
$input-color-placeholder: #999;

@mixin state-focus() {
  color: $grey-50;
  background-color: hsl(0, 0%, 96%);
  border: 1px solid hsl(0, 0%, 92%);
}

@mixin state-selected($color) {
  background-color: $color;
  color: white;
  border: 1px solid $transparent;

  &:hover {
    background-color: $color;
    color: white;
    border: 1px solid $transparent;
  }
}

@mixin each-color($element, $prop) {
  @each $type, $color in
    ( 'container': $blue
    , 'item': $orange
    , 'attribute.campus': $aqua
    , 'message.draft': $orange
    , 'message.scheduled': $green) {

    .#{$type} #{$element} {
      #{$prop}: $color;
    }
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // See https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  // Internet Explorer 10+
  &::-webkit-input-placeholder {
    color: $color;
  }
  // Safari and Chrome
}


.rw-widget {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  outline: 0;
  position: relative;

  &.rw-combobox {
    flex-wrap: nowrap;
  }
}


.rw-i {
  display: inline-block;
  font-family: 'Streamline';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: $grey-75;
  line-height: 44px;
}

.list-unstyled {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin: 0;
  padding-left: 0;
  list-style: none;

  > li {
    display: inline-block;
    padding: 0 5px;
  }
}


.rw-btn, .rw-cell, .rw-input, .rw-input-reset {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: 0;
}

button.rw-input {
  overflow: visible;
}

button.rw-input,select.rw-input {
  text-transform: none;
}

button.rw-input,html input[type="button"].rw-input,input[type="reset"].rw-input,input[type="submit"].rw-input {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rw-input,html input[disabled].rw-input {
  cursor: default;
}

button.rw-input::-moz-focus-inner,input.rw-input::-moz-focus-inner,input.rw-input-reset:-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.rw-btn {
  display: flex;
  @include flex-center;

  @include mp;
  width: 44px;
  border: 0;
  background-color: white;
}

@include each-color('.rw-btn:hover > .rw-i', 'color' );

.rw-rtl {
  direction: rtl;
}

.rw-input, .rw-input-reset {
  /*height: 1.94em;*/
  height: 44px;
  line-height: 44px;
  // padding: .177em 0;
  // text-indent: .64em;
}

.rw-i.rw-loading {
  // background: url("loading.gif") no-repeat center;
  width: 16px;
  height: 100%;
}

.rw-state-focus {
  @include state-focus;
}

@each $type, $color in (
    'container': $blue
  , 'item': $orange
  , 'attribute.campus': $aqua
  , 'message.draft': $orange
  , 'message.scheduled': $green) {

  .#{$type} .rw-state-selected {
    @include state-selected($color);
  }
}



.rw-widget.rw-state-focus {
  background: initial;
  border: 0;
}

.rw-btn,.rw-dropdownlist,.rw-cell {
  cursor: pointer;
}

ul.rw-list {

  margin: 0;
  padding: 1px;
  list-style: none;
  overflow: auto;
  outline: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  > li {
    flex: 1 20%;
    cursor: pointer;
    padding: 0 10px;
    margin: 1px;
    border-radius: 3px;
    line-height: 2em;
    border: 1px solid $transparent;

    &.rw-state-focus {
      @include state-focus;
    }

    &:hover {
      border: 1px solid $grey-90;
    }
  }
}

.rw-datetime-picker {
  .rw-list {
    flex-direction: row;
  }
  .rw-list > li {
    flex: 1 calc(25% - 2px);
    text-align: center;
    min-width: 80px;
  }
}

.rw-combobox {
  .rw-btn {
    order: 1;
  }
  .rw-list > li {
    flex: initial;
    line-height: 2.5em;
    padding: 0 15px;
  }
}

.rw-multiselect {
  .rw-list > li {
    flex: initial;
    line-height: 38px;
  }
  .rw-popup-container {
    top: 100%;

    .rw-popup > .rw-list {
      flex-direction: row;
    }
  }
}

.rw-widget-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.rw-dropdown-list-input {
  flex: 1;
  padding-left: 10px;
}

.rw-popup-transition-exited {
  display: none;
}

.rw-dropdownlist {

  .rw-btn {
    order: 1;
  }
  .rw-list {
    min-height: min-content;
    flex-direction: column;
    flex-wrap: nowrap;
    li {
      flex: initial;
      line-height: 2.5em;
    }
  }
  div.rw-input {
    padding-left: 10px;
  }
}

.rw-combobox>.rw-input,.rw-datepicker>.rw-input,.rw-numberpicker>.rw-input,.rw-dropdownlist>.rw-input {
  // width: 100%;
  flex: 1;
  border: none;
  outline: 0;
  // background: none;
}

.rw-select {
  display: flex;
  // position: absolute;
  // width: 1.9em;
  height: 43px;
  // right: 0;

  &.rw-btn, > .rw-btn {
    // height: 100%;
    // vertical-align: middle;
    outline: 0;
    width: 45px;
    border-left: $item_dashed_border;

    // &:last-child {
    //   border-right: $item_dashed_border;
    //   margin-right: 1px;
    // }
  }
}

.rw-numberpicker {
  .rw-select {
    display: none;
    // flex-directi
    // width: 24px;
    // order: 1;

    .rw-btn {
      flex: 1;
    }
  }
  .rw-input {
    text-align: right;
    padding-right: 10px;
  }
}

.rw.open {
  > .rw-btn {
    color: $orange;
  }
}

.rw-combobox.rw-state-focus,.rw-multiselect.rw-state-focus,.rw-datepicker.rw-state-focus,.rw-numberpicker.rw-state-focus,.rw-dropdownlist.rw-state-focus,.rw-combobox.rw-state-focus:hover,.rw-multiselect.rw-state-focus:hover,.rw-datepicker.rw-state-focus:hover,.rw-numberpicker.rw-state-focus:hover,.rw-dropdownlist.rw-state-focus:hover {
  .rw-input {
    color: black;
  }
}

.rw-combobox[disabled],.rw-multiselect[disabled],.rw-datepicker[disabled],.rw-numberpicker[disabled],.rw-dropdownlist[disabled],fieldset[disabled] .rw-combobox,fieldset[disabled] .rw-multiselect,fieldset[disabled] .rw-datepicker,fieldset[disabled] .rw-numberpicker,fieldset[disabled] .rw-dropdownlist {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
}

.rw-combobox[disabled],.rw-multiselect[disabled],.rw-datepicker[disabled],.rw-numberpicker[disabled],.rw-dropdownlist[disabled],fieldset[disabled] .rw-combobox,fieldset[disabled] .rw-multiselect,fieldset[disabled] .rw-datepicker,fieldset[disabled] .rw-numberpicker,fieldset[disabled] .rw-dropdownlist,.rw-combobox[disabled]:hover,.rw-multiselect[disabled]:hover,.rw-datepicker[disabled]:hover,.rw-numberpicker[disabled]:hover,.rw-dropdownlist[disabled]:hover,fieldset[disabled] .rw-combobox:hover,fieldset[disabled] .rw-multiselect:hover,fieldset[disabled] .rw-datepicker:hover,fieldset[disabled] .rw-numberpicker:hover,fieldset[disabled] .rw-dropdownlist:hover,.rw-combobox[disabled]:focus,.rw-multiselect[disabled]:focus,.rw-datepicker[disabled]:focus,.rw-numberpicker[disabled]:focus,.rw-dropdownlist[disabled]:focus,fieldset[disabled] .rw-combobox:focus,fieldset[disabled] .rw-multiselect:focus,fieldset[disabled] .rw-datepicker:focus,fieldset[disabled] .rw-numberpicker:focus,fieldset[disabled] .rw-dropdownlist:focus,.rw-combobox[disabled]:active,.rw-multiselect[disabled]:active,.rw-datepicker[disabled]:active,.rw-numberpicker[disabled]:active,.rw-dropdownlist[disabled]:active,fieldset[disabled] .rw-combobox:active,fieldset[disabled] .rw-multiselect:active,fieldset[disabled] .rw-datepicker:active,fieldset[disabled] .rw-numberpicker:active,fieldset[disabled] .rw-dropdownlist:active {
  box-shadow: none;
  background-color: #eee;
  border-color: #ccc;
}

.rw-combobox .rw-input[disabled],.rw-datepicker .rw-input[disabled],.rw-numberpicker .rw-input[disabled],.rw-dropdownlist .rw-input[disabled],.rw-combobox fieldset[disabled] .rw-input,.rw-datepicker fieldset[disabled] .rw-input,.rw-numberpicker fieldset[disabled] .rw-input,.rw-dropdownlist fieldset[disabled] .rw-input,.rw-combobox .rw-input[disabled]:hover,.rw-datepicker .rw-input[disabled]:hover,.rw-numberpicker .rw-input[disabled]:hover,.rw-dropdownlist .rw-input[disabled]:hover,.rw-combobox fieldset[disabled] .rw-input:hover,.rw-datepicker fieldset[disabled] .rw-input:hover,.rw-numberpicker fieldset[disabled] .rw-input:hover,.rw-dropdownlist fieldset[disabled] .rw-input:hover,.rw-combobox .rw-input[disabled]:focus,.rw-datepicker .rw-input[disabled]:focus,.rw-numberpicker .rw-input[disabled]:focus,.rw-dropdownlist .rw-input[disabled]:focus,.rw-combobox fieldset[disabled] .rw-input:focus,.rw-datepicker fieldset[disabled] .rw-input:focus,.rw-numberpicker fieldset[disabled] .rw-input:focus,.rw-dropdownlist fieldset[disabled] .rw-input:focus,.rw-combobox .rw-input[disabled]:active,.rw-datepicker .rw-input[disabled]:active,.rw-numberpicker .rw-input[disabled]:active,.rw-dropdownlist .rw-input[disabled]:active,.rw-combobox fieldset[disabled] .rw-input:active,.rw-datepicker fieldset[disabled] .rw-input:active,.rw-numberpicker fieldset[disabled] .rw-input:active,.rw-dropdownlist fieldset[disabled] .rw-input:active {
  background-color: #eee;
  border-color: #ccc;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.slide {
  position: absolute;
  overflow: hidden;
  transition: transform 4.25s ease;
}

.slide-down {
  transform: translateY(0);
}

.slide-up {
  transform: translateY(0);
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .25s ease;
}

.slide-left {
  transition: left 4.35s ease;
  position: absolute;
  overflow: hidden;
  left: 100%;
}

.slide-right {
  transition: left 4.35s ease;
  position: absolute;
  overflow: hidden;
  left: -100%;
}

.slide-left.in,.slide-right.in {
  left: 0;
}

.slide-left.out {
  left: -100%;
}

.slide-right.out {
  left: 100%;
}

.rw-popup {
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid $grey-75;
  background: #fff;
  // padding: 2px;
  width: 100%;
  // overflow: auto;
  // display: flex;
  // flex-direction: auto;
}

.rw-popup-container {
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 9000;
  width: 100%;
}

.rw-dropdownlist {
  .rw-popup-container {
    right: 0;
  }
}

.rw-datepicker.rw-has-both>.rw-select {
  // width: 3.8em;
  height: 100%;
}

.rw-calendar {
  // background-color: #fff;
  line-height: 2em;

  .rw-calendar-footer {
    flex: 1;
    .rw-btn {
      width: 100%;
      height: 44px;
    }
  }
}

.rw-calendar thead > tr {
  // line-height: 1.5em;
  // border-bottom: 2px solid #ccc;
}

.rw-calendar .rw-calendar-header {
  flex: 1 100%;
  // line-height: 2em;
  border-bottom: 1px solid $grey-90;
  display: flex;
}

.rw-calendar .rw-calendar-header .rw-calendar-btn-left,.rw-calendar .rw-calendar-header .rw-calendar-btn-right {
  width: 44px;
  background-color: $hover;

  &:hover {
    background-color: white;
  }
}

.rw-calendar .rw-calendar-header .rw-calendar-btn-view {
  flex: 1;
  outline: 0;
  background-color: $hover;

  &:hover {
    background-color: white;
  }
}

@include each-color('.rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover', 'color');


.rw-calendar-grid {
  table-layout: fixed;
  width: 100%;
}

.rw-calendar-grid th {
  // text-align: right;
  // padding: 0 .4em 0 .1em;
}

.rw-calendar-grid .rw-cell {
  width: 100%;
}

.rw-calendar-grid td.rw-cell {
  line-height: 2em;
  border-radius: 3px;
  border: 1px solid $transparent;
}

.rw-calendar-grid td.rw-cell:not(.rw-state-selected):hover {
  border: 1px solid $grey-90;
}

.rw-calendar-grid td.rw-cell.rw-cell-off-range {
  color: hsl(0, 0%, 70%);
}

.rw-calendar-grid.rw-nav-view .rw-cell {
  overflow: hidden;
  text-align: center;
  white-space: normal;
}

.rw-widget-input > div {
  border-radius: 4px;
  position: relative;
  cursor: text;
  @include clearfix();

  span.rw-loading {
    position: absolute;
    right: 3px;
  }

  > .rw-input-reset {
    @include placeholder();
    outline: 0;
    border-width: 0;
    line-height: normal;
    width: auto;
    max-width: 100%;
  }

  .rw-state-readonly > &,
  .rw-state-disabled > & {
    cursor: not-allowed;
  }

  .rw-rtl & > .rw-input {
    float: right;
  }
}

.rw-widget-input > div, .rw-widget-input > div .rw-input-reset {
  flex: 1;
}

.rw-widget-input > div i.rw-loading {
  position: absolute;
  right: 3px;
}

.rw-widget-input > div > .rw-input-reset {
  float: left;
  outline: 0;
  border-width: 0;
  line-height: normal;
  width: auto;
  height: 42px;
  padding-left: 10px;

  &:focus { background: white !important; }
}

.rw-rtl .rw-widget-input > div > .rw-input-reset {
  float: right;
}

.rw-widget-input > div .rw-tag-btn {
  outline: 0;
  font-size: 200%;
  font-weight: 100;
  line-height: 38px;
  cursor: pointer;
}

.rw-multiselect-taglist {
  padding: 1px;
  margin: 0;
  // display: flex;
  // flex-wrap: wrap;
}

.rw-multiselect-taglist > li {
  float: left;
  display: flex;
  align-items: center;
  margin: 1px;
  white-space: nowrap;
  border-radius: 3px;
  border: $item_row_border;
  cursor: default;
  line-height: 1;

  &:last-child {
    margin-bottom: 2px;
  }

  > span {
    padding: 0 10px;
  }
}

@each $type, $color in (
    'container': $blue
  , 'user-row': $orange
  , 'item': $orange
  , 'message.draft': $orange
  , 'message.scheduled': $green) {

  .#{$type} .rw-multiselect-taglist > li {
    border-color: rgba($color, 0.5);
    &:hover {
      border-color: $color;
    }

    .rw-btn, .rw-cell {
      background-color: $hover;
      &:hover {
        color: $color;
        background-color: white;
      }
    }
  }
}

.rw-multiselect-taglist > li.rw-state-focus {
  background-color: #fff;
  border: #e0e0e0 1px solid;
  color: #333;
}

.rw-rtl .rw-multiselect-taglist > li {
  float: right;
}

.rw-i-chevron-left:before {
  content: "\e62a";
}

.rw-i-chevron-right:before {
  content: "\e62b";
}

.rw-i-chevron-up:before {
  content: "\f077";
}

.rw-i-chevron-down:before {
  content: "\f078";
}

.rw-i-times:before {
  content: "\f00d";
}

.rw-i-ban:before {
  content: "\f05e";
}

.rw-i-arrow-left:before {
  content: "\f060";
}

.rw-i-arrow-right:before {
  content: "\f061";
}

.rw-i-arrow-up:before {
  content: "\f062";
}

.rw-i-arrow-down:before {
  content: "\f063";
}

.rw-i-expand:before {
  content: "\f065";
}

.rw-i-compress:before {
  content: "\f066";
}

.rw-i-plus:before {
  content: "\f067";
}

.rw-i-minus:before {
  content: "\f068";
}

.rw-i-calendar:before {
  content: "\e31f";
}

.rw-i-caret-down:before {
  content: "\e628";
}

.rw-i-caret-up:before {
  content: "\e629";
}

.rw-i-caret-left:before {
  content: "\e62a";
}

.rw-i-caret-right:before {
  content: "\e62b";
}

.rw-i-clock-o:before {
  content: "\e321";
}
