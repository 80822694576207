@import "../../styles/global_colors.css";
@import "../../styles/global_themeing.css";

.container .button {
  width: 45px;
  height: 45px;
  padding: 0;
  min-width: 0;
}

.container .button:hover {
  background: none;
}

.container .button:focus {
  outline: -webkit-focus-ring-color auto 1px;
  /* matches styling of a:focus in _universal.scss line 109 */
  color: var(--blue);
  -webkit-filter: drop-shadow(0 0 1px var(--blue)4C);
}

.switcher-icon {
  width: 20px;
  height: 20px;
  fill: var(--blue);
}

.menu .list {
  min-width: 300px;
  overflow: visible;
}

.list-item {
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  font-size: 16px;
  color: var(--grey-darker);
  align-items: center;
}

.list-item-inaccessible {
  cursor: not-allowed;
}

.list-item-active {
  background: var(--grey-94);
  font-weight: 600;
  border-left: 5px solid var(--grey-mid);
  padding-left: 15px;
}

.list-item-header {
  margin-bottom: 5px;
  line-height: 24px;
}

.heading {
  font-size: 18px;
  font-weight: 600;
  color: var(--grey-darker);
  flex: 1;
}

.marketing-link {
  color: var(--grey-dark);
  display: flex;
  align-items: center;
}

.marketing-link-icon {
  display: inline-flex;
  margin-left: 5px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 3px;
  overflow: hidden;
}

.info {
  flex: 1;
}

.text {
  display: flex;
  align-items: center;
}

.name {
  flex: 1;
  padding-right: 10px;
}

.inaccessible-icon {
  display: inline-flex;
}

.learn-more-link {
  font-size: 15px;
}
