@import 'partials/variables';

.modal {
  @extend .special-fullscreen;
  display: flex;
  @include flex-center;
  opacity: 1;
  transition: opacity 0.12s ease-in;

  .title {
    position: absolute;
    width: 100%;
    top: -45px;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    left: 20px;

    img {
      width: 145px;
      margin: 0 13px 4px 0;
    }

    svg {
      height: 30px;
      margin-right: 15px;
    }
  }

  .box-long {
    position: relative;
    width: 50%;
    min-width: 640px;
    min-height: 360px;
    max-height: 70%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 30px 15px hsla(0, 0%, 0%, 0.05);
    display: flex;
    flex-direction: column;
  }

  .content-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    position: relative;
    padding: 45px;

    ul { margin-left: 20px; }
    ol { margin-left: 25px; }
    ul, ol, p { margin-top: 12px; margin-bottom: 12px; }

  }

  .box {
    position: relative;
    width: 50%;
    min-width: 640px;
    height: 50%;
    min-height: 360px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 30px 15px hsla(0, 0%, 0%, 0.05);
    padding: 15px;
  }

  .search {
    width: 100%;
    padding: 12px;
    outline: none;
    font-size: 20px;
  }

  .switcher-action {
    position: fixed;
    color: white;
    cursor: pointer;

    &.tr {
      top: 0;
      right: 0;
    }
    &.br {
      bottom: 0;
      right: 0;
    }
  }

  .helpful-tip {
    position: fixed;
    bottom: 0;
    right: 44px;
    line-height: 44px;
    color: white;
    font-style: italic;
  }

  .watermarks {
    display: flex;
    position: absolute;
    top: 68px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    li {
      flex: 1;
      display: flex;
      @include flex-center;
      list-style: none;
      > .watermark {
        color: hsla(0, 0%, 0%, 0.03);
        font-size: 160px;
        line-height: 160px;
      }
    }
  }

  .listing {
    display: flex;
    position: absolute;
    top: 68px;
    bottom: 15px;
    left: 15px;
    right: 15px;

    ul {
      flex: 1;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.05);
        }
      }
      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 5px;
        background-clip: padding-box;
        border: 2px solid rgba(0, 0, 0, 0);
      }

      &:first-child {
        border-right: 1px solid transparent;
        li {
          margin-right: 1px;
        }
      }

      li {
        cursor: pointer;
        list-style: none;
        padding: 6px 8px;
        margin-left: 3px;
        display: flex;
        flex-direction: column;

        &.current {
          color: $primary;
          font-style: italic;
        }

        &[data-state=selected] {
          background-color: rgba($primary, 0.12);
          box-shadow: -3px 0 0 $primary;
        }

        &.tip {
          color: $grey-75;
          font-style: italic;
          pointer-events: none;
          text-align: center;
        }
      }
    }
  }

  &.confirm {
    .content-scroll {
      display: flex;
      align-items: center;
      flex-direction: column;

      > :first-child {
        margin: auto auto 0 auto;
      }

      .buttons {
        align-self: flex-end;
        margin: auto;
        display: flex;

        .button {
          padding: 0 1em;
          margin-left: 1em;
          min-width: 125px;
          margin-bottom: 0;

          &:first-of-type {
            margin-left: 0;
          }

          &:active {
            background-color: transparentize($primary, .95);
          }

          &.cancel {
            border-color: transparent;
            &:active {
              background-color: transparentize($red, .95);
            }
          }
        }
      }

    }
  }

  &.false {
    pointer-events: none;
    opacity: 0;
  }

  &.none {
    display: none;
  }
}
