.login-page {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
}

.login {
  display: block;
  padding: 0 20px;
  min-width: 600px;
  margin-top: 60px;
  flex: 1;
  font-family: $font-family;

  @media screen and (max-width: $breakpoint-medium) {
    padding: 0;
    min-width: 0;
  }

  .login-heading {
    display: flex;
    @include flex-center;
    flex-direction: column;

    > img {
      height: 50px;
      width: 167px;
      margin-top: 24px;
    }

    .login-main-heading {
      padding-top: 0;
      color: $font-header-color;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: $spacing-vertical-large auto 0 auto;
    justify-content: space-between;
    border-top: solid 6px $grey-mid;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $box-shadow-color;

    @media screen and (max-width: $breakpoint-medium) {
      border-radius: 0;
    }

    .login-form-header {
      font-size: $font-size-medium;
      padding: $spacing-vertical-large 0;
      color: $font-form-header-color;
      text-align: center;
      line-height: 1.5;
    }

    .login-form-body {
      border-top: solid 1px $border-color;
      border-bottom: solid 1px $border-color;
      background-color: $background-grey-light-color;
      display: flex;
      flex-direction: column;
      padding: $spacing-vertical-large calc(50% - 220px);

      @media screen and (max-width: $breakpoint-medium) {
        padding: $spacing-vertical-large;
      }

      label {
        font-size: $font-size-small;
        font-weight: 700;
        color: $font-default-color;
        line-height: 1.6;
      }

      > input {
        flex: 1;
        margin-top: 5px;
        margin-bottom: 18px;
        border-radius: 4px;
        border: solid 1px $border-color;

        padding: 15px 20px;

        &::placeholder {
          font-weight: 500;
          font-size: $font-size-medium;
          line-height: 24px;
          color: $font-placeholder-color;
        }

        &:focus {
          outline: none;
        }
      }

      .forgot_password_link {
        text-align: left;
        font-size: $font-size-small;
        font-weight: 500;
        line-height: 24px;

        a {
          color: $font-default-color;
          border-bottom: dashed 1px $border-link-color;
        }
      }
    }

    > button {
      color: $pushpay-red;
      border-color: $pushpay-red-border;
      margin: $spacing-vertical-large auto;
      padding: 14px 0;
      border-radius: 6px;
      border: 1px solid;
      width: 260px;
      font-weight: 600;

      @media (max-width: $breakpoint-medium) {
        margin: 40px;
        width: auto;
      }

      &:hover {
        color: white;
        background-color: $pushpay-red-highlight;
        cursor: pointer;
      }
    }
  }

  .error-message {
    margin: $spacing-vertical auto 0;
    font-size: $font-size-small;
    color: $red;
    max-width: 600px;
  }
  .success-message {
    margin: $spacing-vertical auto 0;
    font-size: $font-size-small;
    color: $green;
    max-width: 600px;
  }

  .login-footer {
    border-top: none;
    padding-top: $spacing-vertical-large;
    text-align: center;
  }

  .login-logo {
    width: 225px;
    height: auto;
  }
}

.login-promo {
  flex: 1;
  border: none;
  display: block;

  @media (max-width: $breakpoint-login-medium) {
    display: none;
  }
}
