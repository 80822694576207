@import "../styles/global_colors.css";
@import "../styles/global_themeing.css";

:root {
  --spacing-vertical-large: 40px;
  --error-element-color: #cf150a;
  --success-element-color: var(--green);
}

@custom-media --smaller-than-mid-breakpoint (max-width: var(--breakpoint-medium));

.login-page {
  font-family: var(--font-family);
  min-height: 100vh;
  padding: 0 20px;
  min-width: 600px;
  margin-top: 60px;

  display: flex;
  min-height: 100vh;
}

.login-container {
  flex: 1;
}

.login-flex-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.login-panel {
  display: flex;
  flex-direction: column;

  margin: auto 0 auto;
  justify-content: space-between;
  border-top: solid 6px var(--grey-mid);

  border-radius: 4px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-color);
}

.login-banner {
  align-items: center;
  justify-content: center;

  display: flex;
  flex-direction: column;

  margin-bottom: var(--spacing-vertical-large);
}

.login-banner img {
  height: 50px;
  width: 167px;
  margin-top: 24px;
}

.login-banner h1 {
  color: var(--grey-darker);
}

.login-panel p {
  font-size: var(--font-size-medium);
  padding: var(--spacing-vertical-large) 20px;
  color: var(--grey-dark);
  text-align: center;
  line-height: 1.5;
}

.login-panel button {
  color: var(--pushpay-red);
  background-color: var(--background-white-color);
  margin: var(--spacing-vertical-large) auto;
  padding: 14px 0;
  border-radius: 6px;
  border: 1px solid var(--pushpay-red-border);
  width: 260px;
  font-weight: 600;
}

@media --smaller-than-mid-breakpoint {
  .login-panel button {
    margin: 40px;
    width: auto;
  }
}

.login-panel button:hover {
  color: white;
  background-color: var(--pushpay-red-highlight);
  cursor: pointer;
}

.login-promo {
  flex: 1;
  border: none;
  display: block;
}

@media (max-width: 1024px) {
  .login-promo {
    display: none;
  }
}

.login-footer {
  border-top: none;
  padding-top: var(--spacing-vertical-large);
  text-align: center;
}

.forgot_password {
  text-align: left;
  font-size: var(--font-size-small);
  font-weight: 500;
  line-height: 24px;
}

.forgot_password a {
  color: var(--font-default-color);
  border-bottom: dashed 1px var(--border-link-color);
}

.forgot_password a:hover {
  border-bottom-style: solid;
  color: var(--font-default-color);
  filter: none;
  text-decoration: none;
}

.login-form {
  border-top: solid 1px var(--border-color);
  border-bottom: solid 1px var(--border-color);
  background-color: var(--background-grey-light-color);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-vertical-large) calc(50% - 220px);
}

.login-form label {
  font-size: var(--font-size-small);
  font-weight: 700;
  color: var(--font-default-color);
  line-height: 1.6;
}

.login-form input {
  flex: 1;
  margin-top: 5px;
  margin-bottom: 18px;
  border-radius: 4px;
  border: solid 1px var(--border-color);
  padding: 15px 20px;
}

.login-form input:placeholder {
  font-weight: 500;
  font-size: var(--font-size-medium);
  line-height: 24px;
  color: var(--font-placeholder-color);
}

.login-form input:focus {
  outline: none;
}

.login-logo {
  width: 225px;
  height: auto;
}

.error-message, .success-message {
  display: flex;
  border-left-width: 5px;
  border-radius: 4px;
  align-items: center;
  background: var(--background-white-color);
  padding: 15px;
  margin-bottom: 24px;
  border: 1px solid;
  border-left-width: 5px;
}

.error-message {
  color: var(--font-error-color);
  border-color: var(--error-element-color);
}

.success-message {
  color: var(--font-default-color);
  border-color: var(--success-element-color)
}

.success-message .svg {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  fill: var(--success-element-color);
}

.error-message .svg {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  fill: var(--error-element-color);
}

@media screen and --smaller-than-mid-breakpoint {
  /* Snap components to the edges of the screen for smaller viewports. */
  .login-panel, .error-message {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .login-form {
    padding: var(--spacing-vertical-large);
  }
}
