@import "../../../styles/global_colors.css";

.container .button {
  width: 45px;
  height: 45px;
  padding: 0;
  min-width: 0;
}

.container .button:hover {
  background: none;
}

.container .button:focus {
  outline: -webkit-focus-ring-color auto 1px;
  /* matches styling of a:focus in _universal.scss line 109 */
  color: var(--blue);
  -webkit-filter: drop-shadow(0 0 1px var(--blue)4C);
}

.icon {
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 24px;
  width: 24px;
  color: var(--blue);
}

.link {
 color: var(--primary);
 text-decoration: underline
}
