@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-light-italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-regular-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-medium-italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  src: url('../images/ui/sf-bold-italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

.preview {
  @include r('flex', initial, initial, initial, 1 100%, 1 100%);
  @include r('margin', 0 0 30px, 0 0 18px, 0 auto 18px, 0 auto 30px, 0 auto 30px);

  position: relative;
  display: flex;
  @include flex-center;
  @include p('height', 752px);
  @include p('width', 368px);

  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 30px;

  &-branded-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-branded-header,
  // Targeting Dark Mode tinted image rendered with React Native Web
  // The image is composed of divs with generated classnames so we
  // can only select by matching the element with the background image applied
  &-branded-header [style*='background-image'] {
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center 75%;
  }

  &-dark {
    color-scheme: dark;
  }
}

.os {
  position: relative;
  @include p('width', $os-preview-width * 1px);
  @include p('height', 568px);
  @include p('font-size', 14px);
  background: white;
  overflow: hidden;
  border: $os-preview-border-width * 1px solid $border-color;

  a {
    cursor: default;
  }

  .title-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 20;
    color: white;

    .titlebar-action {
      display: flex;
      position: absolute;
      @include p('height', 44px);
    }

    [class^='asset-'] {
      @include p('line-height', 44px);
    }

    .titlebar-action {
      @include flex-center;

      &.titlebar-left {
        justify-content: flex-start;
        text-align: left;

        .asset-back {
          + .titlebar-label {
            @include p('margin-left', -24px);
          }
        }

        .ui-icon-arrow-left {
          @include p('font-size', 20px);
        }
      }

      .titlebar-label-legacy {
        @include p('font-size', 17px);
        @include p('max-width', 48px);
        @include ellip;

        &.wide {
          @include p('max-width', 276px);
        }
      }

      .titlebar-label {
        @include p('font-size', 17px);
        @include p('max-width', 48px);
        @include ellip;
        @include p('height', 44px);
        @include p('line-height', 44px);

        &.wide {
          @include p('max-width', 276px);
        }
      }
    }

    .titlebar-left {
      left: 0px;
    }

    .titlebar-right {
      right: 0px;
    }

  }

  .side-nav {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 20;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      @include p('width', 12px);
      background-image: linear-gradient(to left, hsla(0, 0%, 0%, .2), $transparent);
    }

    .white-fill {
      position: absolute;
      @include p('top', 65px);
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: white;
    }

    ~ .side {
      [class^='list-'] {
        @include p('margin-right', 20px);
      }
    }
  }

  .content {
    position: absolute;
    left: 0px;
    right: 0px;
    height: auto;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;

    // TODO: remove when old Preview UI code is removed
    &.dhs-preview {
      overflow-y: scroll;
      box-sizing: content-box;
      width: 100%;
      background-color: #e5e5e5;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
      }
    }
    // Style for new RN Preview
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
    }
  }

  .container-image { width: 100%; }
}

.preview-warning {
  position: absolute;
  top: 0;
  height: 55px;
  background-color: #fff0d9;
  width: calc(100% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2ddb7;
  @include p('border-top-left-radius', 55px);
  @include p('border-top-right-radius', 55px);
  @include p('font-size', 14px);
  font-weight: 600;
  color: #cc8b1b;
}






.preview.ios {
  background-image: url('../images/preview/iphone_6_outline.svg');
  * {
    font-family: 'San Francisco', 'Helvetica', 'Arial';
  }

  // status bar: 20dp
  // title bar: 44dp
  // tab bar: 49dp

  .title-bar {
    @include p('height', 64px);
    text-align: center;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3); // remove after SEC_SCREENS_DETAILS removal

    &.light {
      background-image: url('../images/preview/ios_status_bar_black.svg');
    }

    &.dark {
      background-image: url('../images/preview/ios_status_bar_white.svg');
    }

    .time {
      @include p('height', 20px);
      @include p('font-size', 12px);
      font-weight: 500;
      display: flex;
      @include flex-center;
    }

    h1 {
      font-weight: normal;
      margin: auto;
      @include p('max-width', 180px);
      @include p('padding', 5px);
      @include p('line-height', 34px);
      @include p('font-size', 17px);
      @include ellip;
    }

    .titlebar-action {
      @include p('top', 20px);
      @include p('font-size', 44px);
    }

    .titlebar-left {
      .asset-back,
      .ui-icon-arrow-left {
        @include p('margin-left', 8px);
      }
    }
  }

  .tab-bar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    @include p('height', 49px);
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    z-index: 20;

    ul {
      @include mp;
      width: 100%;
      height: 100%;
      list-style: none;
      display: flex;
      @include p('font-size', 10px);

      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @include p('margin-top', 6px);
        @include p('margin-bottom', 2px);
        @include p('margin-left', 2px);
        @include p('margin-right', 2px);
        color: hsl(0, 0%, 60%);

        &.active {
          .icon {
            font-weight: bold;
          }
        }

        &.disable {
          pointer-events: none;
        }



        a {
          @include ellip;
          width: 100%;
          text-align: center;
          color: hsl(0, 0%, 60%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;

          > .icon {
            @include p('font-size', 24px);
            width: 100%;
          }

          .nav-bar-icon-height {
            height: 24px;
          }

          .nav-bar-icon-label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }

  .content {
    @include p('top', 64px);
    &.side { bottom: 0px; }
    &.tabbed { @include p('bottom', 49px); }
  }

  .side-nav {
    @include p('width', 44px);
    &:before {
      @include p('right', 44px);
    }
    .white-fill {
      @include p('top', 65px);
    }
  }

  .menu-button {
    position: absolute;
    @include p('bottom', 22px);
    @include p('height', 56px);
    @include p('width', 56px);
    @include p('left', 156px);
    border-radius: 50%;
  }
}

.preview.android {
  background-image: url('../images/preview/galaxy_s6_outline.svg');
  * {
    font-family: 'Roboto', sans-serif;
  }

  // status bar: 24dp
  // title bar: 48dp
  // tab bar: 48dp

  .title-bar {
    @include p('height', 72px);
    text-align: left;

    &.light {
      background-image: url('../images/preview/android_status_bar_black.svg');
    }

    &.dark {
      background-image: url('../images/preview/android_status_bar_white.svg');
    }

    .time {
      @include p('height', 24px);
      @include p('font-size', 15px);
      @include p('line-height', 24px);

      display: flex;
      justify-content: flex-end;
      @include p('padding-right', 6px);
    }

    h1 {
      font-weight: 600;
      @include p('margin-left', 15px);
      @include p('margin-right', 44px);
      @include p('line-height', 48px);
      @include p('font-size', 17px);
      @include ellip;
    }

    .right {
      right: 2px;
    }

    .titlebar-label {
      display: none;
    }

    [class^='asset-'] {
      font-weight: bold;
    }

    .titlebar-action {
      @include p('top', 26px);
      @include p('font-size', 44px);

      & + h1 {
        @include p('margin-left', 44px);
      }
    }
  }

  .tab-bar {
    position: absolute;
    @include p('top', 72px);
    left: 0;
    right: 0;
    @include p('height', 48px);
    z-index: 20;
    box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.25);

    // 120

    ul {
      @include mp;
      width: 100%;
      height: 100%;
      list-style: none;
      @include p('font-size', 12px);
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      text-align: center;

      li {
        position: relative;
        display: inline-block;
        height: 100%;
        @include p('min-width', 90px);

        > .icon {
          display: none;
        }

        a {
          color: white !important;
          font-weight: 700;
          text-transform: uppercase;
          @include p('line-height', 48px);
          @include p('padding-left', 12px);
          @include p('padding-right', 12px);
        }

        &.active {
          position: relative;
          border-bottom: 3px solid #ffffff;
        }
      }
    }
  }

  .content {
    bottom: 0px;
    &.side { @include p('top', 72px); }
    &.tabbed { @include p('top', 120px); }
  }

  .side-nav {
    @include p('width', 48px);
    &:before {
      @include p('right', 48px);
    }
    .white-fill {
      @include p('top', 72px);
    }
  }

  .menu-button {
    position: absolute;
    @include p('bottom', 38px);
    @include p('height', 34px);
    @include p('width', 80px);
    @include p('left', 144px);
    border-radius: 24px;
  }
}







[class^='list-'] {
  display: flex;
  align-items: center;
  @include p('margin-left', 15px);
  margin-bottom: 1px;

  .border {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    box-shadow: 0 1px 0 hsl(0, 0%, 85%);
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .label {
    flex: 1;
    color: black;
    @include p('font-size', 17px);
    @include ellip;

    &.double span, .sublabel {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      // This is because we need to wrap text,
      // but also preverse line-breaks.
      // white-space: initial /* only wraps */
      // white-space: pre /* only preserves line-breaks */
      white-space: pre-wrap;
    }

    .sublabel {
      color: $grey-50;
      margin-top: 1px;
      @include p('font-size', 12px);

      &.single {
        -webkit-line-clamp: 1;
      }
    }
  }

  .icon {
    @include p('font-size', 24px);
    @include p('width', 29px);
    @include p('margin-right', 10px);
  }

  .thumb-square {
    @include p('width', 60px);
    @include p('height', 60px);
    @include p('margin-right', 10px);
  }

  .thumb-wide {
    @include p('width', 140px);
    @include p('height', 78px);
    @include p('margin-right', 10px);
  }

  .tidbit {
    color: $grey-50;
    @include p('font-size', 12px);
    @include r('margin-top', 2px, 0, 0, 0, 2px);
    @include p('width', 60px);
    text-align: right;
  }

  .disclosure {
    @include p('margin-right', 15px);
    @include p('margin-left', 6px);
    @include p('width', 9px);

    > img {
      display: block;
      height: auto !important;
      width: 100% !important;
    }
  }

  .indicator {
    @include p('margin-right', 9px);
    @include p('margin-left', 9px);
    @include p('width', 12px);
    @include p('height', 12px);
    border-radius: 50%;
    &.unread {
      background-color: $primary;
    }
  }
}

.list-44dp {
  @include p('height', 44px);
}

.list-72dp {
  @include p('height', 72px);

  &.indicator {
    margin-left: 0px;
  }
}

.list-90dp {
  @include p('height', 90px);
}

.list-90dp-section {
  @include p('height', 90px);
  box-shadow: 0 1px 0 #d9d9d9;
  margin-left: 0px;
  @include p('padding-left', 15px);

  .thumb {
    @include p('width', 65px);
    @include p('height', 60px);
    @include p('margin-right', 10px);
    display: flex;
    @include flex-center;
    color: $grey-50;
    @include p('font-size', 15px);
  }

  &.header {
    display: flex;
    justify-content: space-between;
    @include p('height', 22px);
    color: $grey-50;
    @include p('font-size', 15px);

    margin: 0px;
    @include p('padding-left', 15px);
    @include p('padding-right', 15px);
    box-shadow:
      0 -1px 0 hsl(0, 0%, 85%),
      0 1px 0 hsl(0, 0%, 85%);
  }
}

.list-132dp {
  position: relative;
  margin: 0;
  @include p('height', 132px);

  > img {
    @include p('height', 132px);
    @include p('width', 320px);
  }

  .photo-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    @include p('height', 132px);
    @include p('width', 320px);
    background: 100% 100% url('../images/preview/photo_overlay.png');
  }

  .photo-label {
    position: absolute;
    color: white;
    @include p('font-size', 24px);
    @include p('padding-left', 15px);
    @include p('padding-right', 15px);
    @include p('bottom', 15px);
    left: 0px;
    @include p('width', 320px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

[class^='detail-'] {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 hsl(0, 0%, 85%);
    margin-bottom: 1px;

    .title {
      color: black;
      @include p('margin-left', 15px);
      @include p('margin-right', 15px);
      @include p('font-size', 22px);
      @include ellip;
      flex: 1;

      &.double span, &.triple span, &.quad span, .subtitle {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap;
      }

      &.triple span {
        -webkit-line-clamp: 3;
      }

      &.quad {
        -webkit-line-clamp: 4;
      }
    }

    .subtitle {
      @include p('font-size', 17px);
      color: $grey-50;
      margin-top: 4px;
    }
  }

  .actionbar {
    display: flex;
    justify-content: center;
    @include p('height', 72px);
    box-shadow: 0 1px 0 hsl(0, 0%, 85%);
    margin-bottom: 1px;

    &.collapsed {
      display: none;
    }

    &.fixed-bottom {
      background: white;
      @include p('width', 320px);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 -1px 0 hsl(0, 0%, 85%);
      margin-bottom: 0;

      &:not(.collapsed) ~ .scroll-view {
        @include p('margin-bottom', 72px);
      }
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include p('padding-top', 12px);
      @include p('padding-bottom', 12px);
    }

    > div + div {
      border-left: 1px solid hsl(0, 0%, 85%);
    }

    .action-icon {
      display: flex;
      justify-content: center;
      width: 100%;
      @include p('font-size', 24px);
    }

    .action-label {
      text-align: center;
      @include p('padding-left', 12px);
      @include p('padding-right', 12px);
      @include ellip;
    }
  }

  .inbox-actions {
    display: flex;
    justify-content: center;
    @include p('height', 72px);
    box-shadow: 0 1px 0 hsl(0, 0%, 85%);
    margin-bottom: 1px;
    color: $grey-50;

    &.collapsed {
      display: none;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include p('padding-top', 12px);
      @include p('padding-bottom', 12px);
    }

    .action-icon {
      display: flex;
      justify-content: center;
      width: 100%;
      @include p('font-size', 24px);
    }

    .action-label {
      text-align: center;
      @include p('padding-left', 12px);
      @include p('padding-right', 12px);
      @include ellip;
    }
  }

  .image-square {
    @include p('width', 100px);
    @include p('height', 100px);
    @include p('margin-left', 15px);
  }

  .image-full-behind, .detail-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    font-size: 0;
  }

  .image-full {
    @include p('width', 320px);
    @include p('height', 180px);
  }

  .properties {
    display: flex;
    flex-direction: column;
    @include p('margin-left', 15px);

    .border {
      display: flex;
      flex: 1;
      @include p('padding-top', 12px);
      @include p('padding-bottom', 12px);
      box-shadow: 0 1px 0 hsl(0, 0%, 85%);
      margin-bottom: 1px;

      &:last-child {
        margin-bottom: 0px;
      }

      .label {
        flex: 1;
        @include p('font-size', 17px);
        @include ellip;
        white-space: pre-wrap;

        span {
          color: black;
        }

        &.wrap {
          overflow: initial;

          ul { @include p('margin-left', 15px); }
          ol { @include p('margin-left', 25px); }
          ul, ol, p {
            @include p('margin-top', 12px);
            @include p('margin-bottom', 12px);
          }

          span.blankify {
            background: black;
            color: white;
            padding: 1px 4px;
            border-radius: 2px;
          }
        }

        .prop-header {
          margin-bottom: 2px;
          @include p('font-size', 12px);
        }

        .prop-action {
          height: 100%;
          @include p('font-size', 17px);
          line-height: 18px;
        }

        span + .prop-header {
          @include p('margin-top', 8px);
        }
      }

      .icon {
        @include p('font-size', 24px);
        @include p('width', 59px);
      }

      .noicon {
        @include p('width', 15px);
        opacity: 0;
      }
    }
  }
}

.detail-plain > .header {
  .title {
    @include p('margin-top', 15px);
    @include p('margin-bottom', 15px);
  }
}

.detail-square > .header {
  @include p('height', 130px);
}

.detail-full {

  &:not(.no-overlay) {
    > .header {
      @include p('height', 180px);
      align-items: flex-end;

      .title {
        color: white;
        @include p('margin-bottom', 15px);
      }

      .subtitle {
        color: white;
      }
    }
  }

  &.no-overlay {
    .image-full-behind {
      box-shadow: 0 1px 0 hsl(0, 0%, 85%);
      margin-bottom: 1px;
    }
    .title {
      @include p('margin-top', 195px);
      @include p('margin-bottom', 15px);
    }
  }

}

/*
  there is some behavior in the app preview that needs
  to be accounted for when height <= 750px and
  width <= 1500px that is not addressed by the r mixin
*/
@mixin app-preview-override($prop, $value) {
  @media (max-width: 1500px) and (max-height: 750px) {
    #{$prop}: $value;
  }
}

.detail-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .scroll-view {
    overflow-y: auto;
    height: 100%;
  }

  .header {
    .title {
      @include p('margin-top', 15px);
      @include p('margin-bottom', 15px);
      white-space: normal;
      @include p('font-size', 17px);
      line-height: 1.4142;
      &.bold {
        font-weight: 500;
      }
    }
  }

  .rich-header {
    color: black;
    padding: 4px 8px;

    .title {
      @include r('font-size', 20px, 20px, 14px, 20px, 20px);
      @include app-preview-override('font-size', 14px);
      font-size: 20px;
      font-weight: 500;
      margin: 4px 0;
    }

    .subtitle {
      @include r('font-size', 11px, 11px, 8px, 11px, 11px);
      @include app-preview-override('font-size', 8px);
      font-weight: 600;
    }

    .timestamp {
      color: $grey;
      @include r('font-size', 11px, 11px, 8px, 11px, 11px);
      @include app-preview-override('font-size', 8px);
      margin-top: 8px;
    }
  }

  .inbox-image {
    width: 100%;
    @include r('height', 179px, 125px, 119px, 125px, 179px);
    @include app-preview-override('height', 80px);
    object-fit: cover;
  }

  .body {
    @include p('margin', 15px);
    @include p('font-size', 17px);
    line-height: 1.4142;
    color: black;
    white-space: pre-wrap;
  }

  .rich-body {
    color: $grey-mid;
    @include r('font-size', 12px, 12px, 9px, 12px, 12px);
    @include app-preview-override('font-size', 9px);
    @include r('line-height', 1.8, 1.8, 1, 1.8, 1.8);
    @include app-preview-override('line-height', 1);
    line-height: 1.8;
    font-weight: 500;
    padding: 8px;
    white-space: pre-wrap;
  }

  .open-response {
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
    flex-flow: column;
    &-respond {
      @include p('min-height', 45px);
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(black, 0.24);
      p {
        @include p('font-size', 13px);
        @include p('margin-top', 26px);
        @include p('margin-bottom', 26px);
        @include p('margin-right', 15px);
        @include p('margin-left', 15px);
        color: rgba(black, 0.35);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &-send {
      border-left: 1px solid rgba(black, 0.24);
      position: absolute;
      right: 0px;
      &-icon {
        @include p('font-size', 23px);
        @include p('margin-top', 19px);
        @include p('margin-bottom', 19px);
        @include p('margin-right', 22px);
        @include p('margin-left', 22px);
        color: rgba(black, 0.60);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        transform: rotate(45deg);
      }
    }
  }

  .attitude-poll {
    &-options {
      &-labels {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        border-top: 1px solid rgba(black, 0.24); // Same as SUBMIT button
      }
      &-label {
        text-align: center;
        width: 20%;
        color: $grey-50;
        @include p('font-size', 12px);
        padding-top: 25px;
      }
      &-radios {
        @include p('padding-top', 15px);
        @include p('padding-bottom', 30px);
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: flex-end;
        @include p('margin-left', 15px);
        @include p('margin-right', 15px);
      }
      &-radio {
        border-radius: 50%;
        border: 1px solid $grey-75;
        @include p('height', 30px);
        @include p('width', 30px);
        background-color: white;
      }
      &-connector {
        height: 1px;
        background-color: $grey-75;
        position: absolute;
        @include p('left', 15px);
        @include p('right', 15px);
        @include p('bottom', 45px);
        z-index: -1;
      }
    }
  }

  .poll {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-question {
      flex: 1;
      display: flex;
      overflow-y: auto;
      .question-wrapper {
        min-height: min-content;
        flex: 1;
        display: flex;
        align-items: center;
        p {
          @include p('font-size', 17px);
          color: black;
          font-weight: bold;
          @include p('margin-top', 10px);
          @include p('margin-right', 10px);
          @include p('margin-left', 10px);
          @include p('margin-bottom', 10px);
          overflow: auto;
          white-space: pre-line;
          width: 100%;
          text-align: center;
        }
      }
    }
    &-answers {
      display: flex;
      flex-flow: column;
      &-item {
        @include p('min-height', 45px);
        display: flex;
        justify-content: left;
        align-items: center;
        border-top: 1px solid rgba(black, 0.24);
        p {
          @include p('font-size', 15px);
          @include p('margin-top', 10px);
          @include p('margin-right', 10px);
          @include p('margin-left', 10px);
          @include p('margin-bottom', 10px);
          color: black;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &-vote {
        @include p('height', 66px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid rgba(black, 0.24);
        text-align: center;
        color: $grey-75;
        text-transform: uppercase;
        @include p('font-size', 25px);
        line-height: 45px;
        font-weight: 300;
      }
    }
  }

  &.attitude-poll-preview {
    padding-top: 20%;
  }

  div[disabled][style^="background-color"] {
    position: relative;
    bottom: -50px;
  }
}

.detail-blank {
  height: 100%;

  .warning {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    @include p('font-size', 15px);
    cursor: pointer;
    background-color: $grey-50;
    color: white;
    white-space: pre-wrap;
  }

  iframe {
    height: 100%;
    border: 0;
  }
}

.push {
  @include p('height', 568px);
  background-image:
    url('../images/preview/ios_status_bar_black.svg'),
    url('../images/preview/push_preview_open.png');
  background-size: 100% auto, 100% 100%;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-flow: column;
  align-items: center;

  &.home {
    background-image:
      url('../images/preview/ios_status_bar_black.svg'),
      url('../images/preview/push_preview_lock.png');
  }

  > .time {
    @include p('font-size', 87.5px);
    font-weight: 100;
    @include p('margin-top', 27px);

    > .special-colon {
      font-family: 'Wire One';
      position: relative;
      @include p('top', -10px);
      @include p('font-size', 96px);
      @include p('padding-left', 8px);
      @include p('padding-right', 8px);
    }
  }

  > .date {
    @include p('font-size', 18px);
    position: relative;
    @include p('top', -4px);
  }

  > .notification {
    display: flex;
    @include p('width', 304px);
    @include p('margin-top', 18px);
    @include p('margin-left', 16px);
    @include p('padding-right', 9px);
    @include p('padding-top', 12px);
    @include p('padding-bottom', 12px);
    box-shadow: 0 -1px 0 rgba(white, 0.24), 0 1px 0 rgba(white, 0.24);

    > .appicon {
      @include p('margin-right', 12px);

      > img {
        @include p('height', 20px);
        @include p('width', 20px);
        border-radius: 22%;
      }
    }

    > .text {
      flex: 1;
      @include r('line-height', 18px, 14px, 12px, 14px, 18px);
      @include p('font-size', 16px);
      @include p('margin-right', 12px);

      > .appname {
        font-weight: 600;
      }

      > .notification-text {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }

      .superfluous {
        @include p('font-size', 12px);
        color: rgba(white, 0.24);
      }
    }
  }

  > .lock-screen-notification {
    display: flex;
    flex-flow: column;
    @include p('width', 304px);
    @include p('margin-top', 18px);
    @include p('margin-left', 16px);
    @include r('margin-right', 0, 12px, 3px, 3px, 3px);
    @include p('padding-top', 12px);
    @include p('padding-bottom', 12px);
    box-shadow: 0 -1px 0 rgba(white, 0.24), 0 1px 0 rgba(white, 0.24);

    > .notification-header {
      @include r('margin-right', 15px, 3px, 3px, 3px, 15px);
      font-size: 10px;
      font-weight: 100;
      @include app-preview-override('font-size', 6.5px);

      > img {
        @include p('height', 20px);
        @include p('width', 20px);
        border-radius: 22%;
      }

      .application-name {
        display: inline-block;
        position: relative;
        left: 4px;
        @include r('bottom', 6px, 4px, 4px, 4px, 6px);
      }

      .timestamp {
        float: right;
      }
    }

    > .notification-content {
      flex: 1;
      @include r('line-height', 18px, 14px, 12px, 14px, 18px);
      @include p('margin-right', 12px);

      .message-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        @include r('font-size', 11.5px, 9px, 8px, 9px, 11.5px);
        @include app-preview-override('font-size', 6.5px);
        @include app-preview-override('line-height', 9px);
      }

      .rich-body {
        display: flex;
        flex-direction: row;

        .rich-body-preview {
          -webkit-line-clamp: 3;
          font-weight: 100;
        }
      }

      .message-header {
        display: flex;

        .message-titles {
          flex: 9;
          margin-right: 16px;
          @include app-preview-override('margin-right', 1px);

          .message-header-text {
            -webkit-line-clamp: 1;
            font-weight: 500;
          }
        }

        .thumbnail-container {
          flex: 1;
          @include app-preview-override('margin-top', 4px);

          .image-thumbnail {
            height: 25px;
            width: 25px;
            @include app-preview-override('height', 15px);
            @include app-preview-override('width', 15px);
          }
        }
      }
    }
  }
}
