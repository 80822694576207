@charset 'UTF-8';

@font-face {
  font-family: 'Streamline';
  src: url('../images/jaqen/streamline-outline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'streamline-outline';
  src: url('../images/jaqen/streamline-outline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Streamline';
  src: url('../images/jaqen/streamline-filled.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'streamline-outline';
  src: url('../images/jaqen/streamline-outline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'streamline-filled';
  src: url('../images/jaqen/streamline-filled.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'asset-web';
  src: url('../images/jaqen/assets-ios.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-']:before, [class*=' icon-']:before {
  font-family: 'Streamline' !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
}

// Custom Icons

.icon-custom-more:before { content: '\e66a'; }
.icon-custom-pulpit:before { content: '\e66b'; }
.icon-custom-church:before { content: '\e66c'; }
.icon-custom-cross:before { content: '\e66d'; }
.icon-custom-prayer:before { content: '\e66e'; }
.icon-custom-share:before { content: '\e66f'; }
.icon-custom-facebook:before { content: '\e670'; }
.icon-custom-twitter:before { content: '\e671'; }
.icon-custom-blankify:before { content: '\e672'; }
.icon-custom-events-feed:before { content: '\e673'; }
.icon-custom-simpleview:before { content: '\e674'; }
.icon-custom-app:before { content: '\e675'; }
.icon-custom-android:before { content: '\e676'; }
.icon-custom-ekklesia:before { content: '\e677'; }
.icon-custom-ccb:before { content: '\e678'; }
.icon-custom-miles:before { content: '\e67b'; }
.icon-custom-bluebridge-1:before { content: '\e67d'; }
.icon-custom-bluebridge-2:before { content: '\e67e'; }
.icon-custom-amazon:before { content: '\e67f'; }
.icon-custom-dribbble:before { content: '\e680'; }
.icon-custom-github:before { content: '\e681'; }
.icon-custom-instagram:before { content: '\e682'; }
.icon-custom-itunes:before { content: '\e683'; }
.icon-custom-linkedin:before { content: '\e684'; }

.icon-custom-echurch:before {
  content: '';
  background-image: url('../images/jaqen/echurch-icon.png');
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

// Standard Icons

.icon-bin-1:before { content: '\e000'; }
.icon-bin-2:before { content: '\e001'; }
.icon-binocular:before { content: '\e002'; }
.icon-clip-1:before { content: '\e004'; }
.icon-clip-2:before { content: '\e005'; }
.icon-crosshair-1:before { content: '\e006'; }
.icon-crosshair-2:before { content: '\e007'; }
.icon-crosshair-3:before { content: '\e008'; }
.icon-cutter:before { content: '\e009'; }
.icon-delete-1:before { content: '\e00a'; }
.icon-delete-2:before { content: '\e00b'; }
.icon-edit-1:before { content: '\e00c'; }
.icon-edit-2:before { content: '\e00d'; }
.icon-edit-3:before { content: '\e00e'; }
.icon-hide:before { content: '\e00f'; }
.icon-ink:before { content: '\e010'; }
.icon-key-1:before { content: '\e011'; }
.icon-key-2:before { content: '\e012'; }
.icon-link-1:before { content: '\e013'; }
.icon-link-2:before { content: '\e014'; }
.icon-link-3:before { content: '\e015'; }
.icon-link-broken-1:before { content: '\e016'; }
.icon-link-broken-2:before { content: '\e017'; }
.icon-lock-1:before { content: '\e018'; }
.icon-lock-2:before { content: '\e019'; }
.icon-lock-3:before { content: '\e01a'; }
.icon-lock-4:before { content: '\e01b'; }
.icon-lock-5:before { content: '\e01c'; }
.icon-lock-unlock-1:before { content: '\e01d'; }
.icon-lock-unlock-2:before { content: '\e01e'; }
.icon-magnifier:before { content: '\e01f'; }
.icon-pen-1:before { content: '\e020'; }
.icon-pen-2:before { content: '\e021'; }
.icon-pen-3:before { content: '\e022'; }
.icon-pen-4:before { content: '\e023'; }
.icon-pencil-1:before { content: '\e024'; }
.icon-pencil-2:before { content: '\e025'; }
.icon-pencil-3:before { content: '\e026'; }
.icon-pin-1:before { content: '\e027'; }
.icon-pin-2:before { content: '\e028'; }
.icon-power-1:before { content: '\e029'; }
.icon-power-2:before { content: '\e02a'; }
.icon-preview-1:before { content: '\e02b'; }
.icon-preview-2:before { content: '\e02c'; }
.icon-scissor-1:before { content: '\e02d'; }
.icon-scissor-2:before { content: '\e02e'; }
.icon-type-1:before { content: '\e031'; }
.icon-type-2:before { content: '\e032'; }
.icon-type-3:before { content: '\e033'; }
.icon-type-4:before { content: '\e034'; }
.icon-zoom-area:before { content: '\e035'; }
.icon-zoom-in:before { content: '\e036'; }
.icon-zoom-out:before { content: '\e037'; }
.icon-cursor-1:before { content: '\e038'; }
.icon-cursor-2:before { content: '\e039'; }
.icon-cursor-add:before { content: '\e03a'; }
.icon-cursor-duplicate:before { content: '\e03b'; }
.icon-cursor-move:before { content: '\e03c'; }
.icon-cursor-move-2:before { content: '\e03d'; }
.icon-cursor-select-area:before { content: '\e03e'; }
.icon-hand:before { content: '\e03f'; }
.icon-hand-block:before { content: '\e040'; }
.icon-hand-grab-1:before { content: '\e041'; }
.icon-hand-grab-2:before { content: '\e042'; }
.icon-hand-point:before { content: '\e043'; }
.icon-hand-touch-1:before { content: '\e044'; }
.icon-hand-touch-2:before { content: '\e045'; }
.icon-hand-touch-3:before { content: '\e046'; }
.icon-hand-touch-4:before { content: '\e047'; }
.icon-bookmark-1:before { content: '\e048'; }
.icon-bookmark-2:before { content: '\e049'; }
.icon-bookmark-3:before { content: '\e04a'; }
.icon-bookmark-4:before { content: '\e04b'; }
.icon-tag-1:before { content: '\e04c'; }
.icon-tag-2:before { content: '\e04d'; }
.icon-tag-add:before { content: '\e04e'; }
.icon-tag-delete:before { content: '\e04f'; }
.icon-tags-1:before { content: '\e050'; }
.icon-tags-2:before { content: '\e051'; }
.icon-anchor-point-1:before { content: '\e052'; }
.icon-anchor-point-2:before { content: '\e053'; }
.icon-arrange-1:before { content: '\e054'; }
.icon-arrange-2:before { content: '\e055'; }
.icon-artboard:before { content: '\e056'; }
.icon-brush-1:before { content: '\e057'; }
.icon-brush-2:before { content: '\e058'; }
.icon-bucket:before { content: '\e059'; }
.icon-crop:before { content: '\e05a'; }
.icon-dropper-1:before { content: '\e05b'; }
.icon-dropper-2:before { content: '\e05c'; }
.icon-dropper-3:before { content: '\e05d'; }
.icon-glue:before { content: '\e05e'; }
.icon-grid:before { content: '\e05f'; }
.icon-layers:before { content: '\e060'; }
.icon-magic-wand-1:before { content: '\e061'; }
.icon-magic-wand-2:before { content: '\e062'; }
.icon-magnet:before { content: '\e063'; }
.icon-marker:before { content: '\e064'; }
.icon-palette:before { content: '\e065'; }
.icon-pen-5:before { content: '\e066'; }
.icon-pen-6:before { content: '\e067'; }
.icon-quill:before { content: '\e068'; }
.icon-reflect:before { content: '\e069'; }
.icon-roller:before { content: '\e06a'; }
.icon-ruler-1:before { content: '\e06b'; }
.icon-ruler-2:before { content: '\e06c'; }
.icon-scale-diagonal-1:before { content: '\e06d'; }
.icon-scale-diagonal-2:before { content: '\e06e'; }
.icon-scale-horizontal:before { content: '\e06f'; }
.icon-scale-tool-1:before { content: '\e070'; }
.icon-scale-tool-2:before { content: '\e071'; }
.icon-scale-tool-3:before { content: '\e072'; }
.icon-scale-vertical:before { content: '\e073'; }
.icon-shear-tool:before { content: '\e074'; }
.icon-spray:before { content: '\e075'; }
.icon-stamp:before { content: '\e076'; }
.icon-stationery-1:before { content: '\e077'; }
.icon-stationery-2:before { content: '\e078'; }
.icon-stationery-3:before { content: '\e079'; }
.icon-vector:before { content: '\e07a'; }
.icon-award-1:before { content: '\e07b'; }
.icon-award-2:before { content: '\e07c'; }
.icon-award-3:before { content: '\e07d'; }
.icon-award-4:before { content: '\e07e'; }
.icon-award-5:before { content: '\e07f'; }
.icon-award-6:before { content: '\e080'; }
.icon-crown-1:before { content: '\e081'; }
.icon-crown-2:before { content: '\e082'; }
.icon-crown-3:before { content: '\e083'; }
.icon-fire:before { content: '\e084'; }
.icon-flag-1:before { content: '\e085'; }
.icon-flag-2:before { content: '\e086'; }
.icon-flag-3:before { content: '\e087'; }
.icon-flag-4:before { content: '\e088'; }
.icon-flag-5:before { content: '\e089'; }
.icon-flag-6:before { content: '\e08a'; }
.icon-flag-7:before { content: '\e08b'; }
.icon-flag-8:before { content: '\e08c'; }
.icon-google-plus-1:before { content: '\e08d'; }
.icon-google-plus-2:before { content: '\e08e'; }
.icon-hand-like-1:before { content: '\e08f'; }
.icon-hand-like-2:before { content: '\e090'; }
.icon-hand-unlike-1:before { content: '\e091'; }
.icon-hand-unlike-2:before { content: '\e092'; }
.icon-heart-1:before { content: '\e093'; }
.icon-heart-2:before { content: '\e094'; }
.icon-heart-angel:before { content: '\e095'; }
.icon-heart-broken:before { content: '\e096'; }
.icon-heart-minus:before { content: '\e097'; }
.icon-heart-plus:before { content: '\e098'; }
.icon-present:before { content: '\e099'; }
.icon-rank-1:before { content: '\e09a'; }
.icon-rank-2:before { content: '\e09b'; }
.icon-ribbon:before { content: '\e09c'; }
.icon-star-1:before { content: '\e09d'; }
.icon-star-2:before { content: '\e09e'; }
.icon-star-3:before { content: '\e09f'; }
.icon-star-4:before { content: '\e0a0'; }
.icon-star-5:before { content: '\e0a1'; }
.icon-star-6:before { content: '\e0a2'; }
.icon-star-7:before { content: '\e0a3'; }
.icon-star-8:before { content: '\e0a4'; }
.icon-star-9:before { content: '\e0a5'; }
.icon-star-10:before { content: '\e0a6'; }
.icon-trophy:before { content: '\e0a7'; }
.icon-bubble-2:before { content: '\e0a8'; }
.icon-bubble-add-1:before { content: '\e0a9'; }
.icon-bubble-add-2:before { content: '\e0aa'; }
.icon-bubble-add-3:before { content: '\e0ab'; }
.icon-bubble-ask-1:before { content: '\e0ac'; }
.icon-bubble-ask-2:before { content: '\e0ad'; }
.icon-bubble-attention-1:before { content: '\e0ae'; }
.icon-bubble-attention-2:before { content: '\e0af'; }
.icon-bubble-attention-3:before { content: '\e0b0'; }
.icon-bubble-attention-4:before { content: '\e0b1'; }
.icon-bubble-attention-5:before { content: '\e0b2'; }
.icon-bubble-attention-6:before { content: '\e0b3'; }
.icon-bubble-attention-7:before { content: '\e0b4'; }
.icon-bubble-block-1:before { content: '\e0b5'; }
.icon-bubble-block-2:before { content: '\e0b6'; }
.icon-bubble-block-3:before { content: '\e0b7'; }
.icon-bubble-chat-1:before { content: '\e0b8'; }
.icon-bubble-chat-2:before { content: '\e0b9'; }
.icon-bubble-check-1:before { content: '\e0ba'; }
.icon-bubble-check-2:before { content: '\e0bb'; }
.icon-bubble-check-3:before { content: '\e0bc'; }
.icon-bubble-comment-1:before { content: '\e0bd'; }
.icon-bubble-comment-2:before { content: '\e0be'; }
.icon-bubble-conversation-1:before { content: '\e0bf'; }
.icon-bubble-conversation-2:before { content: '\e0c0'; }
.icon-bubble-conversation-3:before { content: '\e0c1'; }
.icon-bubble-conversation-4:before { content: '\e0c2'; }
.icon-bubble-conversation-5:before { content: '\e0c3'; }
.icon-bubble-conversation-6:before { content: '\e0c4'; }
.icon-bubble-delete-1:before { content: '\e0c5'; }
.icon-bubble-delete-2:before { content: '\e0c6'; }
.icon-bubble-delete-3:before { content: '\e0c7'; }
.icon-bubble-edit-1:before { content: '\e0c8'; }
.icon-bubble-edit-2:before { content: '\e0c9'; }
.icon-bubble-edit-3:before { content: '\e0ca'; }
.icon-bubble-heart-1:before { content: '\e0cb'; }
.icon-bubble-heart-2:before { content: '\e0cc'; }
.icon-bubble-minus-1:before { content: '\e0cd'; }
.icon-bubble-minus-2:before { content: '\e0ce'; }
.icon-bubble-minus-3:before { content: '\e0cf'; }
.icon-bubble-quote-1:before { content: '\e0d0'; }
.icon-bubble-quote-2:before { content: '\e0d1'; }
.icon-bubble-smiley-3:before { content: '\e0d2'; }
.icon-bubble-smiley-4:before { content: '\e0d3'; }
.icon-bubble-smiley-smile:before { content: '\e0d4'; }
.icon-bubble-smiley-wink:before { content: '\e0d5'; }
.icon-bubble-star-1:before { content: '\e0d6'; }
.icon-bubble-star-2:before { content: '\e0d7'; }
.icon-bubble-star-3:before { content: '\e0d8'; }
.icon-chat-1:before { content: '\e0d9'; }
.icon-chat-2:before { content: '\e0da'; }
.icon-chat-3:before { content: '\e0db'; }
.icon-chat-4:before { content: '\e0dc'; }
.icon-chat-5:before { content: '\e0dd'; }
.icon-chat-bubble-1:before { content: '\e0de'; }
.icon-chat-bubble-2:before { content: '\e0df'; }
.icon-smiley-happy-1:before { content: '\e0e0'; }
.icon-smiley-happy-2:before { content: '\e0e1'; }
.icon-smiley-happy-3:before { content: '\e0e2'; }
.icon-smiley-happy-4:before { content: '\e0e3'; }
.icon-smiley-happy-5:before { content: '\e0e4'; }
.icon-smiley-relax:before { content: '\e0e5'; }
.icon-smiley-sad:before { content: '\e0e6'; }
.icon-smiley-surprise:before { content: '\e0e7'; }
.icon-thinking-1:before { content: '\e0e8'; }
.icon-thinking-2:before { content: '\e0e9'; }
.icon-call-1:before { content: '\e0ea'; }
.icon-call-2:before { content: '\e0eb'; }
.icon-call-3:before { content: '\e0ec'; }
.icon-call-4:before { content: '\e0ed'; }
.icon-call-add:before { content: '\e0ee'; }
.icon-call-block:before { content: '\e0ef'; }
.icon-call-delete:before { content: '\e0f0'; }
.icon-call-in:before { content: '\e0f1'; }
.icon-call-minus:before { content: '\e0f2'; }
.icon-call-out:before { content: '\e0f3'; }
.icon-contact:before { content: '\e0f4'; }
.icon-fax:before { content: '\e0f5'; }
.icon-hang-up:before { content: '\e0f6'; }
.icon-message:before { content: '\e0f7'; }
.icon-mobile-phone-1:before { content: '\e0f8'; }
.icon-mobile-phone-2:before { content: '\e0f9'; }
.icon-phone-1:before { content: '\e0fa'; }
.icon-phone-2:before { content: '\e0fb'; }
.icon-phone-3:before { content: '\e0fc'; }
.icon-phone-4:before { content: '\e0fd'; }
.icon-phone-vibration:before { content: '\e0fe'; }
.icon-signal-fine:before { content: '\e0ff'; }
.icon-signal-full:before { content: '\e100'; }
.icon-signal-high:before { content: '\e101'; }
.icon-signal-no:before { content: '\e102'; }
.icon-signal-poor:before { content: '\e103'; }
.icon-signal-weak:before { content: '\e104'; }
.icon-smartphone:before { content: '\e105'; }
.icon-tape:before { content: '\e106'; }
.icon-camera-symbol-1:before { content: '\e107'; }
.icon-camera-symbol-2:before { content: '\e108'; }
.icon-camera-symbol-3:before { content: '\e109'; }
.icon-antenna-1:before { content: '\e10b'; }
.icon-antenna-2:before { content: '\e10c'; }
.icon-antenna-3:before { content: '\e10d'; }
.icon-hotspot-1:before { content: '\e10e'; }
.icon-hotspot-2:before { content: '\e10f'; }
.icon-link:before { content: '\e110'; }
.icon-megaphone-1:before { content: '\e111'; }
.icon-megaphone-2:before { content: '\e112'; }
.icon-radar:before { content: '\e113'; }
.icon-rss-1:before { content: '\e114'; }
.icon-rss-2:before { content: '\e115'; }
.icon-satellite:before { content: '\e116'; }
.icon-address-1:before { content: '\e117'; }
.icon-address-2:before { content: '\e118'; }
.icon-address-3:before { content: '\e119'; }
.icon-forward:before { content: '\e11a'; }
.icon-inbox-1:before { content: '\e11b'; }
.icon-inbox-2:before { content: '\e11c'; }
.icon-inbox-3:before { content: '\e11d'; }
.icon-inbox-4:before { content: '\e11e'; }
.icon-letter-1:before { content: '\e11f'; }
.icon-letter-2:before { content: '\e120'; }
.icon-letter-3:before { content: '\e121'; }
.icon-letter-4:before { content: '\e122'; }
.icon-letter-5:before { content: '\e123'; }
.icon-mail-1:before { content: '\e124'; }
.icon-mail-2:before { content: '\e125'; }
.icon-mail-add:before { content: '\e126'; }
.icon-mail-attention:before { content: '\e127'; }
.icon-mail-block:before { content: '\e128'; }
.icon-mail-box-1:before { content: '\e129'; }
.icon-mail-box-2:before { content: '\e12a'; }
.icon-mail-box-3:before { content: '\e12b'; }
.icon-mail-checked:before { content: '\e12c'; }
.icon-mail-compose:before { content: '\e12d'; }
.icon-mail-delete:before { content: '\e12e'; }
.icon-mail-favorite:before { content: '\e12f'; }
.icon-mail-inbox:before { content: '\e130'; }
.icon-mail-lock:before { content: '\e131'; }
.icon-mail-minus:before { content: '\e132'; }
.icon-mail-read:before { content: '\e133'; }
.icon-mail-recieved-1:before { content: '\e134'; }
.icon-mail-recieved-2:before { content: '\e135'; }
.icon-mail-search-1:before { content: '\e136'; }
.icon-mail-search-2:before { content: '\e137'; }
.icon-mail-sent-1:before { content: '\e138'; }
.icon-mail-sent-2:before { content: '\e139'; }
.icon-mail-setting:before { content: '\e13a'; }
.icon-mail-star:before { content: '\e13b'; }
.icon-mail-sync:before { content: '\e13c'; }
.icon-mail-time:before { content: '\e13d'; }
.icon-outbox-1:before { content: '\e13e'; }
.icon-outbox-2:before { content: '\e13f'; }
.icon-plane-paper-1:before { content: '\e140'; }
.icon-plane-paper-2:before { content: '\e141'; }
.icon-reply-mail-1:before { content: '\e142'; }
.icon-reply-mail-2:before { content: '\e143'; }
.icon-connection-1:before { content: '\e144'; }
.icon-connection-2:before { content: '\e145'; }
.icon-connection-3:before { content: '\e146'; }
.icon-contacts-1:before { content: '\e147'; }
.icon-contacts-2:before { content: '\e148'; }
.icon-contacts-3:before { content: '\e149'; }
.icon-contacts-4:before { content: '\e14a'; }
.icon-female:before { content: '\e14b'; }
.icon-gender-female:before { content: '\e14c'; }
.icon-gender-male:before { content: '\e14d'; }
.icon-genders:before { content: '\e14e'; }
.icon-id-1:before { content: '\e14f'; }
.icon-id-2:before { content: '\e150'; }
.icon-id-3:before { content: '\e151'; }
.icon-id-4:before { content: '\e152'; }
.icon-id-5:before { content: '\e153'; }
.icon-id-6:before { content: '\e154'; }
.icon-id-7:before { content: '\e155'; }
.icon-id-8:before { content: '\e156'; }
.icon-male:before { content: '\e157'; }
.icon-profile-1:before { content: '\e158'; }
.icon-profile-2:before { content: '\e159'; }
.icon-profile-3:before { content: '\e15a'; }
.icon-profile-4:before { content: '\e15b'; }
.icon-profile-5:before { content: '\e15c'; }
.icon-profile-6:before { content: '\e15d'; }
.icon-profile-athlete:before { content: '\e15e'; }
.icon-profile-bussiness-man:before { content: '\e15f'; }
.icon-profile-chef:before { content: '\e160'; }
.icon-profile-cop:before { content: '\e161'; }
.icon-profile-doctor-1:before { content: '\e162'; }
.icon-profile-doctor-2:before { content: '\e163'; }
.icon-profile-gentleman-1:before { content: '\e164'; }
.icon-profile-gentleman-2:before { content: '\e165'; }
.icon-profile-graduate:before { content: '\e166'; }
.icon-profile-king:before { content: '\e167'; }
.icon-profile-lady-1:before { content: '\e168'; }
.icon-profile-lady-2:before { content: '\e169'; }
.icon-profile-man:before { content: '\e16a'; }
.icon-profile-nurse1:before { content: '\e16b'; }
.icon-profile-nurse-2:before { content: '\e16c'; }
.icon-profile-prisoner:before { content: '\e16d'; }
.icon-profile-serviceman-1:before { content: '\e16e'; }
.icon-profile-serviceman-2:before { content: '\e16f'; }
.icon-profile-spy:before { content: '\e170'; }
.icon-profile-teacher:before { content: '\e171'; }
.icon-profile-thief:before { content: '\e172'; }
.icon-user-1:before { content: '\e173'; }
.icon-user-2:before { content: '\e174'; }
.icon-user-add-1:before { content: '\e175'; }
.icon-user-add-2:before { content: '\e176'; }
.icon-user-block-1:before { content: '\e177'; }
.icon-user-block-2:before { content: '\e178'; }
.icon-user-checked-1:before { content: '\e179'; }
.icon-user-checked-2:before { content: '\e17a'; }
.icon-user-delete-1:before { content: '\e17b'; }
.icon-user-delete-2:before { content: '\e17c'; }
.icon-user-edit-1:before { content: '\e17d'; }
.icon-user-edit-2:before { content: '\e17e'; }
.icon-user-heart-1:before { content: '\e17f'; }
.icon-user-heart-2:before { content: '\e180'; }
.icon-user-lock-1:before { content: '\e181'; }
.icon-user-lock-2:before { content: '\e182'; }
.icon-user-minus-1:before { content: '\e183'; }
.icon-user-minus-2:before { content: '\e184'; }
.icon-user-search-1:before { content: '\e185'; }
.icon-user-search-2:before { content: '\e186'; }
.icon-user-setting-1:before { content: '\e187'; }
.icon-user-setting-2:before { content: '\e188'; }
.icon-user-star-1:before { content: '\e189'; }
.icon-user-star-2:before { content: '\e18a'; }
.icon-bag-shopping-1:before { content: '\e18b'; }
.icon-bag-shopping-2:before { content: '\e18c'; }
.icon-bag-shopping-3:before { content: '\e18d'; }
.icon-basket-1:before { content: '\e18e'; }
.icon-basket-2:before { content: '\e18f'; }
.icon-basket-3:before { content: '\e190'; }
.icon-basket-add:before { content: '\e191'; }
.icon-basket-minus:before { content: '\e192'; }
.icon-briefcase:before { content: '\e193'; }
.icon-cart-1:before { content: '\e194'; }
.icon-cart-2:before { content: '\e195'; }
.icon-cart-3:before { content: '\e196'; }
.icon-cart-4:before { content: '\e197'; }
.icon-cut:before { content: '\e198'; }
.icon-handbag-1:before { content: '\e199'; }
.icon-handbag-2:before { content: '\e19a'; }
.icon-purse-1:before { content: '\e19b'; }
.icon-purse-2:before { content: '\e19c'; }
.icon-qr-code:before { content: '\e19d'; }
.icon-receipt-1:before { content: '\e19e'; }
.icon-receipt-2:before { content: '\e19f'; }
.icon-receipt-3:before { content: '\e1a0'; }
.icon-receipt-4:before { content: '\e1a1'; }
.icon-shopping-1:before { content: '\e1a2'; }
.icon-sign-new-1:before { content: '\e1a3'; }
.icon-sign-new-2:before { content: '\e1a4'; }
.icon-sign-parking:before { content: '\e1a5'; }
.icon-signal-star:before { content: '\e1a6'; }
.icon-trolley-1:before { content: '\e1a7'; }
.icon-trolley-2:before { content: '\e1a8'; }
.icon-trolley-3:before { content: '\e1a9'; }
.icon-trolley-load:before { content: '\e1aa'; }
.icon-trolley-off:before { content: '\e1ab'; }
.icon-wallet-1:before { content: '\e1ac'; }
.icon-wallet-2:before { content: '\e1ad'; }
.icon-wallet-3:before { content: '\e1ae'; }
.icon-camera-1:before { content: '\e1af'; }
.icon-camera-2:before { content: '\e1b0'; }
.icon-camera-3:before { content: '\e1b1'; }
.icon-camera-4:before { content: '\e1b2'; }
.icon-camera-5:before { content: '\e1b3'; }
.icon-camera-back:before { content: '\e1b4'; }
.icon-camera-focus:before { content: '\e1b5'; }
.icon-camera-frames:before { content: '\e1b6'; }
.icon-camera-front:before { content: '\e1b7'; }
.icon-camera-graph-1:before { content: '\e1b8'; }
.icon-camera-graph-2:before { content: '\e1b9'; }
.icon-camera-landscape:before { content: '\e1ba'; }
.icon-camera-lens-1:before { content: '\e1bb'; }
.icon-camera-lens-2:before { content: '\e1bc'; }
.icon-camera-light:before { content: '\e1bd'; }
.icon-camera-portrait:before { content: '\e1be'; }
.icon-camera-view:before { content: '\e1bf'; }
.icon-film-1:before { content: '\e1c0'; }
.icon-film-2:before { content: '\e1c1'; }
.icon-photo-1:before { content: '\e1c2'; }
.icon-photo-2:before { content: '\e1c3'; }
.icon-photo-frame:before { content: '\e1c4'; }
.icon-photos-1:before { content: '\e1c5'; }
.icon-photos-2:before { content: '\e1c6'; }
.icon-polaroid:before { content: '\e1c7'; }
.icon-signal-camera-1:before { content: '\e1c8'; }
.icon-signal-camera-2:before { content: '\e1c9'; }
.icon-user-photo:before { content: '\e1ca'; }
.icon-backward-1:before { content: '\e1cb'; }
.icon-dvd-player:before { content: '\e1cc'; }
.icon-eject-1:before { content: '\e1cd'; }
.icon-film-3:before { content: '\e1ce'; }
.icon-forward-1:before { content: '\e1cf'; }
.icon-handycam:before { content: '\e1d0'; }
.icon-movie-play-1:before { content: '\e1d1'; }
.icon-movie-play-2:before { content: '\e1d2'; }
.icon-movie-play-3:before { content: '\e1d3'; }
.icon-next-1:before { content: '\e1d4'; }
.icon-pause-1:before { content: '\e1d5'; }
.icon-play-1:before { content: '\e1d6'; }
.icon-player:before { content: '\e1d7'; }
.icon-previous-1:before { content: '\e1d8'; }
.icon-record-1:before { content: '\e1d9'; }
.icon-slate:before { content: '\e1da'; }
.icon-stop-1:before { content: '\e1db'; }
.icon-television:before { content: '\e1dc'; }
.icon-video-camera-1:before { content: '\e1dd'; }
.icon-video-camera-2:before { content: '\e1de'; }
.icon-backward-2:before { content: '\e1df'; }
.icon-cd:before { content: '\e1e0'; }
.icon-eject-2:before { content: '\e1e1'; }
.icon-equalizer-2:before { content: '\e1e2'; }
.icon-equalizer-3:before { content: '\e1e3'; }
.icon-forward-2:before { content: '\e1e4'; }
.icon-gramophone:before { content: '\e1e5'; }
.icon-gramophone-record-2:before { content: '\e1e6'; }
.icon-guitar:before { content: '\e1e7'; }
.icon-headphone:before { content: '\e10a'; }
.icon-headphone-1:before { content: '\e1e8'; }
.icon-headphone-2:before { content: '\e1e9'; }
.icon-microphone-1:before { content: '\e1ea'; }
.icon-microphone-2:before { content: '\e1eb'; }
.icon-microphone-3:before { content: '\e1ec'; }
.icon-movie-play-4:before { content: '\e1ed'; }
.icon-music-note-1:before { content: '\e1ee'; }
.icon-music-note-2:before { content: '\e1ef'; }
.icon-music-note-3:before { content: '\e1f0'; }
.icon-music-note-4:before { content: '\e1f1'; }
.icon-next-2:before { content: '\e1f2'; }
.icon-notes-1:before { content: '\e1f3'; }
.icon-notes-2:before { content: '\e1f4'; }
.icon-pause-2:before { content: '\e1f5'; }
.icon-piano:before { content: '\e1f6'; }
.icon-play-2:before { content: '\e1f7'; }
.icon-playlist:before { content: '\e1f8'; }
.icon-previous-2:before { content: '\e1f9'; }
.icon-radio-1:before { content: '\e1fa'; }
.icon-radio-2:before { content: '\e1fb'; }
.icon-record-2:before { content: '\e1fc'; }
.icon-recorder:before { content: '\e1fd'; }
.icon-saxophone:before { content: '\e1fe'; }
.icon-speaker-1:before { content: '\e1ff'; }
.icon-speaker-2:before { content: '\e200'; }
.icon-speaker-3:before { content: '\e201'; }
.icon-stop-2:before { content: '\e202'; }
.icon-tape-1:before { content: '\e203'; }
.icon-trumpet:before { content: '\e204'; }
.icon-volume-down-1:before { content: '\e205'; }
.icon-volume-down-2:before { content: '\e206'; }
.icon-volume-loud-1:before { content: '\e207'; }
.icon-volume-loud-2:before { content: '\e208'; }
.icon-volume-low-1:before { content: '\e209'; }
.icon-volume-low-2:before { content: '\e20a'; }
.icon-volume-medium-1:before { content: '\e20b'; }
.icon-volume-medium-2:before { content: '\e20c'; }
.icon-volume-mute-1:before { content: '\e20d'; }
.icon-volume-mute-2:before { content: '\e20e'; }
.icon-volume-mute-3:before { content: '\e20f'; }
.icon-volume-up-1:before { content: '\e210'; }
.icon-volume-up-2:before { content: '\e211'; }
.icon-walkman:before { content: '\e212'; }
.icon-cloud:before { content: '\e213'; }
.icon-cloud-add:before { content: '\e214'; }
.icon-cloud-checked:before { content: '\e215'; }
.icon-cloud-delete:before { content: '\e216'; }
.icon-cloud-download:before { content: '\e217'; }
.icon-cloud-minus:before { content: '\e218'; }
.icon-cloud-refresh:before { content: '\e219'; }
.icon-cloud-sync:before { content: '\e21a'; }
.icon-cloud-upload:before { content: '\e21b'; }
.icon-download-1:before { content: '\e21c'; }
.icon-download-2:before { content: '\e21d'; }
.icon-download-3:before { content: '\e21e'; }
.icon-download-4:before { content: '\e21f'; }
.icon-download-5:before { content: '\e220'; }
.icon-download-6:before { content: '\e221'; }
.icon-download-7:before { content: '\e222'; }
.icon-download-8:before { content: '\e223'; }
.icon-download-9:before { content: '\e224'; }
.icon-download-10:before { content: '\e225'; }
.icon-download-11:before { content: '\e226'; }
.icon-download-12:before { content: '\e227'; }
.icon-download-13:before { content: '\e228'; }
.icon-download-14:before { content: '\e229'; }
.icon-download-15:before { content: '\e22a'; }
.icon-download-file:before { content: '\e22b'; }
.icon-download-folder:before { content: '\e22c'; }
.icon-goal-1:before { content: '\e22d'; }
.icon-goal-2:before { content: '\e22e'; }
.icon-transfer-1:before { content: '\e22f'; }
.icon-transfer-2:before { content: '\e230'; }
.icon-transfer-3:before { content: '\e231'; }
.icon-transfer-4:before { content: '\e232'; }
.icon-transfer-5:before { content: '\e233'; }
.icon-transfer-6:before { content: '\e234'; }
.icon-transfer-7:before { content: '\e235'; }
.icon-transfer-8:before { content: '\e236'; }
.icon-transfer-9:before { content: '\e237'; }
.icon-transfer-10:before { content: '\e238'; }
.icon-transfer-11:before { content: '\e239'; }
.icon-transfer-12:before { content: '\e23a'; }
.icon-upload-1:before { content: '\e23b'; }
.icon-upload-2:before { content: '\e23c'; }
.icon-upload-3:before { content: '\e23d'; }
.icon-upload-4:before { content: '\e23e'; }
.icon-upload-5:before { content: '\e23f'; }
.icon-upload-6:before { content: '\e240'; }
.icon-upload-7:before { content: '\e241'; }
.icon-upload-8:before { content: '\e242'; }
.icon-upload-9:before { content: '\e243'; }
.icon-upload-10:before { content: '\e244'; }
.icon-upload-11:before { content: '\e245'; }
.icon-upload-12:before { content: '\e246'; }
.icon-clipboard-1:before { content: '\e247'; }
.icon-clipboard-2:before { content: '\e248'; }
.icon-clipboard-3:before { content: '\e249'; }
.icon-clipboard-add:before { content: '\e24a'; }
.icon-clipboard-block:before { content: '\e24b'; }
.icon-clipboard-checked:before { content: '\e24c'; }
.icon-clipboard-delete:before { content: '\e24d'; }
.icon-clipboard-edit:before { content: '\e24e'; }
.icon-clipboard-minus:before { content: '\e24f'; }
.icon-document-1:before { content: '\e250'; }
.icon-document-2:before { content: '\e251'; }
.icon-file-1:before { content: '\e252'; }
.icon-file-2:before { content: '\e253'; }
.icon-file-add:before { content: '\e254'; }
.icon-file-attention:before { content: '\e255'; }
.icon-file-block:before { content: '\e256'; }
.icon-file-bookmark:before { content: '\e257'; }
.icon-file-checked:before { content: '\e258'; }
.icon-file-code:before { content: '\e259'; }
.icon-file-delete:before { content: '\e25a'; }
.icon-file-download:before { content: '\e25b'; }
.icon-file-edit:before { content: '\e25c'; }
.icon-file-favorite-1:before { content: '\e25d'; }
.icon-file-favorite-2:before { content: '\e25e'; }
.icon-file-graph-1:before { content: '\e25f'; }
.icon-file-graph-2:before { content: '\e260'; }
.icon-file-home:before { content: '\e261'; }
.icon-file-image-1:before { content: '\e262'; }
.icon-file-image-2:before { content: '\e263'; }
.icon-file-list:before { content: '\e264'; }
.icon-file-lock:before { content: '\e265'; }
.icon-file-media:before { content: '\e266'; }
.icon-file-minus:before { content: '\e267'; }
.icon-file-music:before { content: '\e268'; }
.icon-file-new:before { content: '\e269'; }
.icon-file-registry:before { content: '\e26a'; }
.icon-file-search:before { content: '\e26b'; }
.icon-file-setting:before { content: '\e26c'; }
.icon-file-sync:before { content: '\e26d'; }
.icon-file-table:before { content: '\e26e'; }
.icon-file-thumbnail:before { content: '\e26f'; }
.icon-file-time:before { content: '\e270'; }
.icon-file-transfer:before { content: '\e271'; }
.icon-file-upload:before { content: '\e272'; }
.icon-file-zip:before { content: '\e273'; }
.icon-files-1:before { content: '\e274'; }
.icon-files-2:before { content: '\e275'; }
.icon-files-3:before { content: '\e276'; }
.icon-files-4:before { content: '\e277'; }
.icon-files-5:before { content: '\e278'; }
.icon-files-6:before { content: '\e279'; }
.icon-hand-file-1:before { content: '\e27a'; }
.icon-hand-file-2:before { content: '\e27b'; }
.icon-note-paper-1:before { content: '\e27c'; }
.icon-note-paper-2:before { content: '\e27d'; }
.icon-note-paper-add:before { content: '\e27e'; }
.icon-note-paper-attention:before { content: '\e27f'; }
.icon-note-paper-block:before { content: '\e280'; }
.icon-note-paper-checked:before { content: '\e281'; }
.icon-note-paper-delete:before { content: '\e282'; }
.icon-note-paper-download:before { content: '\e283'; }
.icon-note-paper-edit:before { content: '\e284'; }
.icon-note-paper-favorite:before { content: '\e285'; }
.icon-note-paper-lock:before { content: '\e286'; }
.icon-note-paper-minus:before { content: '\e287'; }
.icon-note-paper-search:before { content: '\e288'; }
.icon-note-paper-sync:before { content: '\e289'; }
.icon-note-paper-upload:before { content: '\e28a'; }
.icon-print:before { content: '\e28b'; }
.icon-folder-1:before { content: '\e28c'; }
.icon-folder-2:before { content: '\e28d'; }
.icon-folder-3:before { content: '\e28e'; }
.icon-folder-4:before { content: '\e28f'; }
.icon-folder-add:before { content: '\e290'; }
.icon-folder-attention:before { content: '\e291'; }
.icon-folder-block:before { content: '\e292'; }
.icon-folder-bookmark:before { content: '\e293'; }
.icon-folder-checked:before { content: '\e294'; }
.icon-folder-code:before { content: '\e295'; }
.icon-folder-delete:before { content: '\e296'; }
.icon-folder-download:before { content: '\e297'; }
.icon-folder-edit:before { content: '\e298'; }
.icon-folder-favorite:before { content: '\e299'; }
.icon-folder-home:before { content: '\e29a'; }
.icon-folder-image:before { content: '\e29b'; }
.icon-folder-lock:before { content: '\e29c'; }
.icon-folder-media:before { content: '\e29d'; }
.icon-folder-minus:before { content: '\e29e'; }
.icon-folder-music:before { content: '\e29f'; }
.icon-folder-new:before { content: '\e2a0'; }
.icon-folder-search:before { content: '\e2a1'; }
.icon-folder-setting:before { content: '\e2a2'; }
.icon-folder-share-1:before { content: '\e2a3'; }
.icon-folder-share-2:before { content: '\e2a4'; }
.icon-folder-sync:before { content: '\e2a5'; }
.icon-folder-transfer:before { content: '\e2a6'; }
.icon-folder-upload:before { content: '\e2a7'; }
.icon-folder-zip:before { content: '\e2a8'; }
.icon-add-1:before { content: '\e2a9'; }
.icon-add-2:before { content: '\e2aa'; }
.icon-add-3:before { content: '\e2ab'; }
.icon-add-4:before { content: '\e2ac'; }
.icon-add-tag:before { content: '\e2ad'; }
.icon-arrow-1:before { content: '\e2ae'; }
.icon-arrow-2:before { content: '\e2af'; }
.icon-arrow-down-1:before { content: '\e2b0'; }
.icon-arrow-down-2:before { content: '\e2b1'; }
.icon-arrow-left-1:before { content: '\e2b2'; }
.icon-arrow-left-2:before { content: '\e2b3'; }
.icon-arrow-move-1:before { content: '\e2b4'; }
.icon-arrow-move-down:before { content: '\e2b5'; }
.icon-arrow-move-left:before { content: '\e2b6'; }
.icon-arrow-move-right:before { content: '\e2b7'; }
.icon-arrow-move-up:before { content: '\e2b8'; }
.icon-arrow-right-1:before { content: '\e2b9'; }
.icon-arrow-right-2:before { content: '\e2ba'; }
.icon-arrow-up-1:before { content: '\e2bb'; }
.icon-arrow-up-2:before { content: '\e2bc'; }
.icon-back:before { content: '\e2bd'; }
.icon-center-expand:before { content: '\e2be'; }
.icon-center-reduce:before { content: '\e2bf'; }
.icon-delete-1-1:before { content: '\e2c0'; }
.icon-delete-2-1:before { content: '\e2c1'; }
.icon-delete-3:before { content: '\e2c2'; }
.icon-delete-4:before { content: '\e2c3'; }
.icon-delete-tag:before { content: '\e2c4'; }
.icon-expand-horizontal:before { content: '\e2c5'; }
.icon-expand-vertical:before { content: '\e2c6'; }
.icon-forward-3:before { content: '\e2c7'; }
.icon-infinity:before { content: '\e2c8'; }
.icon-loading:before { content: '\e2c9'; }
.icon-log-out-1:before { content: '\e2ca'; }
.icon-log-out-2:before { content: '\e2cb'; }
.icon-loop-1:before { content: '\e2cc'; }
.icon-loop-2:before { content: '\e2cd'; }
.icon-loop-3:before { content: '\e2ce'; }
.icon-minus-1:before { content: '\e2cf'; }
.icon-minus-2:before { content: '\e2d0'; }
.icon-minus-3:before { content: '\e2d1'; }
.icon-minus-4:before { content: '\e2d2'; }
.icon-minus-tag:before { content: '\e2d3'; }
.icon-move-diagonal-1:before { content: '\e2d4'; }
.icon-move-diagonal-2:before { content: '\e2d5'; }
.icon-move-horizontal-1:before { content: '\e2d6'; }
.icon-move-horizontal-2:before { content: '\e2d7'; }
.icon-move-vertical-1:before { content: '\e2d8'; }
.icon-move-vertical-2:before { content: '\e2d9'; }
.icon-next-1-1:before { content: '\e2da'; }
.icon-next-2-1:before { content: '\e2db'; }
.icon-power-1-1:before { content: '\e2dc'; }
.icon-power-2-1:before { content: '\e2dd'; }
.icon-power-3:before { content: '\e2de'; }
.icon-power-4:before { content: '\e2df'; }
.icon-recycle:before { content: '\e2e0'; }
.icon-refresh:before { content: '\e2e1'; }
.icon-repeat:before { content: '\e2e2'; }
.icon-return:before { content: '\e2e3'; }
.icon-scale-all-1:before { content: '\e2e4'; }
.icon-scale-center:before { content: '\e2e5'; }
.icon-scale-horizontal-1:before { content: '\e2e6'; }
.icon-scale-horizontal-2:before { content: '\e2e7'; }
.icon-scale-reduce-1:before { content: '\e2e8'; }
.icon-scale-reduce-2:before { content: '\e2e9'; }
.icon-scale-reduce-3:before { content: '\e2ea'; }
.icon-scale-spread-1:before { content: '\e2eb'; }
.icon-scale-spread-2:before { content: '\e2ec'; }
.icon-scale-spread-3:before { content: '\e2ed'; }
.icon-scale-vertical-1:before { content: '\e2ee'; }
.icon-scale-vertical-2:before { content: '\e2ef'; }
.icon-scroll-horizontal-1:before { content: '\e2f0'; }
.icon-scroll-horizontal-2:before { content: '\e2f1'; }
.icon-scroll-omnidirectional-1:before { content: '\e2f2'; }
.icon-scroll-omnidirectional-2:before { content: '\e2f3'; }
.icon-scroll-vertical-1:before { content: '\e2f4'; }
.icon-scroll-vertical-2:before { content: '\e2f5'; }
.icon-shuffle:before { content: '\e2f6'; }
.icon-split:before { content: '\e2f7'; }
.icon-sync-1:before { content: '\e2f8'; }
.icon-sync-2:before { content: '\e2f9'; }
.icon-timer:before { content: '\e2fa'; }
.icon-transfer:before { content: '\e2fb'; }
.icon-transfer-1-1:before { content: '\e2fc'; }
.icon-check-1:before { content: '\e2fd'; }
.icon-check-2:before { content: '\e2fe'; }
.icon-check-3:before { content: '\e2ff'; }
.icon-check-box:before { content: '\e300'; }
.icon-check-bubble:before { content: '\e301'; }
.icon-check-circle-1:before { content: '\e302'; }
.icon-check-circle-2:before { content: '\e303'; }
.icon-check-list:before { content: '\e304'; }
.icon-check-shield:before { content: '\e305'; }
.icon-cross:before { content: '\e306'; }
.icon-cross-bubble:before { content: '\e307'; }
.icon-cross-shield:before { content: '\e308'; }
.icon-briefcase-1:before { content: '\e309'; }
.icon-brightness-high:before { content: '\e30a'; }
.icon-brightness-low:before { content: '\e30b'; }
.icon-hammer-1:before { content: '\e30c'; }
.icon-hammer-2:before { content: '\e30d'; }
.icon-pulse:before { content: '\e30e'; }
.icon-scale:before { content: '\e30f'; }
.icon-screw-driver:before { content: '\e310'; }
.icon-setting-adjustment:before { content: '\e311'; }
.icon-setting-gear:before { content: '\e312'; }
.icon-setting-gears-1:before { content: '\e313'; }
.icon-setting-gears-2:before { content: '\e314'; }
.icon-setting-wrenches:before { content: '\e315'; }
.icon-switch-1:before { content: '\e316'; }
.icon-switch-2:before { content: '\e317'; }
.icon-wrench:before { content: '\e318'; }
.icon-alarm-1:before { content: '\e319'; }
.icon-alarm-clock:before { content: '\e31a'; }
.icon-alarm-off:before { content: '\e31b'; }
.icon-alarm-snooze:before { content: '\e31c'; }
.icon-bell:before { content: '\e31d'; }
.icon-calendar-1:before { content: '\e31e'; }
.icon-calendar-2:before { content: '\e31f'; }
.icon-clock-1:before { content: '\e320'; }
.icon-clock-2:before { content: '\e321'; }
.icon-clock-3:before { content: '\e322'; }
.icon-hourglass-1:before { content: '\e323'; }
.icon-hourglass-2:before { content: '\e324'; }
.icon-timer-1:before { content: '\e325'; }
.icon-timer-3-quarter-1:before { content: '\e326'; }
.icon-timer-3-quarter-2:before { content: '\e327'; }
.icon-timer-full-1:before { content: '\e328'; }
.icon-timer-full-2:before { content: '\e329'; }
.icon-timer-half-1:before { content: '\e32a'; }
.icon-timer-half-2:before { content: '\e32b'; }
.icon-timer-half-3:before { content: '\e32c'; }
.icon-timer-half-4:before { content: '\e32d'; }
.icon-timer-quarter-1:before { content: '\e32e'; }
.icon-timer-quarter-2:before { content: '\e32f'; }
.icon-watch-1:before { content: '\e330'; }
.icon-watch-2:before { content: '\e331'; }
.icon-alert-1:before { content: '\e332'; }
.icon-alert-2:before { content: '\e333'; }
.icon-alert-3:before { content: '\e334'; }
.icon-information:before { content: '\e335'; }
.icon-nuclear-1:before { content: '\e336'; }
.icon-nuclear-2:before { content: '\e337'; }
.icon-question-mark:before { content: '\e338'; }
.icon-abacus:before { content: '\e339'; }
.icon-amex-card:before { content: '\e33a'; }
.icon-atm:before { content: '\e33b'; }
.icon-balance:before { content: '\e33c'; }
.icon-bank-1:before { content: '\e33d'; }
.icon-bank-2:before { content: '\e33e'; }
.icon-bank-note-1:before { content: '\e33f'; }
.icon-bank-note-2:before { content: '\e340'; }
.icon-bank-note-3:before { content: '\e341'; }
.icon-bitcoins:before { content: '\e342'; }
.icon-board:before { content: '\e343'; }
.icon-box-1:before { content: '\e344'; }
.icon-box-2:before { content: '\e345'; }
.icon-box-3:before { content: '\e346'; }
.icon-box-download:before { content: '\e347'; }
.icon-box-shipping:before { content: '\e348'; }
.icon-box-upload:before { content: '\e349'; }
.icon-business-chart-1:before { content: '\e34a'; }
.icon-business-chart-2:before { content: '\e34b'; }
.icon-calculator-1:before { content: '\e34c'; }
.icon-calculator-2:before { content: '\e34d'; }
.icon-calculator-3:before { content: '\e34e'; }
.icon-cash-register:before { content: '\e34f'; }
.icon-chart-board:before { content: '\e350'; }
.icon-chart-down:before { content: '\e351'; }
.icon-chart-up:before { content: '\e352'; }
.icon-check:before { content: '\e353'; }
.icon-coins-1:before { content: '\e354'; }
.icon-coins-2:before { content: '\e355'; }
.icon-court:before { content: '\e356'; }
.icon-credit-card:before { content: '\e357'; }
.icon-credit-card-lock:before { content: '\e358'; }
.icon-delivery:before { content: '\e359'; }
.icon-dollar-bag:before { content: '\e35a'; }
.icon-dollar-currency-1:before { content: '\e35b'; }
.icon-dollar-currency-2:before { content: '\e35c'; }
.icon-dollar-currency-3:before { content: '\e35d'; }
.icon-dollar-currency-4:before { content: '\e35e'; }
.icon-euro-bag:before { content: '\e35f'; }
.icon-euro-currency-1:before { content: '\e360'; }
.icon-euro-currency-2:before { content: '\e361'; }
.icon-euro-currency-3:before { content: '\e362'; }
.icon-euro-currency-4:before { content: '\e363'; }
.icon-forklift:before { content: '\e364'; }
.icon-hand-card:before { content: '\e365'; }
.icon-hand-coin:before { content: '\e366'; }
.icon-keynote:before { content: '\e367'; }
.icon-master-card:before { content: '\e368'; }
.icon-money:before { content: '\e369'; }
.icon-parking-meter:before { content: '\e36a'; }
.icon-percent-1:before { content: '\e36b'; }
.icon-percent-2:before { content: '\e36c'; }
.icon-percent-3:before { content: '\e36d'; }
.icon-percent-4:before { content: '\e36e'; }
.icon-percent-5:before { content: '\e36f'; }
.icon-percent-up:before { content: '\e370'; }
.icon-pie-chart-1:before { content: '\e371'; }
.icon-pie-chart-2:before { content: '\e372'; }
.icon-piggy-bank:before { content: '\e373'; }
.icon-pound-currency-1:before { content: '\e374'; }
.icon-pound-currency-2:before { content: '\e375'; }
.icon-pound-currency-3:before { content: '\e376'; }
.icon-pound-currency-4:before { content: '\e377'; }
.icon-safe-1:before { content: '\e378'; }
.icon-safe-2:before { content: '\e379'; }
.icon-shop:before { content: '\e37a'; }
.icon-sign:before { content: '\e37b'; }
.icon-trolley:before { content: '\e37c'; }
.icon-truck-1:before { content: '\e37d'; }
.icon-truck-2:before { content: '\e37e'; }
.icon-visa-card:before { content: '\e37f'; }
.icon-yen-currency-1:before { content: '\e380'; }
.icon-yen-currency-2:before { content: '\e381'; }
.icon-yen-currency-3:before { content: '\e382'; }
.icon-yen-currency-4:before { content: '\e383'; }
.icon-add-marker-1:before { content: '\e384'; }
.icon-add-marker-1-1:before { content: '\e385'; }
.icon-add-marker-2:before { content: '\e386'; }
.icon-add-marker-2-1:before { content: '\e387'; }
.icon-add-marker-3:before { content: '\e388'; }
.icon-compass-1:before { content: '\e389'; }
.icon-compass-2:before { content: '\e38a'; }
.icon-compass-3:before { content: '\e38b'; }
.icon-delete-marker-1:before { content: '\e38c'; }
.icon-delete-marker-1-1:before { content: '\e38d'; }
.icon-delete-marker-2:before { content: '\e38e'; }
.icon-delete-marker-2-1:before { content: '\e38f'; }
.icon-favorite-marker-1:before { content: '\e390'; }
.icon-favorite-marker-1-1:before { content: '\e391'; }
.icon-favorite-marker-2:before { content: '\e392'; }
.icon-favorite-marker-2-1:before { content: '\e393'; }
.icon-globe:before { content: '\e394'; }
.icon-location:before { content: '\e395'; }
.icon-map-1:before { content: '\e396'; }
.icon-map-location:before { content: '\e397'; }
.icon-map-marker-1:before { content: '\e398'; }
.icon-map-marker-1-1:before { content: '\e399'; }
.icon-map-marker-2:before { content: '\e39a'; }
.icon-map-marker-3:before { content: '\e39b'; }
.icon-map-marker-pin:before { content: '\e39c'; }
.icon-map-pin:before { content: '\e39d'; }
.icon-marker-1:before { content: '\e39e'; }
.icon-marker-1-1:before { content: '\e39f'; }
.icon-marker-2:before { content: '\e3a0'; }
.icon-marker-2-1:before { content: '\e3a1'; }
.icon-marker-pin-1:before { content: '\e3a2'; }
.icon-marker-pin-2:before { content: '\e3a3'; }
.icon-marker-pin-location:before { content: '\e3a4'; }
.icon-minus-marker-1:before { content: '\e3a5'; }
.icon-minus-marker-1-1:before { content: '\e3a6'; }
.icon-minus-marker-2:before { content: '\e3a7'; }
.icon-minus-marker-2-1:before { content: '\e3a8'; }
.icon-minus-marker-3:before { content: '\e3a9'; }
.icon-anchor:before { content: '\e3aa'; }
.icon-bank:before { content: '\e3ab'; }
.icon-beach:before { content: '\e3ac'; }
.icon-boat:before { content: '\e3ad'; }
.icon-building-1:before { content: '\e3ae'; }
.icon-building-2:before { content: '\e3af'; }
.icon-building-3:before { content: '\e3b0'; }
.icon-buildings-1:before { content: '\e3b1'; }
.icon-buildings-2:before { content: '\e3b2'; }
.icon-buildings-3:before { content: '\e3b3'; }
.icon-buildings-4:before { content: '\e3b4'; }
.icon-castle:before { content: '\e3b5'; }
.icon-column:before { content: '\e3b6'; }
.icon-direction-sign:before { content: '\e3b7'; }
.icon-factory:before { content: '\e3b8'; }
.icon-fence:before { content: '\e3b9'; }
.icon-garage:before { content: '\e3ba'; }
.icon-globe-1:before { content: '\e3bb'; }
.icon-globe-2:before { content: '\e3bc'; }
.icon-house-1:before { content: '\e3bd'; }
.icon-house-2:before { content: '\e3be'; }
.icon-house-3:before { content: '\e3bf'; }
.icon-house-4:before { content: '\e3c0'; }
.icon-library:before { content: '\e3c1'; }
.icon-light-house:before { content: '\e3c2'; }
.icon-pisa:before { content: '\e3c3'; }
.icon-skyscraper:before { content: '\e3c4'; }
.icon-temple:before { content: '\e3c5'; }
.icon-treasure-map:before { content: '\e3c6'; }
.icon-tree:before { content: '\e3c7'; }
.icon-tree-pine:before { content: '\e3c8'; }
.icon-attention:before { content: '\e3c9'; }
.icon-bug-1:before { content: '\e3ca'; }
.icon-bug-2:before { content: '\e3cb'; }
.icon-css3:before { content: '\e3cc'; }
.icon-firewall:before { content: '\e3cd'; }
.icon-html5:before { content: '\e3ce'; }
.icon-plugins-1:before { content: '\e3cf'; }
.icon-plugins-2:before { content: '\e3d0'; }
.icon-script:before { content: '\e3d1'; }
.icon-new-window:before { content: '\e3d2'; }
.icon-window-1:before { content: '\e3d3'; }
.icon-window-2:before { content: '\e3d4'; }
.icon-window-3:before { content: '\e3d5'; }
.icon-window-add:before { content: '\e3d6'; }
.icon-window-alert:before { content: '\e3d7'; }
.icon-window-check:before { content: '\e3d8'; }
.icon-window-code-1:before { content: '\e3d9'; }
.icon-window-code-2:before { content: '\e3da'; }
.icon-window-code-3:before { content: '\e3db'; }
.icon-window-column:before { content: '\e3dc'; }
.icon-window-delete:before { content: '\e3dd'; }
.icon-window-denied:before { content: '\e3de'; }
.icon-window-download-1:before { content: '\e3df'; }
.icon-window-download-2:before { content: '\e3e0'; }
.icon-window-edit:before { content: '\e3e1'; }
.icon-window-favorite:before { content: '\e3e2'; }
.icon-window-graph-1:before { content: '\e3e3'; }
.icon-window-graph-2:before { content: '\e3e4'; }
.icon-window-hand:before { content: '\e3e5'; }
.icon-window-home:before { content: '\e3e6'; }
.icon-window-like:before { content: '\e3e7'; }
.icon-window-list-1:before { content: '\e3e8'; }
.icon-window-list-2:before { content: '\e3e9'; }
.icon-window-lock:before { content: '\e3ea'; }
.icon-window-minimize:before { content: '\e3eb'; }
.icon-window-minus:before { content: '\e3ec'; }
.icon-window-refresh:before { content: '\e3ed'; }
.icon-window-register:before { content: '\e3ee'; }
.icon-window-search:before { content: '\e3ef'; }
.icon-window-selection:before { content: '\e3f0'; }
.icon-window-setting:before { content: '\e3f1'; }
.icon-window-sync:before { content: '\e3f2'; }
.icon-window-thumbnails-1:before { content: '\e3f3'; }
.icon-window-thumbnails-2:before { content: '\e3f4'; }
.icon-window-time:before { content: '\e3f5'; }
.icon-window-upload-1:before { content: '\e3f6'; }
.icon-window-upload-2:before { content: '\e3f7'; }
.icon-windows-selection:before { content: '\e3f8'; }
.icon-database:before { content: '\e3f9'; }
.icon-database-alert:before { content: '\e3fa'; }
.icon-database-block:before { content: '\e3fb'; }
.icon-database-check:before { content: '\e3fc'; }
.icon-database-delete:before { content: '\e3fd'; }
.icon-database-download:before { content: '\e3fe'; }
.icon-database-edit:before { content: '\e3ff'; }
.icon-database-lock:before { content: '\e400'; }
.icon-database-minus:before { content: '\e401'; }
.icon-database-network:before { content: '\e402'; }
.icon-database-plus:before { content: '\e403'; }
.icon-database-refresh:before { content: '\e404'; }
.icon-database-search:before { content: '\e405'; }
.icon-database-setting:before { content: '\e406'; }
.icon-database-sync:before { content: '\e407'; }
.icon-database-time:before { content: '\e408'; }
.icon-database-upload:before { content: '\e409'; }
.icon-battery-charging:before { content: '\e40a'; }
.icon-battery-full:before { content: '\e40b'; }
.icon-battery-high:before { content: '\e40c'; }
.icon-battery-low:before { content: '\e40d'; }
.icon-battery-medium:before { content: '\e40e'; }
.icon-cd-1:before { content: '\e40f'; }
.icon-cd-2:before { content: '\e410'; }
.icon-chip:before { content: '\e411'; }
.icon-computer:before { content: '\e412'; }
.icon-disc:before { content: '\e413'; }
.icon-filter:before { content: '\e414'; }
.icon-floppy-disk:before { content: '\e415'; }
.icon-gameboy:before { content: '\e416'; }
.icon-harddisk-1:before { content: '\e417'; }
.icon-harddisk-2:before { content: '\e418'; }
.icon-imac:before { content: '\e419'; }
.icon-ipad-1:before { content: '\e41a'; }
.icon-ipad-2:before { content: '\e41b'; }
.icon-ipod:before { content: '\e41c'; }
.icon-joystick-1:before { content: '\e41d'; }
.icon-joystick-2:before { content: '\e41e'; }
.icon-joystick-3:before { content: '\e41f'; }
.icon-keyboard-1:before { content: '\e420'; }
.icon-keyboard-2:before { content: '\e421'; }
.icon-kindle-1:before { content: '\e422'; }
.icon-kindle-2:before { content: '\e423'; }
.icon-laptop-1:before { content: '\e424'; }
.icon-laptop-2:before { content: '\e425'; }
.icon-memory-card:before { content: '\e426'; }
.icon-mobile-phone:before { content: '\e427'; }
.icon-mouse-1:before { content: '\e428'; }
.icon-mouse-2:before { content: '\e429'; }
.icon-mp3player:before { content: '\e42a'; }
.icon-plug-1:before { content: '\e42b'; }
.icon-plug-2:before { content: '\e42c'; }
.icon-plug-slot:before { content: '\e42d'; }
.icon-printer:before { content: '\e42e'; }
.icon-projector:before { content: '\e42f'; }
.icon-remote:before { content: '\e430'; }
.icon-router:before { content: '\e431'; }
.icon-screen-1:before { content: '\e432'; }
.icon-screen-2:before { content: '\e433'; }
.icon-screen-3:before { content: '\e434'; }
.icon-screen-4:before { content: '\e435'; }
.icon-smartphone-1:before { content: '\e436'; }
.icon-television-1:before { content: '\e437'; }
.icon-typewriter-1:before { content: '\e438'; }
.icon-typewriter-2:before { content: '\e439'; }
.icon-usb-1:before { content: '\e43a'; }
.icon-usb-2:before { content: '\e43b'; }
.icon-webcam:before { content: '\e43c'; }
.icon-wireless-router-1:before { content: '\e43d'; }
.icon-wireless-router-2:before { content: '\e43e'; }
.icon-bluetooth:before { content: '\e43f'; }
.icon-ethernet:before { content: '\e440'; }
.icon-ethernet-slot:before { content: '\e441'; }
.icon-firewire-1:before { content: '\e442'; }
.icon-firewire-2:before { content: '\e443'; }
.icon-network-1:before { content: '\e444'; }
.icon-network-2:before { content: '\e445'; }
.icon-server-1:before { content: '\e446'; }
.icon-server-2:before { content: '\e447'; }
.icon-server-3:before { content: '\e448'; }
.icon-usb:before { content: '\e449'; }
.icon-wireless-signal:before { content: '\e44a'; }
.icon-book-1:before { content: '\e44b'; }
.icon-book-2:before { content: '\e44c'; }
.icon-book-3:before { content: '\e44d'; }
.icon-book-4:before { content: '\e44e'; }
.icon-book-5:before { content: '\e44f'; }
.icon-book-6:before { content: '\e450'; }
.icon-book-7:before { content: '\e451'; }
.icon-book-8:before { content: '\e452'; }
.icon-book-download-1:before { content: '\e453'; }
.icon-book-download-2:before { content: '\e454'; }
.icon-book-favorite-1:before { content: '\e455'; }
.icon-book-favorite-2:before { content: '\e456'; }
.icon-bookmark-1-1:before { content: '\e457'; }
.icon-bookmark-2-1:before { content: '\e458'; }
.icon-bookmark-3-1:before { content: '\e459'; }
.icon-bookmark-4-1:before { content: '\e45a'; }
.icon-books-1:before { content: '\e45b'; }
.icon-books-2:before { content: '\e45c'; }
.icon-books-3:before { content: '\e45d'; }
.icon-briefcase-2:before { content: '\e45e'; }
.icon-contact-book-1:before { content: '\e45f'; }
.icon-contact-book-2:before { content: '\e460'; }
.icon-contact-book-3:before { content: '\e461'; }
.icon-contact-book-4:before { content: '\e462'; }
.icon-copyright:before { content: '\e463'; }
.icon-creative-commons:before { content: '\e464'; }
.icon-cube:before { content: '\e465'; }
.icon-data-filter:before { content: '\e466'; }
.icon-document-box-1:before { content: '\e467'; }
.icon-document-box-2:before { content: '\e468'; }
.icon-document-box-3:before { content: '\e469'; }
.icon-drawer-1:before { content: '\e46a'; }
.icon-drawer-2:before { content: '\e46b'; }
.icon-drawer-3:before { content: '\e46c'; }
.icon-envelope:before { content: '\e46d'; }
.icon-file:before { content: '\e46e'; }
.icon-files:before { content: '\e46f'; }
.icon-filter-1:before { content: '\e470'; }
.icon-filter-2:before { content: '\e471'; }
.icon-layers-1:before { content: '\e472'; }
.icon-list-1:before { content: '\e473'; }
.icon-list-2:before { content: '\e474'; }
.icon-newspaper-1:before { content: '\e475'; }
.icon-newspaper-2:before { content: '\e476'; }
.icon-registry-1:before { content: '\e477'; }
.icon-registry-2:before { content: '\e478'; }
.icon-shield-1:before { content: '\e479'; }
.icon-shield-2:before { content: '\e47a'; }
.icon-shield-3:before { content: '\e47b'; }
.icon-sketchbook:before { content: '\e47c'; }
.icon-sound-book:before { content: '\e47d'; }
.icon-thumbnails-1:before { content: '\e47e'; }
.icon-thumbnails-2:before { content: '\e47f'; }
.icon-graph:before { content: '\e480'; }
.icon-hierarchy-1:before { content: '\e481'; }
.icon-hierarchy-2:before { content: '\e482'; }
.icon-hierarchy-3:before { content: '\e483'; }
.icon-hierarchy-4:before { content: '\e484'; }
.icon-hierarchy-5:before { content: '\e485'; }
.icon-hierarchy-6:before { content: '\e486'; }
.icon-hierarchy-7:before { content: '\e487'; }
.icon-network-1-1:before { content: '\e488'; }
.icon-network-2-1:before { content: '\e489'; }
.icon-backpack:before { content: '\e48a'; }
.icon-balance-1:before { content: '\e48b'; }
.icon-bed:before { content: '\e48c'; }
.icon-bench:before { content: '\e48d'; }
.icon-bricks:before { content: '\e48f'; }
.icon-bullets:before { content: '\e490'; }
.icon-buoy:before { content: '\e491'; }
.icon-campfire:before { content: '\e492'; }
.icon-can:before { content: '\e493'; }
.icon-candle:before { content: '\e494'; }
.icon-cctv-1:before { content: '\e496'; }
.icon-cctv-2:before { content: '\e497'; }
.icon-chair:before { content: '\e498'; }
.icon-chair-director:before { content: '\e499'; }
.icon-cigarette:before { content: '\e49a'; }
.icon-construction-sign:before { content: '\e49b'; }
.icon-diamond:before { content: '\e49c'; }
.icon-disabled:before { content: '\e49d'; }
.icon-door:before { content: '\e49e'; }
.icon-drawer:before { content: '\e49f'; }
.icon-driller:before { content: '\e4a0'; }
.icon-dumbbells:before { content: '\e4a1'; }
.icon-fire-extinguisher:before { content: '\e4a2'; }
.icon-flashlight:before { content: '\e4a3'; }
.icon-gas-station:before { content: '\e4a4'; }
.icon-lamp-1:before { content: '\e4a6'; }
.icon-lamp-2:before { content: '\e4a7'; }
.icon-lamp-3:before { content: '\e4a8'; }
.icon-lamp-4:before { content: '\e4a9'; }
.icon-lightbulb-1:before { content: '\e4aa'; }
.icon-lightbulb-2:before { content: '\e4ab'; }
.icon-measuring-tape:before { content: '\e4ac'; }
.icon-mine-cart:before { content: '\e4ad'; }
.icon-ring:before { content: '\e4af'; }
.icon-scale-1:before { content: '\e4b0'; }
.icon-shovel:before { content: '\e4b1'; }
.icon-smoke-no:before { content: '\e4b2'; }
.icon-sofa-1:before { content: '\e4b3'; }
.icon-sofa-2:before { content: '\e4b4'; }
.icon-sofa-3:before { content: '\e4b5'; }
.icon-target:before { content: '\e4b6'; }
.icon-torch:before { content: '\e4b7'; }
.icon-traffic-cone:before { content: '\e4b8'; }
.icon-traffic-light-1:before { content: '\e4b9'; }
.icon-traffic-light-2:before { content: '\e4ba'; }
.icon-treasure-chest-1:before { content: '\e4bb'; }
.icon-treasure-chest-2:before { content: '\e4bc'; }
.icon-trowel:before { content: '\e4bd'; }
.icon-watering-can:before { content: '\e4be'; }
.icon-weigh:before { content: '\e4bf'; }
.icon-academic-cap:before { content: '\e4c0'; }
.icon-baseball-helmet:before { content: '\e4c1'; }
.icon-beanie:before { content: '\e4c2'; }
.icon-bike-helmet:before { content: '\e4c3'; }
.icon-bow:before { content: '\e4c4'; }
.icon-cap:before { content: '\e4c5'; }
.icon-chaplin:before { content: '\e4c6'; }
.icon-chef-hat:before { content: '\e4c7'; }
.icon-cloth-hanger:before { content: '\e4c8'; }
.icon-fins:before { content: '\e4c9'; }
.icon-football-helmet:before { content: '\e4ca'; }
.icon-glasses:before { content: '\e4cb'; }
.icon-glasses-1:before { content: '\e4cc'; }
.icon-glasses-2:before { content: '\e4cd'; }
.icon-magician-hat:before { content: '\e4ce'; }
.icon-monocle-1:before { content: '\e4cf'; }
.icon-monocle-2:before { content: '\e4d0'; }
.icon-necktie:before { content: '\e4d1'; }
.icon-safety-helmet:before { content: '\e4d2'; }
.icon-scuba-tank:before { content: '\e4d3'; }
.icon-shirt-1:before { content: '\e4d4'; }
.icon-shirt-2:before { content: '\e4d5'; }
.icon-shirt-3:before { content: '\e4d6'; }
.icon-sneakers:before { content: '\e4d7'; }
.icon-snorkel:before { content: '\e4d8'; }
.icon-sombrero:before { content: '\e4d9'; }
.icon-sunglasses:before { content: '\e4da'; }
.icon-tall-hat:before { content: '\e4db'; }
.icon-trousers:before { content: '\e4dc'; }
.icon-walking-stick:before { content: '\e4dd'; }
.icon-arrow-redo:before  { content: '\e4de'; }
.icon-arrow-undo:before { content: '\e4df'; }
.icon-bold:before { content: '\e4e0'; }
.icon-columns:before { content: '\e4e1'; }
.icon-eraser:before { content: '\e4e2'; }
.icon-font-color:before { content: '\e4e3'; }
.icon-html:before { content: '\e4e4'; }
.icon-italic:before { content: '\e4e5'; }
.icon-list-1-1:before { content: '\e4e6'; }
.icon-list-2-1:before { content: '\e4e7'; }
.icon-list-3:before { content: '\e4e8'; }
.icon-list-4:before { content: '\e4e9'; }
.icon-paragraph:before { content: '\e4ea'; }
.icon-paste:before { content: '\e4eb'; }
.icon-print-preview:before { content: '\e4ec'; }
.icon-quote:before { content: '\e4ed'; }
.icon-strikethrough:before { content: '\e4ee'; }
.icon-text:before { content: '\e4ef'; }
.icon-text-wrapping-1:before { content: '\e4f0'; }
.icon-text-wrapping-2:before { content: '\e4f1'; }
.icon-text-wrapping-3:before { content: '\e4f2'; }
.icon-underline:before { content: '\e4f3'; }
.icon-align-center:before { content: '\e4f4'; }
.icon-align-left:before { content: '\e4f5'; }
.icon-align-right:before { content: '\e4f6'; }
.icon-all-caps:before { content: '\e4f7'; }
.icon-arrange-2-1:before { content: '\e4f8'; }
.icon-arrange-2-2:before { content: '\e4f9'; }
.icon-arrange-2-3:before { content: '\e4fa'; }
.icon-arrange-2-4:before { content: '\e4fb'; }
.icon-arrange-3-1:before { content: '\e4fc'; }
.icon-arrange-3-2:before { content: '\e4fd'; }
.icon-arrange-3-3:before { content: '\e4fe'; }
.icon-arrange-3-4:before { content: '\e4ff'; }
.icon-arrange-3-5:before { content: '\e500'; }
.icon-arrange-4-1:before { content: '\e501'; }
.icon-arrange-4-2:before { content: '\e502'; }
.icon-arrange-4-3:before { content: '\e503'; }
.icon-arrange-5:before { content: '\e504'; }
.icon-consolidate-all:before { content: '\e505'; }
.icon-decrease-indent-1:before { content: '\e506'; }
.icon-decrease-indent-2:before { content: '\e507'; }
.icon-horizontal-page:before { content: '\e508'; }
.icon-increase-indent-1:before { content: '\e509'; }
.icon-increase-indent-2:before { content: '\e50a'; }
.icon-justify:before { content: '\e50b'; }
.icon-leading-1:before { content: '\e50c'; }
.icon-leading-2:before { content: '\e50d'; }
.icon-left-indent:before { content: '\e50e'; }
.icon-right-indent:before { content: '\e50f'; }
.icon-small-caps:before { content: '\e510'; }
.icon-vertical-page:before { content: '\e511'; }
.icon-alt-mac:before { content: '\e512'; }
.icon-alt-windows:before { content: '\e513'; }
.icon-arrow-down:before { content: '\e514'; }
.icon-arrow-down-left:before { content: '\e515'; }
.icon-arrow-down-right:before { content: '\e516'; }
.icon-arrow-left:before { content: '\e517'; }
.icon-arrow-right:before { content: '\e518'; }
.icon-arrow-up:before { content: '\e519'; }
.icon-arrow-up-left:before { content: '\e51a'; }
.icon-arrow-up-right:before { content: '\e51b'; }
.icon-asterisk-1:before { content: '\e51c'; }
.icon-asterisk-2:before { content: '\e51d'; }
.icon-back-tab-1:before { content: '\e51e'; }
.icon-back-tab-2:before { content: '\e51f'; }
.icon-backward-delete:before { content: '\e520'; }
.icon-blank:before { content: '\e521'; }
.icon-eject:before { content: '\e522'; }
.icon-enter-1:before { content: '\e523'; }
.icon-enter-2:before { content: '\e524'; }
.icon-escape:before { content: '\e525'; }
.icon-page-down:before { content: '\e526'; }
.icon-page-up:before { content: '\e527'; }
.icon-return-1:before { content: '\e528'; }
.icon-shift-1:before { content: '\e529'; }
.icon-shift-2:before { content: '\e52a'; }
.icon-tab:before { content: '\e52b'; }
.icon-apple:before { content: '\e52c'; }
.icon-beer:before { content: '\e52d'; }
.icon-boil:before { content: '\e52e'; }
.icon-bottle-1:before { content: '\e52f'; }
.icon-bottle-2:before { content: '\e530'; }
.icon-bottle-3:before { content: '\e531'; }
.icon-bottle-4:before { content: '\e532'; }
.icon-bread:before { content: '\e533'; }
.icon-burger-1:before { content: '\e534'; }
.icon-burger-2:before { content: '\e535'; }
.icon-cake-1:before { content: '\e536'; }
.icon-cake-2:before { content: '\e537'; }
.icon-champagne:before { content: '\e538'; }
.icon-cheese:before { content: '\e539'; }
.icon-cocktail:before { content: '\e53a'; }
.icon-cocktail-1:before { content: '\e53b'; }
.icon-cocktail-2:before { content: '\e53c'; }
.icon-coffee-pot:before { content: '\e53d'; }
.icon-cup1:before { content: '\e53e'; }
.icon-cup-2:before { content: '\e53f'; }
.icon-deep-fry:before { content: '\e540'; }
.icon-energy-drink:before { content: '\e541'; }
.icon-espresso-machine:before { content: '\e542'; }
.icon-food-dome:before { content: '\e543'; }
.icon-fork-and-knife:before { content: '\e544'; }
.icon-fork-and-spoon:before { content: '\e545'; }
.icon-grape:before { content: '\e546'; }
.icon-grater:before { content: '\e547'; }
.icon-grill:before { content: '\e548'; }
.icon-hot-drinks-glass:before { content: '\e549'; }
.icon-hotdog:before { content: '\e54a'; }
.icon-ice-cream-1:before { content: '\e54b'; }
.icon-ice-cream-2:before { content: '\e54c'; }
.icon-ice-cream-3:before { content: '\e54d'; }
.icon-ice-drinks-galss:before { content: '\e54e'; }
.icon-juicer:before { content: '\e54f'; }
.icon-kitchen-timer:before { content: '\e550'; }
.icon-milk:before { content: '\e551'; }
.icon-orange:before { content: '\e552'; }
.icon-oven:before { content: '\e553'; }
.icon-pan-fry:before { content: '\e554'; }
.icon-pepper-salt:before { content: '\e555'; }
.icon-pizza:before { content: '\e556'; }
.icon-popcorn:before { content: '\e557'; }
.icon-serving:before { content: '\e558'; }
.icon-soda:before { content: '\e559'; }
.icon-soda-can-1:before { content: '\e55a'; }
.icon-soda-can-2:before { content: '\e55b'; }
.icon-steam:before { content: '\e55c'; }
.icon-tea-pot:before { content: '\e55d'; }
.icon-thermometer-high:before { content: '\e55e'; }
.icon-thermometer-low:before { content: '\e55f'; }
.icon-thermometer-medium:before { content: '\e560'; }
.icon-water:before { content: '\e561'; }
.icon-wine:before { content: '\e562'; }
.icon-ambulance:before { content: '\e563'; }
.icon-beaker-1:before { content: '\e564'; }
.icon-beaker-2:before { content: '\e565'; }
.icon-blood:before { content: '\e566'; }
.icon-drug:before { content: '\e567'; }
.icon-first-aid:before { content: '\e568'; }
.icon-heartpulse:before { content: '\e56a'; }
.icon-hospital-1:before { content: '\e56b'; }
.icon-hospital-2:before { content: '\e56c'; }
.icon-hospital-sign-1:before { content: '\e56d'; }
.icon-hospital-sign-2:before { content: '\e56e'; }
.icon-hospital-sign-3:before { content: '\e56f'; }
.icon-medicine:before { content: '\e570'; }
.icon-microscope:before { content: '\e571'; }
.icon-mortar-and-pestle:before { content: '\e572'; }
.icon-plaster:before { content: '\e573'; }
.icon-pulse-graph-1:before { content: '\e574'; }
.icon-pulse-graph-2:before { content: '\e575'; }
.icon-pulse-graph-3:before { content: '\e576'; }
.icon-red-cross:before { content: '\e577'; }
.icon-stethoscope:before { content: '\e578'; }
.icon-syringe:before { content: '\e579'; }
.icon-yin-yang:before { content: '\e57a'; }
.icon-balloon:before { content: '\e57b'; }
.icon-briefcase-lock:before { content: '\e57c'; }
.icon-card:before { content: '\e57d'; }
.icon-cards:before { content: '\e57e'; }
.icon-cards-2:before { content: '\e57f'; }
.icon-curtain:before { content: '\e580'; }
.icon-dice-1:before { content: '\e581'; }
.icon-dice-2:before { content: '\e582'; }
.icon-happy-smiley:before { content: '\e583'; }
.icon-pacman:before { content: '\e584'; }
.icon-pacman-ghost:before { content: '\e585'; }
.icon-sad-smiley:before { content: '\e586'; }
.icon-sign-1:before { content: '\e587'; }
.icon-smileys:before { content: '\e588'; }
.icon-suitcase-1:before { content: '\e589'; }
.icon-suitcase-2:before { content: '\e58a'; }
.icon-tetris:before { content: '\e58b'; }
.icon-ticket-1:before { content: '\e58c'; }
.icon-ticket-2:before { content: '\e58d'; }
.icon-ticket-3:before { content: '\e58e'; }
.icon-virus:before { content: '\e58f'; }
.icon-cloud-1:before { content: '\e590'; }
.icon-cloud-lightning:before { content: '\e591'; }
.icon-clouds:before { content: '\e592'; }
.icon-first-quarter-moon:before { content: '\e593'; }
.icon-full-moon:before { content: '\e594'; }
.icon-hail:before { content: '\e595'; }
.icon-heavy-rain:before { content: '\e596'; }
.icon-moon-cloud:before { content: '\e597'; }
.icon-rain:before { content: '\e598'; }
.icon-rain-lightning:before { content: '\e599'; }
.icon-snow:before { content: '\e59a'; }
.icon-sun:before { content: '\e59b'; }
.icon-sun-cloud:before { content: '\e59c'; }
.icon-thermometer:before { content: '\e59d'; }
.icon-third-quarter-moon:before { content: '\e59e'; }
.icon-umbrella:before { content: '\e59f'; }
.icon-waning-crescent-moon:before { content: '\e5a0'; }
.icon-waning-gibbous-moon:before { content: '\e5a1'; }
.icon-waxing-crescent-moon:before { content: '\e5a2'; }
.icon-waxing-gibbous-moon:before { content: '\e5a3'; }
.icon-bicycle:before { content: '\e5a4'; }
.icon-bus-1:before { content: '\e5a5'; }
.icon-bus-2:before { content: '\e5a6'; }
.icon-car-1:before { content: '\e5a7'; }
.icon-car-2:before { content: '\e5a8'; }
.icon-car-3:before { content: '\e5a9'; }
.icon-car-4:before { content: '\e5aa'; }
.icon-helicopter:before { content: '\e5ab'; }
.icon-mountain-bike:before { content: '\e5ac'; }
.icon-pickup:before { content: '\e5ad'; }
.icon-plane-1:before { content: '\e5ae'; }
.icon-plane-2:before { content: '\e5af'; }
.icon-plane-landing:before { content: '\e5b0'; }
.icon-plane-takeoff:before { content: '\e5b1'; }
.icon-road:before { content: '\e5b2'; }
.icon-road-bike:before { content: '\e5b3'; }
.icon-rocket:before { content: '\e5b4'; }
.icon-scooter:before { content: '\e5b5'; }
.icon-ship:before { content: '\e5b6'; }
.icon-train:before { content: '\e5b7'; }
.icon-tram:before { content: '\e5b8'; }
.icon-cactus:before { content: '\e5b9'; }
.icon-clover:before { content: '\e5ba'; }
.icon-flower:before { content: '\e5bb'; }
.icon-hand-eco:before { content: '\e5bc'; }
.icon-hand-globe:before { content: '\e5bd'; }
.icon-leaf:before { content: '\e5be'; }
.icon-light-eco:before { content: '\e5bf'; }
.icon-potted-plant-1:before { content: '\e5c0'; }
.icon-potted-plant-2:before { content: '\e5c1'; }
.icon-2-fingers-double-tap:before { content: '\e5c2'; }
.icon-2-fingers-down-swipe:before { content: '\e5c3'; }
.icon-2-fingers-horizontal-swipe:before { content: '\e5c4'; }
.icon-2-fingers-left-swipe:before { content: '\e5c5'; }
.icon-2-fingers-omnidirectional-swipe:before { content: '\e5c6'; }
.icon-2-fingers-right-swipe:before { content: '\e5c7'; }
.icon-2-fingers-tab-hold:before { content: '\e5c8'; }
.icon-2-fingers-tap:before { content: '\e5c9'; }
.icon-2-fingers-up-swipe:before { content: '\e5ca'; }
.icon-2-fingers-vertical-swipe:before { content: '\e5cb'; }
.icon-double-tap:before { content: '\e5cc'; }
.icon-drag-down:before { content: '\e5cd'; }
.icon-drag-horizontal:before { content: '\e5ce'; }
.icon-drag-left:before { content: '\e5cf'; }
.icon-drag-right:before { content: '\e5d0'; }
.icon-drag-up:before { content: '\e5d1'; }
.icon-drag-vertical:before { content: '\e5d2'; }
.icon-filck-down:before { content: '\e5d3'; }
.icon-flick-up:before { content: '\e5d4'; }
.icon-horizontal-flick:before { content: '\e5d5'; }
.icon-left-flick:before { content: '\e5d6'; }
.icon-omnidirectional-drag:before { content: '\e5d7'; }
.icon-omnidirectional-flick:before { content: '\e5d8'; }
.icon-omnidirectional-swipe:before { content: '\e5d9'; }
.icon-pinch:before { content: '\e5da'; }
.icon-right-flick:before { content: '\e5db'; }
.icon-rotate-clockwise:before { content: '\e5dc'; }
.icon-rotate-counterclockwise:before { content: '\e5dd'; }
.icon-spread:before { content: '\e5de'; }
.icon-swipe-down:before { content: '\e5df'; }
.icon-swipe-horizontal:before { content: '\e5e0'; }
.icon-swipe-left:before { content: '\e5e1'; }
.icon-swipe-right:before { content: '\e5e2'; }
.icon-swipe-up:before { content: '\e5e3'; }
.icon-swipe-vertical:before { content: '\e5e4'; }
.icon-tap:before { content: '\e5e5'; }
.icon-tap-hold:before { content: '\e5e6'; }
.icon-vertical-flick:before { content: '\e5e7'; }
.icon-arrow-1-1:before { content: '\e5e8'; }
.icon-arrow-2-1:before { content: '\e5e9'; }
.icon-arrow-3:before { content: '\e5ea'; }
.icon-arrow-4:before { content: '\e5eb'; }
.icon-arrow-5:before { content: '\e5ec'; }
.icon-arrow-6:before { content: '\e5ed'; }
.icon-arrow-7:before { content: '\e5ee'; }
.icon-arrow-8:before { content: '\e5ef'; }
.icon-arrow-9:before { content: '\e5f0'; }
.icon-arrow-10:before { content: '\e5f1'; }
.icon-arrow-11:before { content: '\e5f2'; }
.icon-arrow-12:before { content: '\e5f3'; }
.icon-arrow-13:before { content: '\e5f4'; }
.icon-arrow-14:before { content: '\e5f5'; }
.icon-arrow-15:before { content: '\e5f6'; }
.icon-arrow-16:before { content: '\e5f7'; }
.icon-arrow-17:before { content: '\e5f8'; }
.icon-arrow-18:before { content: '\e5f9'; }
.icon-arrow-19:before { content: '\e5fa'; }
.icon-arrow-20:before { content: '\e5fb'; }
.icon-arrow-21:before { content: '\e5fc'; }
.icon-arrow-22:before { content: '\e5fd'; }
.icon-arrow-23:before { content: '\e5fe'; }
.icon-arrow-24:before { content: '\e5ff'; }
.icon-arrow-25:before { content: '\e600'; }
.icon-arrow-26:before { content: '\e601'; }
.icon-arrow-27:before { content: '\e602'; }
.icon-arrow-28:before { content: '\e603'; }
.icon-arrow-29:before { content: '\e604'; }
.icon-arrow-30:before { content: '\e605'; }
.icon-arrow-31:before { content: '\e606'; }
.icon-arrow-32:before { content: '\e607'; }
.icon-arrow-33:before { content: '\e608'; }
.icon-arrow-34:before { content: '\e609'; }
.icon-arrow-35:before { content: '\e60a'; }
.icon-arrow-36:before { content: '\e60b'; }
.icon-arrow-37:before { content: '\e60c'; }
.icon-arrow-38:before { content: '\e60d'; }
.icon-arrow-39:before { content: '\e60e'; }
.icon-arrow-40:before { content: '\e60f'; }
.icon-arrow-41:before { content: '\e610'; }
.icon-arrow-42:before { content: '\e611'; }
.icon-arrow-43:before { content: '\e612'; }
.icon-arrow-44:before { content: '\e613'; }
.icon-arrow-45:before { content: '\e614'; }
.icon-arrow-46:before { content: '\e615'; }
.icon-arrow-47:before { content: '\e616'; }
.icon-arrow-48:before { content: '\e617'; }
.icon-arrow-49:before { content: '\e618'; }
.icon-arrow-50:before { content: '\e619'; }
.icon-arrow-51:before { content: '\e61a'; }
.icon-arrow-52:before { content: '\e61b'; }
.icon-arrow-53:before { content: '\e61c'; }
.icon-arrow-54:before { content: '\e61d'; }
.icon-arrow-55:before { content: '\e61e'; }
.icon-arrow-56:before { content: '\e61f'; }
.icon-arrow-57:before { content: '\e620'; }
.icon-arrow-58:before { content: '\e621'; }
.icon-arrow-59:before { content: '\e622'; }
.icon-arrow-60:before { content: '\e623'; }
.icon-arrow-61:before { content: '\e624'; }
.icon-arrow-62:before { content: '\e625'; }
.icon-arrow-63:before { content: '\e626'; }
.icon-arrow-64:before { content: '\e627'; }
.icon-arrow-65:before { content: '\e628'; }
.icon-arrow-66:before { content: '\e629'; }
.icon-arrow-67:before { content: '\e62a'; }
.icon-arrow-68:before { content: '\e62b'; }
.icon-arrow-69:before { content: '\e62c'; }
.icon-arrow-70:before { content: '\e62d'; }
.icon-arrow-71:before { content: '\e62e'; }
.icon-arrow-72:before { content: '\e62f'; }
.icon-arrow-circle-1:before { content: '\e630'; }
.icon-arrow-circle-2:before { content: '\e631'; }
.icon-arrow-circle-3:before { content: '\e632'; }
.icon-arrow-circle-4:before { content: '\e633'; }
.icon-arrow-circle-5:before { content: '\e634'; }
.icon-arrow-circle-6:before { content: '\e635'; }
.icon-arrow-circle-7:before { content: '\e636'; }
.icon-arrow-circle-8:before { content: '\e637'; }
.icon-arrow-circle-9:before { content: '\e638'; }
.icon-arrow-circle-10:before { content: '\e639'; }
.icon-arrow-circle-11:before { content: '\e63a'; }
.icon-arrow-circle-12:before { content: '\e63b'; }
.icon-arrow-circle-13:before { content: '\e63c'; }
.icon-arrow-circle-14:before { content: '\e63d'; }
.icon-arrow-circle-15:before { content: '\e63e'; }
.icon-arrow-circle-16:before { content: '\e63f'; }
.icon-arrow-circle-17:before { content: '\e640'; }
.icon-arrow-circle-18:before { content: '\e641'; }
.icon-arrow-circle-19:before { content: '\e642'; }
.icon-arrow-circle-20:before { content: '\e643'; }
.icon-arrow-circle-21:before { content: '\e644'; }
.icon-arrow-circle-22:before { content: '\e645'; }
.icon-arrow-circle-23:before { content: '\e646'; }
.icon-arrow-circle-24:before { content: '\e647'; }
.icon-arrow-circle-25:before { content: '\e648'; }
.icon-arrow-circle-26:before { content: '\e649'; }
.icon-arrow-circle-27:before { content: '\e64a'; }
.icon-arrow-circle-28:before { content: '\e64b'; }
.icon-arrow-circle-29:before { content: '\e64c'; }
.icon-arrow-circle-30:before { content: '\e64d'; }
.icon-arrow-delete-1:before { content: '\e64e'; }
.icon-arrow-delete-2:before { content: '\e64f'; }
.icon-arrow-dot-1:before { content: '\e650'; }
.icon-arrow-dot-2:before { content: '\e651'; }
.icon-arrow-dot-3:before { content: '\e652'; }
.icon-arrow-dot-4:before { content: '\e653'; }
.icon-arrow-dot-5:before { content: '\e654'; }
.icon-arrow-dot-6:before { content: '\e655'; }
.icon-arrow-rectangle-1:before { content: '\e656'; }
.icon-arrow-rectangle-2:before { content: '\e657'; }
.icon-arrow-rectangle-3:before { content: '\e658'; }
.icon-arrow-rectangle-4:before { content: '\e659'; }
.icon-arrow-rectangle-5:before { content: '\e65a'; }
.icon-arrow-rectangle-6:before { content: '\e65b'; }
.icon-arrow-rectangle-7:before { content: '\e65c'; }
.icon-arrow-rectangle-8:before { content: '\e65d'; }
.icon-arrow-rectangle-9:before { content: '\e65e'; }
.icon-arrow-rectangle-10:before { content: '\e65f'; }
.icon-arrow-rectangle-11:before { content: '\e660'; }
.icon-arrow-rectangle-12:before { content: '\e661'; }
.icon-arrow-rectangle-13:before { content: '\e662'; }
.icon-arrow-rectangle-14:before { content: '\e663'; }
.icon-arrow-rectangle-15:before { content: '\e664'; }
.icon-arrow-rectangle-16:before { content: '\e665'; }
.icon-arrow-rectangle-17:before { content: '\e666'; }
.icon-arrow-rectangle-18:before { content: '\e667'; }
.icon-arrow-rectangle-19:before { content: '\e668'; }
.icon-arrow-rectangle-20:before { content: '\e669'; }
