@import "../../styles/global_colors.css";

.chartWrapper {
  margin: 18px 15px 15px;
}

.list {
  margin-bottom: 120px;
}

.listHeader {
  display: flex;
  margin: 32px 15px 0;
  color: hsl(0, 0%, 50%);
  box-shadow: 0 1px 0 hsl(0, 0%, 85%);
  background-image:
    linear-gradient(hsl(0, 0%, 100%), hsl(0, 0%, 85%)),
    linear-gradient(hsl(0, 0%, 100%), hsl(0, 0%, 85%));
  background-size: 1px 100%, 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
}

.icon {
  display: flex;
  color: var(--blue);
  justify-content: center;
  width: 44px;
  align-items: center;
}

.title {
  display: flex;
  flex: 6;
  padding: 12px 10px;
}

.count {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 12px 10px;
}
