@import '../../styles/global_colors.css';

.container {
  width: 20px;
  height: 20px;
  border: solid 2px #eee;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.unchecked_container {
  background-color: white;
}

.checked_container {
  border-color: var(--primary);
  background-color: var(--primary);
}

.icon {
  width: 18px;
  height: 18px;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding-left: 2px;
  opacity: 0;
  color: white;
}

.checked_icon {
  opacity: 1;
}

.disabled {
  cursor: not-allowed;
  opacity: 50%;
  background-color: #ccc;
  border-color: #ccc;
}
