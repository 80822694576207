.header {
  margin: 1px 15px 3px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #808080;
  height: 28px;
}

.header > div {
  height: 14px;
}

.tooltipIcon {
  height: 16px;
  font-size: 16px;
  margin-top: 1px;
  margin-left: 4px;
}
