.message {
  font-size: 12px;
  font-style: normal;
  background: hsla(0, 0%, 0%, 0.8);
  color: #e0e0e0;
  padding: 8px 12px 10px;
  border-radius: 4px;
  line-height: 1.6;
  pointer-events: none;
  min-width: 210px;
}

.rich-text {
  left: 0px;
  min-width: 0px;
  white-space: nowrap;
}

.product-switcher {
  margin-top: -15px;
}

.qrcode {
  min-width: 180px;
}
