.left {
  @include r('order', initial, initial, initial, 1, 1);
  @include r('flex', initial, initial, initial, 1 100%, 1 100%);
  @include r('width', 368px, 276px, 245px, 276px, 368px);
  position: relative;
  @include r('margin-top', 0, 0, 0, 32px, 32px);
}

@import 'sync';
@import 'preview';

.remove-zone {
  position: absolute;
  z-index: 9000;
  left: -30px;
  top: -15px;
  width: calc(100% + 45px);
  height: calc(100% + 15px);
  background-color: rgba(#ffffff, 0.75);
  display: flex;
  opacity: 1;
  transition: opacity 0.12s ease-in;

  .border {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px 15px 30px 30px;
    padding: 3px;
    border: 2px dashed;
    border-color: transparent;
    border-radius: 12px;
    transition: border-color 0.08s ease-in;
  }

  .jaqen {
    font-size: 48px;
    color: $red;
    margin-bottom: 24px;
  }

  label {
    color: $grey-50;
    font-style: italic;
  }

  &.hovering {
    .border {
      border-color: rgba($red, 0.4);
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &:not(.hidden) {
    ~ div {
      -webkit-filter: blur(8px);
    }
  }

  ~ div {
    transition: -webkit-filter 0s;
  }
}
