
.organization-wizard {
  flex-grow: 1;
  border-top: 1px solid $grey-90;
  margin-top: 20px;

  &-container {
    padding: 10px;
  }

  &-step-indicator {
    size: 16px;
    color: $grey-mid;
    line-height: 20px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  &-search-form {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-search-input {
    box-sizing: border-box;
    height: 44px;
    width: 400px;
    border: 1px solid $grey-lighter;
    border-radius: 4px;
    margin: 5px 15px 5px 5px;
    padding: 5px;
  }

  &-search-icon {
    cursor: pointer;
    position: absolute;
    left: 385px;
  }

  &-creation-button {
    height: 44px;
    border-radius: 6px;
    border: 0;
    color: white;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    padding: 0px 15px;

    &.primary {
      border: 0;
      color: white;
      background-color: $primary;
    }

    &.secondary {
      border: 1px solid $primary;
      color: $primary;
      background-color: white;

      &:hover {
      background-color: $primary;
      color: white;
      }
    }

    &:hover {
      background-color: #0d5c96;
      cursor: pointer;
    }
    &:disabled {
      background-color: $grey-lighter;
      cursor: default;
    }

    &.medium {
      width: 150px;
    }

    &.small {
      width: 100px;
    }

    &.large {
      width: 200px;
    }
  }

  &-chevron-icon {
    margin: 2px 0px 0px 5px;
  }

  &-no-org {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $grey-98;
    color: $grey-lighter;
    border-radius: 4px;
    width: 100%;
    padding: 27px;
    font-style: italic;
  }

  &-icon {
    justify-content: center;
  }

  &-text {
    color: #999999;
    padding: 10px;
  }

  &-results {
    width: 100%;
    background-color: $grey-98;
  }

  &-result-head > th {
    padding: 16px;
    color: $grey-mid;
    font-weight: 600;
    border-bottom: solid;
    border-color: $grey-lighter;
    border-spacing: 0;
    border-width: 1px;
    text-align: left;
  }

  &-result > td {
    padding: 16px;
    color: $grey-darker;
    font-weight: 100;

    &.organization-wizard-result-name {
      font-weight: 600;
    }
  }

  &-button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    *:not(:last-child) {
      margin-right: 15px;
    }
  }

  &-tooltip {
    display: inline-block;
    margin-left: 15px;
    align-content: center;

    &-icon {
      height: 16px;
      line-height: 16px;
      font-size: 16px;
    }
  }

  &-input-label {
    display: flex;
    align-items: center;
  }

  &-border-bottom {
    border-bottom: 1px solid $grey-90;
  }

}

