.sortHandle {
  width: 44px;
  min-width: 44px;
}

.name {
  width: 100%;
}

.no-focus:focus {
  outline: unset;
}

.action {
  width: 70px;
  min-width: 70px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.row {
  display: flex;
}
