@value colorOrange: #d67d00;
@value colorPrimary: #1173bc;
@value colorPurple: #9763db;

@value colorWhite: #ffffff;

@value colorGreyBase: #000000;
@value colorGreyDarker: #262626;
@value colorGreyLighter: #cccccc;

:root {
  --primary: colorPrimary;

  /* TODO determine whether --blue is staying the same as --primary and alter/clean up accordingly */
  --blue: #1173BC;
  --aqua: #4ed4b7;
  --green: #7ed321;
  --orange: colorOrange;
  --red: #d0021b;
  --purple: colorPurple;
  --pushpay-red: #cf150a;
  --pushpay-red-border: #950f07;
  --pushpay-red-highlight: #9e1008;

  --grey-90: #e6e6e6;
  --grey-75: #bfbfbf;
  --grey-50: #808080;

  --grey-85: #d9d9d9;
  --grey-88: #e0e0e0;
  --grey-94: #f0f0f0;
  --grey-98: #fafafa;

  --item_row_border: 1px solid var(--grey-90);
  --item_dashed_border: 1px dashed var(--grey-90);

  --grey-base:             colorGreyBase;
  --grey-darker:           colorGreyDarker;
  --grey-dark:             #4d4d4d;
  --grey-dark-300:         #4D4C4E;
  --grey-dark-400:         #242528;
  --grey-mid:              #757575;
  --grey:                  #999;
  --grey-light:            #b2b2b2;
  --grey-lighter:          colorGreyLighter;
  --grey-lightest:         #e5e5e5;

  --border-color: var(--grey-lighter);
  --border-link-color: #b3b3b3;

  --background-white-color: colorWhite;
  --background-grey-light-color: #f7f7f8;

  --box-shadow-color: rgba(0, 0, 0, 0.3);
}
