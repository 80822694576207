@import "../../styles/global_colors.css";
@import "../../styles/global_themeing.css";

.container {
  margin: -10px 0 20px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.container [class~='switch-button'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 16px;
  width: 16px;
}

.icon-moon svg {
  fill: var(--grey-darker);
}

.icon-sun svg {
  fill: var(--orange);
}

.label {
  margin-bottom: 10px;
  font-size: var(--font-size-small);
  color: var(--grey-darker);
}
