.popover-container {
  position: relative;
  display: flex;
}

.popover {
  position: absolute;
  z-index: 999;
  transition: opacity 0.3s ease-out 0.1s;
}
.popover-arrow:after {
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-width: 10px;
}

.right {
  left: 100%;
  margin-top: 50%;
  margin-left: 10px;
  transform: translateY(-50%);
}

.popover-arrow.right:after {
  right: 100%;
  top: 50%;
  border-right-color: hsla(0, 0%, 0%, 0.8);
  margin-top: -10px;
}

.left {
  margin-top: 50%;
  margin-left: -10px;
  transform: translateX(-100%)   translateY(-50%);
}

.popover-arrow.left:after {
  left: 100%;
  top: 50%;
  border-left-color: hsla(0, 0%, 0%, 0.8);
  margin-top: -10px;
}

.top {
  margin-left: 50%;
  margin-top: -10px;
  transform: translateX(-50%)   translateY(-100%);
}

.popover-arrow.top:after {
  top: 100%;
	left: 50%;
  border-top-color: hsla(0, 0%, 0%, 0.8);
  margin-left: -10px;
}

.bottom {
  top: 100%;
  margin-left: 50%;
  margin-top: 10px;
  transform: translateX(-50%);
}

.popover-arrow.bottom:after {
  bottom: 100%;
	left: 50%;
  border-bottom-color: hsla(0, 0%, 0%, 0.8);
	margin-left: -10px;
}
