@import "../../../styles/global_colors.css";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
}

.icon {
  font-size: 24px;
  height: 24px;
  color: var(--blue);
}
