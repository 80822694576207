.title {
  display: flex;
  justify-content: space-between;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;

  font-size: 16px;
  color: hsl(0, 0%, 75%);
  text-align: right;

  box-shadow: 0 1px 0 hsl(0, 0%, 85%);

  background-image:
    linear-gradient(hsla(0, 0%, 100%, 0), hsl(0, 0%, 85%)),
    linear-gradient(hsla(0, 0%, 100%, 0), hsl(0, 0%, 85%));
  background-size: 1px 100%, 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
}

.stat {
  color: hsl(0, 0%, 50%);
  text-align: right;
  font-family: 'Helvetica', 'Arial';
  font-weight: 200;
  padding: 24px 15px;
  font-size: 64px;
  line-height: 64px;
}

.statHelperText {
  color: hsl(0, 0%, 75%);
  text-align: center;
  padding: 10px;
  font-style: italic;
  font-size: 13px;
}
