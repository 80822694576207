@import '../variables';

.bridge-cancel-button {
  width: 80px;
  height: 30px;
  margin-right: 15px;
  background-color: white;
  line-height: 27px;
  text-align: center;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 6px;
  margin-top: 7px;
  &:hover {
    background-color: $primary;
    color: white;
    cursor: pointer;
  }
}
