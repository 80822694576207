.compose-button {
  height: 45px;
  line-height: 45px;
  margin-bottom: 18px;
  text-align: center;
  @include all-caps;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: $primary;

  border-radius: 4px;
  border: 1px solid $grey-85;

  &:not(.disabled):not(.active):not(.cancel):hover {
    border-color: $primary;
  }

  &.active {
    color: $orange;
  }

  &.cancel {
    color: $red;
  }

  &.is-committed {
    color: $green;
  }

  &.disabled {
    color: $grey-75;
    cursor: wait;
  }
}

.auth-error-button {
  padding: 0 10px 0 10px;
  background-color: white;
}

.creation-tab {
  border: 1px solid $grey-90;
  margin: 0 15px;
  position: relative;

  .covering-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: hsla(0, 0%, 43%, 0.5);
    z-index: 1;
    display: flex;
    @include flex-center;
  }

  @include spinner(
    relative, // $position
    0px, // $topValue
    'left', // $side
    0px, // $sideValue
    96px, // $diameter
    4px solid rgba(#000000, 0.25), // $borderOn
    4px solid rgba(#ffffff, 0.5) // $borderOff
  );

  .field-group {
    .field {
      margin-bottom: 10px;
      background: white;
    }
    .field:first-child {
      border-top: $item_row_border;
    }
    .field:last-child {
      margin-bottom: 20px;
    }
  }

  .field-simple {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    p {
      padding: 10px;
    }
  }
  .field-center {
    align-items: center;
  }

  .field {
    @extend .field-simple;

    input[type='text'], textarea, input.rw-input {
      flex: 1;
      border: 0;
      outline: 0;
      padding: 15px 10px;
      background-size: 45px 46px;
      color: $grey-50;

      $stripe: rgba($grey-50, .06);
      background-image:
        linear-gradient(-45deg, $stripe 25%, $transparent 25%, $transparent 50%, $stripe 50%, $stripe 75%, $transparent 75%, $transparent);

      &:focus {
        $stripe: rgba($blue, .05);
        $primaryStripe: rgba($primary, .05);

        box-shadow: inset 0 0 3px rgba($primary, .2);

        background-image:
          linear-gradient(-45deg, $primaryStripe 25%, $transparent 25%, $transparent 50%, $primaryStripe 50%, $primaryStripe 75%, $transparent 75%, $transparent);

        color: black;
      }

    }

    &-striped {
      input[type='text'], textarea {
        $stripe: rgba($grey-50, .06);
        background-image:
          linear-gradient(-45deg, $stripe 25%, $transparent 25%, $transparent 50%, $stripe 50%, $stripe 75%, $transparent 75%, $transparent);
      }
    }

    &-draggable {
      &:hover {
        cursor: pointer;
      }
    }

    .answer-label {
      font-size: 16px;
      color: rgba($grey-50, .8);
      padding-top: 0px;
      padding-bottom: 10px;
      &:last-of-type {
        padding-bottom: 25px;
      }
    }

    .composer-header {
      padding: 10px;
    }

    .content-toggle-header {
      float: right;
    }

    .content-toggle {
      display: inline-block;
      margin-left: 16px;
    }

    .content-tooltip {
      display: inline-block;
    }

    .tooltip {
      width: 20px;
      display: inline-block;
      margin-left: 6px;
    }

    .tooltip-icon {
      position: relative;
      top: 4px;
      font-size: 20px;
      color: $grey-75;
    }

    .message-image-upload {
      position: relative;
      width: 25%;
      cursor: pointer;
      padding-bottom: 10px;
      padding-top: 20px;
      bottom: 12px;
      left: 12px;

      &:before {
        content: 'Upload Image';
        padding: 10px 20px;
        color: white;
        background-color: #0da8da;
      }
    }

    .message-image-upload-input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .uploading-message-image {
      padding: 12px;
      @include spinner(
        relative, // $position
        0px,      // $topValue
        'left',   // $side
        0px,      // $sideValue
        24px,     // $diameter
        4px solid rgba(#000000, 0.25),  // $borderOn
        4px solid rgba(#ffffff, 0.5)    // $borderOff
      );

      .spinner {
        display: inline-block;
        margin-right: 12px;
      }
    }

    .uploaded-message {
      padding: 10px;
      margin-bottom: 8px;

      .uploaded-message-icon {
        display: inline-block;
        height: 30px;
        margin-right: 8px;
        margin-top: 4px;
      }
    }
  }

  .remove-message-image {
    display: inline-block;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
    color: $primary;
    font-weight: 600;
  }

  .field-title {
    font-size: 16px;
    color: $grey-50;
    font-weight: 700;
    &.active {
      color: $primary;
    }

    &.may-drag {
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }

  .attribute {
    display: flex;
  }

  .rw-multiselect-taglist {
    .rw-btn {
      line-height: 37px;
    }
    li:first-child {
      margin-left: 10px;
    }
  }
  .rw-multiselect-wrapper {
    display: flex;
    input[type='text'], input.rw-input {
      min-height: 44px;
    }
  }

  .rw-dropdownlist {
    .rw-input {
      flex: 1;
      border: 0;
      outline: 0;
      padding: 15px 10px;
      color: $grey-50;
    }

    .rw-select.rw-btn {
      border-left: none;

      &:hover {
        background-color: hsl(0, 0%, 95%);
      }
    }
  }

  .drop-area {
    flex: 1;
    display: flex;
    @include flex-center;
    min-height: 90px;
    margin: 10px 10px 20px;
    border: 1px dashed $grey-90;
    border-radius: 6px;
    font-style: italic;
    color: $grey-75;
  }

  &.when-tab {
    display: flex;

    .message-scheduler {
      flex: 1;
      text-align: center;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        background-color: hsl(0, 0%, 95%);
      }

      .button-title {
        color: $grey-75;
        @include all-caps;
        margin: 15px 0 10px;
      }

      .description {
        color: $grey-50;
        line-height: normal;
        font-size: 14px;

        b { font-weight: 600; }
      }

      ul {
        border: $item_row_border;
        border-radius: 6px;
        list-style: none;
        margin: 15px;

        li.option {
          display: flex;
          @include flex-center;
          @include all-caps;
          height: 60px;
          font-weight: 600;
          font-size: 18px;

          color: $primary;

          cursor: pointer;

          border: 1px solid $grey-75;
          margin: -1px;

          &.active {
            color: $green;
            background-color: rgba($green, 0.2);
            cursor: default;
          }

          &:first-of-type {
            border-radius: 6px 6px 0 0;
            border-top: 1px solid $grey-75;
            border-left: 1px solid $grey-75;
            border-right: 1px solid $grey-75;
          }

          &:last-of-type {
            border-radius: 0 0 6px 6px;
          }
        }

        li.disabled {
          color: $grey-75;
          cursor: not-allowed;
        }

        li.droppy-down {
          margin: 1px 0 0 0;
          display: flex;
          height: 34px;

          .react-kronos {
            flex: 1;
          }

          input {
            border: 0;
            background-color: $transparent;

            &.outside-range {
              color: $red;
            }
          }
        }
      }

    }
  }
}

.message-helper-text {
  font-style: italic;
  color: $grey-75;
  font-size: 14px;
  margin-left: 10px;
}

.message-restricted-filter-text {
  color: $blue;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-left: 10px;

  .message-restricted-item-highlight {
    color: $orange;
  }
}

.message-audience {
  padding-left: 10px;
  font-size: 14px;
  color: $blue;
}

.message-next {
  display: flex;
  @include flex-center;
  border: 1px solid $grey-75;
  margin: 0 15px;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  top: -1px;

  .next-title {
    font-size: 18px;
    @include all-caps;
    font-weight: 600;
    color: $primary;
  }
}

.message-finalize {
  @extend .message-next;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;
    padding: 0;
  }

  &.not-yet {
    cursor: default;
  }

  .tab {
    flex: 1;
    text-align: center;
    font-size: 18px;
    @include all-caps;
    font-weight: 600;
    padding: 10px 0;
    border-left: 1px solid $grey-75;
    border-right: 1px solid $grey-75;

    &.green {
      color: $green;
    }

    &.red {
      color: $red;
    }

    &.counting-down {
      display: flex;
      flex-direction: column;

      .counting-down-title {
        font-size: 14px;
        color: $grey-50;
        font-weight: normal;
      }

      .counting-down-time {
        font-size: 28px;
        color: $primary;
      }
    }

    &.no-click {
      cursor: default;
    }

    &:first-of-type, &:last-of-type {
      border-right: 0;
      border-left: 0;
    }
  }

  .finish-title {
    position: relative;
    font-size: 18px;
    @include all-caps;
    font-weight: 600;
    color: $green;
    margin-bottom: 5px;

    &.not-yet {
      color: $grey-50;
    }
  }
}

.superfluous-countdown-helper-text {
  @extend .message-helper-text;
  margin: 0;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  position: absolute;
  bottom: -36px;
  left: 0;
  right: 0px;
}

.rich-content-disclaimer {
  padding: 10px 20px;
  font-size: 14px;
}

.push-cert-banner {
  border-radius: 96px;
  display: flex;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 48px;
}

.push-cert-banner-message {
  flex: 24;
}

.push-cert-icon {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  right: 24px;
}

.push-cert-error {
  border: $red 1px solid;
}

.push-cert-icon-error {
  color: $red;
}

.push-cert-warning {
  border: $orange 1px solid;
}

.push-cert-icon-warning {
  color: $orange;
}

.countdown-disclaimer {
  margin-top: 36px;
}

.message-breadcrumb {
  height: 37px;
  display: flex;
  list-style: none;

  li {
    text-align: center;
    font-size: 16px;
    @include all-caps;
    flex: 1;
    color: $grey-75;
    padding-top: 11px;
    cursor: pointer;

    &.active {
      color: $primary;
      font-weight: 700;
    }

    &.disabled {
      pointer-events: none;
    }
  }
}

.message-progress-indicator {
  display: flex;
  flex: 1;
  height: 8px;
  border-radius: 4px;
  border: 1px solid $grey-85;
  margin: 0 15px 16px;

  .filled {
    background-color: $primary;
    border-radius: 3px 0 0 3px;
  }

  div:last-child {
    border-radius: 3px;
  }
}

.commition {
  flex: 1;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.0625em;
  cursor: pointer;

  & + .commition {
    border-left: $item_dashed_border;
  }

  &.no-hover {
    cursor: default;
  }

  &.blue {
    color: $primary;
  }
}

.poll {
  overflow: hidden;
  .answer {
    &-field {
      display: flex;
      margin-bottom: 0.5rem;
      .answer-input:hover + .answer-action,
      .answer-input + .answer-action:hover  {
        opacity: 1;
        width: 70px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-input {
      flex: 1;
    }
    &-action {
      width: 0;
      opacity: 0;
      padding: 0;
      color: $grey-75;
      cursor: pointer;
      transition: all .25s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0 0 3px rgba($primary, .2);
      &:hover {
        color: $red;
        box-shadow: inset 0 0 3px rgba($red, .2);
      }
    }
    &-add {
      width: 100%;
      font-size: 30pt;
      margin-bottom: 10px;
      cursor: pointer;
      border: 0;
      color: $grey-75;
      background: white;
      &:not(.disabled):not(.active):not(.cancel):hover {
        color: $primary;
      }
      &:active, &:focus {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
      }
    }
  }
}

.deep-link-form {
  background-color: $grey-98;
  border: 1px solid #e2e2e2;
  margin: 11px 13px;
  padding-bottom: 21px;

  .deep-link-form-select {
    display: flex;
    flex-direction: row;
    padding-top: 28px;

    .deep-link-form-label {
      flex: 1;
      display: inline-block;
      color: $grey-50;
      font-size: 14px;
      line-height: 18px;
      margin-left: 17px;
    }

    .deep-link-form-selection {
      flex: 15;
      display: inline-block;

      .deep-link-form-button {
        background-color: #0da8da;
        color: white;
        padding: 10px 20px;
        display: inline-block;
      }

      .deep-link-form-selected {
        .deep-link-form-parent-name {
          color: #7a7a7a;
        }

        .deep-link-form-child-name {
          color: black;
        }

        .deep-link-form-remove-link {
          height: 20px;
          width: 89px;
          color: #D0021B;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          margin-left: 6px;
          text-decoration: underline;
        }

        .deep-link-path {
          display: inline-block;
        }

        .deep-link-path-spinner {
          display: inline-block;
          @include spinner(
            relative, // $position
            0px, // $topValue
            'left', // $side
            0px, // $sideValue
            22px, // $diameter
            4px solid rgba(#000000, 0.25), // $borderOn
            4px solid rgba(#ffffff, 0.5) // $borderOff
          );

          .spinner {
            display: inline-block;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.deep-link-header {
  flex: 1;
  max-height: 80px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 32px;
  background: linear-gradient(180deg, #fcfcfc 0%, $background-grey-light-color 100%);
  border-radius: 14px 14px 0 0;

  .deep-link-header-title {
    flex: 1;
    color: $blue;
    height: 25px;
    width: 191px;
    font-size: 20px;
    line-height: 25px;
    padding-top: 8px;
  }

  .deep-link-header-selection {
    display: flex;
    flex: 1;
    flex-flow: row-reverse;

    .deep-link-selection {
      .deep-link-no-selection {
        float: right;
        padding: 10px 23px;
        width: 160px;
        border: 1px solid #808080;
        color: #808080;
        font-size: 16px;
        font-style: italic;
        line-height: 20px;
        text-align: center;
      }

      .deep-link-selected-name {
        font-weight: 600;
      }
    }
  }
}

.deep-link-tree {
  height: 500px;
  display: flex;
  flex-direction: column;

  .deep-link-tree-nodes {
    flex: 2;
    max-height: 400px;
    overflow: auto;
  }

  .deep-link-node {
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    color: #808080;
    font-size: 16px;
    line-height: 22px;

    &.deep-link-node-selected {
      background-color: rgba(17, 115, 188, 0.12);
    }

    .deep-link-empty-node {
      padding: 12px 45%;
      font-style: italic;
    }

    .deep-link-node-body {
      display: inline-flex;
      padding-left: 4px;
      position: relative;
      bottom: 5px;

      .deep-link-node-name {
        flex: 4;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .deep-link-node-campus {
        flex: 1;
        width: 20%;
        color: #4ed4b7;
        font-size: 14px;
        font-style: italic;
        display: inline-flex;
      }
    }
  }

  .tree-node {
    display: -webkit-inline-box;

    &.tree-node-item {
      margin-left: 24px;
    }

    .node-selection {
      height: 47px;
      width: 7px;

      &.selected-node {
        background-color: $blue;
      }
    }

    .deep-link-node-level {
      display: inline-block;
    }

    .node-icon {
      display: inline-block;
      padding-left: 14px;

      &.node-icon-container {
        color: $blue;
      }

      &.node-icon-item {
        color: #808080;
      }
    }
  }
}

.deep-link-campus-conflict {
  line-height: 20px;
  font-size: 16px;

  .deep-link-campus-conflict-detail {
    padding: 41px 32px;

    .deep-link-target-campus {
      font-weight: normal;
      font-style: italic;
      color: #4ed4b7;
    }

    .deep-link-content-campus {
      font-style: italic;
      color: $blue;
    }

    .deep-link-campus-notification {
      font-weight: bold;
    }
  }

  .deep-link-campus-confirmation-buttons {
    padding: 0 20px;
    position: relative;
    top: 32px;

    .deep-link-campus-confirmation-button {
      display: inline-block;
      margin-left: 12px;
    }
  }
}

.deep-link-select-button {
  background-color: #0da8da;
  color: white;
  padding: 10px 20px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deep-link-form-content-link {
  text-decoration: underline;
  font-size: 15px;
  line-height: 19px;

  .deep-link-form-link-icon {
    display: inline-block;
    position: relative;
    left: -8px;
  }
}
