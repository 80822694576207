.dropArea {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  min-height: 90px;
  border: 1px dashed hsl(0, 0%, 90%);
  border-radius: 6px;
}

.dropArea:last-child {
  margin-right: 10px;
}

.hasAction {
  border: 0;
}

.dragAccepts {
  background-size: 45px 46px;
  background-image: linear-gradient(
    -45deg,
    hsl(0, 0%, 97%) 25%,
    hsla(0, 0%, 0%, 0) 25%,
    hsla(0, 0%, 0%, 0) 50%,
    hsl(0, 0%, 97%) 50%,
    hsl(0, 0%, 97%) 75%,
    hsla(0, 0%, 0%, 0) 75%,
    hsla(0, 0%, 0%, 0)
  );
}

.dragUnder {
  box-shadow: inset 0 0 3px rgba(0, 155, 223, 0.2);
  background-size: 45px 46px;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 155, 223, 0.05) 25%,
    hsla(0, 0%, 0%, 0) 25%,
    hsla(0, 0%, 0%, 0) 50%,
    rgba(0, 155, 223, 0.05) 50%,
    rgba(0, 155, 223, 0.05) 75%,
    hsla(0, 0%, 0%, 0) 75%,
    hsla(0, 0%, 0%, 0)
  );
}

.empty {
  font-style: italic;
  color: hsl(0, 0%, 75%);
  align-self: center;
}
