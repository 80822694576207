@import "../../styles/global_colors.css";

.barWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.barInner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 15px 25% 0 25%;
}

.barFilled {
  position: relative;
  display: flex;
  background-color: hsl(0, 0%, 85%);
  box-shadow: inset 0 0 25px hsla(0, 0%, 100%, 0.5);
}

.barFilledNoShadow {
  position: relative;
  display: flex;
  background-color: hsl(0, 0%, 85%);
}

.barLabel {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 5px;
  color: hsl(0, 0%, 25%);
}

.barLeftLabelWrapper {
  z-index: 2;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.barLeftLabelFlexBox {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-right: 56px;
}

.barLeftLabel {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: hsl(0, 0%, 25%);
  margin: 10px 10px 10px 10px;
}

.graph {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0 hsl(0, 0%, 75%);
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  color: var(--blue);
}

.horGraph {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.graphWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
}

.horBarWrapper {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  margin: 0 0 2px 0;
  min-height: 66px;
}

.horBarInner {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  height: 100%;
}

.subRows {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-width: 44px;
  max-width: 56px;
  margin: 0 2px 0 2px;
  position: absolute;
  right: 0;
}

.subRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  color: var(--blue);
}
