.iconAction:focus{
  outline: none;
}

.svg, .svg svg{
  height: 100%;
}

.iconAction:hover {
  cursor: pointer;
}
