// Variables

$primary: #1173BC;

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
$blue: #1173BC;
$aqua: #4ed4b7;
$green: #7ed321;
$orange: #d67d00;
$red: #d0021b;
$purple: #9763db;
$brown: #7A5200;
$pushpay-red: #cf150a;
$pushpay-red-border: #950f07;
$pushpay-red-highlight: #9e1008;

// Old grey color convention
$grey-90: hsl(0, 0%, 90%);
$grey-75: hsl(0, 0%, 75%);
$grey-50: hsl(0, 0%, 50%);

$grey-85: hsl(0, 0%, 85%);
$grey-88: hsl(0, 0%, 88%);
$grey-98: hsl(0, 0%, 98%);

$transparent: hsla(0, 0%, 100%, 0);

$item_row_border: 1px solid $grey-90;
$item_dashed_border: 1px dashed $grey-90;

$breakpoint-x-large: 1500px;
$breakpoint-large: 1200px;
$breakpoint-medium: 800px;

$media-ratio-desktop: 1;
$media-ratio-laptop: 0.75;
$media-ratio-laptop-short: 0.666;
$media-ratio-tablet: 0.75;
$media-ratio-phone-landscape: 0.5;
$media-ratio-phone: 1;

$os-preview-width: 320;
$os-preview-border-width: 1;


//Font colors
$grey-base:             #000;
$grey-darker:           #262626;
$grey-dark:             #4d4d4d;
$grey-mid:				      #757575;
$grey:                  #999;
$grey-light:            #b2b2b2;
$grey-lighter:          #ccc;
$grey-lightest:         #e5e5e5;


// TODO Discuss and replace breakpoints to inductry standard and replace variables
$breakpoint-login-medium: 1024px;

// Components
$field-upload-min-width: 300px;

// border colors
$border-color: $grey-lighter;
$border-link-color: #b3b3b3;

// background colors
$background-white-color: white;
$background-grey-light-color: #f7f7f8;

// TODO Discuss and standized spacing
$spacing-vertical: 15px;
$spacing-vertical-medium: 20px;
$spacing-vertical-large: 40px;

// TODO Discuss and standized font-family
$font-family: Helvetica, Arial, sans-serif;

// TODO Discuss and standized font-size
$font-size-small: 14px;
$font-size-medium: 17px;

// font-colors
$font-placeholder-color: $grey-light;
$font-header-color: $grey-darker;
$font-form-header-color: $grey-dark;
$font-default-color: $grey-dark;

// box shadow color
$box-shadow-color: rgba(0, 0, 0, 0.3);


// Mixins

@mixin mp {
  margin: 0;
  padding: 0;
}

@mixin r($prop, $desktop, $laptop, $laptop-short, $tablet, $phone) {
  #{$prop}: $desktop;
  @media (max-height: 1000px) { #{$prop}: $laptop; }
  @media (max-width: $breakpoint-x-large) { #{$prop}: $laptop; }
  @media (max-width: $breakpoint-x-large) and (max-height: 800px) { #{$prop}: $laptop-short; }
  @media (max-width: $breakpoint-large) { #{$prop}: $tablet; }
  @media (max-width: $breakpoint-medium) { #{$prop}: $phone; }
}

@mixin p($prop, $val) {
  @include r($prop, $val * $media-ratio-desktop, $val * $media-ratio-laptop, $val * $media-ratio-laptop-short, $val * $media-ratio-tablet, $val * $media-ratio-phone );
  @media (max-width: $breakpoint-x-large) and (max-height: 750px) { #{$prop}: $val * $media-ratio-phone-landscape; }
}

@mixin color-svg($color) {
  path, circle, rect, polygon {
    fill: $color;
  }
}

@mixin flex-center {
  align-items: center;
  justify-content: center;
}

@mixin t {
  transition: all 0.33s ease-out;
}

@mixin ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin all-caps {
  text-transform: uppercase;
  letter-spacing: 0.0625em;
}

@mixin spinner($position, $topValue, $side, $sideValue, $diameter, $borderOn, $borderOff) {
  .spinner {
    position: $position;
    top: #{$topValue};
    #{$side}: #{$sideValue};
    width: $diameter;
    height: $diameter;
    border-top: $borderOn;
    border-right: $borderOff;
    border-bottom: $borderOff;
    border-left: $borderOff;
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(.66,.26,.26,.66);
    animation-fill-mode: both;
  }
  .spinner,
  .spinner:after {
    border-radius: 50%;
  }
}

@mixin scrollbars {
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 0%, 0.25);
    border-radius: 5px;
    background-clip: padding-box;
    border: 3px solid rgba(0, 0, 0, 0);
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 10px;
    }
  }
}

@mixin disabled {
  $stripe: hsl(0, 0%, 97.5%);
  background-size: 45px 46px;
  background-image: linear-gradient(-45deg, $stripe 25%, $transparent 25%, $transparent 50%, $stripe 50%, $stripe 75%, $transparent 75%, $transparent);
}

@keyframes fade-first {
  0%, 60% { opacity: 1; }
  70%, 90% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-second {
  0%, 60% { opacity: 0; }
  70%, 90% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Export variables for the use of JavaScript
:export {
  mediaRatioDesktop: $media-ratio-desktop;
  mediaRatioLaptop: $media-ratio-laptop;
  mediaRatioLaptopShort: $media-ratio-laptop-short;
  mediaRatioTablet: $media-ratio-tablet;
  mediaRatioPhoneLandscape: $media-ratio-phone-landscape;
  mediaRatioPhone: $media-ratio-phone;
  osPreviewWidth: $os-preview-width;
  osPreviewBorderWidth: $os-preview-border-width;
}
