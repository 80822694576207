@mixin score-item {
  display: flex;
  position: relative;
  margin: 1px 15px 0;
  line-height: 44px;
  font-size: 16px;
  color: gray;
  background-color: white;
}

.score_in_app_usage {
  display: flex;
  padding-left: 15px;
  margin-top: 25px;

  .heatmap_dot_list_header {
    @include score-item;
    color: $primary;
    font-weight: 600;

    .title {
      flex: 5
    }
    .views {
      flex: 1;
      text-align: right;
    }
  }
  .score_item {
    @include score-item;
    box-shadow: 0 -1px 0 $grey-90, 0 1px 0 $grey-90;

    .root_icon {
      width: 44px;
      color: $primary;
    }

    .title {
      flex: 10;
    }
    .views {
      flex: 1;
      text-align: right;
    }
    .circle {
      width: 30px;
      text-align: center;

      span {
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
        vertical-align: middle;
        margin-top: -5px;
        margin: auto;
      }
    }

    &.tripple_dot {
      flex-direction: column;
      padding: 2px 0;

      p {
        line-height: 3px;
        padding-bottom: 5px;
        font-weight: bold;
        text-align: center;
        color: $grey-90
      }
    }
  }
}
