.single {
  flex: 1;
  user-select: text;
}

.appleIdRow {
  flex-direction: row;
}

.headingRow {
  margin: 15px;
  flex: 1;
  flex-direction: row;
  display: flex;
}

.message {
  color: gray;
  padding: 20px 15px;
  font-size: 18px;
}
