div.info {
  flex-direction: column;
  line-height: 1.3;
  justify-content: center;
  padding: 0px 5px 0px 10px;
  min-width: 220px;
}

span.exceeding-max-error {
  font-size: 11px;
  color: red;
}

div.max-items-selector {
  flex-basis: 20%;
}
