/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);
@import url(https://fonts.googleapis.com/css?family=Wire+One&text=%3A);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);
@import url(https://fonts.googleapis.com/css?family=Wire+One&text=%3A);
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

@supports not (-apple-trailing-word: inherit) {
  div[data-slate-editor] {
    -webkit-user-modify: read-write !important; } }

html, body {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro'; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

article, section {
  transform: translateZ(0); }

.isvg {
  pointer-events: none; }
  .isvg.loaded {
    display: flex; }

.page {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  isolation: isolate;
  padding: 30px 30px 0 30px; }
  @media (max-height: 1000px) {
    .page {
      padding: 30px 30px 0 30px; } }
  @media (max-width: 1500px) {
    .page {
      padding: 30px 30px 0 30px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .page {
      padding: 30px 30px 0 30px; } }
  @media (max-width: 1200px) {
    .page {
      padding: 30px 30px 0 15px; } }
  @media (max-width: 800px) {
    .page {
      padding: 30px 15px 0 15px; } }

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px; }
  .icon svg {
    height: 24px;
    width: 24px; }

.jaqen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  font-size: 24px; }
  .jaqen:not(.interactive) {
    pointer-events: none; }
  .jaqen.interactive {
    cursor: pointer; }

.text-input {
  position: relative;
  overflow: hidden;
  line-height: normal;
  padding: 5px 10px;
  background-color: white; }
  .text-input.disabled {
    background-size: 45px 46px;
    background-image: linear-gradient(-45deg, #f9f9f9 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, #f9f9f9 50%, #f9f9f9 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }
    .text-input.disabled:focus {
      box-shadow: none;
      background-image: linear-gradient(-45deg, #f9f9f9 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, #f9f9f9 50%, #f9f9f9 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

.text-disabled {
  color: #bfbfbf !important; }

.disableField {
  pointer-events: none; }

a {
  color: rgba(17, 115, 188, 0.6);
  text-decoration: none; }
  a:hover, a:focus {
    color: #1173BC;
    -webkit-filter: drop-shadow(0 0 1px rgba(17, 115, 188, 0.3)); }

div {
  user-select: none; }

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  resize: none; }

h1 {
  font-size: 32px;
  font-weight: 300; }

::-webkit-input-placeholder {
  color: rgba(128, 128, 128, 0.5); }

::-moz-placeholder {
  color: rgba(128, 128, 128, 0.5); }

:-moz-placeholder {
  color: rgba(128, 128, 128, 0.5); }

:-ms-input-placeholder {
  color: rgba(128, 128, 128, 0.5); }

.special-fullscreen, .blurred-overlay, .modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9000; }

.blurred-overlay {
  z-index: 7000;
  background-color: rgba(0, 0, 0, 0.42);
  opacity: 1;
  transition: opacity 0.12s ease-in;
  pointer-events: none; }
  .blurred-overlay.true ~ div {
    -webkit-filter: blur(8px); }
  .blurred-overlay ~ div {
    transition: -webkit-filter 0s; }
  .blurred-overlay.false {
    opacity: 0; }
  .blurred-overlay.none {
    display: none; }

.placeholder-text-input {
  display: flex;
  flex: 1;
  position: relative; }
  .placeholder-text-input input {
    display: flex;
    flex: 1; }
    .placeholder-text-input input[type='checkbox'] {
      height: 100%;
      margin-left: 10px; }
    .placeholder-text-input input:hover + .placeholder.active, .placeholder-text-input input:focus + .placeholder.active {
      opacity: 1; }
  .placeholder-text-input .placeholder {
    opacity: 0;
    position: absolute;
    right: 10px;
    color: rgba(128, 128, 128, 0.5);
    pointer-events: none; }
    .placeholder-text-input .placeholder.force {
      opacity: 1; }
  .placeholder-text-input + .placeholder-text-input {
    border-left: 1px dashed #e6e6e6; }

.alert {
  position: absolute;
  z-index: 8000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 44px;
  visibility: hidden;
  opacity: 0;
  color: gray;
  cursor: pointer;
  transition: opacity 0.3s ease; }
  .alert span {
    font-weight: 600;
    letter-spacing: 0.0625em;
    text-transform: uppercase; }
  .alert.success {
    visibility: visible;
    opacity: 1;
    color: #7ed321;
    border-bottom: 1px solid #7ed321; }
  .alert.error {
    visibility: visible;
    opacity: 1;
    color: #d0021b;
    border-bottom: 1px solid #d0021b; }
  .alert.warning {
    visibility: visible;
    opacity: 1;
    color: #d67d00;
    border-bottom: 1px solid #d67d00; }
  .alert.info {
    visibility: visible;
    opacity: 1;
    color: #bfbfbf;
    border-bottom: 1px solid #bfbfbf; }
  .alert.reload {
    visibility: visible;
    opacity: 1;
    color: #1173BC;
    border-bottom: 1px solid #1173BC;
    border-bottom: 1px solid #1173BC;
    color: #1173BC; }
  .alert.reload {
    height: 90px; }
  .alert.fixed {
    position: fixed; }

.react-kronos .days {
  line-height: normal; }

.image-container {
  display: flex;
  flex: 1; }
  .image-container + .image-container {
    border-left: 1px dashed #e6e6e6; }
  .image-container .image-current {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 200px; }
  .image-container .image-processing {
    display: flex;
    flex: 1;
    position: relative;
    background-color: rgba(230, 230, 230, 0.5); }
  .image-container .image-detail {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    background-color: rgba(230, 230, 230, 0.2); }
    .image-container .image-detail .image-detail-label {
      padding: 0px 5px;
      font-style: italic;
      color: #d9d9d9; }
  .image-container .image-preview {
    display: flex;
    flex: 1;
    flex-direction: column; }
    .image-container .image-preview .image-preview-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      flex-direction: column;
      background-color: rgba(230, 230, 230, 0.5); }
      .image-container .image-preview .image-preview-wrapper .image-preview-image-wrapper {
        max-height: 115px;
        position: relative; }
        .image-container .image-preview .image-preview-wrapper .image-preview-image-wrapper img {
          max-height: 115px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
    .image-container .image-preview:hover .delete-button {
      display: flex;
      cursor: pointer; }
      .image-container .image-preview:hover .delete-button:hover .red-button {
        background: #d0021b; }
    .image-container .image-preview .delete-button {
      display: none;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      position: absolute;
      right: -22px;
      top: -22px; }
      .image-container .image-preview .delete-button .red-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #ffffff;
        background: rgba(208, 2, 27, 0.4);
        border-radius: 24px;
        width: 22px;
        height: 22px; }
  .image-container .upload-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    border-top: dashed 1px #e6e6e6; }
    .image-container .upload-image:hover {
      background-color: rgba(126, 211, 33, 0.12); }
    .image-container .upload-image:before {
      content: 'Upload Image';
      padding: 0 15px;
      color: #7ed321; }
    .image-container .upload-image ::-webkit-file-upload-button {
      visibility: hidden; }
    .image-container .upload-image input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      opacity: 0;
      cursor: pointer !important; }
    .image-container .upload-image .upload-icon {
      font-size: 24px;
      width: 44px;
      text-align: center;
      pointer-events: none; }
      .image-container .upload-image .upload-icon:before {
        color: #7ed321; }
  .image-container .spinner {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-top: 4px solid #cccccc;
    border-right: 4px solid white;
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .image-container .spinner,
  .image-container .spinner:after {
    border-radius: 50%; }

.empty {
  text-align: center;
  font-style: italic;
  color: #bfbfbf; }

/* Bug workaround for flex issues on Chrome 48 */
* {
  min-width: 0;
  min-height: 0; }

.primary {
  color: #1173BC; }

.progress-bar {
  height: 2px;
  background-color: #1173BC;
  position: relative;
  border-radius: 6px;
  transition: all 500ms; }
  .progress-bar:after {
    position: absolute;
    right: 0;
    height: 2px;
    width: 24px;
    content: "";
    display: block;
    box-shadow: 12px 0 6px 1px rgba(17, 115, 188, 0.6), 10px 0 6px 1px rgba(17, 115, 188, 0.6), 8px 0 6px 1px rgba(17, 115, 188, 0.6), 6px 0 6px 1px rgba(17, 115, 188, 0.6), 4px 0 6px 1px rgba(17, 115, 188, 0.6), 2px 0 6px 1px rgba(17, 115, 188, 0.7), 0 0 6px 1px rgba(17, 115, 188, 0.8), -4px 0 6px 1px rgba(17, 115, 188, 0.7), -8px 0 6px 1px rgba(17, 115, 188, 0.6), -12px 0 6px 1px rgba(17, 115, 188, 0.5), -16px 0 6px 1px rgba(17, 115, 188, 0.4), -20px 0 6px 1px rgba(17, 115, 188, 0.3), -24px 0 6px 1px rgba(17, 115, 188, 0.2); }

.login-page {
  display: flex;
  min-height: 100vh;
  flex-direction: row; }

.login {
  display: block;
  padding: 0 20px;
  min-width: 600px;
  margin-top: 60px;
  flex: 1;
  font-family: Helvetica, Arial, sans-serif; }
  @media screen and (max-width: 800px) {
    .login {
      padding: 0;
      min-width: 0; } }
  .login .login-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .login .login-heading > img {
      height: 50px;
      width: 167px;
      margin-top: 24px; }
    .login .login-heading .login-main-heading {
      padding-top: 0;
      color: #262626; }
  .login .login-form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 40px auto 0 auto;
    justify-content: space-between;
    border-top: solid 6px #757575;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3); }
    @media screen and (max-width: 800px) {
      .login .login-form {
        border-radius: 0; } }
    .login .login-form .login-form-header {
      font-size: 17px;
      padding: 40px 0;
      color: #4d4d4d;
      text-align: center;
      line-height: 1.5; }
    .login .login-form .login-form-body {
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      background-color: #f7f7f8;
      display: flex;
      flex-direction: column;
      padding: 40px calc(50% - 220px); }
      @media screen and (max-width: 800px) {
        .login .login-form .login-form-body {
          padding: 40px; } }
      .login .login-form .login-form-body label {
        font-size: 14px;
        font-weight: 700;
        color: #4d4d4d;
        line-height: 1.6; }
      .login .login-form .login-form-body > input {
        flex: 1;
        margin-top: 5px;
        margin-bottom: 18px;
        border-radius: 4px;
        border: solid 1px #ccc;
        padding: 15px 20px; }
        .login .login-form .login-form-body > input::placeholder {
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #b2b2b2; }
        .login .login-form .login-form-body > input:focus {
          outline: none; }
      .login .login-form .login-form-body .forgot_password_link {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px; }
        .login .login-form .login-form-body .forgot_password_link a {
          color: #4d4d4d;
          border-bottom: dashed 1px #b3b3b3; }
    .login .login-form > button {
      color: #cf150a;
      border-color: #950f07;
      margin: 40px auto;
      padding: 14px 0;
      border-radius: 6px;
      border: 1px solid;
      width: 260px;
      font-weight: 600; }
      @media (max-width: 800px) {
        .login .login-form > button {
          margin: 40px;
          width: auto; } }
      .login .login-form > button:hover {
        color: white;
        background-color: #9e1008;
        cursor: pointer; }
  .login .error-message {
    margin: 15px auto 0;
    font-size: 14px;
    color: #d0021b;
    max-width: 600px; }
  .login .success-message {
    margin: 15px auto 0;
    font-size: 14px;
    color: #7ed321;
    max-width: 600px; }
  .login .login-footer {
    border-top: none;
    padding-top: 40px;
    text-align: center; }
  .login .login-logo {
    width: 225px;
    height: auto; }

.login-promo {
  flex: 1;
  border: none;
  display: block; }
  @media (max-width: 1024px) {
    .login-promo {
      display: none; } }

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: initial;
  justify-content: initial;
  align-items: center;
  margin: 0;
  z-index: 100; }
  @media (max-height: 1000px) {
    .header {
      flex-direction: row; } }
  @media (max-width: 1500px) {
    .header {
      flex-direction: row; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .header {
      flex-direction: row; } }
  @media (max-width: 1200px) {
    .header {
      flex-direction: row; } }
  @media (max-width: 800px) {
    .header {
      flex-direction: column; } }
  @media (max-height: 1000px) {
    .header {
      flex-wrap: initial; } }
  @media (max-width: 1500px) {
    .header {
      flex-wrap: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .header {
      flex-wrap: initial; } }
  @media (max-width: 1200px) {
    .header {
      flex-wrap: wrap; } }
  @media (max-width: 800px) {
    .header {
      flex-wrap: initial; } }
  @media (max-height: 1000px) {
    .header {
      justify-content: initial; } }
  @media (max-width: 1500px) {
    .header {
      justify-content: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .header {
      justify-content: initial; } }
  @media (max-width: 1200px) {
    .header {
      justify-content: space-between; } }
  @media (max-width: 800px) {
    .header {
      justify-content: initial; } }
  @media (max-height: 1000px) {
    .header {
      margin: 0; } }
  @media (max-width: 1500px) {
    .header {
      margin: 0; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .header {
      margin: 0; } }
  @media (max-width: 1200px) {
    .header {
      margin: 0 0 0 15px; } }
  @media (max-width: 800px) {
    .header {
      margin: 0 0 0 15px; } }
  .header ul, .header li {
    list-style: none; }

.breadcrumb {
  position: relative;
  padding: 0;
  align-items: center;
  border-radius: 0;
  background: rgba(255, 255, 255, 0);
  font-size: 20px;
  line-height: 90px;
  color: #bfbfbf;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 30px;
  margin-left: 30px;
  flex: 1;
  width: initial;
  order: initial; }
  @media (max-height: 1000px) {
    .breadcrumb {
      margin-right: 30px; } }
  @media (max-width: 1500px) {
    .breadcrumb {
      margin-right: 30px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .breadcrumb {
      margin-right: 30px; } }
  @media (max-width: 1200px) {
    .breadcrumb {
      margin-right: 0; } }
  @media (max-width: 800px) {
    .breadcrumb {
      margin-right: 0; } }
  @media (max-height: 1000px) {
    .breadcrumb {
      margin-left: 30px; } }
  @media (max-width: 1500px) {
    .breadcrumb {
      margin-left: 30px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .breadcrumb {
      margin-left: 30px; } }
  @media (max-width: 1200px) {
    .breadcrumb {
      margin-left: 0; } }
  @media (max-width: 800px) {
    .breadcrumb {
      margin-left: 0; } }
  @media (max-height: 1000px) {
    .breadcrumb {
      flex: 1; } }
  @media (max-width: 1500px) {
    .breadcrumb {
      flex: 1; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .breadcrumb {
      flex: 1; } }
  @media (max-width: 1200px) {
    .breadcrumb {
      flex: 1 calc(100% - 240px); } }
  @media (max-width: 800px) {
    .breadcrumb {
      flex: 1; } }
  @media (max-height: 1000px) {
    .breadcrumb {
      width: initial; } }
  @media (max-width: 1500px) {
    .breadcrumb {
      width: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .breadcrumb {
      width: initial; } }
  @media (max-width: 1200px) {
    .breadcrumb {
      width: initial; } }
  @media (max-width: 800px) {
    .breadcrumb {
      width: 100%; } }
  @media (max-height: 1000px) {
    .breadcrumb {
      order: initial; } }
  @media (max-width: 1500px) {
    .breadcrumb {
      order: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .breadcrumb {
      order: initial; } }
  @media (max-width: 1200px) {
    .breadcrumb {
      order: 1; } }
  @media (max-width: 800px) {
    .breadcrumb {
      order: 2; } }
  .breadcrumb li {
    white-space: nowrap;
    display: inline-block;
    cursor: pointer; }
  .breadcrumb .active, .breadcrumb .active > a {
    color: #1173BC;
    cursor: default; }
  .breadcrumb li + li:before {
    content: url("../images/ui/nav-arrow-right-small.svg");
    padding: 0 12px;
    position: relative;
    top: 1px; }
  .breadcrumb a {
    color: #c7c7c7;
    text-decoration: none; }
  @media (max-width: 1200px) {
    .breadcrumb:before {
      display: none; } }

.selector {
  background-color: #ffffff;
  display: flex;
  height: 45px;
  border: 1px solid #1173BC;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  width: 352px; }
  @media (max-height: 1000px) {
    .selector {
      width: 240px; } }
  @media (max-width: 1500px) {
    .selector {
      width: 240px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .selector {
      width: 240px; } }
  @media (max-width: 1200px) {
    .selector {
      width: 240px; } }
  @media (max-width: 800px) {
    .selector {
      width: 100%; } }
  .selector > .kind {
    position: relative; }
    .selector > .kind > a {
      color: #1173BC;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      margin: -1px 0 0 -1px; }
    .selector > .kind > a + div {
      visibility: hidden;
      opacity: 0;
      width: 352px;
      position: absolute;
      z-index: -10;
      padding: 7px 0 0 0;
      margin: -8px 0 0 -1px;
      border-radius: 4px;
      border: 1px solid #bfbfbf;
      transition: all 0.2s; }
      @media (max-height: 1000px) {
        .selector > .kind > a + div {
          width: 240px; } }
      @media (max-width: 1500px) {
        .selector > .kind > a + div {
          width: 240px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .selector > .kind > a + div {
          width: 240px; } }
      @media (max-width: 1200px) {
        .selector > .kind > a + div {
          width: 240px; } }
      @media (max-width: 800px) {
        .selector > .kind > a + div {
          width: 100%; } }
    .selector > .kind:hover > a + div {
      visibility: visible;
      opacity: 1; }
  .selector .drilldown {
    position: relative;
    z-index: 1;
    flex: 1;
    margin-top: -1px;
    margin-right: -1px;
    border-radius: 4px; }
    .selector .drilldown[data-state=open] .selected {
      background-image: url("../images/ui/nav-arrow-up.svg"); }
  .selector .selected {
    position: relative;
    z-index: 1;
    background-color: white;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-image: url("../images/ui/nav-arrow-down.svg");
    transition: ease-in background-image 0.2s;
    height: 45px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    line-height: 45px;
    cursor: pointer;
    padding: 0 44px 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .selector .options {
    position: relative;
    flex: 1;
    overflow: auto;
    max-height: 600px;
    background-color: white;
    border: 1px solid #bfbfbf;
    border-radius: 0 0 4px 4px;
    padding-top: 7px;
    margin-top: -8px; }
    .selector .options li {
      padding: 12px 10px; }
  .selector ul li {
    display: flex;
    align-items: center;
    color: gray;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s; }
    .selector ul li .icon {
      color: #bfbfbf; }
    .selector ul li:hover {
      background-color: #fafafa; }
    .selector ul li.active {
      color: #1173BC; }
      .selector ul li.active .icon {
        color: #1173BC; }
      .selector ul li.active:active, .selector ul li.active:focus, .selector ul li.active:hover {
        background-color: white;
        cursor: default; }
  .selector ul li:last-child {
    border-radius: 0 0 3px 3px; }
  .selector ul li + li {
    margin: 1px 0 0 0;
    box-shadow: 0 -1px 0 #e7e7e7; }

main {
  display: flex;
  flex: 1;
  overflow: auto;
  overflow: initial;
  flex-wrap: initial; }
  @media (max-height: 1000px) {
    main {
      overflow: initial; } }
  @media (max-width: 1500px) {
    main {
      overflow: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    main {
      overflow: initial; } }
  @media (max-width: 1200px) {
    main {
      overflow: auto; } }
  @media (max-width: 800px) {
    main {
      overflow: auto; } }
  @media (max-height: 1000px) {
    main {
      flex-wrap: initial; } }
  @media (max-width: 1500px) {
    main {
      flex-wrap: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    main {
      flex-wrap: initial; } }
  @media (max-width: 1200px) {
    main {
      flex-wrap: wrap; } }
  @media (max-width: 800px) {
    main {
      flex-wrap: wrap; } }

.left {
  order: initial;
  flex: initial;
  width: 368px;
  position: relative;
  margin-top: 0; }
  @media (max-height: 1000px) {
    .left {
      order: initial; } }
  @media (max-width: 1500px) {
    .left {
      order: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .left {
      order: initial; } }
  @media (max-width: 1200px) {
    .left {
      order: 1; } }
  @media (max-width: 800px) {
    .left {
      order: 1; } }
  @media (max-height: 1000px) {
    .left {
      flex: initial; } }
  @media (max-width: 1500px) {
    .left {
      flex: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .left {
      flex: initial; } }
  @media (max-width: 1200px) {
    .left {
      flex: 1 100%; } }
  @media (max-width: 800px) {
    .left {
      flex: 1 100%; } }
  @media (max-height: 1000px) {
    .left {
      width: 276px; } }
  @media (max-width: 1500px) {
    .left {
      width: 276px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .left {
      width: 245px; } }
  @media (max-width: 1200px) {
    .left {
      width: 276px; } }
  @media (max-width: 800px) {
    .left {
      width: 368px; } }
  @media (max-height: 1000px) {
    .left {
      margin-top: 0; } }
  @media (max-width: 1500px) {
    .left {
      margin-top: 0; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .left {
      margin-top: 0; } }
  @media (max-width: 1200px) {
    .left {
      margin-top: 32px; } }
  @media (max-width: 800px) {
    .left {
      margin-top: 32px; } }

.sync {
  position: relative;
  display: flex;
  height: 45px;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 0;
  order: initial;
  color: #bfbfbf;
  font-size: 15px;
  line-height: 44px;
  border-radius: 4px;
  cursor: default;
  transition: all 0.33s ease-out; }
  @media (max-height: 1000px) {
    .sync {
      width: 100%; } }
  @media (max-width: 1500px) {
    .sync {
      width: 100%; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .sync {
      width: 100%; } }
  @media (max-width: 1200px) {
    .sync {
      width: 240px; } }
  @media (max-width: 800px) {
    .sync {
      width: 100%; } }
  @media (max-height: 1000px) {
    .sync {
      margin-top: 0; } }
  @media (max-width: 1500px) {
    .sync {
      margin-top: 0; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .sync {
      margin-top: 0; } }
  @media (max-width: 1200px) {
    .sync {
      margin-top: 16px; } }
  @media (max-width: 800px) {
    .sync {
      margin-top: 16px; } }
  @media (max-height: 1000px) {
    .sync {
      order: initial; } }
  @media (max-width: 1500px) {
    .sync {
      order: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .sync {
      order: initial; } }
  @media (max-width: 1200px) {
    .sync {
      order: 2; } }
  @media (max-width: 800px) {
    .sync {
      order: 1; } }
  .sync div {
    display: flex;
    flex: 1; }
  .sync .title {
    flex: 1;
    justify-content: center;
    font-weight: 600;
    letter-spacing: 0.0625em; }
  .sync .details {
    flex: 1;
    justify-content: center; }
  .sync .title-bg {
    position: relative;
    height: 45px;
    top: -1px;
    left: -1px;
    border: 1px solid #bfbfbf;
    border-radius: 4px; }
  .sync.live {
    border: 1px solid #7ed321; }
  .sync .live {
    color: #7ed321; }
  .sync.needs_sync {
    border: 1px solid #d67d00; }
  .sync .needs_sync {
    color: #d67d00; }
  .sync.re_sync {
    border: 1px solid #d67d00; }
  .sync .re_sync {
    color: #d67d00; }
  .sync.requested {
    border: 1px solid #1173BC; }
  .sync .requested {
    color: #1173BC; }
  .sync.in_process {
    border: 1px solid #1173BC; }
  .sync .in_process {
    color: #1173BC; }
  .sync.needs_sync .alternating.first .title {
    color: #d67d00; }
  .sync.re_sync .alternating.first .title {
    color: #d67d00; }
  .sync.in_process .alternating.first .title {
    color: #1173BC; }
  .sync .state, .sync .always, .sync .alternating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .sync .show {
    opacity: 1; }
  .sync .hide {
    opacity: 0; }
  .sync .icon, .sync .jaqen {
    flex: initial;
    width: 44px; }
  .sync .alternating {
    animation-fill-mode: both; }
    .sync .alternating.first {
      animation: fade-first 10s infinite ease-in-out; }
    .sync .alternating.second {
      animation: fade-second 10s infinite ease-in-out; }
  .sync.needs_sync {
    cursor: pointer; }
  .sync .spinner {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    border-top: 2px solid #1173BC;
    border-right: 2px solid rgba(17, 115, 188, 0.25);
    border-bottom: 2px solid rgba(17, 115, 188, 0.25);
    border-left: 2px solid rgba(17, 115, 188, 0.25);
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .sync .spinner,
  .sync .spinner:after {
    border-radius: 50%; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-light-italic.otf") format("opentype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-regular-italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-medium-italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'San Francisco';
  src: url("../images/ui/sf-bold-italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic; }

.preview {
  flex: initial;
  margin: 0 0 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 752px;
  width: 368px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 30px; }
  @media (max-height: 1000px) {
    .preview {
      flex: initial; } }
  @media (max-width: 1500px) {
    .preview {
      flex: initial; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview {
      flex: initial; } }
  @media (max-width: 1200px) {
    .preview {
      flex: 1 100%; } }
  @media (max-width: 800px) {
    .preview {
      flex: 1 100%; } }
  @media (max-height: 1000px) {
    .preview {
      margin: 0 0 18px; } }
  @media (max-width: 1500px) {
    .preview {
      margin: 0 0 18px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview {
      margin: 0 auto 18px; } }
  @media (max-width: 1200px) {
    .preview {
      margin: 0 auto 30px; } }
  @media (max-width: 800px) {
    .preview {
      margin: 0 auto 30px; } }
  @media (max-height: 1000px) {
    .preview {
      height: 564px; } }
  @media (max-width: 1500px) {
    .preview {
      height: 564px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview {
      height: 500.832px; } }
  @media (max-width: 1200px) {
    .preview {
      height: 564px; } }
  @media (max-width: 800px) {
    .preview {
      height: 752px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .preview {
      height: 376px; } }
  @media (max-height: 1000px) {
    .preview {
      width: 276px; } }
  @media (max-width: 1500px) {
    .preview {
      width: 276px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview {
      width: 245.088px; } }
  @media (max-width: 1200px) {
    .preview {
      width: 276px; } }
  @media (max-width: 800px) {
    .preview {
      width: 368px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .preview {
      width: 184px; } }
  .preview-branded-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .preview-branded-header,
  .preview-branded-header [style*='background-image'] {
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center 75%; }
  .preview-dark {
    color-scheme: dark; }

.os {
  position: relative;
  width: 320px;
  height: 568px;
  font-size: 14px;
  background: white;
  overflow: hidden;
  border: 1px solid #ccc; }
  @media (max-height: 1000px) {
    .os {
      width: 240px; } }
  @media (max-width: 1500px) {
    .os {
      width: 240px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .os {
      width: 213.12px; } }
  @media (max-width: 1200px) {
    .os {
      width: 240px; } }
  @media (max-width: 800px) {
    .os {
      width: 320px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .os {
      width: 160px; } }
  @media (max-height: 1000px) {
    .os {
      height: 426px; } }
  @media (max-width: 1500px) {
    .os {
      height: 426px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .os {
      height: 378.288px; } }
  @media (max-width: 1200px) {
    .os {
      height: 426px; } }
  @media (max-width: 800px) {
    .os {
      height: 568px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .os {
      height: 284px; } }
  @media (max-height: 1000px) {
    .os {
      font-size: 10.5px; } }
  @media (max-width: 1500px) {
    .os {
      font-size: 10.5px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .os {
      font-size: 9.324px; } }
  @media (max-width: 1200px) {
    .os {
      font-size: 10.5px; } }
  @media (max-width: 800px) {
    .os {
      font-size: 14px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .os {
      font-size: 7px; } }
  .os a {
    cursor: default; }
  .os .title-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 20;
    color: white; }
    .os .title-bar .titlebar-action {
      display: flex;
      position: absolute;
      height: 44px; }
      @media (max-height: 1000px) {
        .os .title-bar .titlebar-action {
          height: 33px; } }
      @media (max-width: 1500px) {
        .os .title-bar .titlebar-action {
          height: 33px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .os .title-bar .titlebar-action {
          height: 29.304px; } }
      @media (max-width: 1200px) {
        .os .title-bar .titlebar-action {
          height: 33px; } }
      @media (max-width: 800px) {
        .os .title-bar .titlebar-action {
          height: 44px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .os .title-bar .titlebar-action {
          height: 22px; } }
    .os .title-bar [class^='asset-'] {
      line-height: 44px; }
      @media (max-height: 1000px) {
        .os .title-bar [class^='asset-'] {
          line-height: 33px; } }
      @media (max-width: 1500px) {
        .os .title-bar [class^='asset-'] {
          line-height: 33px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .os .title-bar [class^='asset-'] {
          line-height: 29.304px; } }
      @media (max-width: 1200px) {
        .os .title-bar [class^='asset-'] {
          line-height: 33px; } }
      @media (max-width: 800px) {
        .os .title-bar [class^='asset-'] {
          line-height: 44px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .os .title-bar [class^='asset-'] {
          line-height: 22px; } }
    .os .title-bar .titlebar-action {
      align-items: center;
      justify-content: center; }
      .os .title-bar .titlebar-action.titlebar-left {
        justify-content: flex-start;
        text-align: left; }
        .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
          margin-left: -24px; }
          @media (max-height: 1000px) {
            .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
              margin-left: -18px; } }
          @media (max-width: 1500px) {
            .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
              margin-left: -18px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
              margin-left: -15.984px; } }
          @media (max-width: 1200px) {
            .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
              margin-left: -18px; } }
          @media (max-width: 800px) {
            .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
              margin-left: -24px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .os .title-bar .titlebar-action.titlebar-left .asset-back + .titlebar-label {
              margin-left: -12px; } }
        .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
          font-size: 20px; }
          @media (max-height: 1000px) {
            .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
              font-size: 15px; } }
          @media (max-width: 1500px) {
            .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
              font-size: 15px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
              font-size: 13.32px; } }
          @media (max-width: 1200px) {
            .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
              font-size: 15px; } }
          @media (max-width: 800px) {
            .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
              font-size: 20px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .os .title-bar .titlebar-action.titlebar-left .ui-icon-arrow-left {
              font-size: 10px; } }
      .os .title-bar .titlebar-action .titlebar-label-legacy {
        font-size: 17px;
        max-width: 48px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        @media (max-height: 1000px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            font-size: 12.75px; } }
        @media (max-width: 1500px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            font-size: 12.75px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            font-size: 11.322px; } }
        @media (max-width: 1200px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            font-size: 12.75px; } }
        @media (max-width: 800px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            font-size: 17px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            font-size: 8.5px; } }
        @media (max-height: 1000px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            max-width: 36px; } }
        @media (max-width: 1500px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            max-width: 36px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            max-width: 31.968px; } }
        @media (max-width: 1200px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            max-width: 36px; } }
        @media (max-width: 800px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            max-width: 48px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .os .title-bar .titlebar-action .titlebar-label-legacy {
            max-width: 24px; } }
        .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
          max-width: 276px; }
          @media (max-height: 1000px) {
            .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
              max-width: 207px; } }
          @media (max-width: 1500px) {
            .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
              max-width: 207px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
              max-width: 183.816px; } }
          @media (max-width: 1200px) {
            .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
              max-width: 207px; } }
          @media (max-width: 800px) {
            .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
              max-width: 276px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .os .title-bar .titlebar-action .titlebar-label-legacy.wide {
              max-width: 138px; } }
      .os .title-bar .titlebar-action .titlebar-label {
        font-size: 17px;
        max-width: 48px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 44px;
        line-height: 44px; }
        @media (max-height: 1000px) {
          .os .title-bar .titlebar-action .titlebar-label {
            font-size: 12.75px; } }
        @media (max-width: 1500px) {
          .os .title-bar .titlebar-action .titlebar-label {
            font-size: 12.75px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            font-size: 11.322px; } }
        @media (max-width: 1200px) {
          .os .title-bar .titlebar-action .titlebar-label {
            font-size: 12.75px; } }
        @media (max-width: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            font-size: 17px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .os .title-bar .titlebar-action .titlebar-label {
            font-size: 8.5px; } }
        @media (max-height: 1000px) {
          .os .title-bar .titlebar-action .titlebar-label {
            max-width: 36px; } }
        @media (max-width: 1500px) {
          .os .title-bar .titlebar-action .titlebar-label {
            max-width: 36px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            max-width: 31.968px; } }
        @media (max-width: 1200px) {
          .os .title-bar .titlebar-action .titlebar-label {
            max-width: 36px; } }
        @media (max-width: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            max-width: 48px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .os .title-bar .titlebar-action .titlebar-label {
            max-width: 24px; } }
        @media (max-height: 1000px) {
          .os .title-bar .titlebar-action .titlebar-label {
            height: 33px; } }
        @media (max-width: 1500px) {
          .os .title-bar .titlebar-action .titlebar-label {
            height: 33px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            height: 29.304px; } }
        @media (max-width: 1200px) {
          .os .title-bar .titlebar-action .titlebar-label {
            height: 33px; } }
        @media (max-width: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            height: 44px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .os .title-bar .titlebar-action .titlebar-label {
            height: 22px; } }
        @media (max-height: 1000px) {
          .os .title-bar .titlebar-action .titlebar-label {
            line-height: 33px; } }
        @media (max-width: 1500px) {
          .os .title-bar .titlebar-action .titlebar-label {
            line-height: 33px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            line-height: 29.304px; } }
        @media (max-width: 1200px) {
          .os .title-bar .titlebar-action .titlebar-label {
            line-height: 33px; } }
        @media (max-width: 800px) {
          .os .title-bar .titlebar-action .titlebar-label {
            line-height: 44px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .os .title-bar .titlebar-action .titlebar-label {
            line-height: 22px; } }
        .os .title-bar .titlebar-action .titlebar-label.wide {
          max-width: 276px; }
          @media (max-height: 1000px) {
            .os .title-bar .titlebar-action .titlebar-label.wide {
              max-width: 207px; } }
          @media (max-width: 1500px) {
            .os .title-bar .titlebar-action .titlebar-label.wide {
              max-width: 207px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .os .title-bar .titlebar-action .titlebar-label.wide {
              max-width: 183.816px; } }
          @media (max-width: 1200px) {
            .os .title-bar .titlebar-action .titlebar-label.wide {
              max-width: 207px; } }
          @media (max-width: 800px) {
            .os .title-bar .titlebar-action .titlebar-label.wide {
              max-width: 276px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .os .title-bar .titlebar-action .titlebar-label.wide {
              max-width: 138px; } }
    .os .title-bar .titlebar-left {
      left: 0px; }
    .os .title-bar .titlebar-right {
      right: 0px; }
  .os .side-nav {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 20; }
    .os .side-nav:before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 12px;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0)); }
      @media (max-height: 1000px) {
        .os .side-nav:before {
          width: 9px; } }
      @media (max-width: 1500px) {
        .os .side-nav:before {
          width: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .os .side-nav:before {
          width: 7.992px; } }
      @media (max-width: 1200px) {
        .os .side-nav:before {
          width: 9px; } }
      @media (max-width: 800px) {
        .os .side-nav:before {
          width: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .os .side-nav:before {
          width: 6px; } }
    .os .side-nav .white-fill {
      position: absolute;
      top: 65px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: white; }
      @media (max-height: 1000px) {
        .os .side-nav .white-fill {
          top: 48.75px; } }
      @media (max-width: 1500px) {
        .os .side-nav .white-fill {
          top: 48.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .os .side-nav .white-fill {
          top: 43.29px; } }
      @media (max-width: 1200px) {
        .os .side-nav .white-fill {
          top: 48.75px; } }
      @media (max-width: 800px) {
        .os .side-nav .white-fill {
          top: 65px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .os .side-nav .white-fill {
          top: 32.5px; } }
    .os .side-nav ~ .side [class^='list-'] {
      margin-right: 20px; }
      @media (max-height: 1000px) {
        .os .side-nav ~ .side [class^='list-'] {
          margin-right: 15px; } }
      @media (max-width: 1500px) {
        .os .side-nav ~ .side [class^='list-'] {
          margin-right: 15px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .os .side-nav ~ .side [class^='list-'] {
          margin-right: 13.32px; } }
      @media (max-width: 1200px) {
        .os .side-nav ~ .side [class^='list-'] {
          margin-right: 15px; } }
      @media (max-width: 800px) {
        .os .side-nav ~ .side [class^='list-'] {
          margin-right: 20px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .os .side-nav ~ .side [class^='list-'] {
          margin-right: 10px; } }
  .os .content {
    position: absolute;
    left: 0px;
    right: 0px;
    height: auto;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10; }
    .os .content.dhs-preview {
      overflow-y: scroll;
      box-sizing: content-box;
      width: 100%;
      background-color: #e5e5e5; }
      .os .content.dhs-preview::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0; }
    .os .content::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0; }
  .os .container-image {
    width: 100%; }

.preview-warning {
  position: absolute;
  top: 0;
  height: 55px;
  background-color: #fff0d9;
  width: calc(100% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2ddb7;
  border-top-left-radius: 55px;
  border-top-right-radius: 55px;
  font-size: 14px;
  font-weight: 600;
  color: #cc8b1b; }
  @media (max-height: 1000px) {
    .preview-warning {
      border-top-left-radius: 41.25px; } }
  @media (max-width: 1500px) {
    .preview-warning {
      border-top-left-radius: 41.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview-warning {
      border-top-left-radius: 36.63px; } }
  @media (max-width: 1200px) {
    .preview-warning {
      border-top-left-radius: 41.25px; } }
  @media (max-width: 800px) {
    .preview-warning {
      border-top-left-radius: 55px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .preview-warning {
      border-top-left-radius: 27.5px; } }
  @media (max-height: 1000px) {
    .preview-warning {
      border-top-right-radius: 41.25px; } }
  @media (max-width: 1500px) {
    .preview-warning {
      border-top-right-radius: 41.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview-warning {
      border-top-right-radius: 36.63px; } }
  @media (max-width: 1200px) {
    .preview-warning {
      border-top-right-radius: 41.25px; } }
  @media (max-width: 800px) {
    .preview-warning {
      border-top-right-radius: 55px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .preview-warning {
      border-top-right-radius: 27.5px; } }
  @media (max-height: 1000px) {
    .preview-warning {
      font-size: 10.5px; } }
  @media (max-width: 1500px) {
    .preview-warning {
      font-size: 10.5px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .preview-warning {
      font-size: 9.324px; } }
  @media (max-width: 1200px) {
    .preview-warning {
      font-size: 10.5px; } }
  @media (max-width: 800px) {
    .preview-warning {
      font-size: 14px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .preview-warning {
      font-size: 7px; } }

.preview.ios {
  background-image: url("../images/preview/iphone_6_outline.svg"); }
  .preview.ios * {
    font-family: 'San Francisco', 'Helvetica', 'Arial'; }
  .preview.ios .title-bar {
    height: 64px;
    text-align: center;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3); }
    @media (max-height: 1000px) {
      .preview.ios .title-bar {
        height: 48px; } }
    @media (max-width: 1500px) {
      .preview.ios .title-bar {
        height: 48px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .title-bar {
        height: 42.624px; } }
    @media (max-width: 1200px) {
      .preview.ios .title-bar {
        height: 48px; } }
    @media (max-width: 800px) {
      .preview.ios .title-bar {
        height: 64px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .title-bar {
        height: 32px; } }
    .preview.ios .title-bar.light {
      background-image: url("../images/preview/ios_status_bar_black.svg"); }
    .preview.ios .title-bar.dark {
      background-image: url("../images/preview/ios_status_bar_white.svg"); }
    .preview.ios .title-bar .time {
      height: 20px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-height: 1000px) {
        .preview.ios .title-bar .time {
          height: 15px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar .time {
          height: 15px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar .time {
          height: 13.32px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar .time {
          height: 15px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar .time {
          height: 20px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar .time {
          height: 10px; } }
      @media (max-height: 1000px) {
        .preview.ios .title-bar .time {
          font-size: 9px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar .time {
          font-size: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar .time {
          font-size: 7.992px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar .time {
          font-size: 9px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar .time {
          font-size: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar .time {
          font-size: 6px; } }
    .preview.ios .title-bar h1 {
      font-weight: normal;
      margin: auto;
      max-width: 180px;
      padding: 5px;
      line-height: 34px;
      font-size: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-height: 1000px) {
        .preview.ios .title-bar h1 {
          max-width: 135px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar h1 {
          max-width: 135px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar h1 {
          max-width: 119.88px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar h1 {
          max-width: 135px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar h1 {
          max-width: 180px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar h1 {
          max-width: 90px; } }
      @media (max-height: 1000px) {
        .preview.ios .title-bar h1 {
          padding: 3.75px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar h1 {
          padding: 3.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar h1 {
          padding: 3.33px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar h1 {
          padding: 3.75px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar h1 {
          padding: 5px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar h1 {
          padding: 2.5px; } }
      @media (max-height: 1000px) {
        .preview.ios .title-bar h1 {
          line-height: 25.5px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar h1 {
          line-height: 25.5px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar h1 {
          line-height: 22.644px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar h1 {
          line-height: 25.5px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar h1 {
          line-height: 34px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar h1 {
          line-height: 17px; } }
      @media (max-height: 1000px) {
        .preview.ios .title-bar h1 {
          font-size: 12.75px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar h1 {
          font-size: 12.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar h1 {
          font-size: 11.322px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar h1 {
          font-size: 12.75px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar h1 {
          font-size: 17px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar h1 {
          font-size: 8.5px; } }
    .preview.ios .title-bar .titlebar-action {
      top: 20px;
      font-size: 44px; }
      @media (max-height: 1000px) {
        .preview.ios .title-bar .titlebar-action {
          top: 15px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar .titlebar-action {
          top: 15px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar .titlebar-action {
          top: 13.32px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar .titlebar-action {
          top: 15px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar .titlebar-action {
          top: 20px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar .titlebar-action {
          top: 10px; } }
      @media (max-height: 1000px) {
        .preview.ios .title-bar .titlebar-action {
          font-size: 33px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar .titlebar-action {
          font-size: 33px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar .titlebar-action {
          font-size: 29.304px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar .titlebar-action {
          font-size: 33px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar .titlebar-action {
          font-size: 44px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar .titlebar-action {
          font-size: 22px; } }
    .preview.ios .title-bar .titlebar-left .asset-back,
    .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
      margin-left: 8px; }
      @media (max-height: 1000px) {
        .preview.ios .title-bar .titlebar-left .asset-back,
        .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
          margin-left: 6px; } }
      @media (max-width: 1500px) {
        .preview.ios .title-bar .titlebar-left .asset-back,
        .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
          margin-left: 6px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .title-bar .titlebar-left .asset-back,
        .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
          margin-left: 5.328px; } }
      @media (max-width: 1200px) {
        .preview.ios .title-bar .titlebar-left .asset-back,
        .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
          margin-left: 6px; } }
      @media (max-width: 800px) {
        .preview.ios .title-bar .titlebar-left .asset-back,
        .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
          margin-left: 8px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .title-bar .titlebar-left .asset-back,
        .preview.ios .title-bar .titlebar-left .ui-icon-arrow-left {
          margin-left: 4px; } }
  .preview.ios .tab-bar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 49px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    z-index: 20; }
    @media (max-height: 1000px) {
      .preview.ios .tab-bar {
        height: 36.75px; } }
    @media (max-width: 1500px) {
      .preview.ios .tab-bar {
        height: 36.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .tab-bar {
        height: 32.634px; } }
    @media (max-width: 1200px) {
      .preview.ios .tab-bar {
        height: 36.75px; } }
    @media (max-width: 800px) {
      .preview.ios .tab-bar {
        height: 49px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .tab-bar {
        height: 24.5px; } }
    .preview.ios .tab-bar ul {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      list-style: none;
      display: flex;
      font-size: 10px; }
      @media (max-height: 1000px) {
        .preview.ios .tab-bar ul {
          font-size: 7.5px; } }
      @media (max-width: 1500px) {
        .preview.ios .tab-bar ul {
          font-size: 7.5px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .tab-bar ul {
          font-size: 6.66px; } }
      @media (max-width: 1200px) {
        .preview.ios .tab-bar ul {
          font-size: 7.5px; } }
      @media (max-width: 800px) {
        .preview.ios .tab-bar ul {
          font-size: 10px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .tab-bar ul {
          font-size: 5px; } }
      .preview.ios .tab-bar ul li {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 6px;
        margin-bottom: 2px;
        margin-left: 2px;
        margin-right: 2px;
        color: #999999; }
        @media (max-height: 1000px) {
          .preview.ios .tab-bar ul li {
            margin-top: 4.5px; } }
        @media (max-width: 1500px) {
          .preview.ios .tab-bar ul li {
            margin-top: 4.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .preview.ios .tab-bar ul li {
            margin-top: 3.996px; } }
        @media (max-width: 1200px) {
          .preview.ios .tab-bar ul li {
            margin-top: 4.5px; } }
        @media (max-width: 800px) {
          .preview.ios .tab-bar ul li {
            margin-top: 6px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .preview.ios .tab-bar ul li {
            margin-top: 3px; } }
        @media (max-height: 1000px) {
          .preview.ios .tab-bar ul li {
            margin-bottom: 1.5px; } }
        @media (max-width: 1500px) {
          .preview.ios .tab-bar ul li {
            margin-bottom: 1.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .preview.ios .tab-bar ul li {
            margin-bottom: 1.332px; } }
        @media (max-width: 1200px) {
          .preview.ios .tab-bar ul li {
            margin-bottom: 1.5px; } }
        @media (max-width: 800px) {
          .preview.ios .tab-bar ul li {
            margin-bottom: 2px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .preview.ios .tab-bar ul li {
            margin-bottom: 1px; } }
        @media (max-height: 1000px) {
          .preview.ios .tab-bar ul li {
            margin-left: 1.5px; } }
        @media (max-width: 1500px) {
          .preview.ios .tab-bar ul li {
            margin-left: 1.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .preview.ios .tab-bar ul li {
            margin-left: 1.332px; } }
        @media (max-width: 1200px) {
          .preview.ios .tab-bar ul li {
            margin-left: 1.5px; } }
        @media (max-width: 800px) {
          .preview.ios .tab-bar ul li {
            margin-left: 2px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .preview.ios .tab-bar ul li {
            margin-left: 1px; } }
        @media (max-height: 1000px) {
          .preview.ios .tab-bar ul li {
            margin-right: 1.5px; } }
        @media (max-width: 1500px) {
          .preview.ios .tab-bar ul li {
            margin-right: 1.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .preview.ios .tab-bar ul li {
            margin-right: 1.332px; } }
        @media (max-width: 1200px) {
          .preview.ios .tab-bar ul li {
            margin-right: 1.5px; } }
        @media (max-width: 800px) {
          .preview.ios .tab-bar ul li {
            margin-right: 2px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .preview.ios .tab-bar ul li {
            margin-right: 1px; } }
        .preview.ios .tab-bar ul li.active .icon {
          font-weight: bold; }
        .preview.ios .tab-bar ul li.disable {
          pointer-events: none; }
        .preview.ios .tab-bar ul li a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: center;
          color: #999999;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center; }
          .preview.ios .tab-bar ul li a > .icon {
            font-size: 24px;
            width: 100%; }
            @media (max-height: 1000px) {
              .preview.ios .tab-bar ul li a > .icon {
                font-size: 18px; } }
            @media (max-width: 1500px) {
              .preview.ios .tab-bar ul li a > .icon {
                font-size: 18px; } }
            @media (max-width: 1500px) and (max-height: 800px) {
              .preview.ios .tab-bar ul li a > .icon {
                font-size: 15.984px; } }
            @media (max-width: 1200px) {
              .preview.ios .tab-bar ul li a > .icon {
                font-size: 18px; } }
            @media (max-width: 800px) {
              .preview.ios .tab-bar ul li a > .icon {
                font-size: 24px; } }
            @media (max-width: 1500px) and (max-height: 750px) {
              .preview.ios .tab-bar ul li a > .icon {
                font-size: 12px; } }
          .preview.ios .tab-bar ul li a .nav-bar-icon-height {
            height: 24px; }
          .preview.ios .tab-bar ul li a .nav-bar-icon-label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }
  .preview.ios .content {
    top: 64px; }
    @media (max-height: 1000px) {
      .preview.ios .content {
        top: 48px; } }
    @media (max-width: 1500px) {
      .preview.ios .content {
        top: 48px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .content {
        top: 42.624px; } }
    @media (max-width: 1200px) {
      .preview.ios .content {
        top: 48px; } }
    @media (max-width: 800px) {
      .preview.ios .content {
        top: 64px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .content {
        top: 32px; } }
    .preview.ios .content.side {
      bottom: 0px; }
    .preview.ios .content.tabbed {
      bottom: 49px; }
      @media (max-height: 1000px) {
        .preview.ios .content.tabbed {
          bottom: 36.75px; } }
      @media (max-width: 1500px) {
        .preview.ios .content.tabbed {
          bottom: 36.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .content.tabbed {
          bottom: 32.634px; } }
      @media (max-width: 1200px) {
        .preview.ios .content.tabbed {
          bottom: 36.75px; } }
      @media (max-width: 800px) {
        .preview.ios .content.tabbed {
          bottom: 49px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .content.tabbed {
          bottom: 24.5px; } }
  .preview.ios .side-nav {
    width: 44px; }
    @media (max-height: 1000px) {
      .preview.ios .side-nav {
        width: 33px; } }
    @media (max-width: 1500px) {
      .preview.ios .side-nav {
        width: 33px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .side-nav {
        width: 29.304px; } }
    @media (max-width: 1200px) {
      .preview.ios .side-nav {
        width: 33px; } }
    @media (max-width: 800px) {
      .preview.ios .side-nav {
        width: 44px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .side-nav {
        width: 22px; } }
    .preview.ios .side-nav:before {
      right: 44px; }
      @media (max-height: 1000px) {
        .preview.ios .side-nav:before {
          right: 33px; } }
      @media (max-width: 1500px) {
        .preview.ios .side-nav:before {
          right: 33px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .side-nav:before {
          right: 29.304px; } }
      @media (max-width: 1200px) {
        .preview.ios .side-nav:before {
          right: 33px; } }
      @media (max-width: 800px) {
        .preview.ios .side-nav:before {
          right: 44px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .side-nav:before {
          right: 22px; } }
    .preview.ios .side-nav .white-fill {
      top: 65px; }
      @media (max-height: 1000px) {
        .preview.ios .side-nav .white-fill {
          top: 48.75px; } }
      @media (max-width: 1500px) {
        .preview.ios .side-nav .white-fill {
          top: 48.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.ios .side-nav .white-fill {
          top: 43.29px; } }
      @media (max-width: 1200px) {
        .preview.ios .side-nav .white-fill {
          top: 48.75px; } }
      @media (max-width: 800px) {
        .preview.ios .side-nav .white-fill {
          top: 65px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.ios .side-nav .white-fill {
          top: 32.5px; } }
  .preview.ios .menu-button {
    position: absolute;
    bottom: 22px;
    height: 56px;
    width: 56px;
    left: 156px;
    border-radius: 50%; }
    @media (max-height: 1000px) {
      .preview.ios .menu-button {
        bottom: 16.5px; } }
    @media (max-width: 1500px) {
      .preview.ios .menu-button {
        bottom: 16.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .menu-button {
        bottom: 14.652px; } }
    @media (max-width: 1200px) {
      .preview.ios .menu-button {
        bottom: 16.5px; } }
    @media (max-width: 800px) {
      .preview.ios .menu-button {
        bottom: 22px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .menu-button {
        bottom: 11px; } }
    @media (max-height: 1000px) {
      .preview.ios .menu-button {
        height: 42px; } }
    @media (max-width: 1500px) {
      .preview.ios .menu-button {
        height: 42px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .menu-button {
        height: 37.296px; } }
    @media (max-width: 1200px) {
      .preview.ios .menu-button {
        height: 42px; } }
    @media (max-width: 800px) {
      .preview.ios .menu-button {
        height: 56px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .menu-button {
        height: 28px; } }
    @media (max-height: 1000px) {
      .preview.ios .menu-button {
        width: 42px; } }
    @media (max-width: 1500px) {
      .preview.ios .menu-button {
        width: 42px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .menu-button {
        width: 37.296px; } }
    @media (max-width: 1200px) {
      .preview.ios .menu-button {
        width: 42px; } }
    @media (max-width: 800px) {
      .preview.ios .menu-button {
        width: 56px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .menu-button {
        width: 28px; } }
    @media (max-height: 1000px) {
      .preview.ios .menu-button {
        left: 117px; } }
    @media (max-width: 1500px) {
      .preview.ios .menu-button {
        left: 117px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.ios .menu-button {
        left: 103.896px; } }
    @media (max-width: 1200px) {
      .preview.ios .menu-button {
        left: 117px; } }
    @media (max-width: 800px) {
      .preview.ios .menu-button {
        left: 156px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.ios .menu-button {
        left: 78px; } }

.preview.android {
  background-image: url("../images/preview/galaxy_s6_outline.svg"); }
  .preview.android * {
    font-family: 'Roboto', sans-serif; }
  .preview.android .title-bar {
    height: 72px;
    text-align: left; }
    @media (max-height: 1000px) {
      .preview.android .title-bar {
        height: 54px; } }
    @media (max-width: 1500px) {
      .preview.android .title-bar {
        height: 54px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .title-bar {
        height: 47.952px; } }
    @media (max-width: 1200px) {
      .preview.android .title-bar {
        height: 54px; } }
    @media (max-width: 800px) {
      .preview.android .title-bar {
        height: 72px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .title-bar {
        height: 36px; } }
    .preview.android .title-bar.light {
      background-image: url("../images/preview/android_status_bar_black.svg"); }
    .preview.android .title-bar.dark {
      background-image: url("../images/preview/android_status_bar_white.svg"); }
    .preview.android .title-bar .time {
      height: 24px;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      justify-content: flex-end;
      padding-right: 6px; }
      @media (max-height: 1000px) {
        .preview.android .title-bar .time {
          height: 18px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar .time {
          height: 18px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar .time {
          height: 15.984px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar .time {
          height: 18px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar .time {
          height: 24px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar .time {
          height: 12px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar .time {
          font-size: 11.25px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar .time {
          font-size: 11.25px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar .time {
          font-size: 9.99px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar .time {
          font-size: 11.25px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar .time {
          font-size: 15px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar .time {
          font-size: 7.5px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar .time {
          line-height: 18px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar .time {
          line-height: 18px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar .time {
          line-height: 15.984px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar .time {
          line-height: 18px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar .time {
          line-height: 24px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar .time {
          line-height: 12px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar .time {
          padding-right: 4.5px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar .time {
          padding-right: 4.5px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar .time {
          padding-right: 3.996px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar .time {
          padding-right: 4.5px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar .time {
          padding-right: 6px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar .time {
          padding-right: 3px; } }
    .preview.android .title-bar h1 {
      font-weight: 600;
      margin-left: 15px;
      margin-right: 44px;
      line-height: 48px;
      font-size: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-height: 1000px) {
        .preview.android .title-bar h1 {
          margin-left: 11.25px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar h1 {
          margin-left: 11.25px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar h1 {
          margin-left: 9.99px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar h1 {
          margin-left: 11.25px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar h1 {
          margin-left: 15px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar h1 {
          margin-left: 7.5px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar h1 {
          margin-right: 33px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar h1 {
          margin-right: 33px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar h1 {
          margin-right: 29.304px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar h1 {
          margin-right: 33px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar h1 {
          margin-right: 44px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar h1 {
          margin-right: 22px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar h1 {
          line-height: 36px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar h1 {
          line-height: 36px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar h1 {
          line-height: 31.968px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar h1 {
          line-height: 36px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar h1 {
          line-height: 48px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar h1 {
          line-height: 24px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar h1 {
          font-size: 12.75px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar h1 {
          font-size: 12.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar h1 {
          font-size: 11.322px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar h1 {
          font-size: 12.75px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar h1 {
          font-size: 17px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar h1 {
          font-size: 8.5px; } }
    .preview.android .title-bar .right, .preview.android .title-bar .row-layer {
      right: 2px; }
    .preview.android .title-bar .titlebar-label {
      display: none; }
    .preview.android .title-bar [class^='asset-'] {
      font-weight: bold; }
    .preview.android .title-bar .titlebar-action {
      top: 26px;
      font-size: 44px; }
      @media (max-height: 1000px) {
        .preview.android .title-bar .titlebar-action {
          top: 19.5px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar .titlebar-action {
          top: 19.5px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar .titlebar-action {
          top: 17.316px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar .titlebar-action {
          top: 19.5px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar .titlebar-action {
          top: 26px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar .titlebar-action {
          top: 13px; } }
      @media (max-height: 1000px) {
        .preview.android .title-bar .titlebar-action {
          font-size: 33px; } }
      @media (max-width: 1500px) {
        .preview.android .title-bar .titlebar-action {
          font-size: 33px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .title-bar .titlebar-action {
          font-size: 29.304px; } }
      @media (max-width: 1200px) {
        .preview.android .title-bar .titlebar-action {
          font-size: 33px; } }
      @media (max-width: 800px) {
        .preview.android .title-bar .titlebar-action {
          font-size: 44px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .title-bar .titlebar-action {
          font-size: 22px; } }
      .preview.android .title-bar .titlebar-action + h1 {
        margin-left: 44px; }
        @media (max-height: 1000px) {
          .preview.android .title-bar .titlebar-action + h1 {
            margin-left: 33px; } }
        @media (max-width: 1500px) {
          .preview.android .title-bar .titlebar-action + h1 {
            margin-left: 33px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .preview.android .title-bar .titlebar-action + h1 {
            margin-left: 29.304px; } }
        @media (max-width: 1200px) {
          .preview.android .title-bar .titlebar-action + h1 {
            margin-left: 33px; } }
        @media (max-width: 800px) {
          .preview.android .title-bar .titlebar-action + h1 {
            margin-left: 44px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .preview.android .title-bar .titlebar-action + h1 {
            margin-left: 22px; } }
  .preview.android .tab-bar {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    height: 48px;
    z-index: 20;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); }
    @media (max-height: 1000px) {
      .preview.android .tab-bar {
        top: 54px; } }
    @media (max-width: 1500px) {
      .preview.android .tab-bar {
        top: 54px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .tab-bar {
        top: 47.952px; } }
    @media (max-width: 1200px) {
      .preview.android .tab-bar {
        top: 54px; } }
    @media (max-width: 800px) {
      .preview.android .tab-bar {
        top: 72px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .tab-bar {
        top: 36px; } }
    @media (max-height: 1000px) {
      .preview.android .tab-bar {
        height: 36px; } }
    @media (max-width: 1500px) {
      .preview.android .tab-bar {
        height: 36px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .tab-bar {
        height: 31.968px; } }
    @media (max-width: 1200px) {
      .preview.android .tab-bar {
        height: 36px; } }
    @media (max-width: 800px) {
      .preview.android .tab-bar {
        height: 48px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .tab-bar {
        height: 24px; } }
    .preview.android .tab-bar ul {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      list-style: none;
      font-size: 12px;
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      text-align: center; }
      @media (max-height: 1000px) {
        .preview.android .tab-bar ul {
          font-size: 9px; } }
      @media (max-width: 1500px) {
        .preview.android .tab-bar ul {
          font-size: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .tab-bar ul {
          font-size: 7.992px; } }
      @media (max-width: 1200px) {
        .preview.android .tab-bar ul {
          font-size: 9px; } }
      @media (max-width: 800px) {
        .preview.android .tab-bar ul {
          font-size: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .tab-bar ul {
          font-size: 6px; } }
      .preview.android .tab-bar ul li {
        position: relative;
        display: inline-block;
        height: 100%;
        min-width: 90px; }
        @media (max-height: 1000px) {
          .preview.android .tab-bar ul li {
            min-width: 67.5px; } }
        @media (max-width: 1500px) {
          .preview.android .tab-bar ul li {
            min-width: 67.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .preview.android .tab-bar ul li {
            min-width: 59.94px; } }
        @media (max-width: 1200px) {
          .preview.android .tab-bar ul li {
            min-width: 67.5px; } }
        @media (max-width: 800px) {
          .preview.android .tab-bar ul li {
            min-width: 90px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .preview.android .tab-bar ul li {
            min-width: 45px; } }
        .preview.android .tab-bar ul li > .icon {
          display: none; }
        .preview.android .tab-bar ul li a {
          color: white !important;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 48px;
          padding-left: 12px;
          padding-right: 12px; }
          @media (max-height: 1000px) {
            .preview.android .tab-bar ul li a {
              line-height: 36px; } }
          @media (max-width: 1500px) {
            .preview.android .tab-bar ul li a {
              line-height: 36px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .preview.android .tab-bar ul li a {
              line-height: 31.968px; } }
          @media (max-width: 1200px) {
            .preview.android .tab-bar ul li a {
              line-height: 36px; } }
          @media (max-width: 800px) {
            .preview.android .tab-bar ul li a {
              line-height: 48px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .preview.android .tab-bar ul li a {
              line-height: 24px; } }
          @media (max-height: 1000px) {
            .preview.android .tab-bar ul li a {
              padding-left: 9px; } }
          @media (max-width: 1500px) {
            .preview.android .tab-bar ul li a {
              padding-left: 9px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .preview.android .tab-bar ul li a {
              padding-left: 7.992px; } }
          @media (max-width: 1200px) {
            .preview.android .tab-bar ul li a {
              padding-left: 9px; } }
          @media (max-width: 800px) {
            .preview.android .tab-bar ul li a {
              padding-left: 12px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .preview.android .tab-bar ul li a {
              padding-left: 6px; } }
          @media (max-height: 1000px) {
            .preview.android .tab-bar ul li a {
              padding-right: 9px; } }
          @media (max-width: 1500px) {
            .preview.android .tab-bar ul li a {
              padding-right: 9px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .preview.android .tab-bar ul li a {
              padding-right: 7.992px; } }
          @media (max-width: 1200px) {
            .preview.android .tab-bar ul li a {
              padding-right: 9px; } }
          @media (max-width: 800px) {
            .preview.android .tab-bar ul li a {
              padding-right: 12px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .preview.android .tab-bar ul li a {
              padding-right: 6px; } }
        .preview.android .tab-bar ul li.active {
          position: relative;
          border-bottom: 3px solid #ffffff; }
  .preview.android .content {
    bottom: 0px; }
    .preview.android .content.side {
      top: 72px; }
      @media (max-height: 1000px) {
        .preview.android .content.side {
          top: 54px; } }
      @media (max-width: 1500px) {
        .preview.android .content.side {
          top: 54px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .content.side {
          top: 47.952px; } }
      @media (max-width: 1200px) {
        .preview.android .content.side {
          top: 54px; } }
      @media (max-width: 800px) {
        .preview.android .content.side {
          top: 72px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .content.side {
          top: 36px; } }
    .preview.android .content.tabbed {
      top: 120px; }
      @media (max-height: 1000px) {
        .preview.android .content.tabbed {
          top: 90px; } }
      @media (max-width: 1500px) {
        .preview.android .content.tabbed {
          top: 90px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .content.tabbed {
          top: 79.92px; } }
      @media (max-width: 1200px) {
        .preview.android .content.tabbed {
          top: 90px; } }
      @media (max-width: 800px) {
        .preview.android .content.tabbed {
          top: 120px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .content.tabbed {
          top: 60px; } }
  .preview.android .side-nav {
    width: 48px; }
    @media (max-height: 1000px) {
      .preview.android .side-nav {
        width: 36px; } }
    @media (max-width: 1500px) {
      .preview.android .side-nav {
        width: 36px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .side-nav {
        width: 31.968px; } }
    @media (max-width: 1200px) {
      .preview.android .side-nav {
        width: 36px; } }
    @media (max-width: 800px) {
      .preview.android .side-nav {
        width: 48px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .side-nav {
        width: 24px; } }
    .preview.android .side-nav:before {
      right: 48px; }
      @media (max-height: 1000px) {
        .preview.android .side-nav:before {
          right: 36px; } }
      @media (max-width: 1500px) {
        .preview.android .side-nav:before {
          right: 36px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .side-nav:before {
          right: 31.968px; } }
      @media (max-width: 1200px) {
        .preview.android .side-nav:before {
          right: 36px; } }
      @media (max-width: 800px) {
        .preview.android .side-nav:before {
          right: 48px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .side-nav:before {
          right: 24px; } }
    .preview.android .side-nav .white-fill {
      top: 72px; }
      @media (max-height: 1000px) {
        .preview.android .side-nav .white-fill {
          top: 54px; } }
      @media (max-width: 1500px) {
        .preview.android .side-nav .white-fill {
          top: 54px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .preview.android .side-nav .white-fill {
          top: 47.952px; } }
      @media (max-width: 1200px) {
        .preview.android .side-nav .white-fill {
          top: 54px; } }
      @media (max-width: 800px) {
        .preview.android .side-nav .white-fill {
          top: 72px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .preview.android .side-nav .white-fill {
          top: 36px; } }
  .preview.android .menu-button {
    position: absolute;
    bottom: 38px;
    height: 34px;
    width: 80px;
    left: 144px;
    border-radius: 24px; }
    @media (max-height: 1000px) {
      .preview.android .menu-button {
        bottom: 28.5px; } }
    @media (max-width: 1500px) {
      .preview.android .menu-button {
        bottom: 28.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .menu-button {
        bottom: 25.308px; } }
    @media (max-width: 1200px) {
      .preview.android .menu-button {
        bottom: 28.5px; } }
    @media (max-width: 800px) {
      .preview.android .menu-button {
        bottom: 38px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .menu-button {
        bottom: 19px; } }
    @media (max-height: 1000px) {
      .preview.android .menu-button {
        height: 25.5px; } }
    @media (max-width: 1500px) {
      .preview.android .menu-button {
        height: 25.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .menu-button {
        height: 22.644px; } }
    @media (max-width: 1200px) {
      .preview.android .menu-button {
        height: 25.5px; } }
    @media (max-width: 800px) {
      .preview.android .menu-button {
        height: 34px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .menu-button {
        height: 17px; } }
    @media (max-height: 1000px) {
      .preview.android .menu-button {
        width: 60px; } }
    @media (max-width: 1500px) {
      .preview.android .menu-button {
        width: 60px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .menu-button {
        width: 53.28px; } }
    @media (max-width: 1200px) {
      .preview.android .menu-button {
        width: 60px; } }
    @media (max-width: 800px) {
      .preview.android .menu-button {
        width: 80px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .menu-button {
        width: 40px; } }
    @media (max-height: 1000px) {
      .preview.android .menu-button {
        left: 108px; } }
    @media (max-width: 1500px) {
      .preview.android .menu-button {
        left: 108px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .preview.android .menu-button {
        left: 95.904px; } }
    @media (max-width: 1200px) {
      .preview.android .menu-button {
        left: 108px; } }
    @media (max-width: 800px) {
      .preview.android .menu-button {
        left: 144px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .preview.android .menu-button {
        left: 72px; } }

[class^='list-'] {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 1px; }
  @media (max-height: 1000px) {
    [class^='list-'] {
      margin-left: 11.25px; } }
  @media (max-width: 1500px) {
    [class^='list-'] {
      margin-left: 11.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    [class^='list-'] {
      margin-left: 9.99px; } }
  @media (max-width: 1200px) {
    [class^='list-'] {
      margin-left: 11.25px; } }
  @media (max-width: 800px) {
    [class^='list-'] {
      margin-left: 15px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    [class^='list-'] {
      margin-left: 7.5px; } }
  [class^='list-'] .border {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    box-shadow: 0 1px 0 #d9d9d9; }
  [class^='list-']:last-child {
    margin-bottom: 0px; }
  [class^='list-'] .label {
    flex: 1;
    color: black;
    font-size: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-height: 1000px) {
      [class^='list-'] .label {
        font-size: 12.75px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .label {
        font-size: 12.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .label {
        font-size: 11.322px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .label {
        font-size: 12.75px; } }
    @media (max-width: 800px) {
      [class^='list-'] .label {
        font-size: 17px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .label {
        font-size: 8.5px; } }
    [class^='list-'] .label.double span, [class^='list-'] .label .sublabel {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: pre-wrap; }
    [class^='list-'] .label .sublabel {
      color: gray;
      margin-top: 1px;
      font-size: 12px; }
      @media (max-height: 1000px) {
        [class^='list-'] .label .sublabel {
          font-size: 9px; } }
      @media (max-width: 1500px) {
        [class^='list-'] .label .sublabel {
          font-size: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='list-'] .label .sublabel {
          font-size: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='list-'] .label .sublabel {
          font-size: 9px; } }
      @media (max-width: 800px) {
        [class^='list-'] .label .sublabel {
          font-size: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='list-'] .label .sublabel {
          font-size: 6px; } }
      [class^='list-'] .label .sublabel.single {
        -webkit-line-clamp: 1; }
  [class^='list-'] .icon {
    font-size: 24px;
    width: 29px;
    margin-right: 10px; }
    @media (max-height: 1000px) {
      [class^='list-'] .icon {
        font-size: 18px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .icon {
        font-size: 18px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .icon {
        font-size: 15.984px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .icon {
        font-size: 18px; } }
    @media (max-width: 800px) {
      [class^='list-'] .icon {
        font-size: 24px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .icon {
        font-size: 12px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .icon {
        width: 21.75px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .icon {
        width: 21.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .icon {
        width: 19.314px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .icon {
        width: 21.75px; } }
    @media (max-width: 800px) {
      [class^='list-'] .icon {
        width: 29px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .icon {
        width: 14.5px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .icon {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .icon {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .icon {
        margin-right: 6.66px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .icon {
        margin-right: 7.5px; } }
    @media (max-width: 800px) {
      [class^='list-'] .icon {
        margin-right: 10px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .icon {
        margin-right: 5px; } }
  [class^='list-'] .thumb-square {
    width: 60px;
    height: 60px;
    margin-right: 10px; }
    @media (max-height: 1000px) {
      [class^='list-'] .thumb-square {
        width: 45px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .thumb-square {
        width: 45px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .thumb-square {
        width: 39.96px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .thumb-square {
        width: 45px; } }
    @media (max-width: 800px) {
      [class^='list-'] .thumb-square {
        width: 60px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .thumb-square {
        width: 30px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .thumb-square {
        height: 45px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .thumb-square {
        height: 45px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .thumb-square {
        height: 39.96px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .thumb-square {
        height: 45px; } }
    @media (max-width: 800px) {
      [class^='list-'] .thumb-square {
        height: 60px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .thumb-square {
        height: 30px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .thumb-square {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .thumb-square {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .thumb-square {
        margin-right: 6.66px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .thumb-square {
        margin-right: 7.5px; } }
    @media (max-width: 800px) {
      [class^='list-'] .thumb-square {
        margin-right: 10px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .thumb-square {
        margin-right: 5px; } }
  [class^='list-'] .thumb-wide {
    width: 140px;
    height: 78px;
    margin-right: 10px; }
    @media (max-height: 1000px) {
      [class^='list-'] .thumb-wide {
        width: 105px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .thumb-wide {
        width: 105px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .thumb-wide {
        width: 93.24px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .thumb-wide {
        width: 105px; } }
    @media (max-width: 800px) {
      [class^='list-'] .thumb-wide {
        width: 140px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .thumb-wide {
        width: 70px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .thumb-wide {
        height: 58.5px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .thumb-wide {
        height: 58.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .thumb-wide {
        height: 51.948px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .thumb-wide {
        height: 58.5px; } }
    @media (max-width: 800px) {
      [class^='list-'] .thumb-wide {
        height: 78px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .thumb-wide {
        height: 39px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .thumb-wide {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .thumb-wide {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .thumb-wide {
        margin-right: 6.66px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .thumb-wide {
        margin-right: 7.5px; } }
    @media (max-width: 800px) {
      [class^='list-'] .thumb-wide {
        margin-right: 10px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .thumb-wide {
        margin-right: 5px; } }
  [class^='list-'] .tidbit {
    color: gray;
    font-size: 12px;
    margin-top: 2px;
    width: 60px;
    text-align: right; }
    @media (max-height: 1000px) {
      [class^='list-'] .tidbit {
        font-size: 9px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .tidbit {
        font-size: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .tidbit {
        font-size: 7.992px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .tidbit {
        font-size: 9px; } }
    @media (max-width: 800px) {
      [class^='list-'] .tidbit {
        font-size: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .tidbit {
        font-size: 6px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .tidbit {
        margin-top: 0; } }
    @media (max-width: 1500px) {
      [class^='list-'] .tidbit {
        margin-top: 0; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .tidbit {
        margin-top: 0; } }
    @media (max-width: 1200px) {
      [class^='list-'] .tidbit {
        margin-top: 0; } }
    @media (max-width: 800px) {
      [class^='list-'] .tidbit {
        margin-top: 2px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .tidbit {
        width: 45px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .tidbit {
        width: 45px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .tidbit {
        width: 39.96px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .tidbit {
        width: 45px; } }
    @media (max-width: 800px) {
      [class^='list-'] .tidbit {
        width: 60px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .tidbit {
        width: 30px; } }
  [class^='list-'] .disclosure {
    margin-right: 15px;
    margin-left: 6px;
    width: 9px; }
    @media (max-height: 1000px) {
      [class^='list-'] .disclosure {
        margin-right: 11.25px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .disclosure {
        margin-right: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .disclosure {
        margin-right: 9.99px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .disclosure {
        margin-right: 11.25px; } }
    @media (max-width: 800px) {
      [class^='list-'] .disclosure {
        margin-right: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .disclosure {
        margin-right: 7.5px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .disclosure {
        margin-left: 4.5px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .disclosure {
        margin-left: 4.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .disclosure {
        margin-left: 3.996px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .disclosure {
        margin-left: 4.5px; } }
    @media (max-width: 800px) {
      [class^='list-'] .disclosure {
        margin-left: 6px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .disclosure {
        margin-left: 3px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .disclosure {
        width: 6.75px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .disclosure {
        width: 6.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .disclosure {
        width: 5.994px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .disclosure {
        width: 6.75px; } }
    @media (max-width: 800px) {
      [class^='list-'] .disclosure {
        width: 9px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .disclosure {
        width: 4.5px; } }
    [class^='list-'] .disclosure > img {
      display: block;
      height: auto !important;
      width: 100% !important; }
  [class^='list-'] .indicator {
    margin-right: 9px;
    margin-left: 9px;
    width: 12px;
    height: 12px;
    border-radius: 50%; }
    @media (max-height: 1000px) {
      [class^='list-'] .indicator {
        margin-right: 6.75px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .indicator {
        margin-right: 6.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .indicator {
        margin-right: 5.994px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .indicator {
        margin-right: 6.75px; } }
    @media (max-width: 800px) {
      [class^='list-'] .indicator {
        margin-right: 9px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .indicator {
        margin-right: 4.5px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .indicator {
        margin-left: 6.75px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .indicator {
        margin-left: 6.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .indicator {
        margin-left: 5.994px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .indicator {
        margin-left: 6.75px; } }
    @media (max-width: 800px) {
      [class^='list-'] .indicator {
        margin-left: 9px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .indicator {
        margin-left: 4.5px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .indicator {
        width: 9px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .indicator {
        width: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .indicator {
        width: 7.992px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .indicator {
        width: 9px; } }
    @media (max-width: 800px) {
      [class^='list-'] .indicator {
        width: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .indicator {
        width: 6px; } }
    @media (max-height: 1000px) {
      [class^='list-'] .indicator {
        height: 9px; } }
    @media (max-width: 1500px) {
      [class^='list-'] .indicator {
        height: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='list-'] .indicator {
        height: 7.992px; } }
    @media (max-width: 1200px) {
      [class^='list-'] .indicator {
        height: 9px; } }
    @media (max-width: 800px) {
      [class^='list-'] .indicator {
        height: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='list-'] .indicator {
        height: 6px; } }
    [class^='list-'] .indicator.unread {
      background-color: #1173BC; }

.list-44dp {
  height: 44px; }
  @media (max-height: 1000px) {
    .list-44dp {
      height: 33px; } }
  @media (max-width: 1500px) {
    .list-44dp {
      height: 33px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .list-44dp {
      height: 29.304px; } }
  @media (max-width: 1200px) {
    .list-44dp {
      height: 33px; } }
  @media (max-width: 800px) {
    .list-44dp {
      height: 44px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .list-44dp {
      height: 22px; } }

.list-72dp {
  height: 72px; }
  @media (max-height: 1000px) {
    .list-72dp {
      height: 54px; } }
  @media (max-width: 1500px) {
    .list-72dp {
      height: 54px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .list-72dp {
      height: 47.952px; } }
  @media (max-width: 1200px) {
    .list-72dp {
      height: 54px; } }
  @media (max-width: 800px) {
    .list-72dp {
      height: 72px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .list-72dp {
      height: 36px; } }
  .list-72dp.indicator {
    margin-left: 0px; }

.list-90dp {
  height: 90px; }
  @media (max-height: 1000px) {
    .list-90dp {
      height: 67.5px; } }
  @media (max-width: 1500px) {
    .list-90dp {
      height: 67.5px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .list-90dp {
      height: 59.94px; } }
  @media (max-width: 1200px) {
    .list-90dp {
      height: 67.5px; } }
  @media (max-width: 800px) {
    .list-90dp {
      height: 90px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .list-90dp {
      height: 45px; } }

.list-90dp-section {
  height: 90px;
  box-shadow: 0 1px 0 #d9d9d9;
  margin-left: 0px;
  padding-left: 15px; }
  @media (max-height: 1000px) {
    .list-90dp-section {
      height: 67.5px; } }
  @media (max-width: 1500px) {
    .list-90dp-section {
      height: 67.5px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .list-90dp-section {
      height: 59.94px; } }
  @media (max-width: 1200px) {
    .list-90dp-section {
      height: 67.5px; } }
  @media (max-width: 800px) {
    .list-90dp-section {
      height: 90px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .list-90dp-section {
      height: 45px; } }
  @media (max-height: 1000px) {
    .list-90dp-section {
      padding-left: 11.25px; } }
  @media (max-width: 1500px) {
    .list-90dp-section {
      padding-left: 11.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .list-90dp-section {
      padding-left: 9.99px; } }
  @media (max-width: 1200px) {
    .list-90dp-section {
      padding-left: 11.25px; } }
  @media (max-width: 800px) {
    .list-90dp-section {
      padding-left: 15px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .list-90dp-section {
      padding-left: 7.5px; } }
  .list-90dp-section .thumb {
    width: 65px;
    height: 60px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    font-size: 15px; }
    @media (max-height: 1000px) {
      .list-90dp-section .thumb {
        width: 48.75px; } }
    @media (max-width: 1500px) {
      .list-90dp-section .thumb {
        width: 48.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section .thumb {
        width: 43.29px; } }
    @media (max-width: 1200px) {
      .list-90dp-section .thumb {
        width: 48.75px; } }
    @media (max-width: 800px) {
      .list-90dp-section .thumb {
        width: 65px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section .thumb {
        width: 32.5px; } }
    @media (max-height: 1000px) {
      .list-90dp-section .thumb {
        height: 45px; } }
    @media (max-width: 1500px) {
      .list-90dp-section .thumb {
        height: 45px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section .thumb {
        height: 39.96px; } }
    @media (max-width: 1200px) {
      .list-90dp-section .thumb {
        height: 45px; } }
    @media (max-width: 800px) {
      .list-90dp-section .thumb {
        height: 60px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section .thumb {
        height: 30px; } }
    @media (max-height: 1000px) {
      .list-90dp-section .thumb {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) {
      .list-90dp-section .thumb {
        margin-right: 7.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section .thumb {
        margin-right: 6.66px; } }
    @media (max-width: 1200px) {
      .list-90dp-section .thumb {
        margin-right: 7.5px; } }
    @media (max-width: 800px) {
      .list-90dp-section .thumb {
        margin-right: 10px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section .thumb {
        margin-right: 5px; } }
    @media (max-height: 1000px) {
      .list-90dp-section .thumb {
        font-size: 11.25px; } }
    @media (max-width: 1500px) {
      .list-90dp-section .thumb {
        font-size: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section .thumb {
        font-size: 9.99px; } }
    @media (max-width: 1200px) {
      .list-90dp-section .thumb {
        font-size: 11.25px; } }
    @media (max-width: 800px) {
      .list-90dp-section .thumb {
        font-size: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section .thumb {
        font-size: 7.5px; } }
  .list-90dp-section.header {
    display: flex;
    justify-content: space-between;
    height: 22px;
    color: gray;
    font-size: 15px;
    margin: 0px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 -1px 0 #d9d9d9, 0 1px 0 #d9d9d9; }
    @media (max-height: 1000px) {
      .list-90dp-section.header {
        height: 16.5px; } }
    @media (max-width: 1500px) {
      .list-90dp-section.header {
        height: 16.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section.header {
        height: 14.652px; } }
    @media (max-width: 1200px) {
      .list-90dp-section.header {
        height: 16.5px; } }
    @media (max-width: 800px) {
      .list-90dp-section.header {
        height: 22px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section.header {
        height: 11px; } }
    @media (max-height: 1000px) {
      .list-90dp-section.header {
        font-size: 11.25px; } }
    @media (max-width: 1500px) {
      .list-90dp-section.header {
        font-size: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section.header {
        font-size: 9.99px; } }
    @media (max-width: 1200px) {
      .list-90dp-section.header {
        font-size: 11.25px; } }
    @media (max-width: 800px) {
      .list-90dp-section.header {
        font-size: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section.header {
        font-size: 7.5px; } }
    @media (max-height: 1000px) {
      .list-90dp-section.header {
        padding-left: 11.25px; } }
    @media (max-width: 1500px) {
      .list-90dp-section.header {
        padding-left: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section.header {
        padding-left: 9.99px; } }
    @media (max-width: 1200px) {
      .list-90dp-section.header {
        padding-left: 11.25px; } }
    @media (max-width: 800px) {
      .list-90dp-section.header {
        padding-left: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section.header {
        padding-left: 7.5px; } }
    @media (max-height: 1000px) {
      .list-90dp-section.header {
        padding-right: 11.25px; } }
    @media (max-width: 1500px) {
      .list-90dp-section.header {
        padding-right: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-90dp-section.header {
        padding-right: 9.99px; } }
    @media (max-width: 1200px) {
      .list-90dp-section.header {
        padding-right: 11.25px; } }
    @media (max-width: 800px) {
      .list-90dp-section.header {
        padding-right: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-90dp-section.header {
        padding-right: 7.5px; } }

.list-132dp {
  position: relative;
  margin: 0;
  height: 132px; }
  @media (max-height: 1000px) {
    .list-132dp {
      height: 99px; } }
  @media (max-width: 1500px) {
    .list-132dp {
      height: 99px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .list-132dp {
      height: 87.912px; } }
  @media (max-width: 1200px) {
    .list-132dp {
      height: 99px; } }
  @media (max-width: 800px) {
    .list-132dp {
      height: 132px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .list-132dp {
      height: 66px; } }
  .list-132dp > img {
    height: 132px;
    width: 320px; }
    @media (max-height: 1000px) {
      .list-132dp > img {
        height: 99px; } }
    @media (max-width: 1500px) {
      .list-132dp > img {
        height: 99px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp > img {
        height: 87.912px; } }
    @media (max-width: 1200px) {
      .list-132dp > img {
        height: 99px; } }
    @media (max-width: 800px) {
      .list-132dp > img {
        height: 132px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp > img {
        height: 66px; } }
    @media (max-height: 1000px) {
      .list-132dp > img {
        width: 240px; } }
    @media (max-width: 1500px) {
      .list-132dp > img {
        width: 240px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp > img {
        width: 213.12px; } }
    @media (max-width: 1200px) {
      .list-132dp > img {
        width: 240px; } }
    @media (max-width: 800px) {
      .list-132dp > img {
        width: 320px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp > img {
        width: 160px; } }
  .list-132dp .photo-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 132px;
    width: 320px;
    background: 100% 100% url("../images/preview/photo_overlay.png"); }
    @media (max-height: 1000px) {
      .list-132dp .photo-overlay {
        height: 99px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-overlay {
        height: 99px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-overlay {
        height: 87.912px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-overlay {
        height: 99px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-overlay {
        height: 132px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-overlay {
        height: 66px; } }
    @media (max-height: 1000px) {
      .list-132dp .photo-overlay {
        width: 240px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-overlay {
        width: 240px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-overlay {
        width: 213.12px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-overlay {
        width: 240px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-overlay {
        width: 320px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-overlay {
        width: 160px; } }
  .list-132dp .photo-label {
    position: absolute;
    color: white;
    font-size: 24px;
    padding-left: 15px;
    padding-right: 15px;
    bottom: 15px;
    left: 0px;
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    @media (max-height: 1000px) {
      .list-132dp .photo-label {
        font-size: 18px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-label {
        font-size: 18px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-label {
        font-size: 15.984px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-label {
        font-size: 18px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-label {
        font-size: 24px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-label {
        font-size: 12px; } }
    @media (max-height: 1000px) {
      .list-132dp .photo-label {
        padding-left: 11.25px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-label {
        padding-left: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-label {
        padding-left: 9.99px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-label {
        padding-left: 11.25px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-label {
        padding-left: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-label {
        padding-left: 7.5px; } }
    @media (max-height: 1000px) {
      .list-132dp .photo-label {
        padding-right: 11.25px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-label {
        padding-right: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-label {
        padding-right: 9.99px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-label {
        padding-right: 11.25px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-label {
        padding-right: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-label {
        padding-right: 7.5px; } }
    @media (max-height: 1000px) {
      .list-132dp .photo-label {
        bottom: 11.25px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-label {
        bottom: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-label {
        bottom: 9.99px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-label {
        bottom: 11.25px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-label {
        bottom: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-label {
        bottom: 7.5px; } }
    @media (max-height: 1000px) {
      .list-132dp .photo-label {
        width: 240px; } }
    @media (max-width: 1500px) {
      .list-132dp .photo-label {
        width: 240px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list-132dp .photo-label {
        width: 213.12px; } }
    @media (max-width: 1200px) {
      .list-132dp .photo-label {
        width: 240px; } }
    @media (max-width: 800px) {
      .list-132dp .photo-label {
        width: 320px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .list-132dp .photo-label {
        width: 160px; } }

[class^='detail-'] {
  display: flex;
  flex-direction: column; }
  [class^='detail-'] .header {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 #d9d9d9;
    margin-bottom: 1px; }
    [class^='detail-'] .header .title {
      color: black;
      margin-left: 15px;
      margin-right: 15px;
      font-size: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1; }
      @media (max-height: 1000px) {
        [class^='detail-'] .header .title {
          margin-left: 11.25px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .header .title {
          margin-left: 11.25px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .header .title {
          margin-left: 9.99px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .header .title {
          margin-left: 11.25px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .header .title {
          margin-left: 15px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .header .title {
          margin-left: 7.5px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .header .title {
          margin-right: 11.25px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .header .title {
          margin-right: 11.25px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .header .title {
          margin-right: 9.99px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .header .title {
          margin-right: 11.25px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .header .title {
          margin-right: 15px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .header .title {
          margin-right: 7.5px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .header .title {
          font-size: 16.5px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .header .title {
          font-size: 16.5px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .header .title {
          font-size: 14.652px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .header .title {
          font-size: 16.5px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .header .title {
          font-size: 22px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .header .title {
          font-size: 11px; } }
      [class^='detail-'] .header .title.double span, [class^='detail-'] .header .title.triple span, [class^='detail-'] .header .title.quad span, [class^='detail-'] .header .title .subtitle {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap; }
      [class^='detail-'] .header .title.triple span {
        -webkit-line-clamp: 3; }
      [class^='detail-'] .header .title.quad {
        -webkit-line-clamp: 4; }
    [class^='detail-'] .header .subtitle {
      font-size: 17px;
      color: gray;
      margin-top: 4px; }
      @media (max-height: 1000px) {
        [class^='detail-'] .header .subtitle {
          font-size: 12.75px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .header .subtitle {
          font-size: 12.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .header .subtitle {
          font-size: 11.322px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .header .subtitle {
          font-size: 12.75px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .header .subtitle {
          font-size: 17px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .header .subtitle {
          font-size: 8.5px; } }
  [class^='detail-'] .actionbar {
    display: flex;
    justify-content: center;
    height: 72px;
    box-shadow: 0 1px 0 #d9d9d9;
    margin-bottom: 1px; }
    @media (max-height: 1000px) {
      [class^='detail-'] .actionbar {
        height: 54px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .actionbar {
        height: 54px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .actionbar {
        height: 47.952px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .actionbar {
        height: 54px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .actionbar {
        height: 72px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .actionbar {
        height: 36px; } }
    [class^='detail-'] .actionbar.collapsed {
      display: none; }
    [class^='detail-'] .actionbar.fixed-bottom {
      background: white;
      width: 320px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 -1px 0 #d9d9d9;
      margin-bottom: 0; }
      @media (max-height: 1000px) {
        [class^='detail-'] .actionbar.fixed-bottom {
          width: 240px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .actionbar.fixed-bottom {
          width: 240px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .actionbar.fixed-bottom {
          width: 213.12px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .actionbar.fixed-bottom {
          width: 240px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .actionbar.fixed-bottom {
          width: 320px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .actionbar.fixed-bottom {
          width: 160px; } }
      [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
        margin-bottom: 72px; }
        @media (max-height: 1000px) {
          [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
            margin-bottom: 54px; } }
        @media (max-width: 1500px) {
          [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
            margin-bottom: 54px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
            margin-bottom: 47.952px; } }
        @media (max-width: 1200px) {
          [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
            margin-bottom: 54px; } }
        @media (max-width: 800px) {
          [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
            margin-bottom: 72px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          [class^='detail-'] .actionbar.fixed-bottom:not(.collapsed) ~ .scroll-view {
            margin-bottom: 36px; } }
    [class^='detail-'] .actionbar > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px; }
      @media (max-height: 1000px) {
        [class^='detail-'] .actionbar > div {
          padding-top: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .actionbar > div {
          padding-top: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .actionbar > div {
          padding-top: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .actionbar > div {
          padding-top: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .actionbar > div {
          padding-top: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .actionbar > div {
          padding-top: 6px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .actionbar > div {
          padding-bottom: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .actionbar > div {
          padding-bottom: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .actionbar > div {
          padding-bottom: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .actionbar > div {
          padding-bottom: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .actionbar > div {
          padding-bottom: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .actionbar > div {
          padding-bottom: 6px; } }
    [class^='detail-'] .actionbar > div + div {
      border-left: 1px solid #d9d9d9; }
    [class^='detail-'] .actionbar .action-icon {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 24px; }
      @media (max-height: 1000px) {
        [class^='detail-'] .actionbar .action-icon {
          font-size: 18px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .actionbar .action-icon {
          font-size: 18px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .actionbar .action-icon {
          font-size: 15.984px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .actionbar .action-icon {
          font-size: 18px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .actionbar .action-icon {
          font-size: 24px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .actionbar .action-icon {
          font-size: 12px; } }
    [class^='detail-'] .actionbar .action-label {
      text-align: center;
      padding-left: 12px;
      padding-right: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-height: 1000px) {
        [class^='detail-'] .actionbar .action-label {
          padding-left: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .actionbar .action-label {
          padding-left: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .actionbar .action-label {
          padding-left: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .actionbar .action-label {
          padding-left: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .actionbar .action-label {
          padding-left: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .actionbar .action-label {
          padding-left: 6px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .actionbar .action-label {
          padding-right: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .actionbar .action-label {
          padding-right: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .actionbar .action-label {
          padding-right: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .actionbar .action-label {
          padding-right: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .actionbar .action-label {
          padding-right: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .actionbar .action-label {
          padding-right: 6px; } }
  [class^='detail-'] .inbox-actions {
    display: flex;
    justify-content: center;
    height: 72px;
    box-shadow: 0 1px 0 #d9d9d9;
    margin-bottom: 1px;
    color: gray; }
    @media (max-height: 1000px) {
      [class^='detail-'] .inbox-actions {
        height: 54px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .inbox-actions {
        height: 54px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .inbox-actions {
        height: 47.952px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .inbox-actions {
        height: 54px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .inbox-actions {
        height: 72px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .inbox-actions {
        height: 36px; } }
    [class^='detail-'] .inbox-actions.collapsed {
      display: none; }
    [class^='detail-'] .inbox-actions > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px; }
      @media (max-height: 1000px) {
        [class^='detail-'] .inbox-actions > div {
          padding-top: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .inbox-actions > div {
          padding-top: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .inbox-actions > div {
          padding-top: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .inbox-actions > div {
          padding-top: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .inbox-actions > div {
          padding-top: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .inbox-actions > div {
          padding-top: 6px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .inbox-actions > div {
          padding-bottom: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .inbox-actions > div {
          padding-bottom: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .inbox-actions > div {
          padding-bottom: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .inbox-actions > div {
          padding-bottom: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .inbox-actions > div {
          padding-bottom: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .inbox-actions > div {
          padding-bottom: 6px; } }
    [class^='detail-'] .inbox-actions .action-icon {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 24px; }
      @media (max-height: 1000px) {
        [class^='detail-'] .inbox-actions .action-icon {
          font-size: 18px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .inbox-actions .action-icon {
          font-size: 18px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .inbox-actions .action-icon {
          font-size: 15.984px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .inbox-actions .action-icon {
          font-size: 18px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .inbox-actions .action-icon {
          font-size: 24px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .inbox-actions .action-icon {
          font-size: 12px; } }
    [class^='detail-'] .inbox-actions .action-label {
      text-align: center;
      padding-left: 12px;
      padding-right: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-height: 1000px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-left: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-left: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-left: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-left: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-left: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-left: 6px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-right: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-right: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-right: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-right: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-right: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .inbox-actions .action-label {
          padding-right: 6px; } }
  [class^='detail-'] .image-square {
    width: 100px;
    height: 100px;
    margin-left: 15px; }
    @media (max-height: 1000px) {
      [class^='detail-'] .image-square {
        width: 75px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .image-square {
        width: 75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .image-square {
        width: 66.6px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .image-square {
        width: 75px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .image-square {
        width: 100px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .image-square {
        width: 50px; } }
    @media (max-height: 1000px) {
      [class^='detail-'] .image-square {
        height: 75px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .image-square {
        height: 75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .image-square {
        height: 66.6px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .image-square {
        height: 75px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .image-square {
        height: 100px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .image-square {
        height: 50px; } }
    @media (max-height: 1000px) {
      [class^='detail-'] .image-square {
        margin-left: 11.25px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .image-square {
        margin-left: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .image-square {
        margin-left: 9.99px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .image-square {
        margin-left: 11.25px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .image-square {
        margin-left: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .image-square {
        margin-left: 7.5px; } }
  [class^='detail-'] .image-full-behind, [class^='detail-'] .detail-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    font-size: 0; }
  [class^='detail-'] .image-full {
    width: 320px;
    height: 180px; }
    @media (max-height: 1000px) {
      [class^='detail-'] .image-full {
        width: 240px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .image-full {
        width: 240px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .image-full {
        width: 213.12px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .image-full {
        width: 240px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .image-full {
        width: 320px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .image-full {
        width: 160px; } }
    @media (max-height: 1000px) {
      [class^='detail-'] .image-full {
        height: 135px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .image-full {
        height: 135px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .image-full {
        height: 119.88px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .image-full {
        height: 135px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .image-full {
        height: 180px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .image-full {
        height: 90px; } }
  [class^='detail-'] .properties {
    display: flex;
    flex-direction: column;
    margin-left: 15px; }
    @media (max-height: 1000px) {
      [class^='detail-'] .properties {
        margin-left: 11.25px; } }
    @media (max-width: 1500px) {
      [class^='detail-'] .properties {
        margin-left: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      [class^='detail-'] .properties {
        margin-left: 9.99px; } }
    @media (max-width: 1200px) {
      [class^='detail-'] .properties {
        margin-left: 11.25px; } }
    @media (max-width: 800px) {
      [class^='detail-'] .properties {
        margin-left: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      [class^='detail-'] .properties {
        margin-left: 7.5px; } }
    [class^='detail-'] .properties .border {
      display: flex;
      flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      box-shadow: 0 1px 0 #d9d9d9;
      margin-bottom: 1px; }
      @media (max-height: 1000px) {
        [class^='detail-'] .properties .border {
          padding-top: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .properties .border {
          padding-top: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .properties .border {
          padding-top: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .properties .border {
          padding-top: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .properties .border {
          padding-top: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .properties .border {
          padding-top: 6px; } }
      @media (max-height: 1000px) {
        [class^='detail-'] .properties .border {
          padding-bottom: 9px; } }
      @media (max-width: 1500px) {
        [class^='detail-'] .properties .border {
          padding-bottom: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        [class^='detail-'] .properties .border {
          padding-bottom: 7.992px; } }
      @media (max-width: 1200px) {
        [class^='detail-'] .properties .border {
          padding-bottom: 9px; } }
      @media (max-width: 800px) {
        [class^='detail-'] .properties .border {
          padding-bottom: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        [class^='detail-'] .properties .border {
          padding-bottom: 6px; } }
      [class^='detail-'] .properties .border:last-child {
        margin-bottom: 0px; }
      [class^='detail-'] .properties .border .label {
        flex: 1;
        font-size: 17px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap; }
        @media (max-height: 1000px) {
          [class^='detail-'] .properties .border .label {
            font-size: 12.75px; } }
        @media (max-width: 1500px) {
          [class^='detail-'] .properties .border .label {
            font-size: 12.75px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          [class^='detail-'] .properties .border .label {
            font-size: 11.322px; } }
        @media (max-width: 1200px) {
          [class^='detail-'] .properties .border .label {
            font-size: 12.75px; } }
        @media (max-width: 800px) {
          [class^='detail-'] .properties .border .label {
            font-size: 17px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          [class^='detail-'] .properties .border .label {
            font-size: 8.5px; } }
        [class^='detail-'] .properties .border .label span {
          color: black; }
        [class^='detail-'] .properties .border .label.wrap {
          overflow: initial; }
          [class^='detail-'] .properties .border .label.wrap ul {
            margin-left: 15px; }
            @media (max-height: 1000px) {
              [class^='detail-'] .properties .border .label.wrap ul {
                margin-left: 11.25px; } }
            @media (max-width: 1500px) {
              [class^='detail-'] .properties .border .label.wrap ul {
                margin-left: 11.25px; } }
            @media (max-width: 1500px) and (max-height: 800px) {
              [class^='detail-'] .properties .border .label.wrap ul {
                margin-left: 9.99px; } }
            @media (max-width: 1200px) {
              [class^='detail-'] .properties .border .label.wrap ul {
                margin-left: 11.25px; } }
            @media (max-width: 800px) {
              [class^='detail-'] .properties .border .label.wrap ul {
                margin-left: 15px; } }
            @media (max-width: 1500px) and (max-height: 750px) {
              [class^='detail-'] .properties .border .label.wrap ul {
                margin-left: 7.5px; } }
          [class^='detail-'] .properties .border .label.wrap ol {
            margin-left: 25px; }
            @media (max-height: 1000px) {
              [class^='detail-'] .properties .border .label.wrap ol {
                margin-left: 18.75px; } }
            @media (max-width: 1500px) {
              [class^='detail-'] .properties .border .label.wrap ol {
                margin-left: 18.75px; } }
            @media (max-width: 1500px) and (max-height: 800px) {
              [class^='detail-'] .properties .border .label.wrap ol {
                margin-left: 16.65px; } }
            @media (max-width: 1200px) {
              [class^='detail-'] .properties .border .label.wrap ol {
                margin-left: 18.75px; } }
            @media (max-width: 800px) {
              [class^='detail-'] .properties .border .label.wrap ol {
                margin-left: 25px; } }
            @media (max-width: 1500px) and (max-height: 750px) {
              [class^='detail-'] .properties .border .label.wrap ol {
                margin-left: 12.5px; } }
          [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
            margin-top: 12px;
            margin-bottom: 12px; }
            @media (max-height: 1000px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-top: 9px; } }
            @media (max-width: 1500px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-top: 9px; } }
            @media (max-width: 1500px) and (max-height: 800px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-top: 7.992px; } }
            @media (max-width: 1200px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-top: 9px; } }
            @media (max-width: 800px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-top: 12px; } }
            @media (max-width: 1500px) and (max-height: 750px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-top: 6px; } }
            @media (max-height: 1000px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-bottom: 9px; } }
            @media (max-width: 1500px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-bottom: 9px; } }
            @media (max-width: 1500px) and (max-height: 800px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-bottom: 7.992px; } }
            @media (max-width: 1200px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-bottom: 9px; } }
            @media (max-width: 800px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-bottom: 12px; } }
            @media (max-width: 1500px) and (max-height: 750px) {
              [class^='detail-'] .properties .border .label.wrap ul, [class^='detail-'] .properties .border .label.wrap ol, [class^='detail-'] .properties .border .label.wrap p {
                margin-bottom: 6px; } }
          [class^='detail-'] .properties .border .label.wrap span.blankify {
            background: black;
            color: white;
            padding: 1px 4px;
            border-radius: 2px; }
        [class^='detail-'] .properties .border .label .prop-header {
          margin-bottom: 2px;
          font-size: 12px; }
          @media (max-height: 1000px) {
            [class^='detail-'] .properties .border .label .prop-header {
              font-size: 9px; } }
          @media (max-width: 1500px) {
            [class^='detail-'] .properties .border .label .prop-header {
              font-size: 9px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            [class^='detail-'] .properties .border .label .prop-header {
              font-size: 7.992px; } }
          @media (max-width: 1200px) {
            [class^='detail-'] .properties .border .label .prop-header {
              font-size: 9px; } }
          @media (max-width: 800px) {
            [class^='detail-'] .properties .border .label .prop-header {
              font-size: 12px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            [class^='detail-'] .properties .border .label .prop-header {
              font-size: 6px; } }
        [class^='detail-'] .properties .border .label .prop-action {
          height: 100%;
          font-size: 17px;
          line-height: 18px; }
          @media (max-height: 1000px) {
            [class^='detail-'] .properties .border .label .prop-action {
              font-size: 12.75px; } }
          @media (max-width: 1500px) {
            [class^='detail-'] .properties .border .label .prop-action {
              font-size: 12.75px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            [class^='detail-'] .properties .border .label .prop-action {
              font-size: 11.322px; } }
          @media (max-width: 1200px) {
            [class^='detail-'] .properties .border .label .prop-action {
              font-size: 12.75px; } }
          @media (max-width: 800px) {
            [class^='detail-'] .properties .border .label .prop-action {
              font-size: 17px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            [class^='detail-'] .properties .border .label .prop-action {
              font-size: 8.5px; } }
        [class^='detail-'] .properties .border .label span + .prop-header {
          margin-top: 8px; }
          @media (max-height: 1000px) {
            [class^='detail-'] .properties .border .label span + .prop-header {
              margin-top: 6px; } }
          @media (max-width: 1500px) {
            [class^='detail-'] .properties .border .label span + .prop-header {
              margin-top: 6px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            [class^='detail-'] .properties .border .label span + .prop-header {
              margin-top: 5.328px; } }
          @media (max-width: 1200px) {
            [class^='detail-'] .properties .border .label span + .prop-header {
              margin-top: 6px; } }
          @media (max-width: 800px) {
            [class^='detail-'] .properties .border .label span + .prop-header {
              margin-top: 8px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            [class^='detail-'] .properties .border .label span + .prop-header {
              margin-top: 4px; } }
      [class^='detail-'] .properties .border .icon {
        font-size: 24px;
        width: 59px; }
        @media (max-height: 1000px) {
          [class^='detail-'] .properties .border .icon {
            font-size: 18px; } }
        @media (max-width: 1500px) {
          [class^='detail-'] .properties .border .icon {
            font-size: 18px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          [class^='detail-'] .properties .border .icon {
            font-size: 15.984px; } }
        @media (max-width: 1200px) {
          [class^='detail-'] .properties .border .icon {
            font-size: 18px; } }
        @media (max-width: 800px) {
          [class^='detail-'] .properties .border .icon {
            font-size: 24px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          [class^='detail-'] .properties .border .icon {
            font-size: 12px; } }
        @media (max-height: 1000px) {
          [class^='detail-'] .properties .border .icon {
            width: 44.25px; } }
        @media (max-width: 1500px) {
          [class^='detail-'] .properties .border .icon {
            width: 44.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          [class^='detail-'] .properties .border .icon {
            width: 39.294px; } }
        @media (max-width: 1200px) {
          [class^='detail-'] .properties .border .icon {
            width: 44.25px; } }
        @media (max-width: 800px) {
          [class^='detail-'] .properties .border .icon {
            width: 59px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          [class^='detail-'] .properties .border .icon {
            width: 29.5px; } }
      [class^='detail-'] .properties .border .noicon {
        width: 15px;
        opacity: 0; }
        @media (max-height: 1000px) {
          [class^='detail-'] .properties .border .noicon {
            width: 11.25px; } }
        @media (max-width: 1500px) {
          [class^='detail-'] .properties .border .noicon {
            width: 11.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          [class^='detail-'] .properties .border .noicon {
            width: 9.99px; } }
        @media (max-width: 1200px) {
          [class^='detail-'] .properties .border .noicon {
            width: 11.25px; } }
        @media (max-width: 800px) {
          [class^='detail-'] .properties .border .noicon {
            width: 15px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          [class^='detail-'] .properties .border .noicon {
            width: 7.5px; } }

.detail-plain > .header .title {
  margin-top: 15px;
  margin-bottom: 15px; }
  @media (max-height: 1000px) {
    .detail-plain > .header .title {
      margin-top: 11.25px; } }
  @media (max-width: 1500px) {
    .detail-plain > .header .title {
      margin-top: 11.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .detail-plain > .header .title {
      margin-top: 9.99px; } }
  @media (max-width: 1200px) {
    .detail-plain > .header .title {
      margin-top: 11.25px; } }
  @media (max-width: 800px) {
    .detail-plain > .header .title {
      margin-top: 15px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .detail-plain > .header .title {
      margin-top: 7.5px; } }
  @media (max-height: 1000px) {
    .detail-plain > .header .title {
      margin-bottom: 11.25px; } }
  @media (max-width: 1500px) {
    .detail-plain > .header .title {
      margin-bottom: 11.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .detail-plain > .header .title {
      margin-bottom: 9.99px; } }
  @media (max-width: 1200px) {
    .detail-plain > .header .title {
      margin-bottom: 11.25px; } }
  @media (max-width: 800px) {
    .detail-plain > .header .title {
      margin-bottom: 15px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .detail-plain > .header .title {
      margin-bottom: 7.5px; } }

.detail-square > .header {
  height: 130px; }
  @media (max-height: 1000px) {
    .detail-square > .header {
      height: 97.5px; } }
  @media (max-width: 1500px) {
    .detail-square > .header {
      height: 97.5px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .detail-square > .header {
      height: 86.58px; } }
  @media (max-width: 1200px) {
    .detail-square > .header {
      height: 97.5px; } }
  @media (max-width: 800px) {
    .detail-square > .header {
      height: 130px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .detail-square > .header {
      height: 65px; } }

.detail-full:not(.no-overlay) > .header {
  height: 180px;
  align-items: flex-end; }
  @media (max-height: 1000px) {
    .detail-full:not(.no-overlay) > .header {
      height: 135px; } }
  @media (max-width: 1500px) {
    .detail-full:not(.no-overlay) > .header {
      height: 135px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .detail-full:not(.no-overlay) > .header {
      height: 119.88px; } }
  @media (max-width: 1200px) {
    .detail-full:not(.no-overlay) > .header {
      height: 135px; } }
  @media (max-width: 800px) {
    .detail-full:not(.no-overlay) > .header {
      height: 180px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .detail-full:not(.no-overlay) > .header {
      height: 90px; } }
  .detail-full:not(.no-overlay) > .header .title {
    color: white;
    margin-bottom: 15px; }
    @media (max-height: 1000px) {
      .detail-full:not(.no-overlay) > .header .title {
        margin-bottom: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-full:not(.no-overlay) > .header .title {
        margin-bottom: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-full:not(.no-overlay) > .header .title {
        margin-bottom: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-full:not(.no-overlay) > .header .title {
        margin-bottom: 11.25px; } }
    @media (max-width: 800px) {
      .detail-full:not(.no-overlay) > .header .title {
        margin-bottom: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-full:not(.no-overlay) > .header .title {
        margin-bottom: 7.5px; } }
  .detail-full:not(.no-overlay) > .header .subtitle {
    color: white; }

.detail-full.no-overlay .image-full-behind {
  box-shadow: 0 1px 0 #d9d9d9;
  margin-bottom: 1px; }

.detail-full.no-overlay .title {
  margin-top: 195px;
  margin-bottom: 15px; }
  @media (max-height: 1000px) {
    .detail-full.no-overlay .title {
      margin-top: 146.25px; } }
  @media (max-width: 1500px) {
    .detail-full.no-overlay .title {
      margin-top: 146.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .detail-full.no-overlay .title {
      margin-top: 129.87px; } }
  @media (max-width: 1200px) {
    .detail-full.no-overlay .title {
      margin-top: 146.25px; } }
  @media (max-width: 800px) {
    .detail-full.no-overlay .title {
      margin-top: 195px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .detail-full.no-overlay .title {
      margin-top: 97.5px; } }
  @media (max-height: 1000px) {
    .detail-full.no-overlay .title {
      margin-bottom: 11.25px; } }
  @media (max-width: 1500px) {
    .detail-full.no-overlay .title {
      margin-bottom: 11.25px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .detail-full.no-overlay .title {
      margin-bottom: 9.99px; } }
  @media (max-width: 1200px) {
    .detail-full.no-overlay .title {
      margin-bottom: 11.25px; } }
  @media (max-width: 800px) {
    .detail-full.no-overlay .title {
      margin-bottom: 15px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .detail-full.no-overlay .title {
      margin-bottom: 7.5px; } }

/*
  there is some behavior in the app preview that needs
  to be accounted for when height <= 750px and
  width <= 1500px that is not addressed by the r mixin
*/
.detail-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .detail-message .scroll-view {
    overflow-y: auto;
    height: 100%; }
  .detail-message .header .title {
    margin-top: 15px;
    margin-bottom: 15px;
    white-space: normal;
    font-size: 17px;
    line-height: 1.4142; }
    @media (max-height: 1000px) {
      .detail-message .header .title {
        margin-top: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .header .title {
        margin-top: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .header .title {
        margin-top: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .header .title {
        margin-top: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .header .title {
        margin-top: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .header .title {
        margin-top: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .header .title {
        margin-bottom: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .header .title {
        margin-bottom: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .header .title {
        margin-bottom: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .header .title {
        margin-bottom: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .header .title {
        margin-bottom: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .header .title {
        margin-bottom: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .header .title {
        font-size: 12.75px; } }
    @media (max-width: 1500px) {
      .detail-message .header .title {
        font-size: 12.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .header .title {
        font-size: 11.322px; } }
    @media (max-width: 1200px) {
      .detail-message .header .title {
        font-size: 12.75px; } }
    @media (max-width: 800px) {
      .detail-message .header .title {
        font-size: 17px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .header .title {
        font-size: 8.5px; } }
    .detail-message .header .title.bold {
      font-weight: 500; }
  .detail-message .rich-header {
    color: black;
    padding: 4px 8px; }
    .detail-message .rich-header .title {
      font-size: 20px;
      font-size: 20px;
      font-weight: 500;
      margin: 4px 0; }
      @media (max-height: 1000px) {
        .detail-message .rich-header .title {
          font-size: 20px; } }
      @media (max-width: 1500px) {
        .detail-message .rich-header .title {
          font-size: 20px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .detail-message .rich-header .title {
          font-size: 14px; } }
      @media (max-width: 1200px) {
        .detail-message .rich-header .title {
          font-size: 20px; } }
      @media (max-width: 800px) {
        .detail-message .rich-header .title {
          font-size: 20px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .detail-message .rich-header .title {
          font-size: 14px; } }
    .detail-message .rich-header .subtitle {
      font-size: 11px;
      font-weight: 600; }
      @media (max-height: 1000px) {
        .detail-message .rich-header .subtitle {
          font-size: 11px; } }
      @media (max-width: 1500px) {
        .detail-message .rich-header .subtitle {
          font-size: 11px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .detail-message .rich-header .subtitle {
          font-size: 8px; } }
      @media (max-width: 1200px) {
        .detail-message .rich-header .subtitle {
          font-size: 11px; } }
      @media (max-width: 800px) {
        .detail-message .rich-header .subtitle {
          font-size: 11px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .detail-message .rich-header .subtitle {
          font-size: 8px; } }
    .detail-message .rich-header .timestamp {
      color: #999;
      font-size: 11px;
      margin-top: 8px; }
      @media (max-height: 1000px) {
        .detail-message .rich-header .timestamp {
          font-size: 11px; } }
      @media (max-width: 1500px) {
        .detail-message .rich-header .timestamp {
          font-size: 11px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .detail-message .rich-header .timestamp {
          font-size: 8px; } }
      @media (max-width: 1200px) {
        .detail-message .rich-header .timestamp {
          font-size: 11px; } }
      @media (max-width: 800px) {
        .detail-message .rich-header .timestamp {
          font-size: 11px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .detail-message .rich-header .timestamp {
          font-size: 8px; } }
  .detail-message .inbox-image {
    width: 100%;
    height: 179px;
    object-fit: cover; }
    @media (max-height: 1000px) {
      .detail-message .inbox-image {
        height: 125px; } }
    @media (max-width: 1500px) {
      .detail-message .inbox-image {
        height: 125px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .inbox-image {
        height: 119px; } }
    @media (max-width: 1200px) {
      .detail-message .inbox-image {
        height: 125px; } }
    @media (max-width: 800px) {
      .detail-message .inbox-image {
        height: 179px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .inbox-image {
        height: 80px; } }
  .detail-message .body {
    margin: 15px;
    font-size: 17px;
    line-height: 1.4142;
    color: black;
    white-space: pre-wrap; }
    @media (max-height: 1000px) {
      .detail-message .body {
        margin: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .body {
        margin: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .body {
        margin: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .body {
        margin: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .body {
        margin: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .body {
        margin: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .body {
        font-size: 12.75px; } }
    @media (max-width: 1500px) {
      .detail-message .body {
        font-size: 12.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .body {
        font-size: 11.322px; } }
    @media (max-width: 1200px) {
      .detail-message .body {
        font-size: 12.75px; } }
    @media (max-width: 800px) {
      .detail-message .body {
        font-size: 17px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .body {
        font-size: 8.5px; } }
  .detail-message .rich-body {
    color: #757575;
    font-size: 12px;
    line-height: 1.8;
    line-height: 1.8;
    font-weight: 500;
    padding: 8px;
    white-space: pre-wrap; }
    @media (max-height: 1000px) {
      .detail-message .rich-body {
        font-size: 12px; } }
    @media (max-width: 1500px) {
      .detail-message .rich-body {
        font-size: 12px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .rich-body {
        font-size: 9px; } }
    @media (max-width: 1200px) {
      .detail-message .rich-body {
        font-size: 12px; } }
    @media (max-width: 800px) {
      .detail-message .rich-body {
        font-size: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .rich-body {
        font-size: 9px; } }
    @media (max-height: 1000px) {
      .detail-message .rich-body {
        line-height: 1.8; } }
    @media (max-width: 1500px) {
      .detail-message .rich-body {
        line-height: 1.8; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .rich-body {
        line-height: 1; } }
    @media (max-width: 1200px) {
      .detail-message .rich-body {
        line-height: 1.8; } }
    @media (max-width: 800px) {
      .detail-message .rich-body {
        line-height: 1.8; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .rich-body {
        line-height: 1; } }
  .detail-message .open-response {
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
    flex-flow: column; }
    .detail-message .open-response-respond {
      min-height: 45px;
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.24); }
      @media (max-height: 1000px) {
        .detail-message .open-response-respond {
          min-height: 33.75px; } }
      @media (max-width: 1500px) {
        .detail-message .open-response-respond {
          min-height: 33.75px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .detail-message .open-response-respond {
          min-height: 29.97px; } }
      @media (max-width: 1200px) {
        .detail-message .open-response-respond {
          min-height: 33.75px; } }
      @media (max-width: 800px) {
        .detail-message .open-response-respond {
          min-height: 45px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .detail-message .open-response-respond {
          min-height: 22.5px; } }
      .detail-message .open-response-respond p {
        font-size: 13px;
        margin-top: 26px;
        margin-bottom: 26px;
        margin-right: 15px;
        margin-left: 15px;
        color: rgba(0, 0, 0, 0.35);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media (max-height: 1000px) {
          .detail-message .open-response-respond p {
            font-size: 9.75px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-respond p {
            font-size: 9.75px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-respond p {
            font-size: 8.658px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-respond p {
            font-size: 9.75px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-respond p {
            font-size: 13px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-respond p {
            font-size: 6.5px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-respond p {
            margin-top: 19.5px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-respond p {
            margin-top: 19.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-respond p {
            margin-top: 17.316px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-respond p {
            margin-top: 19.5px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-respond p {
            margin-top: 26px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-respond p {
            margin-top: 13px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-respond p {
            margin-bottom: 19.5px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-respond p {
            margin-bottom: 19.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-respond p {
            margin-bottom: 17.316px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-respond p {
            margin-bottom: 19.5px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-respond p {
            margin-bottom: 26px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-respond p {
            margin-bottom: 13px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-respond p {
            margin-right: 11.25px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-respond p {
            margin-right: 11.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-respond p {
            margin-right: 9.99px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-respond p {
            margin-right: 11.25px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-respond p {
            margin-right: 15px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-respond p {
            margin-right: 7.5px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-respond p {
            margin-left: 11.25px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-respond p {
            margin-left: 11.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-respond p {
            margin-left: 9.99px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-respond p {
            margin-left: 11.25px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-respond p {
            margin-left: 15px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-respond p {
            margin-left: 7.5px; } }
    .detail-message .open-response-send {
      border-left: 1px solid rgba(0, 0, 0, 0.24);
      position: absolute;
      right: 0px; }
      .detail-message .open-response-send-icon {
        font-size: 23px;
        margin-top: 19px;
        margin-bottom: 19px;
        margin-right: 22px;
        margin-left: 22px;
        color: rgba(0, 0, 0, 0.6);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        transform: rotate(45deg); }
        @media (max-height: 1000px) {
          .detail-message .open-response-send-icon {
            font-size: 17.25px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-send-icon {
            font-size: 17.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-send-icon {
            font-size: 15.318px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-send-icon {
            font-size: 17.25px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-send-icon {
            font-size: 23px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-send-icon {
            font-size: 11.5px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-send-icon {
            margin-top: 14.25px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-send-icon {
            margin-top: 14.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-send-icon {
            margin-top: 12.654px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-send-icon {
            margin-top: 14.25px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-send-icon {
            margin-top: 19px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-send-icon {
            margin-top: 9.5px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-send-icon {
            margin-bottom: 14.25px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-send-icon {
            margin-bottom: 14.25px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-send-icon {
            margin-bottom: 12.654px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-send-icon {
            margin-bottom: 14.25px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-send-icon {
            margin-bottom: 19px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-send-icon {
            margin-bottom: 9.5px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-send-icon {
            margin-right: 16.5px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-send-icon {
            margin-right: 16.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-send-icon {
            margin-right: 14.652px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-send-icon {
            margin-right: 16.5px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-send-icon {
            margin-right: 22px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-send-icon {
            margin-right: 11px; } }
        @media (max-height: 1000px) {
          .detail-message .open-response-send-icon {
            margin-left: 16.5px; } }
        @media (max-width: 1500px) {
          .detail-message .open-response-send-icon {
            margin-left: 16.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .open-response-send-icon {
            margin-left: 14.652px; } }
        @media (max-width: 1200px) {
          .detail-message .open-response-send-icon {
            margin-left: 16.5px; } }
        @media (max-width: 800px) {
          .detail-message .open-response-send-icon {
            margin-left: 22px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .open-response-send-icon {
            margin-left: 11px; } }
  .detail-message .attitude-poll-options-labels {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.24); }
  .detail-message .attitude-poll-options-label {
    text-align: center;
    width: 20%;
    color: gray;
    font-size: 12px;
    padding-top: 25px; }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-label {
        font-size: 9px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-label {
        font-size: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-label {
        font-size: 7.992px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-label {
        font-size: 9px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-label {
        font-size: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-label {
        font-size: 6px; } }
  .detail-message .attitude-poll-options-radios {
    padding-top: 15px;
    padding-bottom: 30px;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 15px;
    margin-right: 15px; }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-radios {
        padding-top: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-radios {
        padding-top: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-radios {
        padding-top: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-radios {
        padding-top: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-radios {
        padding-top: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-radios {
        padding-top: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-radios {
        padding-bottom: 22.5px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-radios {
        padding-bottom: 22.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-radios {
        padding-bottom: 19.98px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-radios {
        padding-bottom: 22.5px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-radios {
        padding-bottom: 30px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-radios {
        padding-bottom: 15px; } }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-radios {
        margin-left: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-radios {
        margin-left: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-radios {
        margin-left: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-radios {
        margin-left: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-radios {
        margin-left: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-radios {
        margin-left: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-radios {
        margin-right: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-radios {
        margin-right: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-radios {
        margin-right: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-radios {
        margin-right: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-radios {
        margin-right: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-radios {
        margin-right: 7.5px; } }
  .detail-message .attitude-poll-options-radio {
    border-radius: 50%;
    border: 1px solid #bfbfbf;
    height: 30px;
    width: 30px;
    background-color: white; }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-radio {
        height: 22.5px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-radio {
        height: 22.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-radio {
        height: 19.98px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-radio {
        height: 22.5px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-radio {
        height: 30px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-radio {
        height: 15px; } }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-radio {
        width: 22.5px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-radio {
        width: 22.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-radio {
        width: 19.98px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-radio {
        width: 22.5px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-radio {
        width: 30px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-radio {
        width: 15px; } }
  .detail-message .attitude-poll-options-connector {
    height: 1px;
    background-color: #bfbfbf;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 45px;
    z-index: -1; }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-connector {
        left: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-connector {
        left: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-connector {
        left: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-connector {
        left: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-connector {
        left: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-connector {
        left: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-connector {
        right: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-connector {
        right: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-connector {
        right: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-connector {
        right: 11.25px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-connector {
        right: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-connector {
        right: 7.5px; } }
    @media (max-height: 1000px) {
      .detail-message .attitude-poll-options-connector {
        bottom: 33.75px; } }
    @media (max-width: 1500px) {
      .detail-message .attitude-poll-options-connector {
        bottom: 33.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-message .attitude-poll-options-connector {
        bottom: 29.97px; } }
    @media (max-width: 1200px) {
      .detail-message .attitude-poll-options-connector {
        bottom: 33.75px; } }
    @media (max-width: 800px) {
      .detail-message .attitude-poll-options-connector {
        bottom: 45px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-message .attitude-poll-options-connector {
        bottom: 22.5px; } }
  .detail-message .poll {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .detail-message .poll-question {
      flex: 1;
      display: flex;
      overflow-y: auto; }
      .detail-message .poll-question .question-wrapper {
        min-height: min-content;
        flex: 1;
        display: flex;
        align-items: center; }
        .detail-message .poll-question .question-wrapper p {
          font-size: 17px;
          color: black;
          font-weight: bold;
          margin-top: 10px;
          margin-right: 10px;
          margin-left: 10px;
          margin-bottom: 10px;
          overflow: auto;
          white-space: pre-line;
          width: 100%;
          text-align: center; }
          @media (max-height: 1000px) {
            .detail-message .poll-question .question-wrapper p {
              font-size: 12.75px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-question .question-wrapper p {
              font-size: 12.75px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-question .question-wrapper p {
              font-size: 11.322px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-question .question-wrapper p {
              font-size: 12.75px; } }
          @media (max-width: 800px) {
            .detail-message .poll-question .question-wrapper p {
              font-size: 17px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-question .question-wrapper p {
              font-size: 8.5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-question .question-wrapper p {
              margin-top: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-question .question-wrapper p {
              margin-top: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-top: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-question .question-wrapper p {
              margin-top: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-top: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-question .question-wrapper p {
              margin-top: 5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-question .question-wrapper p {
              margin-right: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-question .question-wrapper p {
              margin-right: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-right: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-question .question-wrapper p {
              margin-right: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-right: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-question .question-wrapper p {
              margin-right: 5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-question .question-wrapper p {
              margin-left: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-question .question-wrapper p {
              margin-left: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-left: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-question .question-wrapper p {
              margin-left: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-left: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-question .question-wrapper p {
              margin-left: 5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-question .question-wrapper p {
              margin-bottom: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-question .question-wrapper p {
              margin-bottom: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-bottom: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-question .question-wrapper p {
              margin-bottom: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-question .question-wrapper p {
              margin-bottom: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-question .question-wrapper p {
              margin-bottom: 5px; } }
    .detail-message .poll-answers {
      display: flex;
      flex-flow: column; }
      .detail-message .poll-answers-item {
        min-height: 45px;
        display: flex;
        justify-content: left;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.24); }
        @media (max-height: 1000px) {
          .detail-message .poll-answers-item {
            min-height: 33.75px; } }
        @media (max-width: 1500px) {
          .detail-message .poll-answers-item {
            min-height: 33.75px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .poll-answers-item {
            min-height: 29.97px; } }
        @media (max-width: 1200px) {
          .detail-message .poll-answers-item {
            min-height: 33.75px; } }
        @media (max-width: 800px) {
          .detail-message .poll-answers-item {
            min-height: 45px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .poll-answers-item {
            min-height: 22.5px; } }
        .detail-message .poll-answers-item p {
          font-size: 15px;
          margin-top: 10px;
          margin-right: 10px;
          margin-left: 10px;
          margin-bottom: 10px;
          color: black;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden; }
          @media (max-height: 1000px) {
            .detail-message .poll-answers-item p {
              font-size: 11.25px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-answers-item p {
              font-size: 11.25px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-answers-item p {
              font-size: 9.99px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-answers-item p {
              font-size: 11.25px; } }
          @media (max-width: 800px) {
            .detail-message .poll-answers-item p {
              font-size: 15px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-answers-item p {
              font-size: 7.5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-answers-item p {
              margin-top: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-answers-item p {
              margin-top: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-answers-item p {
              margin-top: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-answers-item p {
              margin-top: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-answers-item p {
              margin-top: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-answers-item p {
              margin-top: 5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-answers-item p {
              margin-right: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-answers-item p {
              margin-right: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-answers-item p {
              margin-right: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-answers-item p {
              margin-right: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-answers-item p {
              margin-right: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-answers-item p {
              margin-right: 5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-answers-item p {
              margin-left: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-answers-item p {
              margin-left: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-answers-item p {
              margin-left: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-answers-item p {
              margin-left: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-answers-item p {
              margin-left: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-answers-item p {
              margin-left: 5px; } }
          @media (max-height: 1000px) {
            .detail-message .poll-answers-item p {
              margin-bottom: 7.5px; } }
          @media (max-width: 1500px) {
            .detail-message .poll-answers-item p {
              margin-bottom: 7.5px; } }
          @media (max-width: 1500px) and (max-height: 800px) {
            .detail-message .poll-answers-item p {
              margin-bottom: 6.66px; } }
          @media (max-width: 1200px) {
            .detail-message .poll-answers-item p {
              margin-bottom: 7.5px; } }
          @media (max-width: 800px) {
            .detail-message .poll-answers-item p {
              margin-bottom: 10px; } }
          @media (max-width: 1500px) and (max-height: 750px) {
            .detail-message .poll-answers-item p {
              margin-bottom: 5px; } }
      .detail-message .poll-answers-vote {
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid rgba(0, 0, 0, 0.24);
        text-align: center;
        color: #bfbfbf;
        text-transform: uppercase;
        font-size: 25px;
        line-height: 45px;
        font-weight: 300; }
        @media (max-height: 1000px) {
          .detail-message .poll-answers-vote {
            height: 49.5px; } }
        @media (max-width: 1500px) {
          .detail-message .poll-answers-vote {
            height: 49.5px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .poll-answers-vote {
            height: 43.956px; } }
        @media (max-width: 1200px) {
          .detail-message .poll-answers-vote {
            height: 49.5px; } }
        @media (max-width: 800px) {
          .detail-message .poll-answers-vote {
            height: 66px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .poll-answers-vote {
            height: 33px; } }
        @media (max-height: 1000px) {
          .detail-message .poll-answers-vote {
            font-size: 18.75px; } }
        @media (max-width: 1500px) {
          .detail-message .poll-answers-vote {
            font-size: 18.75px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .detail-message .poll-answers-vote {
            font-size: 16.65px; } }
        @media (max-width: 1200px) {
          .detail-message .poll-answers-vote {
            font-size: 18.75px; } }
        @media (max-width: 800px) {
          .detail-message .poll-answers-vote {
            font-size: 25px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .detail-message .poll-answers-vote {
            font-size: 12.5px; } }
  .detail-message.attitude-poll-preview {
    padding-top: 20%; }
  .detail-message div[disabled][style^="background-color"] {
    position: relative;
    bottom: -50px; }

.detail-blank {
  height: 100%; }
  .detail-blank .warning {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    cursor: pointer;
    background-color: gray;
    color: white;
    white-space: pre-wrap; }
    @media (max-height: 1000px) {
      .detail-blank .warning {
        font-size: 11.25px; } }
    @media (max-width: 1500px) {
      .detail-blank .warning {
        font-size: 11.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .detail-blank .warning {
        font-size: 9.99px; } }
    @media (max-width: 1200px) {
      .detail-blank .warning {
        font-size: 11.25px; } }
    @media (max-width: 800px) {
      .detail-blank .warning {
        font-size: 15px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .detail-blank .warning {
        font-size: 7.5px; } }
  .detail-blank iframe {
    height: 100%;
    border: 0; }

.push {
  height: 568px;
  background-image: url("../images/preview/ios_status_bar_black.svg"), url("../images/preview/push_preview_open.png");
  background-size: 100% auto, 100% 100%;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-flow: column;
  align-items: center; }
  @media (max-height: 1000px) {
    .push {
      height: 426px; } }
  @media (max-width: 1500px) {
    .push {
      height: 426px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .push {
      height: 378.288px; } }
  @media (max-width: 1200px) {
    .push {
      height: 426px; } }
  @media (max-width: 800px) {
    .push {
      height: 568px; } }
  @media (max-width: 1500px) and (max-height: 750px) {
    .push {
      height: 284px; } }
  .push.home {
    background-image: url("../images/preview/ios_status_bar_black.svg"), url("../images/preview/push_preview_lock.png"); }
  .push > .time {
    font-size: 87.5px;
    font-weight: 100;
    margin-top: 27px; }
    @media (max-height: 1000px) {
      .push > .time {
        font-size: 65.625px; } }
    @media (max-width: 1500px) {
      .push > .time {
        font-size: 65.625px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .time {
        font-size: 58.275px; } }
    @media (max-width: 1200px) {
      .push > .time {
        font-size: 65.625px; } }
    @media (max-width: 800px) {
      .push > .time {
        font-size: 87.5px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .time {
        font-size: 43.75px; } }
    @media (max-height: 1000px) {
      .push > .time {
        margin-top: 20.25px; } }
    @media (max-width: 1500px) {
      .push > .time {
        margin-top: 20.25px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .time {
        margin-top: 17.982px; } }
    @media (max-width: 1200px) {
      .push > .time {
        margin-top: 20.25px; } }
    @media (max-width: 800px) {
      .push > .time {
        margin-top: 27px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .time {
        margin-top: 13.5px; } }
    .push > .time > .special-colon {
      font-family: 'Wire One';
      position: relative;
      top: -10px;
      font-size: 96px;
      padding-left: 8px;
      padding-right: 8px; }
      @media (max-height: 1000px) {
        .push > .time > .special-colon {
          top: -7.5px; } }
      @media (max-width: 1500px) {
        .push > .time > .special-colon {
          top: -7.5px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .time > .special-colon {
          top: -6.66px; } }
      @media (max-width: 1200px) {
        .push > .time > .special-colon {
          top: -7.5px; } }
      @media (max-width: 800px) {
        .push > .time > .special-colon {
          top: -10px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .time > .special-colon {
          top: -5px; } }
      @media (max-height: 1000px) {
        .push > .time > .special-colon {
          font-size: 72px; } }
      @media (max-width: 1500px) {
        .push > .time > .special-colon {
          font-size: 72px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .time > .special-colon {
          font-size: 63.936px; } }
      @media (max-width: 1200px) {
        .push > .time > .special-colon {
          font-size: 72px; } }
      @media (max-width: 800px) {
        .push > .time > .special-colon {
          font-size: 96px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .time > .special-colon {
          font-size: 48px; } }
      @media (max-height: 1000px) {
        .push > .time > .special-colon {
          padding-left: 6px; } }
      @media (max-width: 1500px) {
        .push > .time > .special-colon {
          padding-left: 6px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .time > .special-colon {
          padding-left: 5.328px; } }
      @media (max-width: 1200px) {
        .push > .time > .special-colon {
          padding-left: 6px; } }
      @media (max-width: 800px) {
        .push > .time > .special-colon {
          padding-left: 8px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .time > .special-colon {
          padding-left: 4px; } }
      @media (max-height: 1000px) {
        .push > .time > .special-colon {
          padding-right: 6px; } }
      @media (max-width: 1500px) {
        .push > .time > .special-colon {
          padding-right: 6px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .time > .special-colon {
          padding-right: 5.328px; } }
      @media (max-width: 1200px) {
        .push > .time > .special-colon {
          padding-right: 6px; } }
      @media (max-width: 800px) {
        .push > .time > .special-colon {
          padding-right: 8px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .time > .special-colon {
          padding-right: 4px; } }
  .push > .date {
    font-size: 18px;
    position: relative;
    top: -4px; }
    @media (max-height: 1000px) {
      .push > .date {
        font-size: 13.5px; } }
    @media (max-width: 1500px) {
      .push > .date {
        font-size: 13.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .date {
        font-size: 11.988px; } }
    @media (max-width: 1200px) {
      .push > .date {
        font-size: 13.5px; } }
    @media (max-width: 800px) {
      .push > .date {
        font-size: 18px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .date {
        font-size: 9px; } }
    @media (max-height: 1000px) {
      .push > .date {
        top: -3px; } }
    @media (max-width: 1500px) {
      .push > .date {
        top: -3px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .date {
        top: -2.664px; } }
    @media (max-width: 1200px) {
      .push > .date {
        top: -3px; } }
    @media (max-width: 800px) {
      .push > .date {
        top: -4px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .date {
        top: -2px; } }
  .push > .notification {
    display: flex;
    width: 304px;
    margin-top: 18px;
    margin-left: 16px;
    padding-right: 9px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.24), 0 1px 0 rgba(255, 255, 255, 0.24); }
    @media (max-height: 1000px) {
      .push > .notification {
        width: 228px; } }
    @media (max-width: 1500px) {
      .push > .notification {
        width: 228px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .notification {
        width: 202.464px; } }
    @media (max-width: 1200px) {
      .push > .notification {
        width: 228px; } }
    @media (max-width: 800px) {
      .push > .notification {
        width: 304px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .notification {
        width: 152px; } }
    @media (max-height: 1000px) {
      .push > .notification {
        margin-top: 13.5px; } }
    @media (max-width: 1500px) {
      .push > .notification {
        margin-top: 13.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .notification {
        margin-top: 11.988px; } }
    @media (max-width: 1200px) {
      .push > .notification {
        margin-top: 13.5px; } }
    @media (max-width: 800px) {
      .push > .notification {
        margin-top: 18px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .notification {
        margin-top: 9px; } }
    @media (max-height: 1000px) {
      .push > .notification {
        margin-left: 12px; } }
    @media (max-width: 1500px) {
      .push > .notification {
        margin-left: 12px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .notification {
        margin-left: 10.656px; } }
    @media (max-width: 1200px) {
      .push > .notification {
        margin-left: 12px; } }
    @media (max-width: 800px) {
      .push > .notification {
        margin-left: 16px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .notification {
        margin-left: 8px; } }
    @media (max-height: 1000px) {
      .push > .notification {
        padding-right: 6.75px; } }
    @media (max-width: 1500px) {
      .push > .notification {
        padding-right: 6.75px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .notification {
        padding-right: 5.994px; } }
    @media (max-width: 1200px) {
      .push > .notification {
        padding-right: 6.75px; } }
    @media (max-width: 800px) {
      .push > .notification {
        padding-right: 9px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .notification {
        padding-right: 4.5px; } }
    @media (max-height: 1000px) {
      .push > .notification {
        padding-top: 9px; } }
    @media (max-width: 1500px) {
      .push > .notification {
        padding-top: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .notification {
        padding-top: 7.992px; } }
    @media (max-width: 1200px) {
      .push > .notification {
        padding-top: 9px; } }
    @media (max-width: 800px) {
      .push > .notification {
        padding-top: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .notification {
        padding-top: 6px; } }
    @media (max-height: 1000px) {
      .push > .notification {
        padding-bottom: 9px; } }
    @media (max-width: 1500px) {
      .push > .notification {
        padding-bottom: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .notification {
        padding-bottom: 7.992px; } }
    @media (max-width: 1200px) {
      .push > .notification {
        padding-bottom: 9px; } }
    @media (max-width: 800px) {
      .push > .notification {
        padding-bottom: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .notification {
        padding-bottom: 6px; } }
    .push > .notification > .appicon {
      margin-right: 12px; }
      @media (max-height: 1000px) {
        .push > .notification > .appicon {
          margin-right: 9px; } }
      @media (max-width: 1500px) {
        .push > .notification > .appicon {
          margin-right: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .notification > .appicon {
          margin-right: 7.992px; } }
      @media (max-width: 1200px) {
        .push > .notification > .appicon {
          margin-right: 9px; } }
      @media (max-width: 800px) {
        .push > .notification > .appicon {
          margin-right: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .notification > .appicon {
          margin-right: 6px; } }
      .push > .notification > .appicon > img {
        height: 20px;
        width: 20px;
        border-radius: 22%; }
        @media (max-height: 1000px) {
          .push > .notification > .appicon > img {
            height: 15px; } }
        @media (max-width: 1500px) {
          .push > .notification > .appicon > img {
            height: 15px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .notification > .appicon > img {
            height: 13.32px; } }
        @media (max-width: 1200px) {
          .push > .notification > .appicon > img {
            height: 15px; } }
        @media (max-width: 800px) {
          .push > .notification > .appicon > img {
            height: 20px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .notification > .appicon > img {
            height: 10px; } }
        @media (max-height: 1000px) {
          .push > .notification > .appicon > img {
            width: 15px; } }
        @media (max-width: 1500px) {
          .push > .notification > .appicon > img {
            width: 15px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .notification > .appicon > img {
            width: 13.32px; } }
        @media (max-width: 1200px) {
          .push > .notification > .appicon > img {
            width: 15px; } }
        @media (max-width: 800px) {
          .push > .notification > .appicon > img {
            width: 20px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .notification > .appicon > img {
            width: 10px; } }
    .push > .notification > .text {
      flex: 1;
      line-height: 18px;
      font-size: 16px;
      margin-right: 12px; }
      @media (max-height: 1000px) {
        .push > .notification > .text {
          line-height: 14px; } }
      @media (max-width: 1500px) {
        .push > .notification > .text {
          line-height: 14px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .notification > .text {
          line-height: 12px; } }
      @media (max-width: 1200px) {
        .push > .notification > .text {
          line-height: 14px; } }
      @media (max-width: 800px) {
        .push > .notification > .text {
          line-height: 18px; } }
      @media (max-height: 1000px) {
        .push > .notification > .text {
          font-size: 12px; } }
      @media (max-width: 1500px) {
        .push > .notification > .text {
          font-size: 12px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .notification > .text {
          font-size: 10.656px; } }
      @media (max-width: 1200px) {
        .push > .notification > .text {
          font-size: 12px; } }
      @media (max-width: 800px) {
        .push > .notification > .text {
          font-size: 16px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .notification > .text {
          font-size: 8px; } }
      @media (max-height: 1000px) {
        .push > .notification > .text {
          margin-right: 9px; } }
      @media (max-width: 1500px) {
        .push > .notification > .text {
          margin-right: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .notification > .text {
          margin-right: 7.992px; } }
      @media (max-width: 1200px) {
        .push > .notification > .text {
          margin-right: 9px; } }
      @media (max-width: 800px) {
        .push > .notification > .text {
          margin-right: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .notification > .text {
          margin-right: 6px; } }
      .push > .notification > .text > .appname {
        font-weight: 600; }
      .push > .notification > .text > .notification-text {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word; }
      .push > .notification > .text .superfluous {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.24); }
        @media (max-height: 1000px) {
          .push > .notification > .text .superfluous {
            font-size: 9px; } }
        @media (max-width: 1500px) {
          .push > .notification > .text .superfluous {
            font-size: 9px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .notification > .text .superfluous {
            font-size: 7.992px; } }
        @media (max-width: 1200px) {
          .push > .notification > .text .superfluous {
            font-size: 9px; } }
        @media (max-width: 800px) {
          .push > .notification > .text .superfluous {
            font-size: 12px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .notification > .text .superfluous {
            font-size: 6px; } }
  .push > .lock-screen-notification {
    display: flex;
    flex-flow: column;
    width: 304px;
    margin-top: 18px;
    margin-left: 16px;
    margin-right: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.24), 0 1px 0 rgba(255, 255, 255, 0.24); }
    @media (max-height: 1000px) {
      .push > .lock-screen-notification {
        width: 228px; } }
    @media (max-width: 1500px) {
      .push > .lock-screen-notification {
        width: 228px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .lock-screen-notification {
        width: 202.464px; } }
    @media (max-width: 1200px) {
      .push > .lock-screen-notification {
        width: 228px; } }
    @media (max-width: 800px) {
      .push > .lock-screen-notification {
        width: 304px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .lock-screen-notification {
        width: 152px; } }
    @media (max-height: 1000px) {
      .push > .lock-screen-notification {
        margin-top: 13.5px; } }
    @media (max-width: 1500px) {
      .push > .lock-screen-notification {
        margin-top: 13.5px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .lock-screen-notification {
        margin-top: 11.988px; } }
    @media (max-width: 1200px) {
      .push > .lock-screen-notification {
        margin-top: 13.5px; } }
    @media (max-width: 800px) {
      .push > .lock-screen-notification {
        margin-top: 18px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .lock-screen-notification {
        margin-top: 9px; } }
    @media (max-height: 1000px) {
      .push > .lock-screen-notification {
        margin-left: 12px; } }
    @media (max-width: 1500px) {
      .push > .lock-screen-notification {
        margin-left: 12px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .lock-screen-notification {
        margin-left: 10.656px; } }
    @media (max-width: 1200px) {
      .push > .lock-screen-notification {
        margin-left: 12px; } }
    @media (max-width: 800px) {
      .push > .lock-screen-notification {
        margin-left: 16px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .lock-screen-notification {
        margin-left: 8px; } }
    @media (max-height: 1000px) {
      .push > .lock-screen-notification {
        margin-right: 12px; } }
    @media (max-width: 1500px) {
      .push > .lock-screen-notification {
        margin-right: 12px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .lock-screen-notification {
        margin-right: 3px; } }
    @media (max-width: 1200px) {
      .push > .lock-screen-notification {
        margin-right: 3px; } }
    @media (max-width: 800px) {
      .push > .lock-screen-notification {
        margin-right: 3px; } }
    @media (max-height: 1000px) {
      .push > .lock-screen-notification {
        padding-top: 9px; } }
    @media (max-width: 1500px) {
      .push > .lock-screen-notification {
        padding-top: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .lock-screen-notification {
        padding-top: 7.992px; } }
    @media (max-width: 1200px) {
      .push > .lock-screen-notification {
        padding-top: 9px; } }
    @media (max-width: 800px) {
      .push > .lock-screen-notification {
        padding-top: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .lock-screen-notification {
        padding-top: 6px; } }
    @media (max-height: 1000px) {
      .push > .lock-screen-notification {
        padding-bottom: 9px; } }
    @media (max-width: 1500px) {
      .push > .lock-screen-notification {
        padding-bottom: 9px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .push > .lock-screen-notification {
        padding-bottom: 7.992px; } }
    @media (max-width: 1200px) {
      .push > .lock-screen-notification {
        padding-bottom: 9px; } }
    @media (max-width: 800px) {
      .push > .lock-screen-notification {
        padding-bottom: 12px; } }
    @media (max-width: 1500px) and (max-height: 750px) {
      .push > .lock-screen-notification {
        padding-bottom: 6px; } }
    .push > .lock-screen-notification > .notification-header {
      margin-right: 15px;
      font-size: 10px;
      font-weight: 100; }
      @media (max-height: 1000px) {
        .push > .lock-screen-notification > .notification-header {
          margin-right: 3px; } }
      @media (max-width: 1500px) {
        .push > .lock-screen-notification > .notification-header {
          margin-right: 3px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .lock-screen-notification > .notification-header {
          margin-right: 3px; } }
      @media (max-width: 1200px) {
        .push > .lock-screen-notification > .notification-header {
          margin-right: 3px; } }
      @media (max-width: 800px) {
        .push > .lock-screen-notification > .notification-header {
          margin-right: 15px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .lock-screen-notification > .notification-header {
          font-size: 6.5px; } }
      .push > .lock-screen-notification > .notification-header > img {
        height: 20px;
        width: 20px;
        border-radius: 22%; }
        @media (max-height: 1000px) {
          .push > .lock-screen-notification > .notification-header > img {
            height: 15px; } }
        @media (max-width: 1500px) {
          .push > .lock-screen-notification > .notification-header > img {
            height: 15px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .lock-screen-notification > .notification-header > img {
            height: 13.32px; } }
        @media (max-width: 1200px) {
          .push > .lock-screen-notification > .notification-header > img {
            height: 15px; } }
        @media (max-width: 800px) {
          .push > .lock-screen-notification > .notification-header > img {
            height: 20px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .lock-screen-notification > .notification-header > img {
            height: 10px; } }
        @media (max-height: 1000px) {
          .push > .lock-screen-notification > .notification-header > img {
            width: 15px; } }
        @media (max-width: 1500px) {
          .push > .lock-screen-notification > .notification-header > img {
            width: 15px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .lock-screen-notification > .notification-header > img {
            width: 13.32px; } }
        @media (max-width: 1200px) {
          .push > .lock-screen-notification > .notification-header > img {
            width: 15px; } }
        @media (max-width: 800px) {
          .push > .lock-screen-notification > .notification-header > img {
            width: 20px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .lock-screen-notification > .notification-header > img {
            width: 10px; } }
      .push > .lock-screen-notification > .notification-header .application-name {
        display: inline-block;
        position: relative;
        left: 4px;
        bottom: 6px; }
        @media (max-height: 1000px) {
          .push > .lock-screen-notification > .notification-header .application-name {
            bottom: 4px; } }
        @media (max-width: 1500px) {
          .push > .lock-screen-notification > .notification-header .application-name {
            bottom: 4px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .lock-screen-notification > .notification-header .application-name {
            bottom: 4px; } }
        @media (max-width: 1200px) {
          .push > .lock-screen-notification > .notification-header .application-name {
            bottom: 4px; } }
        @media (max-width: 800px) {
          .push > .lock-screen-notification > .notification-header .application-name {
            bottom: 6px; } }
      .push > .lock-screen-notification > .notification-header .timestamp {
        float: right; }
    .push > .lock-screen-notification > .notification-content {
      flex: 1;
      line-height: 18px;
      margin-right: 12px; }
      @media (max-height: 1000px) {
        .push > .lock-screen-notification > .notification-content {
          line-height: 14px; } }
      @media (max-width: 1500px) {
        .push > .lock-screen-notification > .notification-content {
          line-height: 14px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .lock-screen-notification > .notification-content {
          line-height: 12px; } }
      @media (max-width: 1200px) {
        .push > .lock-screen-notification > .notification-content {
          line-height: 14px; } }
      @media (max-width: 800px) {
        .push > .lock-screen-notification > .notification-content {
          line-height: 18px; } }
      @media (max-height: 1000px) {
        .push > .lock-screen-notification > .notification-content {
          margin-right: 9px; } }
      @media (max-width: 1500px) {
        .push > .lock-screen-notification > .notification-content {
          margin-right: 9px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .push > .lock-screen-notification > .notification-content {
          margin-right: 7.992px; } }
      @media (max-width: 1200px) {
        .push > .lock-screen-notification > .notification-content {
          margin-right: 9px; } }
      @media (max-width: 800px) {
        .push > .lock-screen-notification > .notification-content {
          margin-right: 12px; } }
      @media (max-width: 1500px) and (max-height: 750px) {
        .push > .lock-screen-notification > .notification-content {
          margin-right: 6px; } }
      .push > .lock-screen-notification > .notification-content .message-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        font-size: 11.5px; }
        @media (max-height: 1000px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            font-size: 9px; } }
        @media (max-width: 1500px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            font-size: 9px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            font-size: 8px; } }
        @media (max-width: 1200px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            font-size: 9px; } }
        @media (max-width: 800px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            font-size: 11.5px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            font-size: 6.5px; } }
        @media (max-width: 1500px) and (max-height: 750px) {
          .push > .lock-screen-notification > .notification-content .message-text {
            line-height: 9px; } }
      .push > .lock-screen-notification > .notification-content .rich-body {
        display: flex;
        flex-direction: row; }
        .push > .lock-screen-notification > .notification-content .rich-body .rich-body-preview {
          -webkit-line-clamp: 3;
          font-weight: 100; }
      .push > .lock-screen-notification > .notification-content .message-header {
        display: flex; }
        .push > .lock-screen-notification > .notification-content .message-header .message-titles {
          flex: 9;
          margin-right: 16px; }
          @media (max-width: 1500px) and (max-height: 750px) {
            .push > .lock-screen-notification > .notification-content .message-header .message-titles {
              margin-right: 1px; } }
          .push > .lock-screen-notification > .notification-content .message-header .message-titles .message-header-text {
            -webkit-line-clamp: 1;
            font-weight: 500; }
        .push > .lock-screen-notification > .notification-content .message-header .thumbnail-container {
          flex: 1; }
          @media (max-width: 1500px) and (max-height: 750px) {
            .push > .lock-screen-notification > .notification-content .message-header .thumbnail-container {
              margin-top: 4px; } }
          .push > .lock-screen-notification > .notification-content .message-header .thumbnail-container .image-thumbnail {
            height: 25px;
            width: 25px; }
            @media (max-width: 1500px) and (max-height: 750px) {
              .push > .lock-screen-notification > .notification-content .message-header .thumbnail-container .image-thumbnail {
                height: 15px; } }
            @media (max-width: 1500px) and (max-height: 750px) {
              .push > .lock-screen-notification > .notification-content .message-header .thumbnail-container .image-thumbnail {
                width: 15px; } }

.remove-zone {
  position: absolute;
  z-index: 9000;
  left: -30px;
  top: -15px;
  width: calc(100% + 45px);
  height: calc(100% + 15px);
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  opacity: 1;
  transition: opacity 0.12s ease-in; }
  .remove-zone .border {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px 15px 30px 30px;
    padding: 3px;
    border: 2px dashed;
    border-color: transparent;
    border-radius: 12px;
    transition: border-color 0.08s ease-in; }
  .remove-zone .jaqen {
    font-size: 48px;
    color: #d0021b;
    margin-bottom: 24px; }
  .remove-zone label {
    color: gray;
    font-style: italic; }
  .remove-zone.hovering .border {
    border-color: rgba(208, 2, 27, 0.4); }
  .remove-zone.hidden {
    opacity: 0;
    pointer-events: none; }
  .remove-zone:not(.hidden) ~ div {
    -webkit-filter: blur(8px); }
  .remove-zone ~ div {
    transition: -webkit-filter 0s; }

.center {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 15px;
  flex: 1; }
  @media (max-height: 1000px) {
    .center {
      margin: 0 15px; } }
  @media (max-width: 1500px) {
    .center {
      margin: 0 15px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .center {
      margin: 0 15px; } }
  @media (max-width: 1200px) {
    .center {
      margin: 0 15px 0 0; } }
  @media (max-width: 800px) {
    .center {
      margin: 0; } }
  @media (max-height: 1000px) {
    .center {
      flex: 1; } }
  @media (max-width: 1500px) {
    .center {
      flex: 1; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .center {
      flex: 1; } }
  @media (max-width: 1200px) {
    .center {
      flex: 1; } }
  @media (max-width: 800px) {
    .center {
      flex: 1 100%; } }
  .center.no-right {
    margin: 0 0 0 15px; }
    @media (max-height: 1000px) {
      .center.no-right {
        margin: 0 0 0 15px; } }
    @media (max-width: 1500px) {
      .center.no-right {
        margin: 0 0 0 15px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .center.no-right {
        margin: 0 0 0 15px; } }
    @media (max-width: 1200px) {
      .center.no-right {
        margin: 0 0 0 15px; } }
    @media (max-width: 800px) {
      .center.no-right {
        margin: 0; } }

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

@supports not (-apple-trailing-word: inherit) {
  div[data-slate-editor] {
    -webkit-user-modify: read-write !important; } }

html, body {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro'; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

article, section {
  transform: translateZ(0); }

.isvg {
  pointer-events: none; }
  .isvg.loaded {
    display: flex; }

.page {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  isolation: isolate;
  padding: 30px 30px 0 30px; }
  @media (max-height: 1000px) {
    .page {
      padding: 30px 30px 0 30px; } }
  @media (max-width: 1500px) {
    .page {
      padding: 30px 30px 0 30px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .page {
      padding: 30px 30px 0 30px; } }
  @media (max-width: 1200px) {
    .page {
      padding: 30px 30px 0 15px; } }
  @media (max-width: 800px) {
    .page {
      padding: 30px 15px 0 15px; } }

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px; }
  .icon svg {
    height: 24px;
    width: 24px; }

.jaqen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  font-size: 24px; }
  .jaqen:not(.interactive) {
    pointer-events: none; }
  .jaqen.interactive {
    cursor: pointer; }

.text-input {
  position: relative;
  overflow: hidden;
  line-height: normal;
  padding: 5px 10px;
  background-color: white; }
  .text-input.disabled {
    background-size: 45px 46px;
    background-image: linear-gradient(-45deg, #f9f9f9 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, #f9f9f9 50%, #f9f9f9 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }
    .text-input.disabled:focus {
      box-shadow: none;
      background-image: linear-gradient(-45deg, #f9f9f9 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, #f9f9f9 50%, #f9f9f9 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

.text-disabled {
  color: #bfbfbf !important; }

.disableField {
  pointer-events: none; }

a {
  color: rgba(17, 115, 188, 0.6);
  text-decoration: none; }
  a:hover, a:focus {
    color: #1173BC;
    -webkit-filter: drop-shadow(0 0 1px rgba(17, 115, 188, 0.3)); }

div {
  user-select: none; }

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  resize: none; }

h1 {
  font-size: 32px;
  font-weight: 300; }

::-webkit-input-placeholder {
  color: rgba(128, 128, 128, 0.5); }

::-moz-placeholder {
  color: rgba(128, 128, 128, 0.5); }

:-moz-placeholder {
  color: rgba(128, 128, 128, 0.5); }

:-ms-input-placeholder {
  color: rgba(128, 128, 128, 0.5); }

.special-fullscreen, .blurred-overlay, .modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9000; }

.blurred-overlay {
  z-index: 7000;
  background-color: rgba(0, 0, 0, 0.42);
  opacity: 1;
  transition: opacity 0.12s ease-in;
  pointer-events: none; }
  .blurred-overlay.true ~ div {
    -webkit-filter: blur(8px); }
  .blurred-overlay ~ div {
    transition: -webkit-filter 0s; }
  .blurred-overlay.false {
    opacity: 0; }
  .blurred-overlay.none {
    display: none; }

.placeholder-text-input {
  display: flex;
  flex: 1;
  position: relative; }
  .placeholder-text-input input {
    display: flex;
    flex: 1; }
    .placeholder-text-input input[type='checkbox'] {
      height: 100%;
      margin-left: 10px; }
    .placeholder-text-input input:hover + .placeholder.active, .placeholder-text-input input:focus + .placeholder.active {
      opacity: 1; }
  .placeholder-text-input .placeholder {
    opacity: 0;
    position: absolute;
    right: 10px;
    color: rgba(128, 128, 128, 0.5);
    pointer-events: none; }
    .placeholder-text-input .placeholder.force {
      opacity: 1; }
  .placeholder-text-input + .placeholder-text-input {
    border-left: 1px dashed #e6e6e6; }

.alert {
  position: absolute;
  z-index: 8000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 44px;
  visibility: hidden;
  opacity: 0;
  color: gray;
  cursor: pointer;
  transition: opacity 0.3s ease; }
  .alert span {
    font-weight: 600;
    letter-spacing: 0.0625em;
    text-transform: uppercase; }
  .alert.success {
    visibility: visible;
    opacity: 1;
    color: #7ed321;
    border-bottom: 1px solid #7ed321; }
  .alert.error {
    visibility: visible;
    opacity: 1;
    color: #d0021b;
    border-bottom: 1px solid #d0021b; }
  .alert.warning {
    visibility: visible;
    opacity: 1;
    color: #d67d00;
    border-bottom: 1px solid #d67d00; }
  .alert.info {
    visibility: visible;
    opacity: 1;
    color: #bfbfbf;
    border-bottom: 1px solid #bfbfbf; }
  .alert.reload {
    visibility: visible;
    opacity: 1;
    color: #1173BC;
    border-bottom: 1px solid #1173BC;
    border-bottom: 1px solid #1173BC;
    color: #1173BC; }
  .alert.reload {
    height: 90px; }
  .alert.fixed {
    position: fixed; }

.react-kronos .days {
  line-height: normal; }

.image-container {
  display: flex;
  flex: 1; }
  .image-container + .image-container {
    border-left: 1px dashed #e6e6e6; }
  .image-container .image-current {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 200px; }
  .image-container .image-processing {
    display: flex;
    flex: 1;
    position: relative;
    background-color: rgba(230, 230, 230, 0.5); }
  .image-container .image-detail {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    background-color: rgba(230, 230, 230, 0.2); }
    .image-container .image-detail .image-detail-label {
      padding: 0px 5px;
      font-style: italic;
      color: #d9d9d9; }
  .image-container .image-preview {
    display: flex;
    flex: 1;
    flex-direction: column; }
    .image-container .image-preview .image-preview-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      flex-direction: column;
      background-color: rgba(230, 230, 230, 0.5); }
      .image-container .image-preview .image-preview-wrapper .image-preview-image-wrapper {
        max-height: 115px;
        position: relative; }
        .image-container .image-preview .image-preview-wrapper .image-preview-image-wrapper img {
          max-height: 115px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
    .image-container .image-preview:hover .delete-button {
      display: flex;
      cursor: pointer; }
      .image-container .image-preview:hover .delete-button:hover .red-button {
        background: #d0021b; }
    .image-container .image-preview .delete-button {
      display: none;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      position: absolute;
      right: -22px;
      top: -22px; }
      .image-container .image-preview .delete-button .red-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #ffffff;
        background: rgba(208, 2, 27, 0.4);
        border-radius: 24px;
        width: 22px;
        height: 22px; }
  .image-container .upload-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    border-top: dashed 1px #e6e6e6; }
    .image-container .upload-image:hover {
      background-color: rgba(126, 211, 33, 0.12); }
    .image-container .upload-image:before {
      content: 'Upload Image';
      padding: 0 15px;
      color: #7ed321; }
    .image-container .upload-image ::-webkit-file-upload-button {
      visibility: hidden; }
    .image-container .upload-image input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      opacity: 0;
      cursor: pointer !important; }
    .image-container .upload-image .upload-icon {
      font-size: 24px;
      width: 44px;
      text-align: center;
      pointer-events: none; }
      .image-container .upload-image .upload-icon:before {
        color: #7ed321; }
  .image-container .spinner {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-top: 4px solid #cccccc;
    border-right: 4px solid white;
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .image-container .spinner,
  .image-container .spinner:after {
    border-radius: 50%; }

.empty {
  text-align: center;
  font-style: italic;
  color: #bfbfbf; }

/* Bug workaround for flex issues on Chrome 48 */
* {
  min-width: 0;
  min-height: 0; }

.primary {
  color: #1173BC; }

.progress-bar {
  height: 2px;
  background-color: #1173BC;
  position: relative;
  border-radius: 6px;
  transition: all 500ms; }
  .progress-bar:after {
    position: absolute;
    right: 0;
    height: 2px;
    width: 24px;
    content: "";
    display: block;
    box-shadow: 12px 0 6px 1px rgba(17, 115, 188, 0.6), 10px 0 6px 1px rgba(17, 115, 188, 0.6), 8px 0 6px 1px rgba(17, 115, 188, 0.6), 6px 0 6px 1px rgba(17, 115, 188, 0.6), 4px 0 6px 1px rgba(17, 115, 188, 0.6), 2px 0 6px 1px rgba(17, 115, 188, 0.7), 0 0 6px 1px rgba(17, 115, 188, 0.8), -4px 0 6px 1px rgba(17, 115, 188, 0.7), -8px 0 6px 1px rgba(17, 115, 188, 0.6), -12px 0 6px 1px rgba(17, 115, 188, 0.5), -16px 0 6px 1px rgba(17, 115, 188, 0.4), -20px 0 6px 1px rgba(17, 115, 188, 0.3), -24px 0 6px 1px rgba(17, 115, 188, 0.2); }

.heading {
  position: relative;
  display: flex;
  height: 43px;
  color: gray; }
  .heading > .jaqen {
    width: 44px;
    color: #bfbfbf; }
  .heading > .title {
    cursor: default;
    font-size: 16px;
    line-height: 43px;
    flex: 1;
    padding: 0 11px; }

.list {
  z-index: 1;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  transition: top 0.3s cubic-bezier(0.46, 0.04, 0.52, 0.9);
  flex: 1; }
  .list article:first-child {
    margin-top: 18px; }
  .list article:last-child {
    margin-bottom: 120px; }
    @media (max-height: 1000px) {
      .list article:last-child {
        margin-bottom: 120px; } }
    @media (max-width: 1500px) {
      .list article:last-child {
        margin-bottom: 120px; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .list article:last-child {
        margin-bottom: 120px; } }
    @media (max-width: 1200px) {
      .list article:last-child {
        margin-bottom: 0; } }
    @media (max-width: 800px) {
      .list article:last-child {
        margin-bottom: 0; } }

.container.expanded ~ .list,
.message-heading.expanded ~ .list {
  position: relative;
  top: 0; }

.message-heading.expanded > .title {
  background-color: whitesmoke;
  border-bottom: 1px solid #e6e6e6; }

article {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1px 15px 0;
  line-height: 44px;
  font-size: 16px;
  color: gray;
  background-color: white;
  border-top: 1px solid #e6e6e6; }
  article:last-child {
    border-bottom: 1px solid #e6e6e6; }
    article:last-child .field input[type=text], article:last-child .field input[type=password] {
      height: 42px; }
  article.empty {
    box-shadow: none; }
    article.empty .warning-about-regions {
      line-height: normal;
      color: #d67d00; }
  article.dragging .details {
    display: none; }
  article input[type='text'], article textarea, article input.rw-input {
    border: 0;
    outline: 0;
    padding-left: 10px;
    background-size: 45px 46px;
    color: gray; }
    article input[type='text']:focus, article textarea:focus, article input.rw-input:focus {
      color: black; }
  article .sort-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    user-select: none;
    border-right: 1px solid white;
    flex: none; }
  article .sort-handle-icon-svg {
    height: 24px; }
    article .sort-handle-icon-svg svg {
      height: 100%; }
  article .left-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    user-select: none;
    border-right: 1px solid white; }
    article .left-handle.sort {
      cursor: -moz-grab;
      cursor: -webkit-grab; }
  article .edit-handle {
    color: #bfbfbf;
    cursor: -moz-grab;
    cursor: -webkit-grab; }
  article .reference {
    cursor: pointer;
    border-left: 1px dashed #e6e6e6; }
  article .attribute .sort-handle, article .templates .sort-handle {
    cursor: default; }
  article .secondary-label {
    flex: 1;
    display: flex;
    justify-content: space-between; }
  article label {
    padding: 0 10px;
    font-style: italic; }
  article:not(.hovering) .jaqen.postscript {
    color: #4ed4b7; }
  article.expanded:not(.region) .sort-handle {
    border-right: 1px solid #e6e6e6; }
  article.expanded:not(.region) .left-handle {
    border-right: 1px solid #e6e6e6; }

section.title {
  display: flex; }
  section.title .name {
    display: flex;
    flex: 1;
    cursor: text;
    outline: none;
    min-height: 43px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    section.title .name:hover a {
      color: #1173BC; }
    section.title .name textarea, section.title .name input {
      flex: 0;
      padding-left: 0px; }
    section.title .name span,
    section.title .name .message-title {
      padding: 0 10px;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    section.title .name a {
      cursor: pointer;
      flex: 1;
      display: block;
      padding-left: 10px;
      padding-right: 15px;
      text-decoration: none;
      line-height: 44px;
      color: gray;
      -webkit-user-drag: none;
      overflow: hidden;
      text-overflow: ellipsis; }
    section.title .name label {
      padding: 0 0 0 10px; }
    section.title .name .campus-label {
      font-size: 14px;
      font-style: italic;
      color: #4ed4b7;
      pointer-events: none; }

article.expanded section.title .name {
  flex: 1;
  display: flex; }
  article.expanded section.title .name textarea, article.expanded section.title .name input {
    flex: 1;
    padding: 12px 10px; }
  article.expanded section.title .name a, article.expanded section.title .name span, article.expanded section.title .name label, article.expanded section.title .name .campus-label {
    display: none; }

.incomplete-label {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: italic;
  color: #d67d00;
  pointer-events: none; }
  .incomplete-label span {
    padding: 0; }

.list > article.container:not(.expanded) .title .name:hover a {
  color: #1173BC; }

.list > article.item:not(.expanded) .title .name:hover a {
  color: gray; }

.list > article.user-row:not(.expanded) .title .name:hover a {
  color: #1173BC; }

.list > article.message.draft:not(.expanded) .title .name:hover a {
  color: #d67d00; }

.list > article.message.scheduled:not(.expanded) .title .name:hover a {
  color: #7ed321; }

.list > article.message.sending:not(.expanded) .title .name:hover a {
  color: #1173BC; }

.list > article.message.complete:not(.expanded) .title .name:hover a {
  color: #bfbfbf; }

.list > article.message.failed:not(.expanded) .title .name:hover a {
  color: #bfbfbf; }

.list > article.message.deleted:not(.expanded) .title .name:hover a {
  color: #d0021b; }

.list > article.region:not(.expanded) .title .name:hover a {
  color: #d0021b; }

article.container .icon {
  color: #1173BC; }

article.container .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.container .sort-handle {
  color: #1173BC; }

article.container .left-handle {
  color: #1173BC; }

article.container .right-handle {
  color: #1173BC; }

article.container .reference {
  color: #bfbfbf; }

article.container .title > [class*='-handle']:hover, article.container .reference:hover {
  color: #1173BC; }

article.container.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #1173BC, -2px 0 0 #1173BC; }

article.container.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #1173BC, -6px 0 0 1px #1173BC; }
  article.container.expanded .name a {
    cursor: text; }

article.container-ccb .icon {
  color: #7A5200; }

article.container-ccb .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.container-ccb .sort-handle {
  color: #7A5200; }

article.container-ccb .left-handle {
  color: #7A5200; }

article.container-ccb .right-handle {
  color: #7A5200; }

article.container-ccb .reference {
  color: #bfbfbf; }

article.container-ccb .title > [class*='-handle']:hover, article.container-ccb .reference:hover {
  color: #7A5200; }

article.container-ccb.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #7A5200, -2px 0 0 #7A5200; }

article.container-ccb.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #7A5200, -6px 0 0 1px #7A5200; }
  article.container-ccb.expanded .name a {
    cursor: text; }

article.item .icon {
  color: gray; }

article.item .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.item .sort-handle {
  color: gray; }

article.item .left-handle {
  color: gray; }

article.item .right-handle {
  color: gray; }

article.item .reference {
  color: #bfbfbf; }

article.item .title > [class*='-handle']:hover, article.item .reference:hover {
  color: gray; }

article.item.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 gray, -2px 0 0 gray; }

article.item.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px gray, -6px 0 0 1px gray; }
  article.item.expanded .name a {
    cursor: text; }

article.message-heading .icon {
  color: #bfbfbf; }

article.message-heading .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message-heading .sort-handle {
  color: #bfbfbf; }

article.message-heading .left-handle {
  color: #bfbfbf; }

article.message-heading .right-handle {
  color: #bfbfbf; }

article.message-heading .reference {
  color: #bfbfbf; }

article.message-heading .title > [class*='-handle']:hover, article.message-heading .reference:hover {
  color: #bfbfbf; }

article.message-heading.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #bfbfbf, -2px 0 0 #bfbfbf; }

article.message-heading.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #bfbfbf, -6px 0 0 1px #bfbfbf; }
  article.message-heading.expanded .name a {
    cursor: text; }

article.user-row .icon {
  color: #1173BC; }

article.user-row .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.user-row .sort-handle {
  color: #1173BC; }

article.user-row .left-handle {
  color: #1173BC; }

article.user-row .right-handle {
  color: #1173BC; }

article.user-row .reference {
  color: #bfbfbf; }

article.user-row .title > [class*='-handle']:hover, article.user-row .reference:hover {
  color: #1173BC; }

article.user-row.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #1173BC, -2px 0 0 #1173BC; }

article.user-row.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #1173BC, -6px 0 0 1px #1173BC; }
  article.user-row.expanded .name a {
    cursor: text; }

article.message.draft .icon {
  color: #d67d00; }

article.message.draft .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message.draft .sort-handle {
  color: #d67d00; }

article.message.draft .left-handle {
  color: #d67d00; }

article.message.draft .right-handle {
  color: #d67d00; }

article.message.draft .reference {
  color: #bfbfbf; }

article.message.draft .title > [class*='-handle']:hover, article.message.draft .reference:hover {
  color: #d67d00; }

article.message.draft.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #d67d00, -2px 0 0 #d67d00; }

article.message.draft.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #d67d00, -6px 0 0 1px #d67d00; }
  article.message.draft.expanded .name a {
    cursor: text; }

article.message.scheduled .icon {
  color: #7ed321; }

article.message.scheduled .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message.scheduled .sort-handle {
  color: #7ed321; }

article.message.scheduled .left-handle {
  color: #7ed321; }

article.message.scheduled .right-handle {
  color: #7ed321; }

article.message.scheduled .reference {
  color: #bfbfbf; }

article.message.scheduled .title > [class*='-handle']:hover, article.message.scheduled .reference:hover {
  color: #7ed321; }

article.message.scheduled.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #7ed321, -2px 0 0 #7ed321; }

article.message.scheduled.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #7ed321, -6px 0 0 1px #7ed321; }
  article.message.scheduled.expanded .name a {
    cursor: text; }

article.message.sending .icon {
  color: #1173BC; }

article.message.sending .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message.sending .sort-handle {
  color: #1173BC; }

article.message.sending .left-handle {
  color: #1173BC; }

article.message.sending .right-handle {
  color: #1173BC; }

article.message.sending .reference {
  color: #bfbfbf; }

article.message.sending .title > [class*='-handle']:hover, article.message.sending .reference:hover {
  color: #1173BC; }

article.message.sending.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #1173BC, -2px 0 0 #1173BC; }

article.message.sending.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #1173BC, -6px 0 0 1px #1173BC; }
  article.message.sending.expanded .name a {
    cursor: text; }

article.message.complete .icon {
  color: #bfbfbf; }

article.message.complete .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message.complete .sort-handle {
  color: #bfbfbf; }

article.message.complete .left-handle {
  color: #bfbfbf; }

article.message.complete .right-handle {
  color: #bfbfbf; }

article.message.complete .reference {
  color: #bfbfbf; }

article.message.complete .title > [class*='-handle']:hover, article.message.complete .reference:hover {
  color: #bfbfbf; }

article.message.complete.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #bfbfbf, -2px 0 0 #bfbfbf; }

article.message.complete.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #bfbfbf, -6px 0 0 1px #bfbfbf; }
  article.message.complete.expanded .name a {
    cursor: text; }

article.message.failed .icon {
  color: #bfbfbf; }

article.message.failed .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message.failed .sort-handle {
  color: #bfbfbf; }

article.message.failed .left-handle {
  color: #bfbfbf; }

article.message.failed .right-handle {
  color: #bfbfbf; }

article.message.failed .reference {
  color: #bfbfbf; }

article.message.failed .title > [class*='-handle']:hover, article.message.failed .reference:hover {
  color: #bfbfbf; }

article.message.failed.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #bfbfbf, -2px 0 0 #bfbfbf; }

article.message.failed.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #bfbfbf, -6px 0 0 1px #bfbfbf; }
  article.message.failed.expanded .name a {
    cursor: text; }

article.message.deleted .icon {
  color: #d0021b; }

article.message.deleted .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.message.deleted .sort-handle {
  color: #d0021b; }

article.message.deleted .left-handle {
  color: #d0021b; }

article.message.deleted .right-handle {
  color: #d0021b; }

article.message.deleted .reference {
  color: #bfbfbf; }

article.message.deleted .title > [class*='-handle']:hover, article.message.deleted .reference:hover {
  color: #d0021b; }

article.message.deleted.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #d0021b, -2px 0 0 #d0021b; }

article.message.deleted.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #d0021b, -6px 0 0 1px #d0021b; }
  article.message.deleted.expanded .name a {
    cursor: text; }

article.region .icon {
  color: #d0021b; }

article.region .reset-password-button {
  color: #bfbfbf;
  border-left: 1px dashed #e6e6e6;
  cursor: pointer; }

article.region .sort-handle {
  color: #d0021b; }

article.region .left-handle {
  color: #d0021b; }

article.region .right-handle {
  color: #d0021b; }

article.region .reference {
  color: #bfbfbf; }

article.region .title > [class*='-handle']:hover, article.region .reference:hover {
  color: #d0021b; }

article.region.hovering {
  box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6, 2px 0 0 #d0021b, -2px 0 0 #d0021b; }

article.region.expanded {
  background-color: white;
  z-index: 250;
  box-shadow: 6px 0 0 1px #d0021b, -6px 0 0 1px #d0021b; }
  article.region.expanded .name a {
    cursor: text; }

article.container.expanded .title .title-text {
  display: inline;
  background-size: 45px 46px;
  color: #3F3F3F;
  -webkit-user-select: text; }

article.container input[type='text']:focus, article.container textarea:focus {
  background-image: linear-gradient(-45deg, rgba(17, 115, 188, 0.05) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(17, 115, 188, 0.05) 50%, rgba(17, 115, 188, 0.05) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article.item.expanded .title .title-text {
  display: inline;
  background-size: 45px 46px;
  color: #3F3F3F;
  -webkit-user-select: text; }

article.item input[type='text']:focus, article.item textarea:focus {
  background-image: linear-gradient(-45deg, rgba(128, 128, 128, 0.06) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(128, 128, 128, 0.06) 50%, rgba(128, 128, 128, 0.06) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article.user-row.expanded .title .title-text {
  display: inline;
  background-size: 45px 46px;
  color: #3F3F3F;
  -webkit-user-select: text; }

article.user-row input[type='text']:focus, article.user-row textarea:focus {
  background-image: linear-gradient(-45deg, rgba(17, 115, 188, 0.05) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(17, 115, 188, 0.05) 50%, rgba(17, 115, 188, 0.05) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article.message.draft.expanded .title .title-text {
  display: inline;
  background-size: 45px 46px;
  color: #3F3F3F;
  -webkit-user-select: text; }

article.message.draft input[type='text']:focus, article.message.draft textarea:focus {
  background-image: linear-gradient(-45deg, rgba(214, 125, 0, 0.08) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(214, 125, 0, 0.08) 50%, rgba(214, 125, 0, 0.08) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article.message.scheduled.expanded .title .title-text {
  display: inline;
  background-size: 45px 46px;
  color: #3F3F3F;
  -webkit-user-select: text; }

article.message.scheduled input[type='text']:focus, article.message.scheduled textarea:focus {
  background-image: linear-gradient(-45deg, rgba(126, 211, 33, 0.08) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(126, 211, 33, 0.08) 50%, rgba(126, 211, 33, 0.08) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article.region.expanded .title .title-text {
  display: inline;
  background-size: 45px 46px;
  color: #3F3F3F;
  -webkit-user-select: text; }

article.region input[type='text']:focus, article.region textarea:focus {
  background-image: linear-gradient(-45deg, rgba(208, 2, 27, 0.05) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(208, 2, 27, 0.05) 50%, rgba(208, 2, 27, 0.05) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article .property input[type='text']:focus, article .property textarea:focus {
  background-image: linear-gradient(-45deg, rgba(214, 125, 0, 0.08) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(214, 125, 0, 0.08) 50%, rgba(214, 125, 0, 0.08) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }

article.container label, article .container label {
  color: #1173BC; }

article.item label, article .item label {
  color: gray; }

article.property label, article .property label {
  color: #d67d00; }

article.attribute.campus label, article .attribute.campus label {
  color: #4ed4b7; }

article.region label, article .region label {
  color: #d0021b; }

article.container .attribute.solid-separated {
  box-shadow: 0 1px 0 #1173BC, 0 -1px 0 #1173BC; }

article.item .attribute.solid-separated {
  box-shadow: 0 1px 0 gray, 0 -1px 0 gray; }

article.message.draft .attribute.solid-separated {
  box-shadow: 0 1px 0 #d67d00, 0 -1px 0 #d67d00; }

article.message.scheduled .attribute.solid-separated {
  box-shadow: 0 1px 0 #7ed321, 0 -1px 0 #7ed321; }

article.message.complete .attribute.solid-separated {
  box-shadow: 0 1px 0 #bfbfbf, 0 -1px 0 #bfbfbf; }

article.message.failed .attribute.solid-separated {
  box-shadow: 0 1px 0 #bfbfbf, 0 -1px 0 #bfbfbf; }

.attitude-poll-results {
  position: relative;
  border-top: dashed 1px #e6e6e6;
  background: white;
  padding-left: 10px;
  padding-right: 10px; }
  .attitude-poll-results .title {
    font-size: 14px;
    color: #1173BC;
    margin: 10px 0 5px; }

section.details .property, section.details .attribute {
  position: relative;
  border-top: dashed 1px #e6e6e6;
  background: white; }

section.details .property:not(:last-child) {
  border-bottom: dashed 1px #e6e6e6;
  margin-bottom: -1px; }

section.details .templates {
  border-top: dashed 1px #e6e6e6;
  height: 77px; }

section.details .solid-separated {
  margin-top: 1px;
  border: 0;
  z-index: 12; }

section.details .card-definition,
section.details .related-content-setting {
  border-top: dashed 1px #e6e6e6;
  background: #f7f7f8;
  align-items: center;
  padding: 10px;
  display: flex;
  color: #757575; }

section.details .card-properties {
  flex-direction: column; }

.attributes .attribute {
  background: white;
  display: flex; }
  .attributes .attribute.hidden {
    height: 0;
    opacity: 0;
    border: 0;
    display: none; }

.attributes .campus .sort-handle {
  color: #4ed4b7;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.attributes .color .sort-handle {
  color: #4ed4b7;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.attributes .restricted .sort-handle, .attributes .restricted label {
  color: #d0021b; }

.attributes .templates {
  display: flex;
  background: white; }

.attributes .template-picker {
  flex: 1;
  display: flex;
  flex-wrap: wrap; }

.attributes .template {
  display: flex;
  cursor: pointer;
  border-right: dashed 1px #e6e6e6;
  transition: all 0.33s ease-out; }
  .attributes .template path, .attributes .template circle, .attributes .template rect, .attributes .template polygon {
    fill: #bfbfbf; }
  .attributes .template .options {
    width: 120px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    transition: all 0.33s ease-out; }
    .attributes .template .options > div {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .attributes .template .options > div > label {
        margin: 0;
        padding: 0; }
      .attributes .template .options > div > input {
        margin-left: 10px; }
  .attributes .template:not(.expanded) .options {
    width: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none; }
  .attributes .template.expanded .options {
    background-color: rgba(255, 255, 255, 0.65);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.08); }

.attributes .upload {
  position: relative;
  width: 25%;
  cursor: pointer !important; }
  .attributes .upload:hover {
    background-color: rgba(126, 211, 33, 0.12); }
  .attributes .upload:before {
    content: 'Upload image';
    padding: 0 15px;
    color: #7ed321; }
  .attributes .upload ::-webkit-file-upload-button {
    visibility: hidden; }
  .attributes .upload input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer !important; }
  .attributes .upload .upload-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 24px;
    width: 44px;
    text-align: center;
    pointer-events: none; }
    .attributes .upload .upload-icon:before {
      color: #7ed321; }

.attributes .image {
  display: flex; }
  .attributes .image .image-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column; }
    .attributes .image .image-wrapper .image-heading {
      background-color: #fafafa;
      border-bottom: 1px dashed #e6e6e6;
      display: flex;
      justify-content: space-between; }
      .attributes .image .image-wrapper .image-heading > div {
        flex: 1;
        padding: 0 15px; }
        .attributes .image .image-wrapper .image-heading > div .dimensions {
          font-style: italic;
          font-size: 13px;
          color: #a6a6a6; }
        .attributes .image .image-wrapper .image-heading > div + div {
          border-left: 1px dashed #e6e6e6; }
    .attributes .image .image-wrapper .image-content {
      display: flex;
      margin: 0;
      padding: 0;
      height: 135px;
      font-size: 0;
      line-height: 0; }
      .attributes .image .image-wrapper .image-content .image-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e6e6e6;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.08); }
        .attributes .image .image-wrapper .image-content .image-item + .image-item {
          border-left: 1px solid white; }
        .attributes .image .image-wrapper .image-content .image-item img {
          max-width: 100%;
          max-height: 115px;
          margin: auto; }
        .attributes .image .image-wrapper .image-content .image-item > img {
          opacity: 0.33;
          outline: 1px solid rgba(0, 0, 0, 0.33); }
        .attributes .image .image-wrapper .image-content .image-item .image-crop {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%; }
          .attributes .image .image-wrapper .image-content .image-item .image-crop img {
            opacity: 0;
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.33); }
          .attributes .image .image-wrapper .image-content .image-item .image-crop img.image-full-opacity {
            opacity: 1; }
  .attributes .image .spinner {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-top: 4px solid #cccccc;
    border-right: 4px solid white;
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .attributes .image .spinner,
  .attributes .image .spinner:after {
    border-radius: 50%; }

.attributes textarea {
  white-space: normal;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: initial;
  line-height: 1.2;
  padding: 13px 10px;
  width: 100%;
  resize: none;
  background-color: rgba(255, 255, 255, 0); }

article.container .template.expanded {
  background-color: rgba(17, 115, 188, 0.08); }
  article.container .template.expanded path, article.container .template.expanded circle, article.container .template.expanded rect, article.container .template.expanded polygon {
    fill: #1173BC; }

article.item .template.expanded {
  background-color: rgba(128, 128, 128, 0.08); }
  article.item .template.expanded path, article.item .template.expanded circle, article.item .template.expanded rect, article.item .template.expanded polygon {
    fill: gray; }

.properties {
  display: flex;
  flex-direction: column; }
  .properties .property {
    display: flex; }
    .properties .property .sort-handle {
      background: white;
      color: #d67d00; }
  .properties .prop-keys, .properties .prop-key {
    display: flex;
    justify-content: space-between;
    flex: 1; }
    .properties .prop-keys label, .properties .prop-key label {
      margin: auto 0; }
  .properties input {
    flex: 1; }
    .properties input[type='checkbox'] {
      flex: initial;
      margin: auto 15px; }
  .properties textarea {
    white-space: normal;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: initial;
    line-height: 1.2;
    padding: 13px 10px;
    width: 100%;
    resize: none; }
  .properties .done {
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(126, 211, 33, 0.1);
    color: #7ed321; }
    .properties .done:hover {
      cursor: pointer; }
  .properties .hidden {
    display: none; }

.property.rich-text-editor {
  max-height: 246px;
  line-height: 1.5; }
  .property.rich-text-editor .juicy-rich-text-editor {
    overflow: scroll;
    word-break: break-word; }
  .property.rich-text-editor .html-preview {
    overflow: scroll;
    word-break: break-word;
    padding: 0px 15px;
    overflow: auto;
    width: 100%;
    display: block; }
    .property.rich-text-editor .html-preview:before, .property.rich-text-editor .html-preview:after {
      content: '';
      position: absolute;
      left: 0px;
      height: 15px;
      width: 100%;
      z-index: 1; }
    .property.rich-text-editor .html-preview:before {
      top: 0px;
      background-image: linear-gradient(white, rgba(255, 255, 255, 0)); }
    .property.rich-text-editor .html-preview:after {
      bottom: 0px;
      background-image: linear-gradient(rgba(255, 255, 255, 0), white); }

.property .geocode {
  width: 44px;
  cursor: pointer;
  border-left: 1px dashed #e6e6e6;
  background-color: rgba(126, 211, 33, 0.1);
  color: #7ed321; }
  .property .geocode.disabled {
    background-color: white;
    cursor: not-allowed; }
  .property .geocode .spinner {
    position: relative;
    top: 8px;
    left: 8px;
    width: 28px;
    height: 28px;
    border-top: 2px solid #7ed321;
    border-right: 2px solid rgba(126, 211, 33, 0.25);
    border-bottom: 2px solid rgba(126, 211, 33, 0.25);
    border-left: 2px solid rgba(126, 211, 33, 0.25);
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .property .geocode .spinner,
  .property .geocode .spinner:after {
    border-radius: 50%; }

.attribute .run-feed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-left: 1px dashed #e6e6e6;
  background-color: rgba(78, 212, 183, 0.1);
  color: #4ed4b7; }
  .attribute .run-feed.disabled {
    background-color: white;
    cursor: not-allowed; }
  .attribute .run-feed .spinner {
    position: relative;
    top: 0px;
    left: 0px;
    width: 28px;
    height: 28px;
    border-top: 2px solid #4ed4b7;
    border-right: 2px solid rgba(78, 212, 183, 0.25);
    border-bottom: 2px solid rgba(78, 212, 183, 0.25);
    border-left: 2px solid rgba(78, 212, 183, 0.25);
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .attribute .run-feed .spinner,
  .attribute .run-feed .spinner:after {
    border-radius: 50%; }

.attr-bos, .prop-bos {
  flex: 1;
  display: flex;
  flex-direction: column; }

.value {
  display: flex;
  justify-content: space-between;
  flex: 1; }
  .value input {
    flex: 1; }
  .value textarea {
    flex: 1;
    padding: 12px 10px; }
  .value > div {
    display: flex; }
  .value > span {
    padding: 0 15px 0 10px; }
  .value.pad {
    padding-left: 10px; }
  .value.zero {
    flex: initial; }
  .value.double {
    flex: 2; }
  .value.triple {
    flex: 3; }
  .value.selected input.rw-input {
    color: #404040 !important;
    background-color: rgba(128, 128, 128, 0.08); }
  .value.unselected input.rw-input, .value.unselected label, .value.unselected span {
    color: #bfbfbf !important; }
  .value.option {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .value.option > input[type='checkbox'] {
      flex: initial;
      margin: 0 15px; }
  .value.vertical {
    flex-direction: column;
    padding-top: 12px; }
    .value.vertical > span {
      width: 100%;
      line-height: 1;
      padding-bottom: 5px; }
  .value.disabled {
    color: #e6e6e6;
    cursor: default;
    pointer-events: none; }
  .value .border-left {
    border-left: dashed 1px #e6e6e6; }

.value-row {
  height: 45px;
  display: flex; }
  .value-row:first-child {
    height: 44px; }
  .value-row.double {
    height: 90px; }
  .value-row.triple {
    height: 135px; }
  .value-row.unconstrained {
    height: auto; }
  .value-row.show-dashed-border {
    height: 45px;
    border-top: dashed 1px #e6e6e6; }
  .value-row.column {
    flex-direction: column; }
  .value-row + .value-row {
    border-top: dashed 1px #e6e6e6; }
  .value-row .value + .value {
    border-left: dashed 1px #e6e6e6; }

.valid-input {
  display: flex;
  flex: 1; }
  .valid-input .icon {
    position: absolute;
    right: 0; }
    .valid-input .icon.valid {
      color: #7ed321; }
    .valid-input .icon.invalid {
      color: #d0021b; }

.row-layer {
  display: flex; }

.row-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0 0 10px 10px; }
  .row-container .sub-section {
    display: flex;
    align-items: center; }
    .row-container .sub-section span {
      padding-left: 10px; }
    .row-container .sub-section:not(:first-child) {
      padding-left: 10px; }

.switch-container {
  display: flex;
  align-items: center;
  outline: none;
  border-style: solid;
  border-width: 1px;
  cursor: pointer; }

.switch-container-lock {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 50%; }

.switch-background {
  flex: 1;
  height: 100%; }

.switch-button {
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s; }

.card-definition-greyout {
  opacity: 0.7; }

.card-definition-section,
.related-content-section {
  display: flex;
  align-items: center;
  flex: 1; }

.card-definition-label,
.related-content-label {
  font-size: 15px;
  padding-right: 12px; }

.related-content-label-wrapper {
  line-height: 100%;
  margin-left: 10px;
  align-items: baseline; }

.card-properties-container {
  display: flex; }

.card-properties-detail-container {
  flex-direction: column;
  flex: 1; }
  .card-properties-detail-container .text-container {
    display: flex;
    flex-direction: column;
    flex: 1; }

.card-definition-cta {
  align-items: center;
  justify-content: center;
  flex: 1;
  fill: grey;
  padding: 0 7px; }

.card-definition-disabled {
  cursor: not-allowed;
  opacity: 0.5; }

.related-content-custom-title .value-row {
  flex: 1; }

.action-bar-hide-label {
  line-height: 1.2; }

.action-bar-dropdown-list .rw-dropdown-list-input {
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  padding-left: 10px; }

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

.right, .row-layer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 352px; }
  @media (max-height: 1000px) {
    .right, .row-layer {
      margin: 0; } }
  @media (max-width: 1500px) {
    .right, .row-layer {
      margin: 0; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .right, .row-layer {
      margin: 0; } }
  @media (max-width: 1200px) {
    .right, .row-layer {
      margin: 0; } }
  @media (max-width: 800px) {
    .right, .row-layer {
      margin: 0 15px; } }
  @media (max-height: 1000px) {
    .right, .row-layer {
      width: 240px; } }
  @media (max-width: 1500px) {
    .right, .row-layer {
      width: 240px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .right, .row-layer {
      width: 240px; } }
  @media (max-width: 1200px) {
    .right, .row-layer {
      width: 240px; } }
  @media (max-width: 800px) {
    .right, .row-layer {
      width: 100%; } }

.tabs {
  display: flex;
  height: 44px; }
  .tabs:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 1px;
    right: 1px;
    height: 15px;
    background-image: linear-gradient(#fafafa, rgba(250, 250, 250, 0)); }
  .tabs > div {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #bfbfbf;
    border-top: 1px solid rgba(255, 255, 255, 0);
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0); }
    .tabs > div.active {
      color: gray;
      border-top: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      background-color: #fafafa; }
      .tabs > div.active:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0px;
        height: 1px;
        width: 100%;
        z-index: 1;
        background-color: #fafafa; }

.tray {
  min-height: 112px;
  padding-bottom: 16px;
  border-top: 1px solid #d9d9d9;
  background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#fafafa, rgba(255, 255, 255, 0));
  background-size: 1px 100%, 1px 100%, 100% 100%;
  background-position: 0 0, 100% 0, 0 0;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
  position: absolute;
  top: 44px;
  left: 0px;
  right: 0px;
  bottom: 0px; }
  @media (max-height: 1000px) {
    .tray {
      position: absolute; } }
  @media (max-width: 1500px) {
    .tray {
      position: absolute; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .tray {
      position: absolute; } }
  @media (max-width: 1200px) {
    .tray {
      position: initial; } }
  @media (max-width: 800px) {
    .tray {
      position: initial; } }
  .tray::-webkit-scrollbar {
    width: 0px; }
  .tray::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-clip: padding-box;
    border: 3px solid rgba(0, 0, 0, 0); }
  .tray:hover::-webkit-scrollbar {
    width: 10px; }

.right .container, .row-layer .container {
  color: #1173BC; }

.right .container-ccb, .row-layer .container-ccb {
  color: #7A5200; }

.right .unique-container, .row-layer .unique-container {
  color: #9763db; }

.right .item, .row-layer .item {
  color: gray; }

.right .attribute, .row-layer .attribute {
  color: #4ed4b7; }

.right .property, .row-layer .property {
  color: #d67d00; }

.right .message, .row-layer .message {
  color: #1173BC; }

.right .action, .row-layer .action {
  color: #9763db; }

.right .target, .row-layer .target {
  color: #d0021b; }

.right .droppable, .row-layer .droppable {
  color: #7ed321; }

.right .region, .row-layer .region {
  color: #d0021b; }

.right .message_type, .row-layer .message_type {
  color: #7ed321; }

.draggable, .pup {
  display: flex;
  align-items: center;
  cursor: -webkit-grab;
  cursor: -moz-grab; }
  .draggable .label, .pup .label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.draggable {
  flex-direction: column;
  width: 96px;
  height: 96px;
  background: white;
  border: 1px solid #e6e6e6;
  margin: 16px 0 0 16px;
  position: relative; }
  .draggable > .draggable-icon {
    font-size: 48px;
    flex: 1;
    margin-top: 12px; }
    .draggable > .draggable-icon svg {
      height: 100%; }
  .draggable > .draggable-icon-svg {
    height: 100%;
    justify-content: center; }
  .draggable .label {
    color: #bfbfbf;
    height: 36px; }

.draggable-group {
  width: 100%;
  margin-top: 30px; }
  .draggable-group:first-child {
    margin-top: 15px; }

.draggables-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start; }

.draggable-group-title {
  margin-left: 16px;
  margin-right: 16px;
  color: white;
  border-radius: 3px;
  line-height: 25px;
  font-weight: normal;
  text-align: center;
  min-width: 208px; }
  .draggable-group-title-pushpay {
    background-color: #1173BC; }
  .draggable-group-title-ccb {
    background-color: #7A5200; }

.droppables {
  display: flex;
  flex-direction: column;
  flex: 1; }
  .droppables .tray {
    position: relative;
    top: 0px;
    height: auto;
    min-height: 44px;
    overflow: visible; }
    .droppables .tray:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -16px;
      left: 1px;
      right: 1px;
      height: 15px;
      background-image: linear-gradient(#e0e0e0, rgba(224, 224, 224, 0)); }
  .droppables .droppable:not(.dragging)::before {
    content: attr(data-tip);
    font-size: 12px;
    position: absolute;
    z-index: 999;
    top: 112px;
    left: -32px;
    background: rgba(0, 0, 0, 0.8);
    color: #e0e0e0;
    padding: 8px 12px 10px;
    border-radius: 4px;
    line-height: 1.6;
    width: 136px;
    opacity: 0;
    transition: opacity 0.3s ease-out 0.1s;
    pointer-events: none; }
  .droppables .droppable:not(.dragging):hover::before {
    opacity: 1;
    overflow: visible;
    transition: opacity 0.3s ease-out 0.3s; }
  .droppables.dragging::before {
    background: blue; }

.pouch-holder {
  position: relative;
  flex: 1; }

.pouch {
  flex: 1;
  padding: 12px 0 66px 0;
  border-top: 1px solid #bfbfbf;
  background-image: linear-gradient(#bfbfbf, rgba(255, 255, 255, 0)), linear-gradient(#bfbfbf, rgba(255, 255, 255, 0)), linear-gradient(#e0e0e0, rgba(255, 255, 255, 0));
  background-size: 1px 100%, 1px 100%, 100% 100%;
  background-position: 0 0, 100% 0, 0 0;
  background-repeat: no-repeat;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .pouch .warning {
    color: #1173BC;
    padding: 0 15px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.pouched {
  display: flex;
  align-items: center;
  height: 36px;
  background: white;
  border: 1px solid #d9d9d9;
  margin: 4px 16px 0 16px; }
  .pouched > .pouched-icon {
    font-size: 16px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .pouched .label {
    font-size: 14px;
    padding-right: 8px;
    flex: 1;
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.instructions {
  flex: 1 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 12px;
  color: #bfbfbf; }

.empty-pouch {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), white); }
  .empty-pouch .button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    background: white;
    border-radius: 4px;
    font-size: 15px;
    color: #d0021b;
    font-weight: 600;
    letter-spacing: 0.0625em;
    border: 1px solid #d0021b;
    margin: 15px 16px; }
  .empty-pouch.disabled .button {
    cursor: default;
    color: #bfbfbf;
    border: 1px solid #bfbfbf; }

.pouch-layer {
  display: flex; }
  .pouch-layer .draggable.droppable {
    width: 48px;
    height: 48px;
    margin: 4px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pouch-layer .draggable.droppable > .draggable-icon {
      font-size: 24px;
      flex: 1; }

.stat-icon {
  position: relative;
  top: 4px;
  font-size: 12px;
  font-weight: bold; }

.compose-button {
  height: 45px;
  line-height: 45px;
  margin-bottom: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #1173BC;
  border-radius: 4px;
  border: 1px solid #d9d9d9; }
  .compose-button:not(.disabled):not(.active):not(.cancel):hover {
    border-color: #1173BC; }
  .compose-button.active {
    color: #d67d00; }
  .compose-button.cancel {
    color: #d0021b; }
  .compose-button.is-committed {
    color: #7ed321; }
  .compose-button.disabled {
    color: #bfbfbf;
    cursor: wait; }

.auth-error-button {
  padding: 0 10px 0 10px;
  background-color: white; }

.creation-tab {
  border: 1px solid #e6e6e6;
  margin: 0 15px;
  position: relative; }
  .creation-tab .covering-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(110, 110, 110, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .creation-tab .spinner {
    position: relative;
    top: 0px;
    left: 0px;
    width: 96px;
    height: 96px;
    border-top: 4px solid rgba(0, 0, 0, 0.25);
    border-right: 4px solid rgba(255, 255, 255, 0.5);
    border-bottom: 4px solid rgba(255, 255, 255, 0.5);
    border-left: 4px solid rgba(255, 255, 255, 0.5);
    animation: spinner 2s infinite;
    animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
    animation-fill-mode: both; }
  .creation-tab .spinner,
  .creation-tab .spinner:after {
    border-radius: 50%; }
  .creation-tab .field-group .field {
    margin-bottom: 10px;
    background: white; }
  .creation-tab .field-group .field:first-child {
    border-top: 1px solid #e6e6e6; }
  .creation-tab .field-group .field:last-child {
    margin-bottom: 20px; }
  .creation-tab .field-simple, .creation-tab .field {
    margin-top: 10px;
    display: flex;
    flex-direction: column; }
    .creation-tab .field-simple p, .creation-tab .field p {
      padding: 10px; }
  .creation-tab .field-center {
    align-items: center; }
  .creation-tab .field input[type='text'], .creation-tab .field textarea, .creation-tab .field input.rw-input {
    flex: 1;
    border: 0;
    outline: 0;
    padding: 15px 10px;
    background-size: 45px 46px;
    color: gray;
    background-image: linear-gradient(-45deg, rgba(128, 128, 128, 0.06) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(128, 128, 128, 0.06) 50%, rgba(128, 128, 128, 0.06) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }
    .creation-tab .field input[type='text']:focus, .creation-tab .field textarea:focus, .creation-tab .field input.rw-input:focus {
      box-shadow: inset 0 0 3px rgba(17, 115, 188, 0.2);
      background-image: linear-gradient(-45deg, rgba(17, 115, 188, 0.05) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(17, 115, 188, 0.05) 50%, rgba(17, 115, 188, 0.05) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0));
      color: black; }
  .creation-tab .field-striped input[type='text'], .creation-tab .field-striped textarea {
    background-image: linear-gradient(-45deg, rgba(128, 128, 128, 0.06) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(128, 128, 128, 0.06) 50%, rgba(128, 128, 128, 0.06) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0)); }
  .creation-tab .field-draggable:hover {
    cursor: pointer; }
  .creation-tab .field .answer-label {
    font-size: 16px;
    color: rgba(128, 128, 128, 0.8);
    padding-top: 0px;
    padding-bottom: 10px; }
    .creation-tab .field .answer-label:last-of-type {
      padding-bottom: 25px; }
  .creation-tab .field .composer-header {
    padding: 10px; }
  .creation-tab .field .content-toggle-header {
    float: right; }
  .creation-tab .field .content-toggle {
    display: inline-block;
    margin-left: 16px; }
  .creation-tab .field .content-tooltip {
    display: inline-block; }
  .creation-tab .field .tooltip {
    width: 20px;
    display: inline-block;
    margin-left: 6px; }
  .creation-tab .field .tooltip-icon {
    position: relative;
    top: 4px;
    font-size: 20px;
    color: #bfbfbf; }
  .creation-tab .field .message-image-upload {
    position: relative;
    width: 25%;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 20px;
    bottom: 12px;
    left: 12px; }
    .creation-tab .field .message-image-upload:before {
      content: 'Upload Image';
      padding: 10px 20px;
      color: white;
      background-color: #0da8da; }
  .creation-tab .field .message-image-upload-input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  .creation-tab .field .uploading-message-image {
    padding: 12px; }
    .creation-tab .field .uploading-message-image .spinner {
      position: relative;
      top: 0px;
      left: 0px;
      width: 24px;
      height: 24px;
      border-top: 4px solid rgba(0, 0, 0, 0.25);
      border-right: 4px solid rgba(255, 255, 255, 0.5);
      border-bottom: 4px solid rgba(255, 255, 255, 0.5);
      border-left: 4px solid rgba(255, 255, 255, 0.5);
      animation: spinner 2s infinite;
      animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
      animation-fill-mode: both; }
    .creation-tab .field .uploading-message-image .spinner,
    .creation-tab .field .uploading-message-image .spinner:after {
      border-radius: 50%; }
    .creation-tab .field .uploading-message-image .spinner {
      display: inline-block;
      margin-right: 12px; }
  .creation-tab .field .uploaded-message {
    padding: 10px;
    margin-bottom: 8px; }
    .creation-tab .field .uploaded-message .uploaded-message-icon {
      display: inline-block;
      height: 30px;
      margin-right: 8px;
      margin-top: 4px; }
  .creation-tab .remove-message-image {
    display: inline-block;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
    color: #1173BC;
    font-weight: 600; }
  .creation-tab .field-title {
    font-size: 16px;
    color: gray;
    font-weight: 700; }
    .creation-tab .field-title.active {
      color: #1173BC; }
    .creation-tab .field-title.may-drag {
      cursor: -moz-grab;
      cursor: -webkit-grab; }
  .creation-tab .attribute {
    display: flex; }
  .creation-tab .rw-multiselect-taglist .rw-btn {
    line-height: 37px; }
  .creation-tab .rw-multiselect-taglist li:first-child {
    margin-left: 10px; }
  .creation-tab .rw-multiselect-wrapper {
    display: flex; }
    .creation-tab .rw-multiselect-wrapper input[type='text'], .creation-tab .rw-multiselect-wrapper input.rw-input {
      min-height: 44px; }
  .creation-tab .rw-dropdownlist .rw-input {
    flex: 1;
    border: 0;
    outline: 0;
    padding: 15px 10px;
    color: gray; }
  .creation-tab .rw-dropdownlist .rw-select.rw-btn {
    border-left: none; }
    .creation-tab .rw-dropdownlist .rw-select.rw-btn:hover {
      background-color: #f2f2f2; }
  .creation-tab .drop-area {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90px;
    margin: 10px 10px 20px;
    border: 1px dashed #e6e6e6;
    border-radius: 6px;
    font-style: italic;
    color: #bfbfbf; }
  .creation-tab.when-tab {
    display: flex; }
    .creation-tab.when-tab .message-scheduler {
      flex: 1;
      text-align: center; }
      .creation-tab.when-tab .message-scheduler.disabled {
        pointer-events: none;
        opacity: 0.5;
        background-color: #f2f2f2; }
      .creation-tab.when-tab .message-scheduler .button-title {
        color: #bfbfbf;
        text-transform: uppercase;
        letter-spacing: 0.0625em;
        margin: 15px 0 10px; }
      .creation-tab.when-tab .message-scheduler .description {
        color: gray;
        line-height: normal;
        font-size: 14px; }
        .creation-tab.when-tab .message-scheduler .description b {
          font-weight: 600; }
      .creation-tab.when-tab .message-scheduler ul {
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        list-style: none;
        margin: 15px; }
        .creation-tab.when-tab .message-scheduler ul li.option {
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          letter-spacing: 0.0625em;
          height: 60px;
          font-weight: 600;
          font-size: 18px;
          color: #1173BC;
          cursor: pointer;
          border: 1px solid #bfbfbf;
          margin: -1px; }
          .creation-tab.when-tab .message-scheduler ul li.option.active {
            color: #7ed321;
            background-color: rgba(126, 211, 33, 0.2);
            cursor: default; }
          .creation-tab.when-tab .message-scheduler ul li.option:first-of-type {
            border-radius: 6px 6px 0 0;
            border-top: 1px solid #bfbfbf;
            border-left: 1px solid #bfbfbf;
            border-right: 1px solid #bfbfbf; }
          .creation-tab.when-tab .message-scheduler ul li.option:last-of-type {
            border-radius: 0 0 6px 6px; }
        .creation-tab.when-tab .message-scheduler ul li.disabled {
          color: #bfbfbf;
          cursor: not-allowed; }
        .creation-tab.when-tab .message-scheduler ul li.droppy-down {
          margin: 1px 0 0 0;
          display: flex;
          height: 34px; }
          .creation-tab.when-tab .message-scheduler ul li.droppy-down .react-kronos {
            flex: 1; }
          .creation-tab.when-tab .message-scheduler ul li.droppy-down input {
            border: 0;
            background-color: rgba(255, 255, 255, 0); }
            .creation-tab.when-tab .message-scheduler ul li.droppy-down input.outside-range {
              color: #d0021b; }

.message-helper-text, .superfluous-countdown-helper-text {
  font-style: italic;
  color: #bfbfbf;
  font-size: 14px;
  margin-left: 10px; }

.message-restricted-filter-text {
  color: #1173BC;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-left: 10px; }
  .message-restricted-filter-text .message-restricted-item-highlight {
    color: #d67d00; }

.message-audience {
  padding-left: 10px;
  font-size: 14px;
  color: #1173BC; }

.message-next, .message-finalize {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
  margin: 0 15px;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  top: -1px; }
  .message-next .next-title, .message-finalize .next-title {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: 600;
    color: #1173BC; }

.message-finalize {
  flex-direction: column; }
  .message-finalize.horizontal {
    flex-direction: row;
    padding: 0; }
  .message-finalize.not-yet {
    cursor: default; }
  .message-finalize .tab {
    flex: 1;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: 600;
    padding: 10px 0;
    border-left: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf; }
    .message-finalize .tab.green {
      color: #7ed321; }
    .message-finalize .tab.red {
      color: #d0021b; }
    .message-finalize .tab.counting-down {
      display: flex;
      flex-direction: column; }
      .message-finalize .tab.counting-down .counting-down-title {
        font-size: 14px;
        color: gray;
        font-weight: normal; }
      .message-finalize .tab.counting-down .counting-down-time {
        font-size: 28px;
        color: #1173BC; }
    .message-finalize .tab.no-click {
      cursor: default; }
    .message-finalize .tab:first-of-type, .message-finalize .tab:last-of-type {
      border-right: 0;
      border-left: 0; }
  .message-finalize .finish-title {
    position: relative;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: 600;
    color: #7ed321;
    margin-bottom: 5px; }
    .message-finalize .finish-title.not-yet {
      color: gray; }

.superfluous-countdown-helper-text {
  margin: 0;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  position: absolute;
  bottom: -36px;
  left: 0;
  right: 0px; }

.rich-content-disclaimer {
  padding: 10px 20px;
  font-size: 14px; }

.push-cert-banner {
  border-radius: 96px;
  display: flex;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 48px; }

.push-cert-banner-message {
  flex: 24; }

.push-cert-icon {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  right: 24px; }

.push-cert-error {
  border: #d0021b 1px solid; }

.push-cert-icon-error {
  color: #d0021b; }

.push-cert-warning {
  border: #d67d00 1px solid; }

.push-cert-icon-warning {
  color: #d67d00; }

.countdown-disclaimer {
  margin-top: 36px; }

.message-breadcrumb {
  height: 37px;
  display: flex;
  list-style: none; }
  .message-breadcrumb li {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    flex: 1;
    color: #bfbfbf;
    padding-top: 11px;
    cursor: pointer; }
    .message-breadcrumb li.active {
      color: #1173BC;
      font-weight: 700; }
    .message-breadcrumb li.disabled {
      pointer-events: none; }

.message-progress-indicator {
  display: flex;
  flex: 1;
  height: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin: 0 15px 16px; }
  .message-progress-indicator .filled {
    background-color: #1173BC;
    border-radius: 3px 0 0 3px; }
  .message-progress-indicator div:last-child {
    border-radius: 3px; }

.commition {
  flex: 1;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.0625em;
  cursor: pointer; }
  .commition + .commition {
    border-left: 1px dashed #e6e6e6; }
  .commition.no-hover {
    cursor: default; }
  .commition.blue {
    color: #1173BC; }

.poll {
  overflow: hidden; }
  .poll .answer-field {
    display: flex;
    margin-bottom: 0.5rem; }
    .poll .answer-field .answer-input:hover + .answer-action,
    .poll .answer-field .answer-input + .answer-action:hover {
      opacity: 1;
      width: 70px; }
    .poll .answer-field:last-child {
      margin-bottom: 0; }
  .poll .answer-input {
    flex: 1; }
  .poll .answer-action {
    width: 0;
    opacity: 0;
    padding: 0;
    color: #bfbfbf;
    cursor: pointer;
    transition: all .25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 3px rgba(17, 115, 188, 0.2); }
    .poll .answer-action:hover {
      color: #d0021b;
      box-shadow: inset 0 0 3px rgba(208, 2, 27, 0.2); }
  .poll .answer-add {
    width: 100%;
    font-size: 30pt;
    margin-bottom: 10px;
    cursor: pointer;
    border: 0;
    color: #bfbfbf;
    background: white; }
    .poll .answer-add:not(.disabled):not(.active):not(.cancel):hover {
      color: #1173BC; }
    .poll .answer-add:active, .poll .answer-add:focus {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none; }

.deep-link-form {
  background-color: #fafafa;
  border: 1px solid #e2e2e2;
  margin: 11px 13px;
  padding-bottom: 21px; }
  .deep-link-form .deep-link-form-select {
    display: flex;
    flex-direction: row;
    padding-top: 28px; }
    .deep-link-form .deep-link-form-select .deep-link-form-label {
      flex: 1;
      display: inline-block;
      color: gray;
      font-size: 14px;
      line-height: 18px;
      margin-left: 17px; }
    .deep-link-form .deep-link-form-select .deep-link-form-selection {
      flex: 15;
      display: inline-block; }
      .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-button {
        background-color: #0da8da;
        color: white;
        padding: 10px 20px;
        display: inline-block; }
      .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-form-parent-name {
        color: #7a7a7a; }
      .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-form-child-name {
        color: black; }
      .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-form-remove-link {
        height: 20px;
        width: 89px;
        color: #D0021B;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-left: 6px;
        text-decoration: underline; }
      .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-path {
        display: inline-block; }
      .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-path-spinner {
        display: inline-block; }
        .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-path-spinner .spinner {
          position: relative;
          top: 0px;
          left: 0px;
          width: 22px;
          height: 22px;
          border-top: 4px solid rgba(0, 0, 0, 0.25);
          border-right: 4px solid rgba(255, 255, 255, 0.5);
          border-bottom: 4px solid rgba(255, 255, 255, 0.5);
          border-left: 4px solid rgba(255, 255, 255, 0.5);
          animation: spinner 2s infinite;
          animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
          animation-fill-mode: both; }
        .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-path-spinner .spinner,
        .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-path-spinner .spinner:after {
          border-radius: 50%; }
        .deep-link-form .deep-link-form-select .deep-link-form-selection .deep-link-form-selected .deep-link-path-spinner .spinner {
          display: inline-block;
          margin-right: 12px; }

.deep-link-header {
  flex: 1;
  max-height: 80px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 32px;
  background: linear-gradient(180deg, #fcfcfc 0%, #f7f7f8 100%);
  border-radius: 14px 14px 0 0; }
  .deep-link-header .deep-link-header-title {
    flex: 1;
    color: #1173BC;
    height: 25px;
    width: 191px;
    font-size: 20px;
    line-height: 25px;
    padding-top: 8px; }
  .deep-link-header .deep-link-header-selection {
    display: flex;
    flex: 1;
    flex-flow: row-reverse; }
    .deep-link-header .deep-link-header-selection .deep-link-selection .deep-link-no-selection {
      float: right;
      padding: 10px 23px;
      width: 160px;
      border: 1px solid #808080;
      color: #808080;
      font-size: 16px;
      font-style: italic;
      line-height: 20px;
      text-align: center; }
    .deep-link-header .deep-link-header-selection .deep-link-selection .deep-link-selected-name {
      font-weight: 600; }

.deep-link-tree {
  height: 500px;
  display: flex;
  flex-direction: column; }
  .deep-link-tree .deep-link-tree-nodes {
    flex: 2;
    max-height: 400px;
    overflow: auto; }
  .deep-link-tree .deep-link-node {
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    color: #808080;
    font-size: 16px;
    line-height: 22px; }
    .deep-link-tree .deep-link-node.deep-link-node-selected {
      background-color: rgba(17, 115, 188, 0.12); }
    .deep-link-tree .deep-link-node .deep-link-empty-node {
      padding: 12px 45%;
      font-style: italic; }
    .deep-link-tree .deep-link-node .deep-link-node-body {
      display: inline-flex;
      padding-left: 4px;
      position: relative;
      bottom: 5px; }
      .deep-link-tree .deep-link-node .deep-link-node-body .deep-link-node-name {
        flex: 4;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .deep-link-tree .deep-link-node .deep-link-node-body .deep-link-node-campus {
        flex: 1;
        width: 20%;
        color: #4ed4b7;
        font-size: 14px;
        font-style: italic;
        display: inline-flex; }
  .deep-link-tree .tree-node {
    display: -webkit-inline-box; }
    .deep-link-tree .tree-node.tree-node-item {
      margin-left: 24px; }
    .deep-link-tree .tree-node .node-selection {
      height: 47px;
      width: 7px; }
      .deep-link-tree .tree-node .node-selection.selected-node {
        background-color: #1173BC; }
    .deep-link-tree .tree-node .deep-link-node-level {
      display: inline-block; }
    .deep-link-tree .tree-node .node-icon {
      display: inline-block;
      padding-left: 14px; }
      .deep-link-tree .tree-node .node-icon.node-icon-container {
        color: #1173BC; }
      .deep-link-tree .tree-node .node-icon.node-icon-item {
        color: #808080; }

.deep-link-campus-conflict {
  line-height: 20px;
  font-size: 16px; }
  .deep-link-campus-conflict .deep-link-campus-conflict-detail {
    padding: 41px 32px; }
    .deep-link-campus-conflict .deep-link-campus-conflict-detail .deep-link-target-campus {
      font-weight: normal;
      font-style: italic;
      color: #4ed4b7; }
    .deep-link-campus-conflict .deep-link-campus-conflict-detail .deep-link-content-campus {
      font-style: italic;
      color: #1173BC; }
    .deep-link-campus-conflict .deep-link-campus-conflict-detail .deep-link-campus-notification {
      font-weight: bold; }
  .deep-link-campus-conflict .deep-link-campus-confirmation-buttons {
    padding: 0 20px;
    position: relative;
    top: 32px; }
    .deep-link-campus-conflict .deep-link-campus-confirmation-buttons .deep-link-campus-confirmation-button {
      display: inline-block;
      margin-left: 12px; }

.deep-link-select-button {
  background-color: #0da8da;
  color: white;
  padding: 10px 20px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.deep-link-form-content-link {
  text-decoration: underline;
  font-size: 15px;
  line-height: 19px; }
  .deep-link-form-content-link .deep-link-form-link-icon {
    display: inline-block;
    position: relative;
    left: -8px; }

.heading.tabbed div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 16px;
  margin: 0 1px;
  text-transform: uppercase;
  letter-spacing: 0.0625em; }
  .heading.tabbed div:not(.active) {
    color: #bfbfbf;
    cursor: pointer; }
  .heading.tabbed div.active {
    color: gray;
    cursor: default;
    background-image: linear-gradient(white, #f2f2f2);
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat; }

.messages-nav, .timeframe-filter-nav {
  width: 368px; }
  @media (max-height: 1000px) {
    .messages-nav, .timeframe-filter-nav {
      width: 276px; } }
  @media (max-width: 1500px) {
    .messages-nav, .timeframe-filter-nav {
      width: 276px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .messages-nav, .timeframe-filter-nav {
      width: 245px; } }
  @media (max-width: 1200px) {
    .messages-nav, .timeframe-filter-nav {
      width: 276px; } }
  @media (max-width: 800px) {
    .messages-nav, .timeframe-filter-nav {
      width: 368px; } }
  .messages-nav ul, .messages-nav li, .timeframe-filter-nav ul, .timeframe-filter-nav li {
    list-style: none; }
  .messages-nav > li a, .timeframe-filter-nav > li a {
    height: 90px;
    font-size: 20px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    color: #b3b3b3;
    border: 1px solid #d9d9d9;
    border-top: 0; }
    @media (max-height: 969px) {
      .messages-nav > li a, .timeframe-filter-nav > li a {
        height: 75px;
        font-size: 19px; } }
    @media (max-height: 875px) {
      .messages-nav > li a, .timeframe-filter-nav > li a {
        height: 60px;
        font-size: 18px; } }
    @media (max-height: 775px) {
      .messages-nav > li a, .timeframe-filter-nav > li a {
        height: 45px;
        font-size: 16px; } }
    .messages-nav > li a:hover, .timeframe-filter-nav > li a:hover {
      color: #1173BC;
      background-color: #f7f7f7;
      transition: all 0.33s ease-out;
      cursor: pointer; }
    .messages-nav > li a.active.current, .timeframe-filter-nav > li a.active.current {
      background-color: #f0f0f0;
      color: gray; }
  .messages-nav > li:first-child a, .timeframe-filter-nav > li:first-child a {
    border: 0;
    box-shadow: 0 1px 0 #d9d9d9;
    margin-bottom: 1px;
    background-image: linear-gradient(rgba(255, 255, 255, 0), #d9d9d9), linear-gradient(rgba(255, 255, 255, 0), #d9d9d9);
    background-size: 1px 100%, 1px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat; }
    .messages-nav > li:first-child a:hover, .timeframe-filter-nav > li:first-child a:hover {
      background-color: rgba(255, 255, 255, 0);
      background-image: linear-gradient(rgba(255, 255, 255, 0), #d9d9d9), linear-gradient(rgba(255, 255, 255, 0), #d9d9d9), linear-gradient(rgba(255, 255, 255, 0), #f7f7f7);
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0; }
    .messages-nav > li:first-child a.active.current, .timeframe-filter-nav > li:first-child a.active.current {
      background-color: rgba(255, 255, 255, 0);
      background-image: linear-gradient(rgba(255, 255, 255, 0), #d9d9d9), linear-gradient(rgba(255, 255, 255, 0), #d9d9d9), linear-gradient(rgba(255, 255, 255, 0), #f0f0f0);
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0; }
  .messages-nav > li:last-child a, .timeframe-filter-nav > li:last-child a {
    border: 0;
    box-shadow: 0 -1px 0 #d9d9d9;
    background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0));
    background-size: 1px 100%, 1px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat; }
    .messages-nav > li:last-child a:hover, .timeframe-filter-nav > li:last-child a:hover {
      background-color: rgba(255, 255, 255, 0);
      background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#f7f7f7, rgba(255, 255, 255, 0));
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0; }
    .messages-nav > li:last-child a.active.current, .timeframe-filter-nav > li:last-child a.active.current {
      background-color: rgba(255, 255, 255, 0);
      background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#f0f0f0, rgba(255, 255, 255, 0));
      background-size: 1px 100%, 1px 100%, 100% 100%;
      background-position: 0 0, 100% 0, 0 0; }

.message .message {
  line-height: 44px;
  flex: 1;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .message .message > span {
    padding-left: 10px; }

.message .title .name label {
  color: #d67d00;
  padding: 0 10px 0 0; }

.message.deleted .title .name label {
  color: #d0021b; }

.message .status {
  padding-right: 10px;
  font-style: italic;
  color: #bfbfbf; }

.message.cannot-edit * {
  cursor: wait !important; }

.message.cannot-edit .sort-handle {
  position: relative; }

.message.cannot-edit .spinner {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 28px;
  height: 28px;
  border-top: 2px solid #d67d00;
  border-right: 2px solid rgba(214, 125, 0, 0.25);
  border-bottom: 2px solid rgba(214, 125, 0, 0.25);
  border-left: 2px solid rgba(214, 125, 0, 0.25);
  animation: spinner 2s infinite;
  animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
  animation-fill-mode: both; }

.message.cannot-edit .spinner,
.message.cannot-edit .spinner:after {
  border-radius: 50%; }

.message.past .name span {
  display: initial !important; }

.message .tabular-regions {
  display: flex; }
  .message .tabular-regions div {
    line-height: normal; }
  .message .tabular-regions > div:not(:first-child) {
    border-left: 1px solid #e6e6e6; }
  .message .tabular-regions .tab-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: #a6a6a6;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    background-image: linear-gradient(whitesmoke, white);
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat; }
  .message .tabular-regions .tab-content {
    padding: 0 10px 10px; }
  .message .tabular-regions .tab-content-title {
    font-size: 14px;
    color: #1173BC;
    margin: 10px 0 5px; }
    .message .tabular-regions .tab-content-title.tab-content-title-link {
      display: inline-flex;
      flex: 3; }
  .message .tabular-regions .tab-content-link {
    display: inline-flex;
    flex: 7;
    position: relative;
    bottom: 6px;
    left: 12px; }
  .message .tabular-regions .tab-content-deep-link {
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px; }

.message.expanded .title, .message.expanded .sort-handle {
  cursor: default; }

.message.expanded .message {
  white-space: normal;
  overflow: hidden;
  text-overflow: initial; }
  .message.expanded .message textarea {
    line-height: 1.2;
    padding: 13px 10px;
    width: 100%;
    height: 90px;
    resize: none; }

.message.expanded div {
  user-select: text; }

.message.expanded .status {
  display: none; }

.preview-toggle {
  display: flex;
  height: 45px;
  margin-bottom: 16px; }
  .preview-toggle div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.0625em;
    border: 1px solid #d9d9d9; }
    .preview-toggle div:not(.active) {
      color: gray;
      cursor: pointer; }
    .preview-toggle div.active {
      color: #757575;
      border-color: #757575;
      cursor: default;
      font-weight: 600; }
    .preview-toggle div:first-child {
      border-radius: 4px 0 0 4px; }
    .preview-toggle div:last-child {
      border-radius: 0 4px 4px 0; }

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  font-size: 24px;
  cursor: pointer; }

.commit .icon-button {
  border-left: 1px dashed #e6e6e6; }

[class*='hovering-'].disabled {
  pointer-events: none; }

.hovering-orange {
  color: #d67d00; }
  .hovering-orange:hover {
    background-color: rgba(214, 125, 0, 0.2); }

.hovering-green {
  color: #7ed321; }
  .hovering-green:hover {
    background-color: rgba(126, 211, 33, 0.2); }

.hovering-red {
  color: #d0021b; }
  .hovering-red:hover {
    background-color: rgba(208, 2, 27, 0.2); }

.score_in_app_usage {
  display: flex;
  padding-left: 15px;
  margin-top: 25px; }
  .score_in_app_usage .heatmap_dot_list_header {
    display: flex;
    position: relative;
    margin: 1px 15px 0;
    line-height: 44px;
    font-size: 16px;
    color: gray;
    background-color: white;
    color: #1173BC;
    font-weight: 600; }
    .score_in_app_usage .heatmap_dot_list_header .title {
      flex: 5; }
    .score_in_app_usage .heatmap_dot_list_header .views {
      flex: 1;
      text-align: right; }
  .score_in_app_usage .score_item {
    display: flex;
    position: relative;
    margin: 1px 15px 0;
    line-height: 44px;
    font-size: 16px;
    color: gray;
    background-color: white;
    box-shadow: 0 -1px 0 #e6e6e6, 0 1px 0 #e6e6e6; }
    .score_in_app_usage .score_item .root_icon {
      width: 44px;
      color: #1173BC; }
    .score_in_app_usage .score_item .title {
      flex: 10; }
    .score_in_app_usage .score_item .views {
      flex: 1;
      text-align: right; }
    .score_in_app_usage .score_item .circle {
      width: 30px;
      text-align: center; }
      .score_in_app_usage .score_item .circle span {
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
        vertical-align: middle;
        margin-top: -5px;
        margin: auto; }
    .score_in_app_usage .score_item.tripple_dot {
      flex-direction: column;
      padding: 2px 0; }
      .score_in_app_usage .score_item.tripple_dot p {
        line-height: 3px;
        padding-bottom: 5px;
        font-weight: bold;
        text-align: center;
        color: #e6e6e6; }

.highchart-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fafafa; }
  .highchart-wrapper [data-highcharts-chart] {
    height: 100%;
    width: 100%; }

.chart-box {
  position: relative;
  min-height: 125px; }
  .chart-box .chart-body-wrapper {
    flex: 1;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative; }
  .chart-box .chart-information-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    z-index: 1; }
  .chart-box .summary {
    display: flex; }
    .chart-box .summary svg {
      max-height: 100%;
      flex: 1; }
  .chart-box .title {
    text-transform: uppercase;
    color: #1173BC;
    padding-top: 10px;
    font-weight: 300; }
    .chart-box .title b {
      font-weight: 600; }
  .chart-box .additional-message {
    color: gray;
    margin-bottom: 15px; }

.chart-body-wrapper:hover .chart-information-wrapper {
  z-index: 0;
  transition: all 0.33s ease-out; }
  .chart-body-wrapper:hover .chart-information-wrapper .additional-message,
  .chart-body-wrapper:hover .chart-information-wrapper .summary {
    opacity: 0.1; }
  .chart-body-wrapper:hover .chart-information-wrapper .summary {
    color: gray;
    text-shadow: none; }

.small-chart-box {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }
  .small-chart-box .chart-body-wrapper {
    min-height: 125px; }
  .small-chart-box .summary {
    margin: 0 15px 15px 0;
    height: calc(100% - 50px);
    align-items: flex-end; }
  .small-chart-box .title {
    padding-right: 15px;
    text-align: right;
    width: 100%;
    height: 36px;
    font-size: 18px;
    line-height: 24px; }
    @media (max-width: 1250px), (max-height: 750px) {
      .small-chart-box .title {
        font-size: 16px; } }

.large-chart-box {
  height: 75%; }
  .large-chart-box .summary {
    height: calc(100% - 100px); }
    .large-chart-box .summary svg text {
      letter-spacing: 0; }
  .large-chart-box .title {
    text-align: center;
    height: 36px;
    font-size: 24px;
    line-height: 24px; }
  .large-chart-box .additional-message {
    text-align: center;
    padding: 0 15px;
    font-style: italic;
    color: gray;
    font-size: 14px;
    white-space: pre; }

.chart-box.disabled .highchart-wrapper {
  background-color: #e6e6e6;
  border: 1px solid #bfbfbf; }

.chart-box.disabled .title,
.chart-box.disabled .summary,
.chart-box.disabled .additional-message {
  color: gray; }

.chart-box.disabled .additional-message {
  text-align: right;
  font-style: italic;
  padding: 0 15px;
  width: 100%;
  font-size: 10pt; }

.large-chart-box.disabled .additional-message {
  text-align: center; }

.chart-box.disabled:hover .additional-message {
  opacity: 1; }

.overview-grid {
  margin-left: 15px;
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 50px;
  left: 0;
  right: 0;
  max-height: 100%;
  min-height: 600px; }
  @media (max-height: 1000px) {
    .overview-grid {
      margin-left: 15px; } }
  @media (max-width: 1500px) {
    .overview-grid {
      margin-left: 15px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .overview-grid {
      margin-left: 15px; } }
  @media (max-width: 1200px) {
    .overview-grid {
      margin-left: 0; } }
  @media (max-width: 800px) {
    .overview-grid {
      margin-left: 0; } }
  .overview-grid .overview-grid-column {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column; }
    .overview-grid .overview-grid-column .overview-grid-row {
      min-height: 125px;
      display: flex;
      height: 25%;
      position: relative;
      padding: 10px; }
      .overview-grid .overview-grid-column .overview-grid-row .chart-box:last-child {
        padding-left: 10px; }
      .overview-grid .overview-grid-column .overview-grid-row .chart-box:first-child {
        padding-right: 10px; }

.score-empty .covering-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center; }

.score-empty .spinner {
  position: relative;
  top: 0px;
  left: 0px;
  width: 96px;
  height: 96px;
  border-top: 4px solid rgba(0, 0, 0, 0.25);
  border-right: 4px solid rgba(255, 255, 255, 0.5);
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  border-left: 4px solid rgba(255, 255, 255, 0.5);
  animation: spinner 2s infinite;
  animation-timing-function: cubic-bezier(0.66, 0.26, 0.26, 0.66);
  animation-fill-mode: both; }

.score-empty .spinner,
.score-empty .spinner:after {
  border-radius: 50%; }

.highcharts-tooltip > span .tooltip-content {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: 'Source Sans Pro';
  text-align: center;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px; }
  .highcharts-tooltip > span .tooltip-content .y-value {
    font-size: 24px;
    font-weight: lighter; }
  .highcharts-tooltip > span .tooltip-content .x-value {
    font-size: 14px; }

.timeframe-filter-nav li.custom_time_frame {
  box-shadow: 0 -1px 0 #d9d9d9;
  background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0));
  background-size: 1px 100%, 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat; }
  .timeframe-filter-nav li.custom_time_frame a, .timeframe-filter-nav li.custom_time_frame a:hover, .timeframe-filter-nav li.custom_time_frame a.active.current {
    border: 1px solid rgba(255, 255, 255, 0);
    border-top: 0;
    background: none; }
  .timeframe-filter-nav li.custom_time_frame.expanded {
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#f0f0f0, rgba(255, 255, 255, 0));
    background-size: 1px 100%, 1px 100%, 100% 100%;
    background-position: 0 0, 100% 0, 0 0; }
    .timeframe-filter-nav li.custom_time_frame.expanded a {
      border: 1px solid #d9d9d9;
      border-top: 0;
      background-color: #f0f0f0;
      color: gray; }
      .timeframe-filter-nav li.custom_time_frame.expanded a.active.current {
        background: none; }
      .timeframe-filter-nav li.custom_time_frame.expanded a:hover {
        color: #1173BC; }
  .timeframe-filter-nav li.custom_time_frame:hover {
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#d9d9d9, rgba(255, 255, 255, 0)), linear-gradient(#f0f0f0, rgba(255, 255, 255, 0));
    background-size: 1px 100%, 1px 100%, 100% 100%;
    background-position: 0 0, 100% 0, 0 0; }
  .timeframe-filter-nav li.custom_time_frame .date_range_fields {
    margin: 10px; }
    .timeframe-filter-nav li.custom_time_frame .date_range_fields .row {
      display: flex;
      flex: 1;
      min-height: 24px;
      justify-content: space-between;
      margin-bottom: 5px; }
      .timeframe-filter-nav li.custom_time_frame .date_range_fields .row label {
        color: gray;
        line-height: 26px; }
      .timeframe-filter-nav li.custom_time_frame .date_range_fields .row input {
        width: 196px; }
        @media (max-height: 800px) {
          .timeframe-filter-nav li.custom_time_frame .date_range_fields .row input {
            width: 175px; } }

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

.stacked-bar-chart .anchor-label {
  text-align: center;
  font-size: 12px;
  line-height: 2; }

.stacked-bar-chart .empty-chart {
  display: flex;
  align-items: center;
  justify-content: center; }
  .stacked-bar-chart .empty-chart span {
    margin-top: 15px;
    margin-bottom: 25px;
    font-weight: 600; }

.stacked-bar-chart .chart-container {
  display: flex;
  justify-content: center; }
  .stacked-bar-chart .chart-container .align-left {
    justify-content: flex-start; }
  .stacked-bar-chart .chart-container .align-right {
    justify-content: flex-end; }
  .stacked-bar-chart .chart-container .anchor {
    position: absolute;
    background-color: black;
    width: 1px;
    height: 90px; }
  .stacked-bar-chart .chart-container .chart {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative; }
    .stacked-bar-chart .chart-container .chart .category {
      height: 50px;
      color: black;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .stacked-bar-chart .chart-container .chart .category .value {
        font-size: 16px; }
      .stacked-bar-chart .chart-container .chart .category .percentage {
        font-size: 11px;
        text-align: center;
        padding-top: 5px; }
    .stacked-bar-chart .chart-container .chart .overflow {
      visibility: hidden; }
    .stacked-bar-chart .chart-container .chart .margin {
      height: 1px;
      background-color: gray; }
  .stacked-bar-chart .chart-container .diverging {
    border-left: gray solid 1px;
    border-right: gray solid 1px; }

.stacked-bar-chart .legend {
  display: flex;
  justify-content: center; }
  .stacked-bar-chart .legend .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px; }
  .stacked-bar-chart .legend .swatch {
    float: left;
    width: 14px;
    height: 14px;
    margin-right: 8px; }
  .stacked-bar-chart .legend .label {
    font-style: italic;
    font-size: 14px; }

.stacked-bar-chart .orange {
  background-color: #d67d00; }

.stacked-bar-chart .light-orange {
  background-color: rgba(214, 125, 0, 0.5); }

.stacked-bar-chart .blue {
  background-color: #1173BC; }

.stacked-bar-chart .light-blue {
  background-color: rgba(17, 115, 188, 0.5); }

.stacked-bar-chart .white {
  background-color: white;
  color: black !important;
  border-style: solid;
  border-width: 1px;
  border-color: gray; }

.collapsible-content-container {
  border: 1px #CCCCCC solid;
  border-radius: 4px; }

.collapsible-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #EEEEEE;
  padding: 15px 25px;
  font-size: 17px;
  font-weight: 500; }

.collapsible-content-children {
  padding: 15px 25px; }

.collapsible-content-button {
  background-image: url("../images/ui/nav-arrow-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  background-size: 15px 15px;
  cursor: pointer;
  transform: rotate(180deg); }
  .collapsible-content-button.collapsible-content-button-collapsed {
    transform: rotate(0deg); }

.rw-dropdown-list .dropdown-standalone {
  background-color: white; }
  .rw-dropdown-list .dropdown-standalone .rw-btn,
  .rw-dropdown-list .dropdown-standalone .rw-input {
    overflow: hidden;
    border: 1px solid #b2b2b2;
    height: 44px;
    line-height: 44px; }
  .rw-dropdown-list .dropdown-standalone .rw-btn {
    border-radius: 0 4px 4px 0;
    border-left: none; }
  .rw-dropdown-list .dropdown-standalone .rw-input {
    border-right: none;
    border-radius: 4px 0 0 4px; }
  .rw-dropdown-list .dropdown-standalone.dropdown-standalone-thin .rw-input,
  .rw-dropdown-list .dropdown-standalone.dropdown-standalone-thin .rw-select,
  .rw-dropdown-list .dropdown-standalone.dropdown-standalone-thin .rw-btn {
    height: 32px;
    line-height: 32px; }
  .rw-dropdown-list .dropdown-standalone.dropdown-standalone-thin + .rw-popup-container {
    top: 32px; }

.rw-dropdown-list.rw-state-disabled .dropdown-standalone .rw-btn,
.rw-dropdown-list.rw-state-disabled .dropdown-standalone .rw-input {
  border-color: #e5e5e5;
  cursor: default; }

.rw-dropdown-list.rw-state-disabled .dropdown-standalone .rw-input,
.rw-dropdown-list.rw-state-disabled .dropdown-standalone .rw-i {
  color: #e5e5e5; }

.rw-dropdown-list.rw-state-disabled .dropdown-standalone .rw-btn:hover > .rw-i {
  color: #e5e5e5; }

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.12s ease-in; }
  .modal .title {
    position: absolute;
    width: 100%;
    top: -45px;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    left: 20px; }
    .modal .title img {
      width: 145px;
      margin: 0 13px 4px 0; }
    .modal .title svg {
      height: 30px;
      margin-right: 15px; }
  .modal .box-long {
    position: relative;
    width: 50%;
    min-width: 640px;
    min-height: 360px;
    max-height: 70%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column; }
  .modal .content-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    position: relative;
    padding: 45px; }
    .modal .content-scroll ul {
      margin-left: 20px; }
    .modal .content-scroll ol {
      margin-left: 25px; }
    .modal .content-scroll ul, .modal .content-scroll ol, .modal .content-scroll p {
      margin-top: 12px;
      margin-bottom: 12px; }
  .modal .box {
    position: relative;
    width: 50%;
    min-width: 640px;
    height: 50%;
    min-height: 360px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.05);
    padding: 15px; }
  .modal .search {
    width: 100%;
    padding: 12px;
    outline: none;
    font-size: 20px; }
  .modal .switcher-action {
    position: fixed;
    color: white;
    cursor: pointer; }
    .modal .switcher-action.tr {
      top: 0;
      right: 0; }
    .modal .switcher-action.br {
      bottom: 0;
      right: 0; }
  .modal .helpful-tip {
    position: fixed;
    bottom: 0;
    right: 44px;
    line-height: 44px;
    color: white;
    font-style: italic; }
  .modal .watermarks {
    display: flex;
    position: absolute;
    top: 68px;
    bottom: 15px;
    left: 15px;
    right: 15px; }
    .modal .watermarks li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none; }
      .modal .watermarks li > .watermark {
        color: rgba(0, 0, 0, 0.03);
        font-size: 160px;
        line-height: 160px; }
  .modal .listing {
    display: flex;
    position: absolute;
    top: 68px;
    bottom: 15px;
    left: 15px;
    right: 15px; }
    .modal .listing ul {
      flex: 1;
      overflow-y: auto; }
      .modal .listing ul::-webkit-scrollbar {
        width: 10px; }
      .modal .listing ul::-webkit-scrollbar-track {
        border-radius: 5px; }
      .modal .listing ul:hover::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05); }
      .modal .listing ul::-webkit-scrollbar-thumb {
        background: #1173BC;
        border-radius: 5px;
        background-clip: padding-box;
        border: 2px solid rgba(0, 0, 0, 0); }
      .modal .listing ul:first-child {
        border-right: 1px solid transparent; }
        .modal .listing ul:first-child li {
          margin-right: 1px; }
      .modal .listing ul li {
        cursor: pointer;
        list-style: none;
        padding: 6px 8px;
        margin-left: 3px;
        display: flex;
        flex-direction: column; }
        .modal .listing ul li.current {
          color: #1173BC;
          font-style: italic; }
        .modal .listing ul li[data-state=selected] {
          background-color: rgba(17, 115, 188, 0.12);
          box-shadow: -3px 0 0 #1173BC; }
        .modal .listing ul li.tip {
          color: #bfbfbf;
          font-style: italic;
          pointer-events: none;
          text-align: center; }
  .modal.confirm .content-scroll {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .modal.confirm .content-scroll > :first-child {
      margin: auto auto 0 auto; }
    .modal.confirm .content-scroll .buttons {
      align-self: flex-end;
      margin: auto;
      display: flex; }
      .modal.confirm .content-scroll .buttons .button {
        padding: 0 1em;
        margin-left: 1em;
        min-width: 125px;
        margin-bottom: 0; }
        .modal.confirm .content-scroll .buttons .button:first-of-type {
          margin-left: 0; }
        .modal.confirm .content-scroll .buttons .button:active {
          background-color: rgba(17, 115, 188, 0.05); }
        .modal.confirm .content-scroll .buttons .button.cancel {
          border-color: transparent; }
          .modal.confirm .content-scroll .buttons .button.cancel:active {
            background-color: rgba(208, 2, 27, 0.05); }
  .modal.false {
    pointer-events: none;
    opacity: 0; }
  .modal.none {
    display: none; }

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

.nav {
  display: flex;
  width: 368px;
  cursor: pointer; }
  @media (max-height: 1000px) {
    .nav {
      width: 276px; } }
  @media (max-width: 1500px) {
    .nav {
      width: 276px; } }
  @media (max-width: 1500px) and (max-height: 800px) {
    .nav {
      width: 245px; } }
  @media (max-width: 1200px) {
    .nav {
      width: 184px; } }
  @media (max-width: 800px) {
    .nav {
      width: 100%; } }
  .nav .text {
    font-size: initial; }
    @media (max-height: 1000px) {
      .nav .text {
        font-size: initial; } }
    @media (max-width: 1500px) {
      .nav .text {
        font-size: initial; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .nav .text {
        font-size: initial; } }
    @media (max-width: 1200px) {
      .nav .text {
        font-size: 0px; } }
    @media (max-width: 800px) {
      .nav .text {
        font-size: initial; } }
  .nav .headline {
    font-weight: 700;
    text-transform: initial; }
    @media (max-height: 1000px) {
      .nav .headline {
        text-transform: initial; } }
    @media (max-width: 1500px) {
      .nav .headline {
        text-transform: initial; } }
    @media (max-width: 1500px) and (max-height: 800px) {
      .nav .headline {
        text-transform: initial; } }
    @media (max-width: 1200px) {
      .nav .headline {
        text-transform: capitalize; } }
    @media (max-width: 800px) {
      .nav .headline {
        text-transform: initial; } }
  .nav > .toggledrop {
    flex: 1;
    position: relative;
    display: flex;
    outline: 0;
    border: 0;
    background-color: #f7f7f8;
    margin-bottom: 6px;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-image: url("../images/ui/nav-arrow-down.svg");
    transition: all 0.33s ease-out; }
    .nav > .toggledrop > .brand {
      width: 90px;
      padding: 0;
      height: 90px;
      display: flex;
      align-items: center;
      pointer-events: auto;
      width: auto;
      flex: 5;
      justify-content: flex-end;
      padding: 16px 13px 16px 20px;
      margin-right: 12px; }
      @media (max-height: 1000px) {
        .nav > .toggledrop > .brand {
          width: 90px; } }
      @media (max-width: 1500px) {
        .nav > .toggledrop > .brand {
          width: 90px; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .nav > .toggledrop > .brand {
          width: 90px; } }
      @media (max-width: 1200px) {
        .nav > .toggledrop > .brand {
          width: 60px; } }
      @media (max-width: 800px) {
        .nav > .toggledrop > .brand {
          width: 90px; } }
      @media (max-height: 1000px) {
        .nav > .toggledrop > .brand {
          padding: 0; } }
      @media (max-width: 1500px) {
        .nav > .toggledrop > .brand {
          padding: 0; } }
      @media (max-width: 1500px) and (max-height: 800px) {
        .nav > .toggledrop > .brand {
          padding: 0; } }
      @media (max-width: 1200px) {
        .nav > .toggledrop > .brand {
          padding: 0 8px 0 0; } }
      @media (max-width: 800px) {
        .nav > .toggledrop > .brand {
          padding: 0; } }
      .nav > .toggledrop > .brand img {
        width: 151px;
        height: auto; }
        @media (max-height: 1000px) {
          .nav > .toggledrop > .brand img {
            width: 151px; } }
        @media (max-width: 1500px) {
          .nav > .toggledrop > .brand img {
            width: 151px; } }
        @media (max-width: 1500px) and (max-height: 800px) {
          .nav > .toggledrop > .brand img {
            width: 129px; } }
        @media (max-width: 1200px) {
          .nav > .toggledrop > .brand img {
            width: 93px; } }
        @media (max-width: 800px) {
          .nav > .toggledrop > .brand img {
            width: 151px; } }
      @media (max-width: 1200px) and (min-width: 801px) {
        .nav > .toggledrop > .brand {
          margin-right: 0;
          padding: 15px 8px 15px 15px;
          flex: 4; }
          .nav > .toggledrop > .brand img {
            width: 100%; } }
    .nav > .toggledrop > .title {
      display: flex;
      height: 90px;
      align-items: center;
      font-size: 20px;
      color: gray;
      flex: 3; }
      @media (max-width: 1200px) and (min-width: 801px) {
        .nav > .toggledrop > .title {
          font-size: 16px;
          flex: 5;
          padding-bottom: 4px; } }
    .nav > .toggledrop[data-state=closed] ul {
      pointer-events: none; }
    .nav > .toggledrop[data-state=open] {
      background-color: #4d4d4d;
      background-image: url("../images/ui/nav-arrow-up-light.svg"); }
      .nav > .toggledrop[data-state=open] path, .nav > .toggledrop[data-state=open] circle, .nav > .toggledrop[data-state=open] rect, .nav > .toggledrop[data-state=open] polygon {
        fill: white; }
      .nav > .toggledrop[data-state=open] > .title {
        color: white; }
      .nav > .toggledrop[data-state=open].home {
        background-image: url("../images/ui/nav-arrow-right-light.svg"); }
    .nav > .toggledrop ul {
      pointer-events: auto;
      position: absolute;
      z-index: 100;
      margin-top: 90px;
      width: 100%; }
      .nav > .toggledrop ul li .nav-link {
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 16px;
        padding: 0 15px;
        background-color: whitesmoke;
        color: gray;
        margin: 1px 0 0 0;
        box-shadow: 0 -1px 0 #e7e7e7;
        transition: ease-in color 0.2s, ease-in background-color 0.2s, ease-in background-image 0.2s; }
        .nav > .toggledrop ul li .nav-link.active {
          background: #e6e6e6;
          color: #1173BC;
          pointer-events: none; }
        .nav > .toggledrop ul li .nav-link:active, .nav > .toggledrop ul li .nav-link:focus, .nav > .toggledrop ul li .nav-link:hover {
          background-image: url("../images/ui/nav-arrow-right-light.svg");
          background-position: right 15px center;
          background-repeat: no-repeat;
          background-color: gray;
          color: white; }

.ReleaseBuildWarning Ul {
  text-decoration: underline; }

.organization-wizard {
  flex-grow: 1;
  border-top: 1px solid #e6e6e6;
  margin-top: 20px; }
  .organization-wizard-container {
    padding: 10px; }
  .organization-wizard-step-indicator {
    size: 16px;
    color: #757575;
    line-height: 20px;
    margin-bottom: 15px;
    margin-top: 5px; }
  .organization-wizard-search-form {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .organization-wizard-search-input {
    box-sizing: border-box;
    height: 44px;
    width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px 15px 5px 5px;
    padding: 5px; }
  .organization-wizard-search-icon {
    cursor: pointer;
    position: absolute;
    left: 385px; }
  .organization-wizard-creation-button {
    height: 44px;
    border-radius: 6px;
    border: 0;
    color: white;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1173BC;
    padding: 0px 15px; }
    .organization-wizard-creation-button.primary {
      border: 0;
      color: white;
      background-color: #1173BC; }
    .organization-wizard-creation-button.secondary {
      border: 1px solid #1173BC;
      color: #1173BC;
      background-color: white; }
      .organization-wizard-creation-button.secondary:hover {
        background-color: #1173BC;
        color: white; }
    .organization-wizard-creation-button:hover {
      background-color: #0d5c96;
      cursor: pointer; }
    .organization-wizard-creation-button:disabled {
      background-color: #ccc;
      cursor: default; }
    .organization-wizard-creation-button.medium {
      width: 150px; }
    .organization-wizard-creation-button.small {
      width: 100px; }
    .organization-wizard-creation-button.large {
      width: 200px; }
  .organization-wizard-chevron-icon {
    margin: 2px 0px 0px 5px; }
  .organization-wizard-no-org {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    color: #ccc;
    border-radius: 4px;
    width: 100%;
    padding: 27px;
    font-style: italic; }
  .organization-wizard-icon {
    justify-content: center; }
  .organization-wizard-text {
    color: #999999;
    padding: 10px; }
  .organization-wizard-results {
    width: 100%;
    background-color: #fafafa; }
  .organization-wizard-result-head > th {
    padding: 16px;
    color: #757575;
    font-weight: 600;
    border-bottom: solid;
    border-color: #ccc;
    border-spacing: 0;
    border-width: 1px;
    text-align: left; }
  .organization-wizard-result > td {
    padding: 16px;
    color: #262626;
    font-weight: 100; }
    .organization-wizard-result > td.organization-wizard-result-name {
      font-weight: 600; }
  .organization-wizard-button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px; }
    .organization-wizard-button-group *:not(:last-child) {
      margin-right: 15px; }
  .organization-wizard-tooltip {
    display: inline-block;
    margin-left: 15px;
    align-content: center; }
    .organization-wizard-tooltip-icon {
      height: 16px;
      line-height: 16px;
      font-size: 16px; }
  .organization-wizard-input-label {
    display: flex;
    align-items: center; }
  .organization-wizard-border-bottom {
    border-bottom: 1px solid #e6e6e6; }

/* TODO determine whether $blue is staying the same as $primary and alter/clean up accordingly */
@keyframes fade-first {
  0%, 60% {
    opacity: 1; }
  70%, 90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-second {
  0%, 60% {
    opacity: 0; }
  70%, 90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:export {
  mediaRatioDesktop: 1;
  mediaRatioLaptop: 0.75;
  mediaRatioLaptopShort: 0.666;
  mediaRatioTablet: 0.75;
  mediaRatioPhoneLandscape: 0.5;
  mediaRatioPhone: 1;
  osPreviewWidth: 320;
  osPreviewBorderWidth: 1; }

.bridge-cancel-button {
  width: 80px;
  height: 30px;
  margin-right: 15px;
  background-color: white;
  line-height: 27px;
  text-align: center;
  color: #1173BC;
  border: 1px solid #1173BC;
  border-radius: 6px;
  margin-top: 7px; }
  .bridge-cancel-button:hover {
    background-color: #1173BC;
    color: white;
    cursor: pointer; }

.rw-widget {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  outline: 0;
  position: relative; }
  .rw-widget.rw-combobox {
    flex-wrap: nowrap; }

.rw-i {
  display: inline-block;
  font-family: 'Streamline';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 44px; }

.list-unstyled {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.list-inline {
  margin: 0;
  padding-left: 0;
  list-style: none; }
  .list-inline > li {
    display: inline-block;
    padding: 0 5px; }

.rw-btn, .rw-cell, .rw-input, .rw-input-reset {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: 0; }

button.rw-input {
  overflow: visible; }

button.rw-input, select.rw-input {
  text-transform: none; }

button.rw-input, html input[type="button"].rw-input, input[type="reset"].rw-input, input[type="submit"].rw-input {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rw-input, html input[disabled].rw-input {
  cursor: default; }

button.rw-input::-moz-focus-inner, input.rw-input::-moz-focus-inner, input.rw-input-reset:-moz-focus-inner {
  border: 0;
  padding: 0; }

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.rw-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 44px;
  border: 0;
  background-color: white; }

.container .rw-btn:hover > .rw-i {
  color: #1173BC; }

.item .rw-btn:hover > .rw-i {
  color: #d67d00; }

.attribute.campus .rw-btn:hover > .rw-i {
  color: #4ed4b7; }

.message.draft .rw-btn:hover > .rw-i {
  color: #d67d00; }

.message.scheduled .rw-btn:hover > .rw-i {
  color: #7ed321; }

.rw-rtl {
  direction: rtl; }

.rw-input, .rw-input-reset {
  /*height: 1.94em;*/
  height: 44px;
  line-height: 44px; }

.rw-i.rw-loading {
  width: 16px;
  height: 100%; }

.rw-state-focus {
  color: gray;
  background-color: whitesmoke;
  border: 1px solid #ebebeb; }

.container .rw-state-selected {
  background-color: #1173BC;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0); }
  .container .rw-state-selected:hover {
    background-color: #1173BC;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0); }

.item .rw-state-selected {
  background-color: #d67d00;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0); }
  .item .rw-state-selected:hover {
    background-color: #d67d00;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0); }

.attribute.campus .rw-state-selected {
  background-color: #4ed4b7;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0); }
  .attribute.campus .rw-state-selected:hover {
    background-color: #4ed4b7;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0); }

.message.draft .rw-state-selected {
  background-color: #d67d00;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0); }
  .message.draft .rw-state-selected:hover {
    background-color: #d67d00;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0); }

.message.scheduled .rw-state-selected {
  background-color: #7ed321;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0); }
  .message.scheduled .rw-state-selected:hover {
    background-color: #7ed321;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0); }

.rw-widget.rw-state-focus {
  background: initial;
  border: 0; }

.rw-btn, .rw-dropdownlist, .rw-cell {
  cursor: pointer; }

ul.rw-list {
  margin: 0;
  padding: 1px;
  list-style: none;
  overflow: auto;
  outline: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  ul.rw-list > li {
    flex: 1 20%;
    cursor: pointer;
    padding: 0 10px;
    margin: 1px;
    border-radius: 3px;
    line-height: 2em;
    border: 1px solid rgba(255, 255, 255, 0); }
    ul.rw-list > li.rw-state-focus {
      color: gray;
      background-color: whitesmoke;
      border: 1px solid #ebebeb; }
    ul.rw-list > li:hover {
      border: 1px solid #e6e6e6; }

.rw-datetime-picker .rw-list {
  flex-direction: row; }

.rw-datetime-picker .rw-list > li {
  flex: 1 calc(25% - 2px);
  text-align: center;
  min-width: 80px; }

.rw-combobox .rw-btn {
  order: 1; }

.rw-combobox .rw-list > li {
  flex: initial;
  line-height: 2.5em;
  padding: 0 15px; }

.rw-multiselect .rw-list > li {
  flex: initial;
  line-height: 38px; }

.rw-multiselect .rw-popup-container {
  top: 100%; }
  .rw-multiselect .rw-popup-container .rw-popup > .rw-list {
    flex-direction: row; }

.rw-widget-container {
  flex: 1;
  display: flex;
  flex-direction: row; }

.rw-dropdown-list-input {
  flex: 1;
  padding-left: 10px; }

.rw-popup-transition-exited {
  display: none; }

.rw-dropdownlist .rw-btn {
  order: 1; }

.rw-dropdownlist .rw-list {
  min-height: min-content;
  flex-direction: column;
  flex-wrap: nowrap; }
  .rw-dropdownlist .rw-list li {
    flex: initial;
    line-height: 2.5em; }

.rw-dropdownlist div.rw-input {
  padding-left: 10px; }

.rw-combobox > .rw-input, .rw-datepicker > .rw-input, .rw-numberpicker > .rw-input, .rw-dropdownlist > .rw-input {
  flex: 1;
  border: none;
  outline: 0; }

.rw-select {
  display: flex;
  height: 43px; }
  .rw-select.rw-btn, .rw-select > .rw-btn {
    outline: 0;
    width: 45px;
    border-left: 1px dashed #e6e6e6; }

.rw-numberpicker .rw-select {
  display: none; }
  .rw-numberpicker .rw-select .rw-btn {
    flex: 1; }

.rw-numberpicker .rw-input {
  text-align: right;
  padding-right: 10px; }

.rw.open > .rw-btn {
  color: #d67d00; }

.rw-combobox.rw-state-focus .rw-input, .rw-multiselect.rw-state-focus .rw-input, .rw-datepicker.rw-state-focus .rw-input, .rw-numberpicker.rw-state-focus .rw-input, .rw-dropdownlist.rw-state-focus .rw-input, .rw-combobox.rw-state-focus:hover .rw-input, .rw-multiselect.rw-state-focus:hover .rw-input, .rw-datepicker.rw-state-focus:hover .rw-input, .rw-numberpicker.rw-state-focus:hover .rw-input, .rw-dropdownlist.rw-state-focus:hover .rw-input {
  color: black; }

.rw-combobox[disabled], .rw-multiselect[disabled], .rw-datepicker[disabled], .rw-numberpicker[disabled], .rw-dropdownlist[disabled], fieldset[disabled] .rw-combobox, fieldset[disabled] .rw-multiselect, fieldset[disabled] .rw-datepicker, fieldset[disabled] .rw-numberpicker, fieldset[disabled] .rw-dropdownlist {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65; }

.rw-combobox[disabled], .rw-multiselect[disabled], .rw-datepicker[disabled], .rw-numberpicker[disabled], .rw-dropdownlist[disabled], fieldset[disabled] .rw-combobox, fieldset[disabled] .rw-multiselect, fieldset[disabled] .rw-datepicker, fieldset[disabled] .rw-numberpicker, fieldset[disabled] .rw-dropdownlist, .rw-combobox[disabled]:hover, .rw-multiselect[disabled]:hover, .rw-datepicker[disabled]:hover, .rw-numberpicker[disabled]:hover, .rw-dropdownlist[disabled]:hover, fieldset[disabled] .rw-combobox:hover, fieldset[disabled] .rw-multiselect:hover, fieldset[disabled] .rw-datepicker:hover, fieldset[disabled] .rw-numberpicker:hover, fieldset[disabled] .rw-dropdownlist:hover, .rw-combobox[disabled]:focus, .rw-multiselect[disabled]:focus, .rw-datepicker[disabled]:focus, .rw-numberpicker[disabled]:focus, .rw-dropdownlist[disabled]:focus, fieldset[disabled] .rw-combobox:focus, fieldset[disabled] .rw-multiselect:focus, fieldset[disabled] .rw-datepicker:focus, fieldset[disabled] .rw-numberpicker:focus, fieldset[disabled] .rw-dropdownlist:focus, .rw-combobox[disabled]:active, .rw-multiselect[disabled]:active, .rw-datepicker[disabled]:active, .rw-numberpicker[disabled]:active, .rw-dropdownlist[disabled]:active, fieldset[disabled] .rw-combobox:active, fieldset[disabled] .rw-multiselect:active, fieldset[disabled] .rw-datepicker:active, fieldset[disabled] .rw-numberpicker:active, fieldset[disabled] .rw-dropdownlist:active {
  box-shadow: none;
  background-color: #eee;
  border-color: #ccc; }

.rw-combobox .rw-input[disabled], .rw-datepicker .rw-input[disabled], .rw-numberpicker .rw-input[disabled], .rw-dropdownlist .rw-input[disabled], .rw-combobox fieldset[disabled] .rw-input, .rw-datepicker fieldset[disabled] .rw-input, .rw-numberpicker fieldset[disabled] .rw-input, .rw-dropdownlist fieldset[disabled] .rw-input, .rw-combobox .rw-input[disabled]:hover, .rw-datepicker .rw-input[disabled]:hover, .rw-numberpicker .rw-input[disabled]:hover, .rw-dropdownlist .rw-input[disabled]:hover, .rw-combobox fieldset[disabled] .rw-input:hover, .rw-datepicker fieldset[disabled] .rw-input:hover, .rw-numberpicker fieldset[disabled] .rw-input:hover, .rw-dropdownlist fieldset[disabled] .rw-input:hover, .rw-combobox .rw-input[disabled]:focus, .rw-datepicker .rw-input[disabled]:focus, .rw-numberpicker .rw-input[disabled]:focus, .rw-dropdownlist .rw-input[disabled]:focus, .rw-combobox fieldset[disabled] .rw-input:focus, .rw-datepicker fieldset[disabled] .rw-input:focus, .rw-numberpicker fieldset[disabled] .rw-input:focus, .rw-dropdownlist fieldset[disabled] .rw-input:focus, .rw-combobox .rw-input[disabled]:active, .rw-datepicker .rw-input[disabled]:active, .rw-numberpicker .rw-input[disabled]:active, .rw-dropdownlist .rw-input[disabled]:active, .rw-combobox fieldset[disabled] .rw-input:active, .rw-datepicker fieldset[disabled] .rw-input:active, .rw-numberpicker fieldset[disabled] .rw-input:active, .rw-dropdownlist fieldset[disabled] .rw-input:active {
  background-color: #eee;
  border-color: #ccc; }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

.slide {
  position: absolute;
  overflow: hidden;
  transition: transform 4.25s ease; }

.slide-down {
  transform: translateY(0); }

.slide-up {
  transform: translateY(0); }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .25s ease; }

.slide-left {
  transition: left 4.35s ease;
  position: absolute;
  overflow: hidden;
  left: 100%; }

.slide-right {
  transition: left 4.35s ease;
  position: absolute;
  overflow: hidden;
  left: -100%; }

.slide-left.in, .slide-right.in {
  left: 0; }

.slide-left.out {
  left: -100%; }

.slide-right.out {
  left: 100%; }

.rw-popup {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid #bfbfbf;
  background: #fff;
  width: 100%; }

.rw-popup-container {
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 9000;
  width: 100%; }

.rw-dropdownlist .rw-popup-container {
  right: 0; }

.rw-datepicker.rw-has-both > .rw-select {
  height: 100%; }

.rw-calendar {
  line-height: 2em; }
  .rw-calendar .rw-calendar-footer {
    flex: 1; }
    .rw-calendar .rw-calendar-footer .rw-btn {
      width: 100%;
      height: 44px; }

.rw-calendar .rw-calendar-header {
  flex: 1 100%;
  border-bottom: 1px solid #e6e6e6;
  display: flex; }

.rw-calendar .rw-calendar-header .rw-calendar-btn-left, .rw-calendar .rw-calendar-header .rw-calendar-btn-right {
  width: 44px;
  background-color: whitesmoke; }
  .rw-calendar .rw-calendar-header .rw-calendar-btn-left:hover, .rw-calendar .rw-calendar-header .rw-calendar-btn-right:hover {
    background-color: white; }

.rw-calendar .rw-calendar-header .rw-calendar-btn-view {
  flex: 1;
  outline: 0;
  background-color: whitesmoke; }
  .rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover {
    background-color: white; }

.container .rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover {
  color: #1173BC; }

.item .rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover {
  color: #d67d00; }

.attribute.campus .rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover {
  color: #4ed4b7; }

.message.draft .rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover {
  color: #d67d00; }

.message.scheduled .rw-calendar .rw-calendar-header .rw-calendar-btn-view:hover {
  color: #7ed321; }

.rw-calendar-grid {
  table-layout: fixed;
  width: 100%; }

.rw-calendar-grid .rw-cell {
  width: 100%; }

.rw-calendar-grid td.rw-cell {
  line-height: 2em;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0); }

.rw-calendar-grid td.rw-cell:not(.rw-state-selected):hover {
  border: 1px solid #e6e6e6; }

.rw-calendar-grid td.rw-cell.rw-cell-off-range {
  color: #b3b3b3; }

.rw-calendar-grid.rw-nav-view .rw-cell {
  overflow: hidden;
  text-align: center;
  white-space: normal; }

.rw-widget-input > div {
  border-radius: 4px;
  position: relative;
  cursor: text; }
  .rw-widget-input > div:before, .rw-widget-input > div:after {
    content: " ";
    display: table; }
  .rw-widget-input > div:after {
    clear: both; }
  .rw-widget-input > div span.rw-loading {
    position: absolute;
    right: 3px; }
  .rw-widget-input > div > .rw-input-reset {
    outline: 0;
    border-width: 0;
    line-height: normal;
    width: auto;
    max-width: 100%; }
    .rw-widget-input > div > .rw-input-reset::-moz-placeholder {
      color: #999;
      opacity: 1; }
    .rw-widget-input > div > .rw-input-reset:-ms-input-placeholder {
      color: #999; }
    .rw-widget-input > div > .rw-input-reset::-webkit-input-placeholder {
      color: #999; }
  .rw-state-readonly > .rw-widget-input > div,
  .rw-state-disabled > .rw-widget-input > div {
    cursor: not-allowed; }
  .rw-rtl .rw-widget-input > div > .rw-input {
    float: right; }

.rw-widget-input > div, .rw-widget-input > div .rw-input-reset {
  flex: 1; }

.rw-widget-input > div i.rw-loading {
  position: absolute;
  right: 3px; }

.rw-widget-input > div > .rw-input-reset {
  float: left;
  outline: 0;
  border-width: 0;
  line-height: normal;
  width: auto;
  height: 42px;
  padding-left: 10px; }
  .rw-widget-input > div > .rw-input-reset:focus {
    background: white !important; }

.rw-rtl .rw-widget-input > div > .rw-input-reset {
  float: right; }

.rw-widget-input > div .rw-tag-btn {
  outline: 0;
  font-size: 200%;
  font-weight: 100;
  line-height: 38px;
  cursor: pointer; }

.rw-multiselect-taglist {
  padding: 1px;
  margin: 0; }

.rw-multiselect-taglist > li {
  float: left;
  display: flex;
  align-items: center;
  margin: 1px;
  white-space: nowrap;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  cursor: default;
  line-height: 1; }
  .rw-multiselect-taglist > li:last-child {
    margin-bottom: 2px; }
  .rw-multiselect-taglist > li > span {
    padding: 0 10px; }

.container .rw-multiselect-taglist > li {
  border-color: rgba(17, 115, 188, 0.5); }
  .container .rw-multiselect-taglist > li:hover {
    border-color: #1173BC; }
  .container .rw-multiselect-taglist > li .rw-btn, .container .rw-multiselect-taglist > li .rw-cell {
    background-color: whitesmoke; }
    .container .rw-multiselect-taglist > li .rw-btn:hover, .container .rw-multiselect-taglist > li .rw-cell:hover {
      color: #1173BC;
      background-color: white; }

.user-row .rw-multiselect-taglist > li {
  border-color: rgba(214, 125, 0, 0.5); }
  .user-row .rw-multiselect-taglist > li:hover {
    border-color: #d67d00; }
  .user-row .rw-multiselect-taglist > li .rw-btn, .user-row .rw-multiselect-taglist > li .rw-cell {
    background-color: whitesmoke; }
    .user-row .rw-multiselect-taglist > li .rw-btn:hover, .user-row .rw-multiselect-taglist > li .rw-cell:hover {
      color: #d67d00;
      background-color: white; }

.item .rw-multiselect-taglist > li {
  border-color: rgba(214, 125, 0, 0.5); }
  .item .rw-multiselect-taglist > li:hover {
    border-color: #d67d00; }
  .item .rw-multiselect-taglist > li .rw-btn, .item .rw-multiselect-taglist > li .rw-cell {
    background-color: whitesmoke; }
    .item .rw-multiselect-taglist > li .rw-btn:hover, .item .rw-multiselect-taglist > li .rw-cell:hover {
      color: #d67d00;
      background-color: white; }

.message.draft .rw-multiselect-taglist > li {
  border-color: rgba(214, 125, 0, 0.5); }
  .message.draft .rw-multiselect-taglist > li:hover {
    border-color: #d67d00; }
  .message.draft .rw-multiselect-taglist > li .rw-btn, .message.draft .rw-multiselect-taglist > li .rw-cell {
    background-color: whitesmoke; }
    .message.draft .rw-multiselect-taglist > li .rw-btn:hover, .message.draft .rw-multiselect-taglist > li .rw-cell:hover {
      color: #d67d00;
      background-color: white; }

.message.scheduled .rw-multiselect-taglist > li {
  border-color: rgba(126, 211, 33, 0.5); }
  .message.scheduled .rw-multiselect-taglist > li:hover {
    border-color: #7ed321; }
  .message.scheduled .rw-multiselect-taglist > li .rw-btn, .message.scheduled .rw-multiselect-taglist > li .rw-cell {
    background-color: whitesmoke; }
    .message.scheduled .rw-multiselect-taglist > li .rw-btn:hover, .message.scheduled .rw-multiselect-taglist > li .rw-cell:hover {
      color: #7ed321;
      background-color: white; }

.rw-multiselect-taglist > li.rw-state-focus {
  background-color: #fff;
  border: #e0e0e0 1px solid;
  color: #333; }

.rw-rtl .rw-multiselect-taglist > li {
  float: right; }

.rw-i-chevron-left:before {
  content: "\e62a"; }

.rw-i-chevron-right:before {
  content: "\e62b"; }

.rw-i-chevron-up:before {
  content: "\f077"; }

.rw-i-chevron-down:before {
  content: "\f078"; }

.rw-i-times:before {
  content: "\f00d"; }

.rw-i-ban:before {
  content: "\f05e"; }

.rw-i-arrow-left:before {
  content: "\f060"; }

.rw-i-arrow-right:before {
  content: "\f061"; }

.rw-i-arrow-up:before {
  content: "\f062"; }

.rw-i-arrow-down:before {
  content: "\f063"; }

.rw-i-expand:before {
  content: "\f065"; }

.rw-i-compress:before {
  content: "\f066"; }

.rw-i-plus:before {
  content: "\f067"; }

.rw-i-minus:before {
  content: "\f068"; }

.rw-i-calendar:before {
  content: "\e31f"; }

.rw-i-caret-down:before {
  content: "\e628"; }

.rw-i-caret-up:before {
  content: "\e629"; }

.rw-i-caret-left:before {
  content: "\e62a"; }

.rw-i-caret-right:before {
  content: "\e62b"; }

.rw-i-clock-o:before {
  content: "\e321"; }

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0; }

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer; }
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0; }
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default; }
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px #0188fe;
    border-color: #0188fe;
    outline: none; }
    .react-tabs__tab:focus:after {
      content: "";
      position: absolute;
      height: 5px;
      left: -4px;
      right: -4px;
      bottom: -5px;
      background: #fff; }

.react-tabs__tab-panel {
  display: none; }
  .react-tabs__tab-panel--selected {
    display: block; }

@font-face {
  font-family: 'Streamline';
  src: url("../images/jaqen/streamline-outline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'streamline-outline';
  src: url("../images/jaqen/streamline-outline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Streamline';
  src: url("../images/jaqen/streamline-filled.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'streamline-outline';
  src: url("../images/jaqen/streamline-outline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'streamline-filled';
  src: url("../images/jaqen/streamline-filled.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'asset-web';
  src: url("../images/jaqen/assets-ios.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

[class^='icon-']:before, [class*=' icon-']:before {
  font-family: 'Streamline' !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1; }

.icon-custom-more:before {
  content: '\e66a'; }

.icon-custom-pulpit:before {
  content: '\e66b'; }

.icon-custom-church:before {
  content: '\e66c'; }

.icon-custom-cross:before {
  content: '\e66d'; }

.icon-custom-prayer:before {
  content: '\e66e'; }

.icon-custom-share:before {
  content: '\e66f'; }

.icon-custom-facebook:before {
  content: '\e670'; }

.icon-custom-twitter:before {
  content: '\e671'; }

.icon-custom-blankify:before {
  content: '\e672'; }

.icon-custom-events-feed:before {
  content: '\e673'; }

.icon-custom-simpleview:before {
  content: '\e674'; }

.icon-custom-app:before {
  content: '\e675'; }

.icon-custom-android:before {
  content: '\e676'; }

.icon-custom-ekklesia:before {
  content: '\e677'; }

.icon-custom-ccb:before {
  content: '\e678'; }

.icon-custom-miles:before {
  content: '\e67b'; }

.icon-custom-bluebridge-1:before {
  content: '\e67d'; }

.icon-custom-bluebridge-2:before {
  content: '\e67e'; }

.icon-custom-amazon:before {
  content: '\e67f'; }

.icon-custom-dribbble:before {
  content: '\e680'; }

.icon-custom-github:before {
  content: '\e681'; }

.icon-custom-instagram:before {
  content: '\e682'; }

.icon-custom-itunes:before {
  content: '\e683'; }

.icon-custom-linkedin:before {
  content: '\e684'; }

.icon-custom-echurch:before {
  content: '';
  background-image: url("../images/jaqen/echurch-icon.png");
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.icon-bin-1:before {
  content: '\e000'; }

.icon-bin-2:before {
  content: '\e001'; }

.icon-binocular:before {
  content: '\e002'; }

.icon-clip-1:before {
  content: '\e004'; }

.icon-clip-2:before {
  content: '\e005'; }

.icon-crosshair-1:before {
  content: '\e006'; }

.icon-crosshair-2:before {
  content: '\e007'; }

.icon-crosshair-3:before {
  content: '\e008'; }

.icon-cutter:before {
  content: '\e009'; }

.icon-delete-1:before {
  content: '\e00a'; }

.icon-delete-2:before {
  content: '\e00b'; }

.icon-edit-1:before {
  content: '\e00c'; }

.icon-edit-2:before {
  content: '\e00d'; }

.icon-edit-3:before {
  content: '\e00e'; }

.icon-hide:before {
  content: '\e00f'; }

.icon-ink:before {
  content: '\e010'; }

.icon-key-1:before {
  content: '\e011'; }

.icon-key-2:before {
  content: '\e012'; }

.icon-link-1:before {
  content: '\e013'; }

.icon-link-2:before {
  content: '\e014'; }

.icon-link-3:before {
  content: '\e015'; }

.icon-link-broken-1:before {
  content: '\e016'; }

.icon-link-broken-2:before {
  content: '\e017'; }

.icon-lock-1:before {
  content: '\e018'; }

.icon-lock-2:before {
  content: '\e019'; }

.icon-lock-3:before {
  content: '\e01a'; }

.icon-lock-4:before {
  content: '\e01b'; }

.icon-lock-5:before {
  content: '\e01c'; }

.icon-lock-unlock-1:before {
  content: '\e01d'; }

.icon-lock-unlock-2:before {
  content: '\e01e'; }

.icon-magnifier:before {
  content: '\e01f'; }

.icon-pen-1:before {
  content: '\e020'; }

.icon-pen-2:before {
  content: '\e021'; }

.icon-pen-3:before {
  content: '\e022'; }

.icon-pen-4:before {
  content: '\e023'; }

.icon-pencil-1:before {
  content: '\e024'; }

.icon-pencil-2:before {
  content: '\e025'; }

.icon-pencil-3:before {
  content: '\e026'; }

.icon-pin-1:before {
  content: '\e027'; }

.icon-pin-2:before {
  content: '\e028'; }

.icon-power-1:before {
  content: '\e029'; }

.icon-power-2:before {
  content: '\e02a'; }

.icon-preview-1:before {
  content: '\e02b'; }

.icon-preview-2:before {
  content: '\e02c'; }

.icon-scissor-1:before {
  content: '\e02d'; }

.icon-scissor-2:before {
  content: '\e02e'; }

.icon-type-1:before {
  content: '\e031'; }

.icon-type-2:before {
  content: '\e032'; }

.icon-type-3:before {
  content: '\e033'; }

.icon-type-4:before {
  content: '\e034'; }

.icon-zoom-area:before {
  content: '\e035'; }

.icon-zoom-in:before {
  content: '\e036'; }

.icon-zoom-out:before {
  content: '\e037'; }

.icon-cursor-1:before {
  content: '\e038'; }

.icon-cursor-2:before {
  content: '\e039'; }

.icon-cursor-add:before {
  content: '\e03a'; }

.icon-cursor-duplicate:before {
  content: '\e03b'; }

.icon-cursor-move:before {
  content: '\e03c'; }

.icon-cursor-move-2:before {
  content: '\e03d'; }

.icon-cursor-select-area:before {
  content: '\e03e'; }

.icon-hand:before {
  content: '\e03f'; }

.icon-hand-block:before {
  content: '\e040'; }

.icon-hand-grab-1:before {
  content: '\e041'; }

.icon-hand-grab-2:before {
  content: '\e042'; }

.icon-hand-point:before {
  content: '\e043'; }

.icon-hand-touch-1:before {
  content: '\e044'; }

.icon-hand-touch-2:before {
  content: '\e045'; }

.icon-hand-touch-3:before {
  content: '\e046'; }

.icon-hand-touch-4:before {
  content: '\e047'; }

.icon-bookmark-1:before {
  content: '\e048'; }

.icon-bookmark-2:before {
  content: '\e049'; }

.icon-bookmark-3:before {
  content: '\e04a'; }

.icon-bookmark-4:before {
  content: '\e04b'; }

.icon-tag-1:before {
  content: '\e04c'; }

.icon-tag-2:before {
  content: '\e04d'; }

.icon-tag-add:before {
  content: '\e04e'; }

.icon-tag-delete:before {
  content: '\e04f'; }

.icon-tags-1:before {
  content: '\e050'; }

.icon-tags-2:before {
  content: '\e051'; }

.icon-anchor-point-1:before {
  content: '\e052'; }

.icon-anchor-point-2:before {
  content: '\e053'; }

.icon-arrange-1:before {
  content: '\e054'; }

.icon-arrange-2:before {
  content: '\e055'; }

.icon-artboard:before {
  content: '\e056'; }

.icon-brush-1:before {
  content: '\e057'; }

.icon-brush-2:before {
  content: '\e058'; }

.icon-bucket:before {
  content: '\e059'; }

.icon-crop:before {
  content: '\e05a'; }

.icon-dropper-1:before {
  content: '\e05b'; }

.icon-dropper-2:before {
  content: '\e05c'; }

.icon-dropper-3:before {
  content: '\e05d'; }

.icon-glue:before {
  content: '\e05e'; }

.icon-grid:before {
  content: '\e05f'; }

.icon-layers:before {
  content: '\e060'; }

.icon-magic-wand-1:before {
  content: '\e061'; }

.icon-magic-wand-2:before {
  content: '\e062'; }

.icon-magnet:before {
  content: '\e063'; }

.icon-marker:before {
  content: '\e064'; }

.icon-palette:before {
  content: '\e065'; }

.icon-pen-5:before {
  content: '\e066'; }

.icon-pen-6:before {
  content: '\e067'; }

.icon-quill:before {
  content: '\e068'; }

.icon-reflect:before {
  content: '\e069'; }

.icon-roller:before {
  content: '\e06a'; }

.icon-ruler-1:before {
  content: '\e06b'; }

.icon-ruler-2:before {
  content: '\e06c'; }

.icon-scale-diagonal-1:before {
  content: '\e06d'; }

.icon-scale-diagonal-2:before {
  content: '\e06e'; }

.icon-scale-horizontal:before {
  content: '\e06f'; }

.icon-scale-tool-1:before {
  content: '\e070'; }

.icon-scale-tool-2:before {
  content: '\e071'; }

.icon-scale-tool-3:before {
  content: '\e072'; }

.icon-scale-vertical:before {
  content: '\e073'; }

.icon-shear-tool:before {
  content: '\e074'; }

.icon-spray:before {
  content: '\e075'; }

.icon-stamp:before {
  content: '\e076'; }

.icon-stationery-1:before {
  content: '\e077'; }

.icon-stationery-2:before {
  content: '\e078'; }

.icon-stationery-3:before {
  content: '\e079'; }

.icon-vector:before {
  content: '\e07a'; }

.icon-award-1:before {
  content: '\e07b'; }

.icon-award-2:before {
  content: '\e07c'; }

.icon-award-3:before {
  content: '\e07d'; }

.icon-award-4:before {
  content: '\e07e'; }

.icon-award-5:before {
  content: '\e07f'; }

.icon-award-6:before {
  content: '\e080'; }

.icon-crown-1:before {
  content: '\e081'; }

.icon-crown-2:before {
  content: '\e082'; }

.icon-crown-3:before {
  content: '\e083'; }

.icon-fire:before {
  content: '\e084'; }

.icon-flag-1:before {
  content: '\e085'; }

.icon-flag-2:before {
  content: '\e086'; }

.icon-flag-3:before {
  content: '\e087'; }

.icon-flag-4:before {
  content: '\e088'; }

.icon-flag-5:before {
  content: '\e089'; }

.icon-flag-6:before {
  content: '\e08a'; }

.icon-flag-7:before {
  content: '\e08b'; }

.icon-flag-8:before {
  content: '\e08c'; }

.icon-google-plus-1:before {
  content: '\e08d'; }

.icon-google-plus-2:before {
  content: '\e08e'; }

.icon-hand-like-1:before {
  content: '\e08f'; }

.icon-hand-like-2:before {
  content: '\e090'; }

.icon-hand-unlike-1:before {
  content: '\e091'; }

.icon-hand-unlike-2:before {
  content: '\e092'; }

.icon-heart-1:before {
  content: '\e093'; }

.icon-heart-2:before {
  content: '\e094'; }

.icon-heart-angel:before {
  content: '\e095'; }

.icon-heart-broken:before {
  content: '\e096'; }

.icon-heart-minus:before {
  content: '\e097'; }

.icon-heart-plus:before {
  content: '\e098'; }

.icon-present:before {
  content: '\e099'; }

.icon-rank-1:before {
  content: '\e09a'; }

.icon-rank-2:before {
  content: '\e09b'; }

.icon-ribbon:before {
  content: '\e09c'; }

.icon-star-1:before {
  content: '\e09d'; }

.icon-star-2:before {
  content: '\e09e'; }

.icon-star-3:before {
  content: '\e09f'; }

.icon-star-4:before {
  content: '\e0a0'; }

.icon-star-5:before {
  content: '\e0a1'; }

.icon-star-6:before {
  content: '\e0a2'; }

.icon-star-7:before {
  content: '\e0a3'; }

.icon-star-8:before {
  content: '\e0a4'; }

.icon-star-9:before {
  content: '\e0a5'; }

.icon-star-10:before {
  content: '\e0a6'; }

.icon-trophy:before {
  content: '\e0a7'; }

.icon-bubble-2:before {
  content: '\e0a8'; }

.icon-bubble-add-1:before {
  content: '\e0a9'; }

.icon-bubble-add-2:before {
  content: '\e0aa'; }

.icon-bubble-add-3:before {
  content: '\e0ab'; }

.icon-bubble-ask-1:before {
  content: '\e0ac'; }

.icon-bubble-ask-2:before {
  content: '\e0ad'; }

.icon-bubble-attention-1:before {
  content: '\e0ae'; }

.icon-bubble-attention-2:before {
  content: '\e0af'; }

.icon-bubble-attention-3:before {
  content: '\e0b0'; }

.icon-bubble-attention-4:before {
  content: '\e0b1'; }

.icon-bubble-attention-5:before {
  content: '\e0b2'; }

.icon-bubble-attention-6:before {
  content: '\e0b3'; }

.icon-bubble-attention-7:before {
  content: '\e0b4'; }

.icon-bubble-block-1:before {
  content: '\e0b5'; }

.icon-bubble-block-2:before {
  content: '\e0b6'; }

.icon-bubble-block-3:before {
  content: '\e0b7'; }

.icon-bubble-chat-1:before {
  content: '\e0b8'; }

.icon-bubble-chat-2:before {
  content: '\e0b9'; }

.icon-bubble-check-1:before {
  content: '\e0ba'; }

.icon-bubble-check-2:before {
  content: '\e0bb'; }

.icon-bubble-check-3:before {
  content: '\e0bc'; }

.icon-bubble-comment-1:before {
  content: '\e0bd'; }

.icon-bubble-comment-2:before {
  content: '\e0be'; }

.icon-bubble-conversation-1:before {
  content: '\e0bf'; }

.icon-bubble-conversation-2:before {
  content: '\e0c0'; }

.icon-bubble-conversation-3:before {
  content: '\e0c1'; }

.icon-bubble-conversation-4:before {
  content: '\e0c2'; }

.icon-bubble-conversation-5:before {
  content: '\e0c3'; }

.icon-bubble-conversation-6:before {
  content: '\e0c4'; }

.icon-bubble-delete-1:before {
  content: '\e0c5'; }

.icon-bubble-delete-2:before {
  content: '\e0c6'; }

.icon-bubble-delete-3:before {
  content: '\e0c7'; }

.icon-bubble-edit-1:before {
  content: '\e0c8'; }

.icon-bubble-edit-2:before {
  content: '\e0c9'; }

.icon-bubble-edit-3:before {
  content: '\e0ca'; }

.icon-bubble-heart-1:before {
  content: '\e0cb'; }

.icon-bubble-heart-2:before {
  content: '\e0cc'; }

.icon-bubble-minus-1:before {
  content: '\e0cd'; }

.icon-bubble-minus-2:before {
  content: '\e0ce'; }

.icon-bubble-minus-3:before {
  content: '\e0cf'; }

.icon-bubble-quote-1:before {
  content: '\e0d0'; }

.icon-bubble-quote-2:before {
  content: '\e0d1'; }

.icon-bubble-smiley-3:before {
  content: '\e0d2'; }

.icon-bubble-smiley-4:before {
  content: '\e0d3'; }

.icon-bubble-smiley-smile:before {
  content: '\e0d4'; }

.icon-bubble-smiley-wink:before {
  content: '\e0d5'; }

.icon-bubble-star-1:before {
  content: '\e0d6'; }

.icon-bubble-star-2:before {
  content: '\e0d7'; }

.icon-bubble-star-3:before {
  content: '\e0d8'; }

.icon-chat-1:before {
  content: '\e0d9'; }

.icon-chat-2:before {
  content: '\e0da'; }

.icon-chat-3:before {
  content: '\e0db'; }

.icon-chat-4:before {
  content: '\e0dc'; }

.icon-chat-5:before {
  content: '\e0dd'; }

.icon-chat-bubble-1:before {
  content: '\e0de'; }

.icon-chat-bubble-2:before {
  content: '\e0df'; }

.icon-smiley-happy-1:before {
  content: '\e0e0'; }

.icon-smiley-happy-2:before {
  content: '\e0e1'; }

.icon-smiley-happy-3:before {
  content: '\e0e2'; }

.icon-smiley-happy-4:before {
  content: '\e0e3'; }

.icon-smiley-happy-5:before {
  content: '\e0e4'; }

.icon-smiley-relax:before {
  content: '\e0e5'; }

.icon-smiley-sad:before {
  content: '\e0e6'; }

.icon-smiley-surprise:before {
  content: '\e0e7'; }

.icon-thinking-1:before {
  content: '\e0e8'; }

.icon-thinking-2:before {
  content: '\e0e9'; }

.icon-call-1:before {
  content: '\e0ea'; }

.icon-call-2:before {
  content: '\e0eb'; }

.icon-call-3:before {
  content: '\e0ec'; }

.icon-call-4:before {
  content: '\e0ed'; }

.icon-call-add:before {
  content: '\e0ee'; }

.icon-call-block:before {
  content: '\e0ef'; }

.icon-call-delete:before {
  content: '\e0f0'; }

.icon-call-in:before {
  content: '\e0f1'; }

.icon-call-minus:before {
  content: '\e0f2'; }

.icon-call-out:before {
  content: '\e0f3'; }

.icon-contact:before {
  content: '\e0f4'; }

.icon-fax:before {
  content: '\e0f5'; }

.icon-hang-up:before {
  content: '\e0f6'; }

.icon-message:before {
  content: '\e0f7'; }

.icon-mobile-phone-1:before {
  content: '\e0f8'; }

.icon-mobile-phone-2:before {
  content: '\e0f9'; }

.icon-phone-1:before {
  content: '\e0fa'; }

.icon-phone-2:before {
  content: '\e0fb'; }

.icon-phone-3:before {
  content: '\e0fc'; }

.icon-phone-4:before {
  content: '\e0fd'; }

.icon-phone-vibration:before {
  content: '\e0fe'; }

.icon-signal-fine:before {
  content: '\e0ff'; }

.icon-signal-full:before {
  content: '\e100'; }

.icon-signal-high:before {
  content: '\e101'; }

.icon-signal-no:before {
  content: '\e102'; }

.icon-signal-poor:before {
  content: '\e103'; }

.icon-signal-weak:before {
  content: '\e104'; }

.icon-smartphone:before {
  content: '\e105'; }

.icon-tape:before {
  content: '\e106'; }

.icon-camera-symbol-1:before {
  content: '\e107'; }

.icon-camera-symbol-2:before {
  content: '\e108'; }

.icon-camera-symbol-3:before {
  content: '\e109'; }

.icon-antenna-1:before {
  content: '\e10b'; }

.icon-antenna-2:before {
  content: '\e10c'; }

.icon-antenna-3:before {
  content: '\e10d'; }

.icon-hotspot-1:before {
  content: '\e10e'; }

.icon-hotspot-2:before {
  content: '\e10f'; }

.icon-link:before {
  content: '\e110'; }

.icon-megaphone-1:before {
  content: '\e111'; }

.icon-megaphone-2:before {
  content: '\e112'; }

.icon-radar:before {
  content: '\e113'; }

.icon-rss-1:before {
  content: '\e114'; }

.icon-rss-2:before {
  content: '\e115'; }

.icon-satellite:before {
  content: '\e116'; }

.icon-address-1:before {
  content: '\e117'; }

.icon-address-2:before {
  content: '\e118'; }

.icon-address-3:before {
  content: '\e119'; }

.icon-forward:before {
  content: '\e11a'; }

.icon-inbox-1:before {
  content: '\e11b'; }

.icon-inbox-2:before {
  content: '\e11c'; }

.icon-inbox-3:before {
  content: '\e11d'; }

.icon-inbox-4:before {
  content: '\e11e'; }

.icon-letter-1:before {
  content: '\e11f'; }

.icon-letter-2:before {
  content: '\e120'; }

.icon-letter-3:before {
  content: '\e121'; }

.icon-letter-4:before {
  content: '\e122'; }

.icon-letter-5:before {
  content: '\e123'; }

.icon-mail-1:before {
  content: '\e124'; }

.icon-mail-2:before {
  content: '\e125'; }

.icon-mail-add:before {
  content: '\e126'; }

.icon-mail-attention:before {
  content: '\e127'; }

.icon-mail-block:before {
  content: '\e128'; }

.icon-mail-box-1:before {
  content: '\e129'; }

.icon-mail-box-2:before {
  content: '\e12a'; }

.icon-mail-box-3:before {
  content: '\e12b'; }

.icon-mail-checked:before {
  content: '\e12c'; }

.icon-mail-compose:before {
  content: '\e12d'; }

.icon-mail-delete:before {
  content: '\e12e'; }

.icon-mail-favorite:before {
  content: '\e12f'; }

.icon-mail-inbox:before {
  content: '\e130'; }

.icon-mail-lock:before {
  content: '\e131'; }

.icon-mail-minus:before {
  content: '\e132'; }

.icon-mail-read:before {
  content: '\e133'; }

.icon-mail-recieved-1:before {
  content: '\e134'; }

.icon-mail-recieved-2:before {
  content: '\e135'; }

.icon-mail-search-1:before {
  content: '\e136'; }

.icon-mail-search-2:before {
  content: '\e137'; }

.icon-mail-sent-1:before {
  content: '\e138'; }

.icon-mail-sent-2:before {
  content: '\e139'; }

.icon-mail-setting:before {
  content: '\e13a'; }

.icon-mail-star:before {
  content: '\e13b'; }

.icon-mail-sync:before {
  content: '\e13c'; }

.icon-mail-time:before {
  content: '\e13d'; }

.icon-outbox-1:before {
  content: '\e13e'; }

.icon-outbox-2:before {
  content: '\e13f'; }

.icon-plane-paper-1:before {
  content: '\e140'; }

.icon-plane-paper-2:before {
  content: '\e141'; }

.icon-reply-mail-1:before {
  content: '\e142'; }

.icon-reply-mail-2:before {
  content: '\e143'; }

.icon-connection-1:before {
  content: '\e144'; }

.icon-connection-2:before {
  content: '\e145'; }

.icon-connection-3:before {
  content: '\e146'; }

.icon-contacts-1:before {
  content: '\e147'; }

.icon-contacts-2:before {
  content: '\e148'; }

.icon-contacts-3:before {
  content: '\e149'; }

.icon-contacts-4:before {
  content: '\e14a'; }

.icon-female:before {
  content: '\e14b'; }

.icon-gender-female:before {
  content: '\e14c'; }

.icon-gender-male:before {
  content: '\e14d'; }

.icon-genders:before {
  content: '\e14e'; }

.icon-id-1:before {
  content: '\e14f'; }

.icon-id-2:before {
  content: '\e150'; }

.icon-id-3:before {
  content: '\e151'; }

.icon-id-4:before {
  content: '\e152'; }

.icon-id-5:before {
  content: '\e153'; }

.icon-id-6:before {
  content: '\e154'; }

.icon-id-7:before {
  content: '\e155'; }

.icon-id-8:before {
  content: '\e156'; }

.icon-male:before {
  content: '\e157'; }

.icon-profile-1:before {
  content: '\e158'; }

.icon-profile-2:before {
  content: '\e159'; }

.icon-profile-3:before {
  content: '\e15a'; }

.icon-profile-4:before {
  content: '\e15b'; }

.icon-profile-5:before {
  content: '\e15c'; }

.icon-profile-6:before {
  content: '\e15d'; }

.icon-profile-athlete:before {
  content: '\e15e'; }

.icon-profile-bussiness-man:before {
  content: '\e15f'; }

.icon-profile-chef:before {
  content: '\e160'; }

.icon-profile-cop:before {
  content: '\e161'; }

.icon-profile-doctor-1:before {
  content: '\e162'; }

.icon-profile-doctor-2:before {
  content: '\e163'; }

.icon-profile-gentleman-1:before {
  content: '\e164'; }

.icon-profile-gentleman-2:before {
  content: '\e165'; }

.icon-profile-graduate:before {
  content: '\e166'; }

.icon-profile-king:before {
  content: '\e167'; }

.icon-profile-lady-1:before {
  content: '\e168'; }

.icon-profile-lady-2:before {
  content: '\e169'; }

.icon-profile-man:before {
  content: '\e16a'; }

.icon-profile-nurse1:before {
  content: '\e16b'; }

.icon-profile-nurse-2:before {
  content: '\e16c'; }

.icon-profile-prisoner:before {
  content: '\e16d'; }

.icon-profile-serviceman-1:before {
  content: '\e16e'; }

.icon-profile-serviceman-2:before {
  content: '\e16f'; }

.icon-profile-spy:before {
  content: '\e170'; }

.icon-profile-teacher:before {
  content: '\e171'; }

.icon-profile-thief:before {
  content: '\e172'; }

.icon-user-1:before {
  content: '\e173'; }

.icon-user-2:before {
  content: '\e174'; }

.icon-user-add-1:before {
  content: '\e175'; }

.icon-user-add-2:before {
  content: '\e176'; }

.icon-user-block-1:before {
  content: '\e177'; }

.icon-user-block-2:before {
  content: '\e178'; }

.icon-user-checked-1:before {
  content: '\e179'; }

.icon-user-checked-2:before {
  content: '\e17a'; }

.icon-user-delete-1:before {
  content: '\e17b'; }

.icon-user-delete-2:before {
  content: '\e17c'; }

.icon-user-edit-1:before {
  content: '\e17d'; }

.icon-user-edit-2:before {
  content: '\e17e'; }

.icon-user-heart-1:before {
  content: '\e17f'; }

.icon-user-heart-2:before {
  content: '\e180'; }

.icon-user-lock-1:before {
  content: '\e181'; }

.icon-user-lock-2:before {
  content: '\e182'; }

.icon-user-minus-1:before {
  content: '\e183'; }

.icon-user-minus-2:before {
  content: '\e184'; }

.icon-user-search-1:before {
  content: '\e185'; }

.icon-user-search-2:before {
  content: '\e186'; }

.icon-user-setting-1:before {
  content: '\e187'; }

.icon-user-setting-2:before {
  content: '\e188'; }

.icon-user-star-1:before {
  content: '\e189'; }

.icon-user-star-2:before {
  content: '\e18a'; }

.icon-bag-shopping-1:before {
  content: '\e18b'; }

.icon-bag-shopping-2:before {
  content: '\e18c'; }

.icon-bag-shopping-3:before {
  content: '\e18d'; }

.icon-basket-1:before {
  content: '\e18e'; }

.icon-basket-2:before {
  content: '\e18f'; }

.icon-basket-3:before {
  content: '\e190'; }

.icon-basket-add:before {
  content: '\e191'; }

.icon-basket-minus:before {
  content: '\e192'; }

.icon-briefcase:before {
  content: '\e193'; }

.icon-cart-1:before {
  content: '\e194'; }

.icon-cart-2:before {
  content: '\e195'; }

.icon-cart-3:before {
  content: '\e196'; }

.icon-cart-4:before {
  content: '\e197'; }

.icon-cut:before {
  content: '\e198'; }

.icon-handbag-1:before {
  content: '\e199'; }

.icon-handbag-2:before {
  content: '\e19a'; }

.icon-purse-1:before {
  content: '\e19b'; }

.icon-purse-2:before {
  content: '\e19c'; }

.icon-qr-code:before {
  content: '\e19d'; }

.icon-receipt-1:before {
  content: '\e19e'; }

.icon-receipt-2:before {
  content: '\e19f'; }

.icon-receipt-3:before {
  content: '\e1a0'; }

.icon-receipt-4:before {
  content: '\e1a1'; }

.icon-shopping-1:before {
  content: '\e1a2'; }

.icon-sign-new-1:before {
  content: '\e1a3'; }

.icon-sign-new-2:before {
  content: '\e1a4'; }

.icon-sign-parking:before {
  content: '\e1a5'; }

.icon-signal-star:before {
  content: '\e1a6'; }

.icon-trolley-1:before {
  content: '\e1a7'; }

.icon-trolley-2:before {
  content: '\e1a8'; }

.icon-trolley-3:before {
  content: '\e1a9'; }

.icon-trolley-load:before {
  content: '\e1aa'; }

.icon-trolley-off:before {
  content: '\e1ab'; }

.icon-wallet-1:before {
  content: '\e1ac'; }

.icon-wallet-2:before {
  content: '\e1ad'; }

.icon-wallet-3:before {
  content: '\e1ae'; }

.icon-camera-1:before {
  content: '\e1af'; }

.icon-camera-2:before {
  content: '\e1b0'; }

.icon-camera-3:before {
  content: '\e1b1'; }

.icon-camera-4:before {
  content: '\e1b2'; }

.icon-camera-5:before {
  content: '\e1b3'; }

.icon-camera-back:before {
  content: '\e1b4'; }

.icon-camera-focus:before {
  content: '\e1b5'; }

.icon-camera-frames:before {
  content: '\e1b6'; }

.icon-camera-front:before {
  content: '\e1b7'; }

.icon-camera-graph-1:before {
  content: '\e1b8'; }

.icon-camera-graph-2:before {
  content: '\e1b9'; }

.icon-camera-landscape:before {
  content: '\e1ba'; }

.icon-camera-lens-1:before {
  content: '\e1bb'; }

.icon-camera-lens-2:before {
  content: '\e1bc'; }

.icon-camera-light:before {
  content: '\e1bd'; }

.icon-camera-portrait:before {
  content: '\e1be'; }

.icon-camera-view:before {
  content: '\e1bf'; }

.icon-film-1:before {
  content: '\e1c0'; }

.icon-film-2:before {
  content: '\e1c1'; }

.icon-photo-1:before {
  content: '\e1c2'; }

.icon-photo-2:before {
  content: '\e1c3'; }

.icon-photo-frame:before {
  content: '\e1c4'; }

.icon-photos-1:before {
  content: '\e1c5'; }

.icon-photos-2:before {
  content: '\e1c6'; }

.icon-polaroid:before {
  content: '\e1c7'; }

.icon-signal-camera-1:before {
  content: '\e1c8'; }

.icon-signal-camera-2:before {
  content: '\e1c9'; }

.icon-user-photo:before {
  content: '\e1ca'; }

.icon-backward-1:before {
  content: '\e1cb'; }

.icon-dvd-player:before {
  content: '\e1cc'; }

.icon-eject-1:before {
  content: '\e1cd'; }

.icon-film-3:before {
  content: '\e1ce'; }

.icon-forward-1:before {
  content: '\e1cf'; }

.icon-handycam:before {
  content: '\e1d0'; }

.icon-movie-play-1:before {
  content: '\e1d1'; }

.icon-movie-play-2:before {
  content: '\e1d2'; }

.icon-movie-play-3:before {
  content: '\e1d3'; }

.icon-next-1:before {
  content: '\e1d4'; }

.icon-pause-1:before {
  content: '\e1d5'; }

.icon-play-1:before {
  content: '\e1d6'; }

.icon-player:before {
  content: '\e1d7'; }

.icon-previous-1:before {
  content: '\e1d8'; }

.icon-record-1:before {
  content: '\e1d9'; }

.icon-slate:before {
  content: '\e1da'; }

.icon-stop-1:before {
  content: '\e1db'; }

.icon-television:before {
  content: '\e1dc'; }

.icon-video-camera-1:before {
  content: '\e1dd'; }

.icon-video-camera-2:before {
  content: '\e1de'; }

.icon-backward-2:before {
  content: '\e1df'; }

.icon-cd:before {
  content: '\e1e0'; }

.icon-eject-2:before {
  content: '\e1e1'; }

.icon-equalizer-2:before {
  content: '\e1e2'; }

.icon-equalizer-3:before {
  content: '\e1e3'; }

.icon-forward-2:before {
  content: '\e1e4'; }

.icon-gramophone:before {
  content: '\e1e5'; }

.icon-gramophone-record-2:before {
  content: '\e1e6'; }

.icon-guitar:before {
  content: '\e1e7'; }

.icon-headphone:before {
  content: '\e10a'; }

.icon-headphone-1:before {
  content: '\e1e8'; }

.icon-headphone-2:before {
  content: '\e1e9'; }

.icon-microphone-1:before {
  content: '\e1ea'; }

.icon-microphone-2:before {
  content: '\e1eb'; }

.icon-microphone-3:before {
  content: '\e1ec'; }

.icon-movie-play-4:before {
  content: '\e1ed'; }

.icon-music-note-1:before {
  content: '\e1ee'; }

.icon-music-note-2:before {
  content: '\e1ef'; }

.icon-music-note-3:before {
  content: '\e1f0'; }

.icon-music-note-4:before {
  content: '\e1f1'; }

.icon-next-2:before {
  content: '\e1f2'; }

.icon-notes-1:before {
  content: '\e1f3'; }

.icon-notes-2:before {
  content: '\e1f4'; }

.icon-pause-2:before {
  content: '\e1f5'; }

.icon-piano:before {
  content: '\e1f6'; }

.icon-play-2:before {
  content: '\e1f7'; }

.icon-playlist:before {
  content: '\e1f8'; }

.icon-previous-2:before {
  content: '\e1f9'; }

.icon-radio-1:before {
  content: '\e1fa'; }

.icon-radio-2:before {
  content: '\e1fb'; }

.icon-record-2:before {
  content: '\e1fc'; }

.icon-recorder:before {
  content: '\e1fd'; }

.icon-saxophone:before {
  content: '\e1fe'; }

.icon-speaker-1:before {
  content: '\e1ff'; }

.icon-speaker-2:before {
  content: '\e200'; }

.icon-speaker-3:before {
  content: '\e201'; }

.icon-stop-2:before {
  content: '\e202'; }

.icon-tape-1:before {
  content: '\e203'; }

.icon-trumpet:before {
  content: '\e204'; }

.icon-volume-down-1:before {
  content: '\e205'; }

.icon-volume-down-2:before {
  content: '\e206'; }

.icon-volume-loud-1:before {
  content: '\e207'; }

.icon-volume-loud-2:before {
  content: '\e208'; }

.icon-volume-low-1:before {
  content: '\e209'; }

.icon-volume-low-2:before {
  content: '\e20a'; }

.icon-volume-medium-1:before {
  content: '\e20b'; }

.icon-volume-medium-2:before {
  content: '\e20c'; }

.icon-volume-mute-1:before {
  content: '\e20d'; }

.icon-volume-mute-2:before {
  content: '\e20e'; }

.icon-volume-mute-3:before {
  content: '\e20f'; }

.icon-volume-up-1:before {
  content: '\e210'; }

.icon-volume-up-2:before {
  content: '\e211'; }

.icon-walkman:before {
  content: '\e212'; }

.icon-cloud:before {
  content: '\e213'; }

.icon-cloud-add:before {
  content: '\e214'; }

.icon-cloud-checked:before {
  content: '\e215'; }

.icon-cloud-delete:before {
  content: '\e216'; }

.icon-cloud-download:before {
  content: '\e217'; }

.icon-cloud-minus:before {
  content: '\e218'; }

.icon-cloud-refresh:before {
  content: '\e219'; }

.icon-cloud-sync:before {
  content: '\e21a'; }

.icon-cloud-upload:before {
  content: '\e21b'; }

.icon-download-1:before {
  content: '\e21c'; }

.icon-download-2:before {
  content: '\e21d'; }

.icon-download-3:before {
  content: '\e21e'; }

.icon-download-4:before {
  content: '\e21f'; }

.icon-download-5:before {
  content: '\e220'; }

.icon-download-6:before {
  content: '\e221'; }

.icon-download-7:before {
  content: '\e222'; }

.icon-download-8:before {
  content: '\e223'; }

.icon-download-9:before {
  content: '\e224'; }

.icon-download-10:before {
  content: '\e225'; }

.icon-download-11:before {
  content: '\e226'; }

.icon-download-12:before {
  content: '\e227'; }

.icon-download-13:before {
  content: '\e228'; }

.icon-download-14:before {
  content: '\e229'; }

.icon-download-15:before {
  content: '\e22a'; }

.icon-download-file:before {
  content: '\e22b'; }

.icon-download-folder:before {
  content: '\e22c'; }

.icon-goal-1:before {
  content: '\e22d'; }

.icon-goal-2:before {
  content: '\e22e'; }

.icon-transfer-1:before {
  content: '\e22f'; }

.icon-transfer-2:before {
  content: '\e230'; }

.icon-transfer-3:before {
  content: '\e231'; }

.icon-transfer-4:before {
  content: '\e232'; }

.icon-transfer-5:before {
  content: '\e233'; }

.icon-transfer-6:before {
  content: '\e234'; }

.icon-transfer-7:before {
  content: '\e235'; }

.icon-transfer-8:before {
  content: '\e236'; }

.icon-transfer-9:before {
  content: '\e237'; }

.icon-transfer-10:before {
  content: '\e238'; }

.icon-transfer-11:before {
  content: '\e239'; }

.icon-transfer-12:before {
  content: '\e23a'; }

.icon-upload-1:before {
  content: '\e23b'; }

.icon-upload-2:before {
  content: '\e23c'; }

.icon-upload-3:before {
  content: '\e23d'; }

.icon-upload-4:before {
  content: '\e23e'; }

.icon-upload-5:before {
  content: '\e23f'; }

.icon-upload-6:before {
  content: '\e240'; }

.icon-upload-7:before {
  content: '\e241'; }

.icon-upload-8:before {
  content: '\e242'; }

.icon-upload-9:before {
  content: '\e243'; }

.icon-upload-10:before {
  content: '\e244'; }

.icon-upload-11:before {
  content: '\e245'; }

.icon-upload-12:before {
  content: '\e246'; }

.icon-clipboard-1:before {
  content: '\e247'; }

.icon-clipboard-2:before {
  content: '\e248'; }

.icon-clipboard-3:before {
  content: '\e249'; }

.icon-clipboard-add:before {
  content: '\e24a'; }

.icon-clipboard-block:before {
  content: '\e24b'; }

.icon-clipboard-checked:before {
  content: '\e24c'; }

.icon-clipboard-delete:before {
  content: '\e24d'; }

.icon-clipboard-edit:before {
  content: '\e24e'; }

.icon-clipboard-minus:before {
  content: '\e24f'; }

.icon-document-1:before {
  content: '\e250'; }

.icon-document-2:before {
  content: '\e251'; }

.icon-file-1:before {
  content: '\e252'; }

.icon-file-2:before {
  content: '\e253'; }

.icon-file-add:before {
  content: '\e254'; }

.icon-file-attention:before {
  content: '\e255'; }

.icon-file-block:before {
  content: '\e256'; }

.icon-file-bookmark:before {
  content: '\e257'; }

.icon-file-checked:before {
  content: '\e258'; }

.icon-file-code:before {
  content: '\e259'; }

.icon-file-delete:before {
  content: '\e25a'; }

.icon-file-download:before {
  content: '\e25b'; }

.icon-file-edit:before {
  content: '\e25c'; }

.icon-file-favorite-1:before {
  content: '\e25d'; }

.icon-file-favorite-2:before {
  content: '\e25e'; }

.icon-file-graph-1:before {
  content: '\e25f'; }

.icon-file-graph-2:before {
  content: '\e260'; }

.icon-file-home:before {
  content: '\e261'; }

.icon-file-image-1:before {
  content: '\e262'; }

.icon-file-image-2:before {
  content: '\e263'; }

.icon-file-list:before {
  content: '\e264'; }

.icon-file-lock:before {
  content: '\e265'; }

.icon-file-media:before {
  content: '\e266'; }

.icon-file-minus:before {
  content: '\e267'; }

.icon-file-music:before {
  content: '\e268'; }

.icon-file-new:before {
  content: '\e269'; }

.icon-file-registry:before {
  content: '\e26a'; }

.icon-file-search:before {
  content: '\e26b'; }

.icon-file-setting:before {
  content: '\e26c'; }

.icon-file-sync:before {
  content: '\e26d'; }

.icon-file-table:before {
  content: '\e26e'; }

.icon-file-thumbnail:before {
  content: '\e26f'; }

.icon-file-time:before {
  content: '\e270'; }

.icon-file-transfer:before {
  content: '\e271'; }

.icon-file-upload:before {
  content: '\e272'; }

.icon-file-zip:before {
  content: '\e273'; }

.icon-files-1:before {
  content: '\e274'; }

.icon-files-2:before {
  content: '\e275'; }

.icon-files-3:before {
  content: '\e276'; }

.icon-files-4:before {
  content: '\e277'; }

.icon-files-5:before {
  content: '\e278'; }

.icon-files-6:before {
  content: '\e279'; }

.icon-hand-file-1:before {
  content: '\e27a'; }

.icon-hand-file-2:before {
  content: '\e27b'; }

.icon-note-paper-1:before {
  content: '\e27c'; }

.icon-note-paper-2:before {
  content: '\e27d'; }

.icon-note-paper-add:before {
  content: '\e27e'; }

.icon-note-paper-attention:before {
  content: '\e27f'; }

.icon-note-paper-block:before {
  content: '\e280'; }

.icon-note-paper-checked:before {
  content: '\e281'; }

.icon-note-paper-delete:before {
  content: '\e282'; }

.icon-note-paper-download:before {
  content: '\e283'; }

.icon-note-paper-edit:before {
  content: '\e284'; }

.icon-note-paper-favorite:before {
  content: '\e285'; }

.icon-note-paper-lock:before {
  content: '\e286'; }

.icon-note-paper-minus:before {
  content: '\e287'; }

.icon-note-paper-search:before {
  content: '\e288'; }

.icon-note-paper-sync:before {
  content: '\e289'; }

.icon-note-paper-upload:before {
  content: '\e28a'; }

.icon-print:before {
  content: '\e28b'; }

.icon-folder-1:before {
  content: '\e28c'; }

.icon-folder-2:before {
  content: '\e28d'; }

.icon-folder-3:before {
  content: '\e28e'; }

.icon-folder-4:before {
  content: '\e28f'; }

.icon-folder-add:before {
  content: '\e290'; }

.icon-folder-attention:before {
  content: '\e291'; }

.icon-folder-block:before {
  content: '\e292'; }

.icon-folder-bookmark:before {
  content: '\e293'; }

.icon-folder-checked:before {
  content: '\e294'; }

.icon-folder-code:before {
  content: '\e295'; }

.icon-folder-delete:before {
  content: '\e296'; }

.icon-folder-download:before {
  content: '\e297'; }

.icon-folder-edit:before {
  content: '\e298'; }

.icon-folder-favorite:before {
  content: '\e299'; }

.icon-folder-home:before {
  content: '\e29a'; }

.icon-folder-image:before {
  content: '\e29b'; }

.icon-folder-lock:before {
  content: '\e29c'; }

.icon-folder-media:before {
  content: '\e29d'; }

.icon-folder-minus:before {
  content: '\e29e'; }

.icon-folder-music:before {
  content: '\e29f'; }

.icon-folder-new:before {
  content: '\e2a0'; }

.icon-folder-search:before {
  content: '\e2a1'; }

.icon-folder-setting:before {
  content: '\e2a2'; }

.icon-folder-share-1:before {
  content: '\e2a3'; }

.icon-folder-share-2:before {
  content: '\e2a4'; }

.icon-folder-sync:before {
  content: '\e2a5'; }

.icon-folder-transfer:before {
  content: '\e2a6'; }

.icon-folder-upload:before {
  content: '\e2a7'; }

.icon-folder-zip:before {
  content: '\e2a8'; }

.icon-add-1:before {
  content: '\e2a9'; }

.icon-add-2:before {
  content: '\e2aa'; }

.icon-add-3:before {
  content: '\e2ab'; }

.icon-add-4:before {
  content: '\e2ac'; }

.icon-add-tag:before {
  content: '\e2ad'; }

.icon-arrow-1:before {
  content: '\e2ae'; }

.icon-arrow-2:before {
  content: '\e2af'; }

.icon-arrow-down-1:before {
  content: '\e2b0'; }

.icon-arrow-down-2:before {
  content: '\e2b1'; }

.icon-arrow-left-1:before {
  content: '\e2b2'; }

.icon-arrow-left-2:before {
  content: '\e2b3'; }

.icon-arrow-move-1:before {
  content: '\e2b4'; }

.icon-arrow-move-down:before {
  content: '\e2b5'; }

.icon-arrow-move-left:before {
  content: '\e2b6'; }

.icon-arrow-move-right:before {
  content: '\e2b7'; }

.icon-arrow-move-up:before {
  content: '\e2b8'; }

.icon-arrow-right-1:before {
  content: '\e2b9'; }

.icon-arrow-right-2:before {
  content: '\e2ba'; }

.icon-arrow-up-1:before {
  content: '\e2bb'; }

.icon-arrow-up-2:before {
  content: '\e2bc'; }

.icon-back:before {
  content: '\e2bd'; }

.icon-center-expand:before {
  content: '\e2be'; }

.icon-center-reduce:before {
  content: '\e2bf'; }

.icon-delete-1-1:before {
  content: '\e2c0'; }

.icon-delete-2-1:before {
  content: '\e2c1'; }

.icon-delete-3:before {
  content: '\e2c2'; }

.icon-delete-4:before {
  content: '\e2c3'; }

.icon-delete-tag:before {
  content: '\e2c4'; }

.icon-expand-horizontal:before {
  content: '\e2c5'; }

.icon-expand-vertical:before {
  content: '\e2c6'; }

.icon-forward-3:before {
  content: '\e2c7'; }

.icon-infinity:before {
  content: '\e2c8'; }

.icon-loading:before {
  content: '\e2c9'; }

.icon-log-out-1:before {
  content: '\e2ca'; }

.icon-log-out-2:before {
  content: '\e2cb'; }

.icon-loop-1:before {
  content: '\e2cc'; }

.icon-loop-2:before {
  content: '\e2cd'; }

.icon-loop-3:before {
  content: '\e2ce'; }

.icon-minus-1:before {
  content: '\e2cf'; }

.icon-minus-2:before {
  content: '\e2d0'; }

.icon-minus-3:before {
  content: '\e2d1'; }

.icon-minus-4:before {
  content: '\e2d2'; }

.icon-minus-tag:before {
  content: '\e2d3'; }

.icon-move-diagonal-1:before {
  content: '\e2d4'; }

.icon-move-diagonal-2:before {
  content: '\e2d5'; }

.icon-move-horizontal-1:before {
  content: '\e2d6'; }

.icon-move-horizontal-2:before {
  content: '\e2d7'; }

.icon-move-vertical-1:before {
  content: '\e2d8'; }

.icon-move-vertical-2:before {
  content: '\e2d9'; }

.icon-next-1-1:before {
  content: '\e2da'; }

.icon-next-2-1:before {
  content: '\e2db'; }

.icon-power-1-1:before {
  content: '\e2dc'; }

.icon-power-2-1:before {
  content: '\e2dd'; }

.icon-power-3:before {
  content: '\e2de'; }

.icon-power-4:before {
  content: '\e2df'; }

.icon-recycle:before {
  content: '\e2e0'; }

.icon-refresh:before {
  content: '\e2e1'; }

.icon-repeat:before {
  content: '\e2e2'; }

.icon-return:before {
  content: '\e2e3'; }

.icon-scale-all-1:before {
  content: '\e2e4'; }

.icon-scale-center:before {
  content: '\e2e5'; }

.icon-scale-horizontal-1:before {
  content: '\e2e6'; }

.icon-scale-horizontal-2:before {
  content: '\e2e7'; }

.icon-scale-reduce-1:before {
  content: '\e2e8'; }

.icon-scale-reduce-2:before {
  content: '\e2e9'; }

.icon-scale-reduce-3:before {
  content: '\e2ea'; }

.icon-scale-spread-1:before {
  content: '\e2eb'; }

.icon-scale-spread-2:before {
  content: '\e2ec'; }

.icon-scale-spread-3:before {
  content: '\e2ed'; }

.icon-scale-vertical-1:before {
  content: '\e2ee'; }

.icon-scale-vertical-2:before {
  content: '\e2ef'; }

.icon-scroll-horizontal-1:before {
  content: '\e2f0'; }

.icon-scroll-horizontal-2:before {
  content: '\e2f1'; }

.icon-scroll-omnidirectional-1:before {
  content: '\e2f2'; }

.icon-scroll-omnidirectional-2:before {
  content: '\e2f3'; }

.icon-scroll-vertical-1:before {
  content: '\e2f4'; }

.icon-scroll-vertical-2:before {
  content: '\e2f5'; }

.icon-shuffle:before {
  content: '\e2f6'; }

.icon-split:before {
  content: '\e2f7'; }

.icon-sync-1:before {
  content: '\e2f8'; }

.icon-sync-2:before {
  content: '\e2f9'; }

.icon-timer:before {
  content: '\e2fa'; }

.icon-transfer:before {
  content: '\e2fb'; }

.icon-transfer-1-1:before {
  content: '\e2fc'; }

.icon-check-1:before {
  content: '\e2fd'; }

.icon-check-2:before {
  content: '\e2fe'; }

.icon-check-3:before {
  content: '\e2ff'; }

.icon-check-box:before {
  content: '\e300'; }

.icon-check-bubble:before {
  content: '\e301'; }

.icon-check-circle-1:before {
  content: '\e302'; }

.icon-check-circle-2:before {
  content: '\e303'; }

.icon-check-list:before {
  content: '\e304'; }

.icon-check-shield:before {
  content: '\e305'; }

.icon-cross:before {
  content: '\e306'; }

.icon-cross-bubble:before {
  content: '\e307'; }

.icon-cross-shield:before {
  content: '\e308'; }

.icon-briefcase-1:before {
  content: '\e309'; }

.icon-brightness-high:before {
  content: '\e30a'; }

.icon-brightness-low:before {
  content: '\e30b'; }

.icon-hammer-1:before {
  content: '\e30c'; }

.icon-hammer-2:before {
  content: '\e30d'; }

.icon-pulse:before {
  content: '\e30e'; }

.icon-scale:before {
  content: '\e30f'; }

.icon-screw-driver:before {
  content: '\e310'; }

.icon-setting-adjustment:before {
  content: '\e311'; }

.icon-setting-gear:before {
  content: '\e312'; }

.icon-setting-gears-1:before {
  content: '\e313'; }

.icon-setting-gears-2:before {
  content: '\e314'; }

.icon-setting-wrenches:before {
  content: '\e315'; }

.icon-switch-1:before {
  content: '\e316'; }

.icon-switch-2:before {
  content: '\e317'; }

.icon-wrench:before {
  content: '\e318'; }

.icon-alarm-1:before {
  content: '\e319'; }

.icon-alarm-clock:before {
  content: '\e31a'; }

.icon-alarm-off:before {
  content: '\e31b'; }

.icon-alarm-snooze:before {
  content: '\e31c'; }

.icon-bell:before {
  content: '\e31d'; }

.icon-calendar-1:before {
  content: '\e31e'; }

.icon-calendar-2:before {
  content: '\e31f'; }

.icon-clock-1:before {
  content: '\e320'; }

.icon-clock-2:before {
  content: '\e321'; }

.icon-clock-3:before {
  content: '\e322'; }

.icon-hourglass-1:before {
  content: '\e323'; }

.icon-hourglass-2:before {
  content: '\e324'; }

.icon-timer-1:before {
  content: '\e325'; }

.icon-timer-3-quarter-1:before {
  content: '\e326'; }

.icon-timer-3-quarter-2:before {
  content: '\e327'; }

.icon-timer-full-1:before {
  content: '\e328'; }

.icon-timer-full-2:before {
  content: '\e329'; }

.icon-timer-half-1:before {
  content: '\e32a'; }

.icon-timer-half-2:before {
  content: '\e32b'; }

.icon-timer-half-3:before {
  content: '\e32c'; }

.icon-timer-half-4:before {
  content: '\e32d'; }

.icon-timer-quarter-1:before {
  content: '\e32e'; }

.icon-timer-quarter-2:before {
  content: '\e32f'; }

.icon-watch-1:before {
  content: '\e330'; }

.icon-watch-2:before {
  content: '\e331'; }

.icon-alert-1:before {
  content: '\e332'; }

.icon-alert-2:before {
  content: '\e333'; }

.icon-alert-3:before {
  content: '\e334'; }

.icon-information:before {
  content: '\e335'; }

.icon-nuclear-1:before {
  content: '\e336'; }

.icon-nuclear-2:before {
  content: '\e337'; }

.icon-question-mark:before {
  content: '\e338'; }

.icon-abacus:before {
  content: '\e339'; }

.icon-amex-card:before {
  content: '\e33a'; }

.icon-atm:before {
  content: '\e33b'; }

.icon-balance:before {
  content: '\e33c'; }

.icon-bank-1:before {
  content: '\e33d'; }

.icon-bank-2:before {
  content: '\e33e'; }

.icon-bank-note-1:before {
  content: '\e33f'; }

.icon-bank-note-2:before {
  content: '\e340'; }

.icon-bank-note-3:before {
  content: '\e341'; }

.icon-bitcoins:before {
  content: '\e342'; }

.icon-board:before {
  content: '\e343'; }

.icon-box-1:before {
  content: '\e344'; }

.icon-box-2:before {
  content: '\e345'; }

.icon-box-3:before {
  content: '\e346'; }

.icon-box-download:before {
  content: '\e347'; }

.icon-box-shipping:before {
  content: '\e348'; }

.icon-box-upload:before {
  content: '\e349'; }

.icon-business-chart-1:before {
  content: '\e34a'; }

.icon-business-chart-2:before {
  content: '\e34b'; }

.icon-calculator-1:before {
  content: '\e34c'; }

.icon-calculator-2:before {
  content: '\e34d'; }

.icon-calculator-3:before {
  content: '\e34e'; }

.icon-cash-register:before {
  content: '\e34f'; }

.icon-chart-board:before {
  content: '\e350'; }

.icon-chart-down:before {
  content: '\e351'; }

.icon-chart-up:before {
  content: '\e352'; }

.icon-check:before {
  content: '\e353'; }

.icon-coins-1:before {
  content: '\e354'; }

.icon-coins-2:before {
  content: '\e355'; }

.icon-court:before {
  content: '\e356'; }

.icon-credit-card:before {
  content: '\e357'; }

.icon-credit-card-lock:before {
  content: '\e358'; }

.icon-delivery:before {
  content: '\e359'; }

.icon-dollar-bag:before {
  content: '\e35a'; }

.icon-dollar-currency-1:before {
  content: '\e35b'; }

.icon-dollar-currency-2:before {
  content: '\e35c'; }

.icon-dollar-currency-3:before {
  content: '\e35d'; }

.icon-dollar-currency-4:before {
  content: '\e35e'; }

.icon-euro-bag:before {
  content: '\e35f'; }

.icon-euro-currency-1:before {
  content: '\e360'; }

.icon-euro-currency-2:before {
  content: '\e361'; }

.icon-euro-currency-3:before {
  content: '\e362'; }

.icon-euro-currency-4:before {
  content: '\e363'; }

.icon-forklift:before {
  content: '\e364'; }

.icon-hand-card:before {
  content: '\e365'; }

.icon-hand-coin:before {
  content: '\e366'; }

.icon-keynote:before {
  content: '\e367'; }

.icon-master-card:before {
  content: '\e368'; }

.icon-money:before {
  content: '\e369'; }

.icon-parking-meter:before {
  content: '\e36a'; }

.icon-percent-1:before {
  content: '\e36b'; }

.icon-percent-2:before {
  content: '\e36c'; }

.icon-percent-3:before {
  content: '\e36d'; }

.icon-percent-4:before {
  content: '\e36e'; }

.icon-percent-5:before {
  content: '\e36f'; }

.icon-percent-up:before {
  content: '\e370'; }

.icon-pie-chart-1:before {
  content: '\e371'; }

.icon-pie-chart-2:before {
  content: '\e372'; }

.icon-piggy-bank:before {
  content: '\e373'; }

.icon-pound-currency-1:before {
  content: '\e374'; }

.icon-pound-currency-2:before {
  content: '\e375'; }

.icon-pound-currency-3:before {
  content: '\e376'; }

.icon-pound-currency-4:before {
  content: '\e377'; }

.icon-safe-1:before {
  content: '\e378'; }

.icon-safe-2:before {
  content: '\e379'; }

.icon-shop:before {
  content: '\e37a'; }

.icon-sign:before {
  content: '\e37b'; }

.icon-trolley:before {
  content: '\e37c'; }

.icon-truck-1:before {
  content: '\e37d'; }

.icon-truck-2:before {
  content: '\e37e'; }

.icon-visa-card:before {
  content: '\e37f'; }

.icon-yen-currency-1:before {
  content: '\e380'; }

.icon-yen-currency-2:before {
  content: '\e381'; }

.icon-yen-currency-3:before {
  content: '\e382'; }

.icon-yen-currency-4:before {
  content: '\e383'; }

.icon-add-marker-1:before {
  content: '\e384'; }

.icon-add-marker-1-1:before {
  content: '\e385'; }

.icon-add-marker-2:before {
  content: '\e386'; }

.icon-add-marker-2-1:before {
  content: '\e387'; }

.icon-add-marker-3:before {
  content: '\e388'; }

.icon-compass-1:before {
  content: '\e389'; }

.icon-compass-2:before {
  content: '\e38a'; }

.icon-compass-3:before {
  content: '\e38b'; }

.icon-delete-marker-1:before {
  content: '\e38c'; }

.icon-delete-marker-1-1:before {
  content: '\e38d'; }

.icon-delete-marker-2:before {
  content: '\e38e'; }

.icon-delete-marker-2-1:before {
  content: '\e38f'; }

.icon-favorite-marker-1:before {
  content: '\e390'; }

.icon-favorite-marker-1-1:before {
  content: '\e391'; }

.icon-favorite-marker-2:before {
  content: '\e392'; }

.icon-favorite-marker-2-1:before {
  content: '\e393'; }

.icon-globe:before {
  content: '\e394'; }

.icon-location:before {
  content: '\e395'; }

.icon-map-1:before {
  content: '\e396'; }

.icon-map-location:before {
  content: '\e397'; }

.icon-map-marker-1:before {
  content: '\e398'; }

.icon-map-marker-1-1:before {
  content: '\e399'; }

.icon-map-marker-2:before {
  content: '\e39a'; }

.icon-map-marker-3:before {
  content: '\e39b'; }

.icon-map-marker-pin:before {
  content: '\e39c'; }

.icon-map-pin:before {
  content: '\e39d'; }

.icon-marker-1:before {
  content: '\e39e'; }

.icon-marker-1-1:before {
  content: '\e39f'; }

.icon-marker-2:before {
  content: '\e3a0'; }

.icon-marker-2-1:before {
  content: '\e3a1'; }

.icon-marker-pin-1:before {
  content: '\e3a2'; }

.icon-marker-pin-2:before {
  content: '\e3a3'; }

.icon-marker-pin-location:before {
  content: '\e3a4'; }

.icon-minus-marker-1:before {
  content: '\e3a5'; }

.icon-minus-marker-1-1:before {
  content: '\e3a6'; }

.icon-minus-marker-2:before {
  content: '\e3a7'; }

.icon-minus-marker-2-1:before {
  content: '\e3a8'; }

.icon-minus-marker-3:before {
  content: '\e3a9'; }

.icon-anchor:before {
  content: '\e3aa'; }

.icon-bank:before {
  content: '\e3ab'; }

.icon-beach:before {
  content: '\e3ac'; }

.icon-boat:before {
  content: '\e3ad'; }

.icon-building-1:before {
  content: '\e3ae'; }

.icon-building-2:before {
  content: '\e3af'; }

.icon-building-3:before {
  content: '\e3b0'; }

.icon-buildings-1:before {
  content: '\e3b1'; }

.icon-buildings-2:before {
  content: '\e3b2'; }

.icon-buildings-3:before {
  content: '\e3b3'; }

.icon-buildings-4:before {
  content: '\e3b4'; }

.icon-castle:before {
  content: '\e3b5'; }

.icon-column:before {
  content: '\e3b6'; }

.icon-direction-sign:before {
  content: '\e3b7'; }

.icon-factory:before {
  content: '\e3b8'; }

.icon-fence:before {
  content: '\e3b9'; }

.icon-garage:before {
  content: '\e3ba'; }

.icon-globe-1:before {
  content: '\e3bb'; }

.icon-globe-2:before {
  content: '\e3bc'; }

.icon-house-1:before {
  content: '\e3bd'; }

.icon-house-2:before {
  content: '\e3be'; }

.icon-house-3:before {
  content: '\e3bf'; }

.icon-house-4:before {
  content: '\e3c0'; }

.icon-library:before {
  content: '\e3c1'; }

.icon-light-house:before {
  content: '\e3c2'; }

.icon-pisa:before {
  content: '\e3c3'; }

.icon-skyscraper:before {
  content: '\e3c4'; }

.icon-temple:before {
  content: '\e3c5'; }

.icon-treasure-map:before {
  content: '\e3c6'; }

.icon-tree:before {
  content: '\e3c7'; }

.icon-tree-pine:before {
  content: '\e3c8'; }

.icon-attention:before {
  content: '\e3c9'; }

.icon-bug-1:before {
  content: '\e3ca'; }

.icon-bug-2:before {
  content: '\e3cb'; }

.icon-css3:before {
  content: '\e3cc'; }

.icon-firewall:before {
  content: '\e3cd'; }

.icon-html5:before {
  content: '\e3ce'; }

.icon-plugins-1:before {
  content: '\e3cf'; }

.icon-plugins-2:before {
  content: '\e3d0'; }

.icon-script:before {
  content: '\e3d1'; }

.icon-new-window:before {
  content: '\e3d2'; }

.icon-window-1:before {
  content: '\e3d3'; }

.icon-window-2:before {
  content: '\e3d4'; }

.icon-window-3:before {
  content: '\e3d5'; }

.icon-window-add:before {
  content: '\e3d6'; }

.icon-window-alert:before {
  content: '\e3d7'; }

.icon-window-check:before {
  content: '\e3d8'; }

.icon-window-code-1:before {
  content: '\e3d9'; }

.icon-window-code-2:before {
  content: '\e3da'; }

.icon-window-code-3:before {
  content: '\e3db'; }

.icon-window-column:before {
  content: '\e3dc'; }

.icon-window-delete:before {
  content: '\e3dd'; }

.icon-window-denied:before {
  content: '\e3de'; }

.icon-window-download-1:before {
  content: '\e3df'; }

.icon-window-download-2:before {
  content: '\e3e0'; }

.icon-window-edit:before {
  content: '\e3e1'; }

.icon-window-favorite:before {
  content: '\e3e2'; }

.icon-window-graph-1:before {
  content: '\e3e3'; }

.icon-window-graph-2:before {
  content: '\e3e4'; }

.icon-window-hand:before {
  content: '\e3e5'; }

.icon-window-home:before {
  content: '\e3e6'; }

.icon-window-like:before {
  content: '\e3e7'; }

.icon-window-list-1:before {
  content: '\e3e8'; }

.icon-window-list-2:before {
  content: '\e3e9'; }

.icon-window-lock:before {
  content: '\e3ea'; }

.icon-window-minimize:before {
  content: '\e3eb'; }

.icon-window-minus:before {
  content: '\e3ec'; }

.icon-window-refresh:before {
  content: '\e3ed'; }

.icon-window-register:before {
  content: '\e3ee'; }

.icon-window-search:before {
  content: '\e3ef'; }

.icon-window-selection:before {
  content: '\e3f0'; }

.icon-window-setting:before {
  content: '\e3f1'; }

.icon-window-sync:before {
  content: '\e3f2'; }

.icon-window-thumbnails-1:before {
  content: '\e3f3'; }

.icon-window-thumbnails-2:before {
  content: '\e3f4'; }

.icon-window-time:before {
  content: '\e3f5'; }

.icon-window-upload-1:before {
  content: '\e3f6'; }

.icon-window-upload-2:before {
  content: '\e3f7'; }

.icon-windows-selection:before {
  content: '\e3f8'; }

.icon-database:before {
  content: '\e3f9'; }

.icon-database-alert:before {
  content: '\e3fa'; }

.icon-database-block:before {
  content: '\e3fb'; }

.icon-database-check:before {
  content: '\e3fc'; }

.icon-database-delete:before {
  content: '\e3fd'; }

.icon-database-download:before {
  content: '\e3fe'; }

.icon-database-edit:before {
  content: '\e3ff'; }

.icon-database-lock:before {
  content: '\e400'; }

.icon-database-minus:before {
  content: '\e401'; }

.icon-database-network:before {
  content: '\e402'; }

.icon-database-plus:before {
  content: '\e403'; }

.icon-database-refresh:before {
  content: '\e404'; }

.icon-database-search:before {
  content: '\e405'; }

.icon-database-setting:before {
  content: '\e406'; }

.icon-database-sync:before {
  content: '\e407'; }

.icon-database-time:before {
  content: '\e408'; }

.icon-database-upload:before {
  content: '\e409'; }

.icon-battery-charging:before {
  content: '\e40a'; }

.icon-battery-full:before {
  content: '\e40b'; }

.icon-battery-high:before {
  content: '\e40c'; }

.icon-battery-low:before {
  content: '\e40d'; }

.icon-battery-medium:before {
  content: '\e40e'; }

.icon-cd-1:before {
  content: '\e40f'; }

.icon-cd-2:before {
  content: '\e410'; }

.icon-chip:before {
  content: '\e411'; }

.icon-computer:before {
  content: '\e412'; }

.icon-disc:before {
  content: '\e413'; }

.icon-filter:before {
  content: '\e414'; }

.icon-floppy-disk:before {
  content: '\e415'; }

.icon-gameboy:before {
  content: '\e416'; }

.icon-harddisk-1:before {
  content: '\e417'; }

.icon-harddisk-2:before {
  content: '\e418'; }

.icon-imac:before {
  content: '\e419'; }

.icon-ipad-1:before {
  content: '\e41a'; }

.icon-ipad-2:before {
  content: '\e41b'; }

.icon-ipod:before {
  content: '\e41c'; }

.icon-joystick-1:before {
  content: '\e41d'; }

.icon-joystick-2:before {
  content: '\e41e'; }

.icon-joystick-3:before {
  content: '\e41f'; }

.icon-keyboard-1:before {
  content: '\e420'; }

.icon-keyboard-2:before {
  content: '\e421'; }

.icon-kindle-1:before {
  content: '\e422'; }

.icon-kindle-2:before {
  content: '\e423'; }

.icon-laptop-1:before {
  content: '\e424'; }

.icon-laptop-2:before {
  content: '\e425'; }

.icon-memory-card:before {
  content: '\e426'; }

.icon-mobile-phone:before {
  content: '\e427'; }

.icon-mouse-1:before {
  content: '\e428'; }

.icon-mouse-2:before {
  content: '\e429'; }

.icon-mp3player:before {
  content: '\e42a'; }

.icon-plug-1:before {
  content: '\e42b'; }

.icon-plug-2:before {
  content: '\e42c'; }

.icon-plug-slot:before {
  content: '\e42d'; }

.icon-printer:before {
  content: '\e42e'; }

.icon-projector:before {
  content: '\e42f'; }

.icon-remote:before {
  content: '\e430'; }

.icon-router:before {
  content: '\e431'; }

.icon-screen-1:before {
  content: '\e432'; }

.icon-screen-2:before {
  content: '\e433'; }

.icon-screen-3:before {
  content: '\e434'; }

.icon-screen-4:before {
  content: '\e435'; }

.icon-smartphone-1:before {
  content: '\e436'; }

.icon-television-1:before {
  content: '\e437'; }

.icon-typewriter-1:before {
  content: '\e438'; }

.icon-typewriter-2:before {
  content: '\e439'; }

.icon-usb-1:before {
  content: '\e43a'; }

.icon-usb-2:before {
  content: '\e43b'; }

.icon-webcam:before {
  content: '\e43c'; }

.icon-wireless-router-1:before {
  content: '\e43d'; }

.icon-wireless-router-2:before {
  content: '\e43e'; }

.icon-bluetooth:before {
  content: '\e43f'; }

.icon-ethernet:before {
  content: '\e440'; }

.icon-ethernet-slot:before {
  content: '\e441'; }

.icon-firewire-1:before {
  content: '\e442'; }

.icon-firewire-2:before {
  content: '\e443'; }

.icon-network-1:before {
  content: '\e444'; }

.icon-network-2:before {
  content: '\e445'; }

.icon-server-1:before {
  content: '\e446'; }

.icon-server-2:before {
  content: '\e447'; }

.icon-server-3:before {
  content: '\e448'; }

.icon-usb:before {
  content: '\e449'; }

.icon-wireless-signal:before {
  content: '\e44a'; }

.icon-book-1:before {
  content: '\e44b'; }

.icon-book-2:before {
  content: '\e44c'; }

.icon-book-3:before {
  content: '\e44d'; }

.icon-book-4:before {
  content: '\e44e'; }

.icon-book-5:before {
  content: '\e44f'; }

.icon-book-6:before {
  content: '\e450'; }

.icon-book-7:before {
  content: '\e451'; }

.icon-book-8:before {
  content: '\e452'; }

.icon-book-download-1:before {
  content: '\e453'; }

.icon-book-download-2:before {
  content: '\e454'; }

.icon-book-favorite-1:before {
  content: '\e455'; }

.icon-book-favorite-2:before {
  content: '\e456'; }

.icon-bookmark-1-1:before {
  content: '\e457'; }

.icon-bookmark-2-1:before {
  content: '\e458'; }

.icon-bookmark-3-1:before {
  content: '\e459'; }

.icon-bookmark-4-1:before {
  content: '\e45a'; }

.icon-books-1:before {
  content: '\e45b'; }

.icon-books-2:before {
  content: '\e45c'; }

.icon-books-3:before {
  content: '\e45d'; }

.icon-briefcase-2:before {
  content: '\e45e'; }

.icon-contact-book-1:before {
  content: '\e45f'; }

.icon-contact-book-2:before {
  content: '\e460'; }

.icon-contact-book-3:before {
  content: '\e461'; }

.icon-contact-book-4:before {
  content: '\e462'; }

.icon-copyright:before {
  content: '\e463'; }

.icon-creative-commons:before {
  content: '\e464'; }

.icon-cube:before {
  content: '\e465'; }

.icon-data-filter:before {
  content: '\e466'; }

.icon-document-box-1:before {
  content: '\e467'; }

.icon-document-box-2:before {
  content: '\e468'; }

.icon-document-box-3:before {
  content: '\e469'; }

.icon-drawer-1:before {
  content: '\e46a'; }

.icon-drawer-2:before {
  content: '\e46b'; }

.icon-drawer-3:before {
  content: '\e46c'; }

.icon-envelope:before {
  content: '\e46d'; }

.icon-file:before {
  content: '\e46e'; }

.icon-files:before {
  content: '\e46f'; }

.icon-filter-1:before {
  content: '\e470'; }

.icon-filter-2:before {
  content: '\e471'; }

.icon-layers-1:before {
  content: '\e472'; }

.icon-list-1:before {
  content: '\e473'; }

.icon-list-2:before {
  content: '\e474'; }

.icon-newspaper-1:before {
  content: '\e475'; }

.icon-newspaper-2:before {
  content: '\e476'; }

.icon-registry-1:before {
  content: '\e477'; }

.icon-registry-2:before {
  content: '\e478'; }

.icon-shield-1:before {
  content: '\e479'; }

.icon-shield-2:before {
  content: '\e47a'; }

.icon-shield-3:before {
  content: '\e47b'; }

.icon-sketchbook:before {
  content: '\e47c'; }

.icon-sound-book:before {
  content: '\e47d'; }

.icon-thumbnails-1:before {
  content: '\e47e'; }

.icon-thumbnails-2:before {
  content: '\e47f'; }

.icon-graph:before {
  content: '\e480'; }

.icon-hierarchy-1:before {
  content: '\e481'; }

.icon-hierarchy-2:before {
  content: '\e482'; }

.icon-hierarchy-3:before {
  content: '\e483'; }

.icon-hierarchy-4:before {
  content: '\e484'; }

.icon-hierarchy-5:before {
  content: '\e485'; }

.icon-hierarchy-6:before {
  content: '\e486'; }

.icon-hierarchy-7:before {
  content: '\e487'; }

.icon-network-1-1:before {
  content: '\e488'; }

.icon-network-2-1:before {
  content: '\e489'; }

.icon-backpack:before {
  content: '\e48a'; }

.icon-balance-1:before {
  content: '\e48b'; }

.icon-bed:before {
  content: '\e48c'; }

.icon-bench:before {
  content: '\e48d'; }

.icon-bricks:before {
  content: '\e48f'; }

.icon-bullets:before {
  content: '\e490'; }

.icon-buoy:before {
  content: '\e491'; }

.icon-campfire:before {
  content: '\e492'; }

.icon-can:before {
  content: '\e493'; }

.icon-candle:before {
  content: '\e494'; }

.icon-cctv-1:before {
  content: '\e496'; }

.icon-cctv-2:before {
  content: '\e497'; }

.icon-chair:before {
  content: '\e498'; }

.icon-chair-director:before {
  content: '\e499'; }

.icon-cigarette:before {
  content: '\e49a'; }

.icon-construction-sign:before {
  content: '\e49b'; }

.icon-diamond:before {
  content: '\e49c'; }

.icon-disabled:before {
  content: '\e49d'; }

.icon-door:before {
  content: '\e49e'; }

.icon-drawer:before {
  content: '\e49f'; }

.icon-driller:before {
  content: '\e4a0'; }

.icon-dumbbells:before {
  content: '\e4a1'; }

.icon-fire-extinguisher:before {
  content: '\e4a2'; }

.icon-flashlight:before {
  content: '\e4a3'; }

.icon-gas-station:before {
  content: '\e4a4'; }

.icon-lamp-1:before {
  content: '\e4a6'; }

.icon-lamp-2:before {
  content: '\e4a7'; }

.icon-lamp-3:before {
  content: '\e4a8'; }

.icon-lamp-4:before {
  content: '\e4a9'; }

.icon-lightbulb-1:before {
  content: '\e4aa'; }

.icon-lightbulb-2:before {
  content: '\e4ab'; }

.icon-measuring-tape:before {
  content: '\e4ac'; }

.icon-mine-cart:before {
  content: '\e4ad'; }

.icon-ring:before {
  content: '\e4af'; }

.icon-scale-1:before {
  content: '\e4b0'; }

.icon-shovel:before {
  content: '\e4b1'; }

.icon-smoke-no:before {
  content: '\e4b2'; }

.icon-sofa-1:before {
  content: '\e4b3'; }

.icon-sofa-2:before {
  content: '\e4b4'; }

.icon-sofa-3:before {
  content: '\e4b5'; }

.icon-target:before {
  content: '\e4b6'; }

.icon-torch:before {
  content: '\e4b7'; }

.icon-traffic-cone:before {
  content: '\e4b8'; }

.icon-traffic-light-1:before {
  content: '\e4b9'; }

.icon-traffic-light-2:before {
  content: '\e4ba'; }

.icon-treasure-chest-1:before {
  content: '\e4bb'; }

.icon-treasure-chest-2:before {
  content: '\e4bc'; }

.icon-trowel:before {
  content: '\e4bd'; }

.icon-watering-can:before {
  content: '\e4be'; }

.icon-weigh:before {
  content: '\e4bf'; }

.icon-academic-cap:before {
  content: '\e4c0'; }

.icon-baseball-helmet:before {
  content: '\e4c1'; }

.icon-beanie:before {
  content: '\e4c2'; }

.icon-bike-helmet:before {
  content: '\e4c3'; }

.icon-bow:before {
  content: '\e4c4'; }

.icon-cap:before {
  content: '\e4c5'; }

.icon-chaplin:before {
  content: '\e4c6'; }

.icon-chef-hat:before {
  content: '\e4c7'; }

.icon-cloth-hanger:before {
  content: '\e4c8'; }

.icon-fins:before {
  content: '\e4c9'; }

.icon-football-helmet:before {
  content: '\e4ca'; }

.icon-glasses:before {
  content: '\e4cb'; }

.icon-glasses-1:before {
  content: '\e4cc'; }

.icon-glasses-2:before {
  content: '\e4cd'; }

.icon-magician-hat:before {
  content: '\e4ce'; }

.icon-monocle-1:before {
  content: '\e4cf'; }

.icon-monocle-2:before {
  content: '\e4d0'; }

.icon-necktie:before {
  content: '\e4d1'; }

.icon-safety-helmet:before {
  content: '\e4d2'; }

.icon-scuba-tank:before {
  content: '\e4d3'; }

.icon-shirt-1:before {
  content: '\e4d4'; }

.icon-shirt-2:before {
  content: '\e4d5'; }

.icon-shirt-3:before {
  content: '\e4d6'; }

.icon-sneakers:before {
  content: '\e4d7'; }

.icon-snorkel:before {
  content: '\e4d8'; }

.icon-sombrero:before {
  content: '\e4d9'; }

.icon-sunglasses:before {
  content: '\e4da'; }

.icon-tall-hat:before {
  content: '\e4db'; }

.icon-trousers:before {
  content: '\e4dc'; }

.icon-walking-stick:before {
  content: '\e4dd'; }

.icon-arrow-redo:before {
  content: '\e4de'; }

.icon-arrow-undo:before {
  content: '\e4df'; }

.icon-bold:before {
  content: '\e4e0'; }

.icon-columns:before {
  content: '\e4e1'; }

.icon-eraser:before {
  content: '\e4e2'; }

.icon-font-color:before {
  content: '\e4e3'; }

.icon-html:before {
  content: '\e4e4'; }

.icon-italic:before {
  content: '\e4e5'; }

.icon-list-1-1:before {
  content: '\e4e6'; }

.icon-list-2-1:before {
  content: '\e4e7'; }

.icon-list-3:before {
  content: '\e4e8'; }

.icon-list-4:before {
  content: '\e4e9'; }

.icon-paragraph:before {
  content: '\e4ea'; }

.icon-paste:before {
  content: '\e4eb'; }

.icon-print-preview:before {
  content: '\e4ec'; }

.icon-quote:before {
  content: '\e4ed'; }

.icon-strikethrough:before {
  content: '\e4ee'; }

.icon-text:before {
  content: '\e4ef'; }

.icon-text-wrapping-1:before {
  content: '\e4f0'; }

.icon-text-wrapping-2:before {
  content: '\e4f1'; }

.icon-text-wrapping-3:before {
  content: '\e4f2'; }

.icon-underline:before {
  content: '\e4f3'; }

.icon-align-center:before {
  content: '\e4f4'; }

.icon-align-left:before {
  content: '\e4f5'; }

.icon-align-right:before {
  content: '\e4f6'; }

.icon-all-caps:before {
  content: '\e4f7'; }

.icon-arrange-2-1:before {
  content: '\e4f8'; }

.icon-arrange-2-2:before {
  content: '\e4f9'; }

.icon-arrange-2-3:before {
  content: '\e4fa'; }

.icon-arrange-2-4:before {
  content: '\e4fb'; }

.icon-arrange-3-1:before {
  content: '\e4fc'; }

.icon-arrange-3-2:before {
  content: '\e4fd'; }

.icon-arrange-3-3:before {
  content: '\e4fe'; }

.icon-arrange-3-4:before {
  content: '\e4ff'; }

.icon-arrange-3-5:before {
  content: '\e500'; }

.icon-arrange-4-1:before {
  content: '\e501'; }

.icon-arrange-4-2:before {
  content: '\e502'; }

.icon-arrange-4-3:before {
  content: '\e503'; }

.icon-arrange-5:before {
  content: '\e504'; }

.icon-consolidate-all:before {
  content: '\e505'; }

.icon-decrease-indent-1:before {
  content: '\e506'; }

.icon-decrease-indent-2:before {
  content: '\e507'; }

.icon-horizontal-page:before {
  content: '\e508'; }

.icon-increase-indent-1:before {
  content: '\e509'; }

.icon-increase-indent-2:before {
  content: '\e50a'; }

.icon-justify:before {
  content: '\e50b'; }

.icon-leading-1:before {
  content: '\e50c'; }

.icon-leading-2:before {
  content: '\e50d'; }

.icon-left-indent:before {
  content: '\e50e'; }

.icon-right-indent:before {
  content: '\e50f'; }

.icon-small-caps:before {
  content: '\e510'; }

.icon-vertical-page:before {
  content: '\e511'; }

.icon-alt-mac:before {
  content: '\e512'; }

.icon-alt-windows:before {
  content: '\e513'; }

.icon-arrow-down:before {
  content: '\e514'; }

.icon-arrow-down-left:before {
  content: '\e515'; }

.icon-arrow-down-right:before {
  content: '\e516'; }

.icon-arrow-left:before {
  content: '\e517'; }

.icon-arrow-right:before {
  content: '\e518'; }

.icon-arrow-up:before {
  content: '\e519'; }

.icon-arrow-up-left:before {
  content: '\e51a'; }

.icon-arrow-up-right:before {
  content: '\e51b'; }

.icon-asterisk-1:before {
  content: '\e51c'; }

.icon-asterisk-2:before {
  content: '\e51d'; }

.icon-back-tab-1:before {
  content: '\e51e'; }

.icon-back-tab-2:before {
  content: '\e51f'; }

.icon-backward-delete:before {
  content: '\e520'; }

.icon-blank:before {
  content: '\e521'; }

.icon-eject:before {
  content: '\e522'; }

.icon-enter-1:before {
  content: '\e523'; }

.icon-enter-2:before {
  content: '\e524'; }

.icon-escape:before {
  content: '\e525'; }

.icon-page-down:before {
  content: '\e526'; }

.icon-page-up:before {
  content: '\e527'; }

.icon-return-1:before {
  content: '\e528'; }

.icon-shift-1:before {
  content: '\e529'; }

.icon-shift-2:before {
  content: '\e52a'; }

.icon-tab:before {
  content: '\e52b'; }

.icon-apple:before {
  content: '\e52c'; }

.icon-beer:before {
  content: '\e52d'; }

.icon-boil:before {
  content: '\e52e'; }

.icon-bottle-1:before {
  content: '\e52f'; }

.icon-bottle-2:before {
  content: '\e530'; }

.icon-bottle-3:before {
  content: '\e531'; }

.icon-bottle-4:before {
  content: '\e532'; }

.icon-bread:before {
  content: '\e533'; }

.icon-burger-1:before {
  content: '\e534'; }

.icon-burger-2:before {
  content: '\e535'; }

.icon-cake-1:before {
  content: '\e536'; }

.icon-cake-2:before {
  content: '\e537'; }

.icon-champagne:before {
  content: '\e538'; }

.icon-cheese:before {
  content: '\e539'; }

.icon-cocktail:before {
  content: '\e53a'; }

.icon-cocktail-1:before {
  content: '\e53b'; }

.icon-cocktail-2:before {
  content: '\e53c'; }

.icon-coffee-pot:before {
  content: '\e53d'; }

.icon-cup1:before {
  content: '\e53e'; }

.icon-cup-2:before {
  content: '\e53f'; }

.icon-deep-fry:before {
  content: '\e540'; }

.icon-energy-drink:before {
  content: '\e541'; }

.icon-espresso-machine:before {
  content: '\e542'; }

.icon-food-dome:before {
  content: '\e543'; }

.icon-fork-and-knife:before {
  content: '\e544'; }

.icon-fork-and-spoon:before {
  content: '\e545'; }

.icon-grape:before {
  content: '\e546'; }

.icon-grater:before {
  content: '\e547'; }

.icon-grill:before {
  content: '\e548'; }

.icon-hot-drinks-glass:before {
  content: '\e549'; }

.icon-hotdog:before {
  content: '\e54a'; }

.icon-ice-cream-1:before {
  content: '\e54b'; }

.icon-ice-cream-2:before {
  content: '\e54c'; }

.icon-ice-cream-3:before {
  content: '\e54d'; }

.icon-ice-drinks-galss:before {
  content: '\e54e'; }

.icon-juicer:before {
  content: '\e54f'; }

.icon-kitchen-timer:before {
  content: '\e550'; }

.icon-milk:before {
  content: '\e551'; }

.icon-orange:before {
  content: '\e552'; }

.icon-oven:before {
  content: '\e553'; }

.icon-pan-fry:before {
  content: '\e554'; }

.icon-pepper-salt:before {
  content: '\e555'; }

.icon-pizza:before {
  content: '\e556'; }

.icon-popcorn:before {
  content: '\e557'; }

.icon-serving:before {
  content: '\e558'; }

.icon-soda:before {
  content: '\e559'; }

.icon-soda-can-1:before {
  content: '\e55a'; }

.icon-soda-can-2:before {
  content: '\e55b'; }

.icon-steam:before {
  content: '\e55c'; }

.icon-tea-pot:before {
  content: '\e55d'; }

.icon-thermometer-high:before {
  content: '\e55e'; }

.icon-thermometer-low:before {
  content: '\e55f'; }

.icon-thermometer-medium:before {
  content: '\e560'; }

.icon-water:before {
  content: '\e561'; }

.icon-wine:before {
  content: '\e562'; }

.icon-ambulance:before {
  content: '\e563'; }

.icon-beaker-1:before {
  content: '\e564'; }

.icon-beaker-2:before {
  content: '\e565'; }

.icon-blood:before {
  content: '\e566'; }

.icon-drug:before {
  content: '\e567'; }

.icon-first-aid:before {
  content: '\e568'; }

.icon-heartpulse:before {
  content: '\e56a'; }

.icon-hospital-1:before {
  content: '\e56b'; }

.icon-hospital-2:before {
  content: '\e56c'; }

.icon-hospital-sign-1:before {
  content: '\e56d'; }

.icon-hospital-sign-2:before {
  content: '\e56e'; }

.icon-hospital-sign-3:before {
  content: '\e56f'; }

.icon-medicine:before {
  content: '\e570'; }

.icon-microscope:before {
  content: '\e571'; }

.icon-mortar-and-pestle:before {
  content: '\e572'; }

.icon-plaster:before {
  content: '\e573'; }

.icon-pulse-graph-1:before {
  content: '\e574'; }

.icon-pulse-graph-2:before {
  content: '\e575'; }

.icon-pulse-graph-3:before {
  content: '\e576'; }

.icon-red-cross:before {
  content: '\e577'; }

.icon-stethoscope:before {
  content: '\e578'; }

.icon-syringe:before {
  content: '\e579'; }

.icon-yin-yang:before {
  content: '\e57a'; }

.icon-balloon:before {
  content: '\e57b'; }

.icon-briefcase-lock:before {
  content: '\e57c'; }

.icon-card:before {
  content: '\e57d'; }

.icon-cards:before {
  content: '\e57e'; }

.icon-cards-2:before {
  content: '\e57f'; }

.icon-curtain:before {
  content: '\e580'; }

.icon-dice-1:before {
  content: '\e581'; }

.icon-dice-2:before {
  content: '\e582'; }

.icon-happy-smiley:before {
  content: '\e583'; }

.icon-pacman:before {
  content: '\e584'; }

.icon-pacman-ghost:before {
  content: '\e585'; }

.icon-sad-smiley:before {
  content: '\e586'; }

.icon-sign-1:before {
  content: '\e587'; }

.icon-smileys:before {
  content: '\e588'; }

.icon-suitcase-1:before {
  content: '\e589'; }

.icon-suitcase-2:before {
  content: '\e58a'; }

.icon-tetris:before {
  content: '\e58b'; }

.icon-ticket-1:before {
  content: '\e58c'; }

.icon-ticket-2:before {
  content: '\e58d'; }

.icon-ticket-3:before {
  content: '\e58e'; }

.icon-virus:before {
  content: '\e58f'; }

.icon-cloud-1:before {
  content: '\e590'; }

.icon-cloud-lightning:before {
  content: '\e591'; }

.icon-clouds:before {
  content: '\e592'; }

.icon-first-quarter-moon:before {
  content: '\e593'; }

.icon-full-moon:before {
  content: '\e594'; }

.icon-hail:before {
  content: '\e595'; }

.icon-heavy-rain:before {
  content: '\e596'; }

.icon-moon-cloud:before {
  content: '\e597'; }

.icon-rain:before {
  content: '\e598'; }

.icon-rain-lightning:before {
  content: '\e599'; }

.icon-snow:before {
  content: '\e59a'; }

.icon-sun:before {
  content: '\e59b'; }

.icon-sun-cloud:before {
  content: '\e59c'; }

.icon-thermometer:before {
  content: '\e59d'; }

.icon-third-quarter-moon:before {
  content: '\e59e'; }

.icon-umbrella:before {
  content: '\e59f'; }

.icon-waning-crescent-moon:before {
  content: '\e5a0'; }

.icon-waning-gibbous-moon:before {
  content: '\e5a1'; }

.icon-waxing-crescent-moon:before {
  content: '\e5a2'; }

.icon-waxing-gibbous-moon:before {
  content: '\e5a3'; }

.icon-bicycle:before {
  content: '\e5a4'; }

.icon-bus-1:before {
  content: '\e5a5'; }

.icon-bus-2:before {
  content: '\e5a6'; }

.icon-car-1:before {
  content: '\e5a7'; }

.icon-car-2:before {
  content: '\e5a8'; }

.icon-car-3:before {
  content: '\e5a9'; }

.icon-car-4:before {
  content: '\e5aa'; }

.icon-helicopter:before {
  content: '\e5ab'; }

.icon-mountain-bike:before {
  content: '\e5ac'; }

.icon-pickup:before {
  content: '\e5ad'; }

.icon-plane-1:before {
  content: '\e5ae'; }

.icon-plane-2:before {
  content: '\e5af'; }

.icon-plane-landing:before {
  content: '\e5b0'; }

.icon-plane-takeoff:before {
  content: '\e5b1'; }

.icon-road:before {
  content: '\e5b2'; }

.icon-road-bike:before {
  content: '\e5b3'; }

.icon-rocket:before {
  content: '\e5b4'; }

.icon-scooter:before {
  content: '\e5b5'; }

.icon-ship:before {
  content: '\e5b6'; }

.icon-train:before {
  content: '\e5b7'; }

.icon-tram:before {
  content: '\e5b8'; }

.icon-cactus:before {
  content: '\e5b9'; }

.icon-clover:before {
  content: '\e5ba'; }

.icon-flower:before {
  content: '\e5bb'; }

.icon-hand-eco:before {
  content: '\e5bc'; }

.icon-hand-globe:before {
  content: '\e5bd'; }

.icon-leaf:before {
  content: '\e5be'; }

.icon-light-eco:before {
  content: '\e5bf'; }

.icon-potted-plant-1:before {
  content: '\e5c0'; }

.icon-potted-plant-2:before {
  content: '\e5c1'; }

.icon-2-fingers-double-tap:before {
  content: '\e5c2'; }

.icon-2-fingers-down-swipe:before {
  content: '\e5c3'; }

.icon-2-fingers-horizontal-swipe:before {
  content: '\e5c4'; }

.icon-2-fingers-left-swipe:before {
  content: '\e5c5'; }

.icon-2-fingers-omnidirectional-swipe:before {
  content: '\e5c6'; }

.icon-2-fingers-right-swipe:before {
  content: '\e5c7'; }

.icon-2-fingers-tab-hold:before {
  content: '\e5c8'; }

.icon-2-fingers-tap:before {
  content: '\e5c9'; }

.icon-2-fingers-up-swipe:before {
  content: '\e5ca'; }

.icon-2-fingers-vertical-swipe:before {
  content: '\e5cb'; }

.icon-double-tap:before {
  content: '\e5cc'; }

.icon-drag-down:before {
  content: '\e5cd'; }

.icon-drag-horizontal:before {
  content: '\e5ce'; }

.icon-drag-left:before {
  content: '\e5cf'; }

.icon-drag-right:before {
  content: '\e5d0'; }

.icon-drag-up:before {
  content: '\e5d1'; }

.icon-drag-vertical:before {
  content: '\e5d2'; }

.icon-filck-down:before {
  content: '\e5d3'; }

.icon-flick-up:before {
  content: '\e5d4'; }

.icon-horizontal-flick:before {
  content: '\e5d5'; }

.icon-left-flick:before {
  content: '\e5d6'; }

.icon-omnidirectional-drag:before {
  content: '\e5d7'; }

.icon-omnidirectional-flick:before {
  content: '\e5d8'; }

.icon-omnidirectional-swipe:before {
  content: '\e5d9'; }

.icon-pinch:before {
  content: '\e5da'; }

.icon-right-flick:before {
  content: '\e5db'; }

.icon-rotate-clockwise:before {
  content: '\e5dc'; }

.icon-rotate-counterclockwise:before {
  content: '\e5dd'; }

.icon-spread:before {
  content: '\e5de'; }

.icon-swipe-down:before {
  content: '\e5df'; }

.icon-swipe-horizontal:before {
  content: '\e5e0'; }

.icon-swipe-left:before {
  content: '\e5e1'; }

.icon-swipe-right:before {
  content: '\e5e2'; }

.icon-swipe-up:before {
  content: '\e5e3'; }

.icon-swipe-vertical:before {
  content: '\e5e4'; }

.icon-tap:before {
  content: '\e5e5'; }

.icon-tap-hold:before {
  content: '\e5e6'; }

.icon-vertical-flick:before {
  content: '\e5e7'; }

.icon-arrow-1-1:before {
  content: '\e5e8'; }

.icon-arrow-2-1:before {
  content: '\e5e9'; }

.icon-arrow-3:before {
  content: '\e5ea'; }

.icon-arrow-4:before {
  content: '\e5eb'; }

.icon-arrow-5:before {
  content: '\e5ec'; }

.icon-arrow-6:before {
  content: '\e5ed'; }

.icon-arrow-7:before {
  content: '\e5ee'; }

.icon-arrow-8:before {
  content: '\e5ef'; }

.icon-arrow-9:before {
  content: '\e5f0'; }

.icon-arrow-10:before {
  content: '\e5f1'; }

.icon-arrow-11:before {
  content: '\e5f2'; }

.icon-arrow-12:before {
  content: '\e5f3'; }

.icon-arrow-13:before {
  content: '\e5f4'; }

.icon-arrow-14:before {
  content: '\e5f5'; }

.icon-arrow-15:before {
  content: '\e5f6'; }

.icon-arrow-16:before {
  content: '\e5f7'; }

.icon-arrow-17:before {
  content: '\e5f8'; }

.icon-arrow-18:before {
  content: '\e5f9'; }

.icon-arrow-19:before {
  content: '\e5fa'; }

.icon-arrow-20:before {
  content: '\e5fb'; }

.icon-arrow-21:before {
  content: '\e5fc'; }

.icon-arrow-22:before {
  content: '\e5fd'; }

.icon-arrow-23:before {
  content: '\e5fe'; }

.icon-arrow-24:before {
  content: '\e5ff'; }

.icon-arrow-25:before {
  content: '\e600'; }

.icon-arrow-26:before {
  content: '\e601'; }

.icon-arrow-27:before {
  content: '\e602'; }

.icon-arrow-28:before {
  content: '\e603'; }

.icon-arrow-29:before {
  content: '\e604'; }

.icon-arrow-30:before {
  content: '\e605'; }

.icon-arrow-31:before {
  content: '\e606'; }

.icon-arrow-32:before {
  content: '\e607'; }

.icon-arrow-33:before {
  content: '\e608'; }

.icon-arrow-34:before {
  content: '\e609'; }

.icon-arrow-35:before {
  content: '\e60a'; }

.icon-arrow-36:before {
  content: '\e60b'; }

.icon-arrow-37:before {
  content: '\e60c'; }

.icon-arrow-38:before {
  content: '\e60d'; }

.icon-arrow-39:before {
  content: '\e60e'; }

.icon-arrow-40:before {
  content: '\e60f'; }

.icon-arrow-41:before {
  content: '\e610'; }

.icon-arrow-42:before {
  content: '\e611'; }

.icon-arrow-43:before {
  content: '\e612'; }

.icon-arrow-44:before {
  content: '\e613'; }

.icon-arrow-45:before {
  content: '\e614'; }

.icon-arrow-46:before {
  content: '\e615'; }

.icon-arrow-47:before {
  content: '\e616'; }

.icon-arrow-48:before {
  content: '\e617'; }

.icon-arrow-49:before {
  content: '\e618'; }

.icon-arrow-50:before {
  content: '\e619'; }

.icon-arrow-51:before {
  content: '\e61a'; }

.icon-arrow-52:before {
  content: '\e61b'; }

.icon-arrow-53:before {
  content: '\e61c'; }

.icon-arrow-54:before {
  content: '\e61d'; }

.icon-arrow-55:before {
  content: '\e61e'; }

.icon-arrow-56:before {
  content: '\e61f'; }

.icon-arrow-57:before {
  content: '\e620'; }

.icon-arrow-58:before {
  content: '\e621'; }

.icon-arrow-59:before {
  content: '\e622'; }

.icon-arrow-60:before {
  content: '\e623'; }

.icon-arrow-61:before {
  content: '\e624'; }

.icon-arrow-62:before {
  content: '\e625'; }

.icon-arrow-63:before {
  content: '\e626'; }

.icon-arrow-64:before {
  content: '\e627'; }

.icon-arrow-65:before {
  content: '\e628'; }

.icon-arrow-66:before {
  content: '\e629'; }

.icon-arrow-67:before {
  content: '\e62a'; }

.icon-arrow-68:before {
  content: '\e62b'; }

.icon-arrow-69:before {
  content: '\e62c'; }

.icon-arrow-70:before {
  content: '\e62d'; }

.icon-arrow-71:before {
  content: '\e62e'; }

.icon-arrow-72:before {
  content: '\e62f'; }

.icon-arrow-circle-1:before {
  content: '\e630'; }

.icon-arrow-circle-2:before {
  content: '\e631'; }

.icon-arrow-circle-3:before {
  content: '\e632'; }

.icon-arrow-circle-4:before {
  content: '\e633'; }

.icon-arrow-circle-5:before {
  content: '\e634'; }

.icon-arrow-circle-6:before {
  content: '\e635'; }

.icon-arrow-circle-7:before {
  content: '\e636'; }

.icon-arrow-circle-8:before {
  content: '\e637'; }

.icon-arrow-circle-9:before {
  content: '\e638'; }

.icon-arrow-circle-10:before {
  content: '\e639'; }

.icon-arrow-circle-11:before {
  content: '\e63a'; }

.icon-arrow-circle-12:before {
  content: '\e63b'; }

.icon-arrow-circle-13:before {
  content: '\e63c'; }

.icon-arrow-circle-14:before {
  content: '\e63d'; }

.icon-arrow-circle-15:before {
  content: '\e63e'; }

.icon-arrow-circle-16:before {
  content: '\e63f'; }

.icon-arrow-circle-17:before {
  content: '\e640'; }

.icon-arrow-circle-18:before {
  content: '\e641'; }

.icon-arrow-circle-19:before {
  content: '\e642'; }

.icon-arrow-circle-20:before {
  content: '\e643'; }

.icon-arrow-circle-21:before {
  content: '\e644'; }

.icon-arrow-circle-22:before {
  content: '\e645'; }

.icon-arrow-circle-23:before {
  content: '\e646'; }

.icon-arrow-circle-24:before {
  content: '\e647'; }

.icon-arrow-circle-25:before {
  content: '\e648'; }

.icon-arrow-circle-26:before {
  content: '\e649'; }

.icon-arrow-circle-27:before {
  content: '\e64a'; }

.icon-arrow-circle-28:before {
  content: '\e64b'; }

.icon-arrow-circle-29:before {
  content: '\e64c'; }

.icon-arrow-circle-30:before {
  content: '\e64d'; }

.icon-arrow-delete-1:before {
  content: '\e64e'; }

.icon-arrow-delete-2:before {
  content: '\e64f'; }

.icon-arrow-dot-1:before {
  content: '\e650'; }

.icon-arrow-dot-2:before {
  content: '\e651'; }

.icon-arrow-dot-3:before {
  content: '\e652'; }

.icon-arrow-dot-4:before {
  content: '\e653'; }

.icon-arrow-dot-5:before {
  content: '\e654'; }

.icon-arrow-dot-6:before {
  content: '\e655'; }

.icon-arrow-rectangle-1:before {
  content: '\e656'; }

.icon-arrow-rectangle-2:before {
  content: '\e657'; }

.icon-arrow-rectangle-3:before {
  content: '\e658'; }

.icon-arrow-rectangle-4:before {
  content: '\e659'; }

.icon-arrow-rectangle-5:before {
  content: '\e65a'; }

.icon-arrow-rectangle-6:before {
  content: '\e65b'; }

.icon-arrow-rectangle-7:before {
  content: '\e65c'; }

.icon-arrow-rectangle-8:before {
  content: '\e65d'; }

.icon-arrow-rectangle-9:before {
  content: '\e65e'; }

.icon-arrow-rectangle-10:before {
  content: '\e65f'; }

.icon-arrow-rectangle-11:before {
  content: '\e660'; }

.icon-arrow-rectangle-12:before {
  content: '\e661'; }

.icon-arrow-rectangle-13:before {
  content: '\e662'; }

.icon-arrow-rectangle-14:before {
  content: '\e663'; }

.icon-arrow-rectangle-15:before {
  content: '\e664'; }

.icon-arrow-rectangle-16:before {
  content: '\e665'; }

.icon-arrow-rectangle-17:before {
  content: '\e666'; }

.icon-arrow-rectangle-18:before {
  content: '\e667'; }

.icon-arrow-rectangle-19:before {
  content: '\e668'; }

.icon-arrow-rectangle-20:before {
  content: '\e669'; }

@font-face {
  font-family: 'Assets';
  src: url("../images/jaqen/assets-ios.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Assets';
  src: url("../images/jaqen/assets-android.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

[class^='asset-']:before, [class*=' asset-']:before {
  font-family: 'Assets' !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1; }

.asset-back:before {
  content: '\e000'; }

.asset-list:before {
  content: '\e001'; }

.asset-filter:before {
  content: '\e002'; }

.asset-search:before {
  content: '\e003'; }

.asset-map:before {
  content: '\e004'; }

.asset-checkmark:before {
  content: '\e005'; }

.asset-row-indicator:before {
  content: '\e006'; }

.asset-settings:before {
  content: '\e007'; }

@font-face {
  font-family: 'UI Icons';
  src: url("~@pushpay/app-components/dist/assets/ui-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

[class^='ui-icon-']:before, [class*=' ui-icon-']:before {
  font-family: 'UI Icons' !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1; }

.ui-icon-plus:before {
  content: 'a'; }

.ui-icon-chevron-down:before {
  content: 'b'; }

.ui-icon-chevron-left:before {
  content: 'c'; }

.ui-icon-chevron-right:before {
  content: 'd'; }

.ui-icon-bin:before {
  content: 'e'; }

.ui-icon-arrow-left:before {
  content: 'f'; }

.ui-icon-circle-cross:before {
  content: 'g'; }

.ui-icon-cross:before {
  content: 'h'; }

.ui-icon-magnify:before {
  content: 'i'; }

.ui-icon-tick:before {
  content: 'j'; }

.ui-icon-chevron-up:before {
  content: 'k'; }

.ui-icon-profile:before {
  content: 'l'; }

.ui-icon-profile-filled:before {
  content: 'm'; }

.ui-icon-pencil:before {
  content: 'n'; }

.ui-icon-headphones:before {
  content: 'o'; }

.ui-icon-cog:before {
  content: 'p'; }

.ui-icon-circle-info:before {
  content: 'q'; }

.ui-icon-circle-question:before {
  content: 'r'; }

.ui-icon-doc-edit:before {
  content: 's'; }

.ui-icon-doc-edit-filled:before {
  content: 't'; }

.ui-icon-lock:before {
  content: 'u'; }

.ui-icon-lock-filled:before {
  content: 'v'; }

.ui-icon-bookmark:before {
  content: 'w'; }

.ui-icon-bookmark-filled:before {
  content: 'x'; }

.ui-icon-cal-tick:before {
  content: 'y'; }

.ui-icon-cal-tick-filled:before {
  content: 'z'; }

.ui-icon-bell:before {
  content: '1'; }

.ui-icon-bell-filled:before {
  content: '2'; }

.ui-icon-burst-star:before {
  content: '3'; }

.ui-icon-burst-heart:before {
  content: '4'; }

.ui-icon-mobile:before {
  content: '5'; }

.ui-icon-exclamation:before {
  content: '6'; }

.ui-icon-triangle-exclamation:before {
  content: '7'; }

.filled:before,
.tab-bar .active > .icon:before {
  font-family: streamline-filled !important; }
