@charset 'UTF-8';

@font-face {
  font-family: 'Assets';
  src: url('../images/jaqen/assets-ios.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Assets';
  src: url('../images/jaqen/assets-android.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

[class^='asset-']:before, [class*=' asset-']:before {
  font-family: 'Assets' !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
}

// Assets

.asset-back:before { content: '\e000'; }
.asset-list:before { content: '\e001'; }
.asset-filter:before { content: '\e002'; }
.asset-search:before { content: '\e003'; }
.asset-map:before { content: '\e004'; }
.asset-checkmark:before { content: '\e005'; }
.asset-row-indicator:before { content: '\e006'; }
.asset-settings:before { content: '\e007'; }
