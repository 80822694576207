.flex-cell {
  align-items: center;
  border: 0;
  display: flex;
  height: 44px;
  padding: 0 10px;
  flex: 1;
}

/*
   Clip all text relative to the flex cell.

   The only exception is CSS tooltips, which must escape the flex box and render
   over the top of anything else.
 */
.flex-cell :not(.icon-with-tooltip) {
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}

.icon-with-tooltip {
  position: relative;
  cursor: copy;

  &::before {
    content: attr(data-tip);
    font-size: 12px;
    position: absolute;
    z-index: 999;
    top: 4px;
    left: 34px;
    background: hsla(0, 0%, 0%, 0.8);
    color: #e0e0e0;
    padding: 8px 12px 10px;
    border-radius: 4px;
    line-height: 1.6;
    opacity: 0;
    transition: opacity 0.3s ease-out 0.1s;
    pointer-events: none;
    max-width: 20vw;
    word-wrap: break-word;
  }

  &:hover::before {
    opacity: 1;
    overflow: visible;
    transition: opacity 0.3s ease-out 0.3s;
  }
}

.icon-with-tooltip-left {
  &::before {
    left: auto;
    right: 32px;
    max-width: 20vw;
    word-wrap: break-word;
  }
}

.svg, .svg svg {
  height: 100%;
}
