@import '../styles/global_colors.css';

.button {
  height: 44px;
  line-height: 44px;
  margin-bottom: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 85%);
}

.create {
  composes: button;
  color: var(--blue);
}

.disabled {
  color: hsl(0, 0%, 90%);
  cursor: default;
}

.cancel {
  composes: button;
  color: #d0021b;
}

.teaser {
  position: relative;
  margin: 48px;
  padding: 24px;
  color: white;
  background-color: hsla(0, 0%, 0%, 0.64);
  border-radius: 12px;
  z-index: 2;
}

.light {
  background-color: hsla(0, 0%, 0%, 0.30);
}

.header {
  font-size: 24px;
}

.teaser > span {
  display: block;
  margin-top: 16px;
}

.teaserLink {
  color: white;
  text-decoration: underline;
}

.numberOfUsers {
  line-height: 44px;
  padding: 0 11px;
}

.segment-selector {
  flex-wrap: wrap;
}

.segment-select-item {
  padding: 2px 6px;
}

.segment-item {
  padding: 6px 8px;
  border-radius: 16px;
  outline: none;
  white-space: normal;
  border: 1px solid var(--blue);
}

.segment-icon {
  font-weight: bolder;
  margin-right: 4px;
  font-size: 12px;
}

.segment-item-unselected {
  background-color: var(--background-white-color);
  color: var(--blue);
}

.segment-item-selected {
  background-color: var(--blue);
  color: var(--background-white-color);
}

.segment-item-disabled {
  border-color: var(--grey-90);
  color: var(--grey-90);
}
