@import "../../../styles/global_colors.css";

.bar {
  display: flex;
  flex: 1;
}

.plain {
  composes: bar;
  border-top: 1px solid hsl(0, 0%, 90%);
  height: 72px;
}

.action {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--blue);
  padding: 6px 12px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action + .action {
  border-left: 1px solid hsl(0, 0%, 90%);
}

.actionIcon > :global(.jaqen) {
  height: 34px;
}
