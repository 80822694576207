.highchart-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid $grey-85;
  background-color: $grey-98;

  [data-highcharts-chart] {
    height: 100%;
    width: 100%;
  }
}

.chart-box {
  position: relative;
  min-height: 125px;
  .chart-body-wrapper {
    flex: 1;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .chart-information-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    z-index: 1;
  }
  .summary {
    display: flex;
    svg {
      max-height: 100%;
      flex: 1;
    }
  }
  .title {
    text-transform: uppercase;
    color: $blue;
    padding-top: 10px;
    font-weight: 300;
    b {
      font-weight: 600;
    }
  }
  .additional-message {
    color: $grey-50;
    margin-bottom: 15px;
  }
}

.chart-body-wrapper:hover {
  .chart-information-wrapper {
    z-index: 0;
    @include t;
    .additional-message,
    .summary {
      opacity: 0.1;
    }
    .summary {
      color: $grey-50;
      text-shadow: none;
    }
  }
}

.small-chart-box {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .chart-body-wrapper {
    min-height: 125px;
  }
  .summary {
    margin: 0 15px 15px 0;
    height: calc(100% - 50px);
    align-items: flex-end;
  }
  .title {
    padding-right: 15px;
    text-align: right;
    width: 100%;
    height: 36px;
    font-size: 18px;
    @media (max-width: 1250px), (max-height: 750px) { font-size: 16px; }
    line-height: 24px;
  }
}

.large-chart-box {
  height: 75%;
  .summary {
    height: calc(100% - 100px);
    svg {
      text {
        letter-spacing: 0;
      }
    }
  }
  .title {
    text-align: center;
    height: 36px;
    font-size: 24px;
    line-height: 24px;
  }
  .additional-message {
    text-align: center;
    padding: 0 15px;
    font-style: italic;
    color: $grey-50;
    font-size: 14px;
    white-space: pre;
  }
}

.chart-box.disabled {
  .highchart-wrapper {
    background-color: $grey-90;
    border: 1px solid $grey-75;
  }
  .title,
  .summary,
  .additional-message {
    color: $grey-50;
  }
  .additional-message {
    text-align: right;
    font-style: italic;
    padding: 0 15px;
    width: 100%;
    font-size: 10pt;
  }
}

.large-chart-box.disabled {
  .additional-message {
    text-align: center;
  }
}

.chart-box.disabled:hover {
  .additional-message {
    opacity: 1;
  }
}

.overview-grid {
  @include r('margin-left', 15px, 15px, 15px, 0, 0);
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 50px;
  left: 0;
  right: 0;
  max-height: 100%;
  min-height: 600px;
  .overview-grid-column {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    .overview-grid-row {
      min-height: 125px;
      display: flex;
      height: 25%;
      position: relative;
      padding: 10px;
      .chart-box {
        &:last-child {
          padding-left: 10px;
        }
        &:first-child {
          padding-right: 10px;
        }
      }
    }
  }
}

.score-empty {
  .covering-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    @include flex-center;
  }

  @include spinner(
                  relative, // $position
                  0px, // $topValue
                  'left', // $side
                  0px, // $sideValue
                  96px, // $diameter
                  4px solid rgba(#000000, 0.25), // $borderOn
                  4px solid rgba(#ffffff, 0.5) // $borderOff
  );
}

.highcharts-tooltip > span {
  .tooltip-content {
    background-color: hsla(0, 0%, 0%, 0.5);
    font-family: 'Source Sans Pro';
    text-align: center;
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
    .y-value {
      font-size: 24px;
      font-weight: lighter;
    }
    .x-value {
      font-size: 14px;
    }
  }
}

.timeframe-filter-nav li.custom_time_frame {

  // border: 1;
  // border-color: $transparent;
  box-shadow: 0 -1px 0 $grey-85;
  background-image:
    linear-gradient($grey-85, $transparent),
    linear-gradient($grey-85, $transparent);
  background-size: 1px 100%, 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;

  a, a:hover, a.active.current{
    border: 1px solid $transparent;
    border-top: 0;
    background: none;
  }

  &.expanded {
    background-color: $transparent;
    background-image:
      linear-gradient($grey-85, $transparent),
      linear-gradient($grey-85, $transparent),
      linear-gradient(hsl(0, 0%, 94%), $transparent);
    background-size: 1px 100%, 1px 100%, 100% 100%;
    background-position: 0 0, 100% 0, 0 0;

    a {
      border: 1px solid $grey-85;
      border-top: 0;
      background-color: hsl(0, 0%, 94%);
      color: $grey-50;

      &.active.current{
        background: none;
      }
      &:hover {
        color: $primary;
      }
    }
  }

  &:hover {
    background-color: $transparent;
    background-image:
      linear-gradient($grey-85, $transparent),
      linear-gradient($grey-85, $transparent),
      linear-gradient(hsl(0, 0%, 94%), $transparent);
    background-size: 1px 100%, 1px 100%, 100% 100%;
    background-position: 0 0, 100% 0, 0 0;
  }

  .date_range_fields{
    margin: 10px;

    .row {
      display: flex;
      flex: 1;
      min-height: 24px;
      justify-content: space-between;
      margin-bottom: 5px;

      label {
        color: $grey-50;
        line-height: 26px;
      }
      input {
        width: 196px;
        @media (max-height: 800px) { width: 175px; }
      }
    }
  }
}
