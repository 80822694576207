@import "../variables.scss";

.right {
  position: relative;
  display: flex;
  flex-direction: column;
  @include r('margin', 0, 0, 0, 0, 0 15px);
  @include r('width', 352px, 240px, 240px, 240px, 100%);
}

.tabs {
  display: flex;
  height: 44px;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 1px;
    right: 1px;
    height: 15px;
    background-image: linear-gradient( rgba($grey-98, 1), rgba($grey-98, 0) );
  }

  > div {
    position: relative;
    flex: 1;
    display: flex;
    @include flex-center;
    cursor: pointer;
    color: $grey-75;
    border-top: 1px solid $transparent;
    border-left: 1px solid $transparent;
    border-right: 1px solid $transparent;

    &.active {
      color: $grey-50;
      border-top: 1px solid $grey-85;
      border-left: 1px solid $grey-85;
      border-right: 1px solid $grey-85;
      background-color: $grey-98;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0px;
        height: 1px;
        width: 100%;
        z-index: 1;
        background-color: $grey-98;
      }
    }
  }
}

.tray {
  min-height: 112px;
  padding-bottom: 16px;

  border-top: 1px solid $grey-85;
  background-image:
      linear-gradient($grey-85, $transparent),
      linear-gradient($grey-85, $transparent),
      linear-gradient($grey-98, $transparent);
  background-size: 1px 100%, 1px 100%, 100% 100%;
  background-position: 0 0, 100% 0, 0 0;
  background-repeat: no-repeat;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  overflow-y: auto;
  @include r('position', absolute, absolute, absolute, initial, initial);
  top: 44px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  @include scrollbars;
}

.right {
  @each $type, $color in (
      'container': $primary
    , 'container-ccb': $brown
    , 'unique-container': $purple
    , 'item': $grey-50
    , 'attribute': $aqua
    , 'property': $orange
    , 'message': $primary
    , 'action': $purple
    , 'target': $red
    , 'droppable': $green
    , 'region': $red
    , 'message_type': $green
  ) {
    .#{$type} {
      color: $color;
    }
  }
}

.draggable, .pup {
  display: flex;
  align-items: center;
  cursor: -webkit-grab;
  cursor: -moz-grab;

  .label {
    display: flex;
    @include flex-center;
    font-size: 14px;

    @include ellip;
  }
}

.draggable {
  flex-direction: column;
  width: 96px;
  height: 96px;
  background: white;
  border: $item_row_border;
  margin: 16px 0 0 16px;
  position: relative;

  > .draggable-icon {
    font-size: 48px;
    flex: 1;
    margin-top: 12px;

    svg {
      height: 100%;
    }
  }

  > .draggable-icon-svg {
    height: 100%;
    justify-content: center;
  }

  .label {
    color: $grey-75;
    height: 36px;
  }
}

.draggable-group {
  width: 100%;
  margin-top: 30px;

  &:first-child {
    margin-top: 15px;
  }
}

.draggables-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.draggable-group-title {
  margin-left: 16px;
  margin-right: 16px;
  color: white;
  border-radius: 3px;
  line-height: 25px;
  font-weight: normal;
  text-align: center;
  min-width: 208px;

  &-pushpay {
    background-color: $primary;
  }

  &-ccb {
    background-color: $brown;
  }
}

.droppables {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tray {
    position: relative;
    top: 0px;
    height: auto;
    min-height: 44px;
    overflow: visible;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -16px;
      left: 1px;
      right: 1px;
      height: 15px;
      background-image: linear-gradient( rgba($grey-88, 1), rgba($grey-88, 0) );
    }
  }

  .droppable {
    &:not(.dragging)::before {
      content: attr(data-tip);
      font-size: 12px;
      position: absolute;
      z-index: 999;
      top: 112px;
      left: -32px;
      background: hsla(0, 0%, 0%, 0.8);
      color: #e0e0e0;
      padding: 8px 12px 10px;
      border-radius: 4px;
      line-height: 1.6;
      width: 136px;
      opacity: 0;
      transition: opacity 0.3s ease-out 0.1s;
      pointer-events: none;
    }

    &:not(.dragging):hover::before {
      opacity: 1;
      overflow: visible;
      transition: opacity 0.3s ease-out 0.3s;
    }
  }

  &.dragging {
    &::before {
      background: blue;
    }
  }
}

.pouch-holder {
  position: relative;
  flex: 1;
}

.pouch {
  flex: 1;
  padding: 12px 0 66px 0;

  border-top: 1px solid $grey-75;
  background-image:
      linear-gradient($grey-75, $transparent),
      linear-gradient($grey-75, $transparent),
      linear-gradient($grey-88, $transparent);
  background-size: 1px 100%, 1px 100%, 100% 100%;
  background-position: 0 0, 100% 0, 0 0;
  background-repeat: no-repeat;

  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .warning {
    color: $blue;
    padding: 0 15px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pouched {
  display: flex;
  align-items: center;
  height: 36px;
  background: white;
  border: 1px solid $grey-85;
  margin: 4px 16px 0 16px;

  > .pouched-icon {
    font-size: 16px;
    width: 30px;
    display: flex;
    @include flex-center;
  }

  .label {
    font-size: 14px;
    padding-right: 8px;
    flex: 1;
    color: $grey-50;
    @include ellip;
  }
}

.instructions {
  flex: 1 100%;
  margin-top: 16px;
  display: flex;
  @include flex-center;
  font-style: italic;
  font-size: 12px;
  color: $grey-75;
}

.empty-pouch {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient($transparent, white);

  .button {
    flex: 1;
    display: flex;
    @include flex-center;
    cursor: pointer;
    height: 36px;
    background: white;
    border-radius: 4px;
    font-size: 15px;
    color: $red;
    font-weight: 600;
    letter-spacing: 0.0625em;
    border: 1px solid $red;
    margin: 15px 16px;
  }

  &.disabled {
    .button {
      cursor: default;
      color: $grey-75;
      border: 1px solid $grey-75;
    }
  }
}

.pouch-layer {
  display: flex;

  .draggable.droppable {
    width: 48px;
    height: 48px;
    margin: 4px 0 0 0;
    display: flex;
    @include flex-center;

    > .draggable-icon {
      font-size: 24px;
      flex: 1;
    }
  }
}

.stat-icon {
  position: relative;
  top: 4px;
  font-size: 12px;
  font-weight: bold;
}
