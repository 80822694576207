@import "../../styles/global_colors.css";

.characters-label {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
  color: var(--grey-darker);
}

.text-warning {
  color: var(--orange) !important;
}
