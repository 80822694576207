.header {
  display: flex;
  @include r('flex-direction', row, row, row, row, column);
  @include r('flex-wrap', initial, initial, initial, wrap, initial);
  @include r('justify-content', initial, initial, initial, space-between, initial);
  align-items: center;
  @include r('margin', 0, 0, 0, 0 0 0 15px, 0 0 0 15px);
  z-index: 100;

  ul, li { list-style: none; }
}

// Title with breadcrumb

.breadcrumb {
  position: relative;
  padding: 0;
  align-items: center;
  border-radius: 0;
  background: $transparent;
  font-size: 20px;
  line-height: 90px;
  color: #bfbfbf;

  margin-bottom: 0;
  margin-top: 0;
  @include r('margin-right', 30px, 30px, 30px, 0, 0);
  @include r('margin-left', 30px, 30px, 30px, 0, 0);
  @include r('flex', 1, 1, 1, 1 calc(100% - 240px), 1);
  @include r('width', initial, initial, initial, initial, 100%);
  @include r('order', initial, initial, initial, 1, 2);

  li {
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
  }

  .active, .active > a {
    color: $primary;
    cursor: default;
  }

  li + li:before {
    content: url('../images/ui/nav-arrow-right-small.svg');
    padding: 0 12px;
    position: relative;
    top: 1px;
  }

  a {
    color: #c7c7c7;
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    &:before {
      display: none;
    }
  }
}


// Organization, application, & campus selector

.selector {
  background-color: #ffffff;
  display: flex;
  height: 45px;
  border: 1px solid $primary;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;

  @include r('width', 352px, 240px, 240px, 240px, 100%);

  > .kind {
    position: relative;

    > a {
      color: $primary;
      display: flex;
      @include flex-center;
      height: 45px;
      width: 45px;
      margin: -1px 0 0 -1px;
    }

    > a + div {
      visibility: hidden;
      opacity: 0;
      // TODO: fix iPhone size
      @include r('width', 352px, 240px, 240px, 240px, 100%);
      position: absolute;
      z-index: -10;
      padding: 7px 0 0 0;
      margin: -8px 0 0 -1px;
      border-radius: 4px;
      border: 1px solid $grey-75;
      transition: all 0.2s;
    }

    &:hover > a + div {
      visibility: visible;
      opacity: 1;
    }
  }

  .drilldown {
    position: relative;
    z-index: 1;

    flex: 1;
    margin-top: -1px;
    margin-right: -1px;

    border-radius: 4px;

    &[data-state=open] {
      .selected {
        background-image: url('../images/ui/nav-arrow-up.svg');
      }
    }
  }

  .selected {
    position: relative;
    z-index: 1;

    background-color: hsl(0, 0%, 100%);
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-image: url('../images/ui/nav-arrow-down.svg');

    transition: ease-in background-image 0.2s;

    height: 45px;
    border: 1px solid hsl(0, 0%, 75%);
    border-radius: 4px;

    line-height: 45px;
    cursor: pointer;

    padding: 0 44px 0 10px;

    @include ellip;
  }

  .options {
    position: relative;
    flex: 1;

    overflow: auto;
    max-height: 600px;

    background-color: hsl(0, 0%, 100%);
    border: 1px solid hsl(0, 0%, 75%);
    border-radius: 0 0 4px 4px;
    padding-top: 7px;
    margin-top: -8px;

    li {
      padding: 12px 10px;
    }
  }

  ul {

    li {
      display: flex;
      align-items: center;
      color: $grey-50;
      background-color: hsl(0, 0%, 100%);
      cursor: pointer;
      transition: all 0.2s;

      .icon { color: $grey-75; }

      &:hover {
        background-color: hsl(0, 0%, 98%);
      }

      &.active {
        color: $primary;
        .icon {
          color: $primary;
        }

        &:active, &:focus, &:hover {
          background-color: hsl(0, 0%, 100%);
          cursor: default;
        }
      }
    }

    li:last-child {
      border-radius: 0 0 3px 3px;
    }

    li + li {
      margin: 1px 0 0 0;
      box-shadow: 0 -1px 0 #e7e7e7;
    }
  }
}
