$dropdown-standalone-height: 44px;
$dropdown-standalone-thin-height: 32px;

.rw-dropdown-list {
  .dropdown-standalone {
    background-color: white;

    .rw-btn,
    .rw-input {
      overflow: hidden;
      border: 1px solid $grey-light;
      height: $dropdown-standalone-height;
      line-height: $dropdown-standalone-height;
    }

    .rw-btn {
      border-radius: 0 4px 4px 0;
      border-left: none;
    }

    .rw-input {
      border-right: none;
      border-radius: 4px 0 0 4px;
    }

    &.dropdown-standalone-thin {
      .rw-input,
      .rw-select,
      .rw-btn {
        height: $dropdown-standalone-thin-height;
        line-height: $dropdown-standalone-thin-height;
      }

      + .rw-popup-container {
        top: $dropdown-standalone-thin-height;
      }
    }
  }

  &.rw-state-disabled {
    .dropdown-standalone {
      .rw-btn,
      .rw-input {
        border-color: $grey-lightest;
        cursor: default;
      }

      .rw-input,
      .rw-i {
        color: $grey-lightest;
      }

      .rw-btn:hover > .rw-i {
        color: $grey-lightest;
      }
    }
  }
}
