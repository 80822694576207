@import "../../../styles/global_colors.css";

.container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--border-color);
}

.header {
  background-color: #eee;
  border-bottom: 1px solid var(--border-color);
}

.headerRow {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.title {
  font-weight: 600;
  margin-right: 5px;
  font-size: 14px;
  padding-left: 10px;
}

.information {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.headerLabel {
  font-style: italic;
  padding-left: 10px;
  font-size: 14px;
}

.headerLabelShort {
  width: 300px;
  composes: headerLabel;
}

.headerLabelLong {
  flex: 1;
  composes: headerLabel;
}

.list {
  background-color: var(--background-white-color);
}

.row {
  display: flex;
  border-bottom: var(--item_dashed_border);
  padding: 0 10px;
}

.rowSet {
  background-color: rgba(0, 157, 214, 0.1);
}

.column {
  width: 300px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  color: var(--grey-mid);
  font-size: 15px;
}

.dropdown {
  width: 300px;
}

.columnLong {
  flex: 1;
  padding-left: 10px;
  color: var(--grey-darker);
  font-size: 14px;
  line-height: 22px;
  margin: auto;
}

.label {
  flex: 1;
}

.iconSet {
  color: var(--blue);
  height: 44px;
  width: 44px;
  font-size: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.warning {
  color: var(--orange);
}
