.container {
  position: absolute;
  width: 600px;
  height: 150px;
  background-color: red;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
  display: flex;
  border-radius: 15px;
  box-shadow: 0 0 30px 15px hsla(0, 0%, 0%, 0.05);
  background-color:#eee;
  overflow: hidden;
}

.smallContainer {
  width: 50px;
  height: 50px;
  background-color: #aaa;
  border-radius: 50%;
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.topContainer {
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 10px;
  border-bottom: solid 1px #aaa;
}

.buttonContainer {
  display: flex;
  align-items: flex-end;
  flex: 1;
  justify-content: flex-end;
}

.menuButton {
  height: 15px;
  width: 15px;
  font-size: 6px;
  border-radius: 50%;
  text-align: center;
  padding-top: 4px;
  cursor: pointer;
}

.hideButtonColor {
  background-color: #d0021b;
}

.minimizeButtonColor {
  background-color: #fdbc3f;
  margin-left: 5px;
}

.flagButton {
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
  padding-left: 15px;
}

.envButton {
  border: 1px solid #555;
  border-radius: 15px;
  font-size: 12px;
  margin: 0 5px;
  padding: 0 5px;
  color: #555;
  cursor: pointer;
}

.envButton:hover {
  background-color: white;
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow-y: auto;
  padding: 5px;
}

.row {
  min-height: 20px;
  flex: 1;
  display: flex;
}

.headerRow {
  font-size: 14px;
}

.row:not(.headerRow):hover {
  background-color: white;
}

.nameColumn {
  flex: 4;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding-left: 5px;
}

.column {
  flex: 1;
  align-items: center;
  text-align: center;
}

.longColumn {
  flex: 4;
  text-align: left;
  padding-left: 5px;
}

.switchColumn {
  display: flex;
  justify-content: center;
}

.off {
  color: #d0021b;
}

.on {
  color: #7ed321;
}
