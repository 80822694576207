.single {
  flex: 1;
}

.double {
  flex: 2;
}

.title {
  padding: 0 10px;
  line-height: 43px;
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
}

.keyword {
  composes: title double;
}

.preferred {
  composes: title single;
  text-align: center;
  padding: 0;
}

.assign {
  composes: title double;
}

.message {
  color: gray;
  padding: 20px 15px;
  font-size: 18px;
}

.entitlementRow {
  flex-direction: row;
}

.headingRow {
  margin: 0 15px;
  flex: 1;
  flex-direction: row;
  display: flex;
}
